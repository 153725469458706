import type { IHedgeStrategy, IStrategy } from './../../interfaces/IStrategy';

export const getStrategy = (
  strategy: IStrategy | IHedgeStrategy | null,
): IStrategy | IHedgeStrategy => {
  if (!strategy) {
    return null;
  }
  if (strategy.ticker?.id && !strategy.ticker.name) {
    return {
      ...strategy,
      ticker: { ...strategy?.ticker, name: 'Custom Ticker' },
    };
  }

  return strategy;
};
