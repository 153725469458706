import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'redux/rootReducer';
import TradeSettings from './Components/SettingsComponents/TradeSettings/TradeSettings';
import MenuItem from './Components/MenuItem/MenuItem';
import { CandleSettings } from './Components/SettingsComponents/CandleSettings/CandleSettings';
import { GridSettings } from './Components/SettingsComponents/GridSettings/GridSettings';
import { XAxisSettings } from './Components/SettingsComponents/XAxisSettings/XAxisSettings';
import { YAxisSettings } from './Components/SettingsComponents/YAxisSettings/YAxisSettings';
import { CrosshairSettings } from './Components/SettingsComponents/CrosshairSettings/CrosshairSettings';
import { BackgroundSettings } from './Components/SettingsComponents/BackgroundSettings/BackgroundSettings';
import { HeaderDayPicker } from '../Chart/components/HeaderDayPicker';
import { MobileContentSwitcherMenu } from '../MobileContentSwitcherMenu/MobileContentSwitcherMenu';
import useWindowWidth from 'hooks/useWindowWidth';
import './ChartStyleSettings.scss';
import SettingIcon from 'assets/images/SettingsIcon.svg';
import CandleIcon from 'assets/images/Strategy/CandleIcon.svg';
import StrategyIcon from 'assets/images/Strategy/StrategyIcon.svg';
import GridIcon from 'assets/images/Strategy/GridIcon.svg';
import BackgroundIcon from 'assets/images/Strategy/BackgroundIcon.svg';
import XAxisIcon from 'assets/images/Strategy/XAxisIcon.svg';
import YAxisIcon from 'assets/images/Strategy/YAxisIcon.svg';
import CrosshairIcon from 'assets/images/Strategy/CrosshairIcon.svg';
import CloseSettingsIcon from 'assets/images/Strategy/CloseSettingsIcon.svg';

const ChartStylingSettings = (): JSX.Element => {
  const [isOpened, setOpened] = useState(false);
  const mobilePage = useSelector((state) => state.homePage.mobileContent);
  const isPreview = useSelector((state) => state.chart.isPreview);
  const isMobileVersion = useWindowWidth() < 768;
  const [menuItem, setMenuItem] = useState<
    | 'Candle'
    | 'Strategy'
    | 'Grid'
    | 'Background'
    | 'X-axis'
    | 'Y-axis'
    | 'Crosshair'
    | 'Trade'
    | null
  >(isMobileVersion ? null : 'Candle');
  useEffect(() => {
    if (mobilePage !== 'chart') {
      setOpened(false);
    }
  }, [mobilePage]);

  return (
    <>
      <img
        onClick={() => {
          setOpened(true);
        }}
        src={SettingIcon}
        alt="settings icon"
      />
      {isOpened &&
        createPortal(
          <div
            className={'container'}
            onClick={() => {
              setOpened(false);
            }}
          >
            <div
              className="container__content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="container__content_header">
                <div className="container__content_header_text">
                  {isMobileVersion && menuItem === null ? (
                    'Chart Settings'
                  ) : (
                    <>
                      {' '}
                      <button
                        onClick={() => {
                          setMenuItem(null);
                        }}
                        className="strategy-settings-header__button"
                      >
                        <div className="strategy-settings-header__button_img"></div>Back
                      </button>{' '}
                      {menuItem}
                    </>
                  )}
                  {!isMobileVersion && 'Chart Settings'}
                </div>
                <img
                  className="container__content_header_icon"
                  onClick={() => {
                    setOpened(false);
                  }}
                  src={CloseSettingsIcon}
                  alt="close"
                />
              </div>
              <div className="container__content_main">
                {((isMobileVersion && menuItem === null) || !isMobileVersion) && (
                  <div className="container__content_main_menu">
                    <MenuItem
                      itemName="Candle"
                      itemIcon={CandleIcon}
                      menuItem={menuItem}
                      setMenuItem={setMenuItem}
                    />
                    <MenuItem
                      itemName="Trade"
                      itemIcon={StrategyIcon}
                      menuItem={menuItem}
                      setMenuItem={setMenuItem}
                    />
                    <MenuItem
                      itemName="Grid"
                      itemIcon={GridIcon}
                      menuItem={menuItem}
                      setMenuItem={setMenuItem}
                    />
                    <MenuItem
                      itemName="Background"
                      itemIcon={BackgroundIcon}
                      menuItem={menuItem}
                      setMenuItem={setMenuItem}
                    />
                    <MenuItem
                      itemName="X-axis"
                      itemIcon={XAxisIcon}
                      menuItem={menuItem}
                      setMenuItem={setMenuItem}
                    />
                    <MenuItem
                      itemName="Y-axis"
                      itemIcon={YAxisIcon}
                      menuItem={menuItem}
                      setMenuItem={setMenuItem}
                    />
                    <MenuItem
                      itemName="Crosshair"
                      itemIcon={CrosshairIcon}
                      menuItem={menuItem}
                      setMenuItem={setMenuItem}
                    />
                    {isMobileVersion && !isPreview && <HeaderDayPicker />}
                    {isMobileVersion && <MobileContentSwitcherMenu settingsVersion={true} />}
                  </div>
                )}

                {((isMobileVersion && menuItem !== null) || !isMobileVersion) && (
                  <div className="container__content_main_content">
                    {menuItem === 'Candle' && <CandleSettings />}
                    {menuItem === 'Grid' && <GridSettings />}
                    {menuItem === 'Background' && <BackgroundSettings />}
                    {menuItem === 'X-axis' && <XAxisSettings />}
                    {menuItem === 'Y-axis' && <YAxisSettings />}
                    {menuItem === 'Crosshair' && <CrosshairSettings />}
                    {menuItem === 'Trade' && <TradeSettings />}
                  </div>
                )}
              </div>
              <div className="container__content_footer">
                {/* <div className="container__content_footer_dropdown">dropdown</div>
                <div className="container__content_footer_buttons">
                  <button
                    className="container__content_footer_buttons_back"
                    onClick={() => {
                      setOpened(false);
                    }}
                  >
                    Back
                  </button>
                  <button
                    className="container__content_footer_buttons_create"
                    onClick={() => {
                      setOpened(false);
                    }}
                  >
                    Create new
                  </button>
                </div> */}
              </div>
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

export default ChartStylingSettings;
