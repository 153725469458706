import { ApiServiceBase } from '../../ApiServiceBase';
import { type IAdminUserData } from './types';
import { type Roles } from '../Profile/types';

class ApiAdmin extends ApiServiceBase {
  protected baseUrl = '/api/admin/';

  public async getData(): Promise<IAdminUserData[] | null> {
    try {
      const response = await this.get('get_all');

      return response.data?.data ? response.data.data : null;
    } catch (_) {
      return null;
    }
  }

  public async getStrategies(id: string): Promise<Record<string, unknown> | null> {
    try {
      const response = await this.get('fetch_strategies/' + id);

      return response.data?.data ? response.data.data : null;
    } catch (_) {
      return null;
    }
  }

  public async addRole(userId: string, role: Roles): Promise<boolean> {
    try {
      const response = await this.post('add_role', { id: userId, role });

      return response.data?.data ? response.data.data : false;
    } catch (_) {
      return false;
    }
  }

  public async removeRole(userId: string, role: Roles): Promise<boolean> {
    try {
      const response = await this.post('remove_role', { id: userId, role });

      return response.data?.data ? response.data.data : false;
    } catch (_) {
      return false;
    }
  }

  public async removeAccount(userId: string): Promise<boolean> {
    try {
      const response = await this.post('remove_account', { id: userId });

      return response.data?.data ? response.data.data : false;
    } catch (_) {
      return false;
    }
  }
}

export const apiAdmin = new ApiAdmin();
