import { type FC } from 'react';
import ComeBackArrow from '../../../../../../../assets/images/Strategy/ComeBackArrow.svg';
import AlertIcon from '../../../../../../../assets/icons/AlertIcon.svg';
import './TgBotStyles.scss';
import GTumbler from 'components/GTumbler/GTumbler';
import { useSelector } from '../../../../../../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import { updateSelectedStrategy } from '../../../../../../../redux/strategy/reducer';
import { apiStrategy } from '../../../../../../../services/api/Strategy/ApiStrategy';

interface IProps {
  onClose: () => void;
}
const TgBotAlerts: FC<IProps> = (props) => {
  const isTgConected = !!useSelector((state) => state.auth.telegram);
  const login = useSelector((state) => state.auth.user.login);
  const strategy = useSelector((state) => state.strategy.selectedStrategy);
  const isAlertsOn = strategy?.alert ?? false;
  const isChannelAlertsOn = !!strategy?.channel_alert ?? false;
  const dispatch = useDispatch();

  if (!isTgConected) {
    return (
      <div className="tg-bot-alerts">
        <div className="tg-bot-alerts-header">
          <div className="tg-bot-alerts-header__back" onClick={props.onClose}>
            <img src={ComeBackArrow} alt="<" /> Back
          </div>
        </div>
        <div className="tg-bot-alerts-text">Telegram alerts</div>
        <div className="tg-bot-alerts-not-connected">
          <img src={AlertIcon} alt="alert" />
          <div className="tg-bot-alerts-not-connected__text">
            Connect the telegram bot in the settings
          </div>
          <div className="tg-bot-alerts-not-connected__title">
            After activating your account in our telegram bot, you will be able to set up strategy
            alerts.
          </div>
          <button className="tg-bot-alerts-not-connected__button">Connect telegram bot</button>
        </div>
      </div>
    );
  }
  return (
    <div className="tg-bot-alerts">
      <div className="tg-bot-alerts-header">
        <div className="tg-bot-alerts-header__back" onClick={props.onClose}>
          <img src={ComeBackArrow} alt="<" /> Back
        </div>
      </div>
      <div className="tg-bot-alerts-text">Telegram alerts</div>
      <div className="tg-bot-alerts-row">
        Receive alerts{' '}
        <GTumbler
          name="tgAlertsTumbler"
          checked={isAlertsOn}
          onToggle={(value) => {
            if (strategy) {
              apiStrategy
                .saveStrategy({
                  ...strategy,
                  alert: value,
                })
                .then((result) => {
                  if (result) {
                    dispatch(updateSelectedStrategy({ alert: value }));
                  }
                });
            }
          }}
        />
      </div>
      {login === 'n0tgod' && (
        <div className="tg-bot-alerts-row">
          Channel alerts{' '}
          <GTumbler
            name="tgAlertsTumbler"
            checked={isChannelAlertsOn}
            onToggle={(value) => {
              if (strategy) {
                const channelAlert = value ? { chat_id: 0 } : undefined;

                apiStrategy
                  .saveStrategy({
                    ...strategy,
                    channel_alert: channelAlert,
                  })
                  .then((result) => {
                    if (result) {
                      dispatch(updateSelectedStrategy({ channel_alert: channelAlert }));
                    }
                  });
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TgBotAlerts;
