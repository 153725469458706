export enum Roles {
  User = 0,
  BetaTester = 1,
}

export interface IProfileView {
  id: string;
  roles: Roles[];
  online: boolean | null;
  description: string;
  trades: number;
  volume: number;
  followers: number;
  following: number;
  socials: {
    telegram: string;
    twitter: string;
    instagram: string;
  };
  created_at: string;
}

export interface IProfileSelf extends IProfileView {
  show_online: boolean;
}
