import { Component, type FunctionComponent } from 'react';

interface IState {
  error: null | Error;
}

interface IProps {
  children: JSX.Element;
  ErrorComponent: FunctionComponent<{ error: Error }>;
}

export class ErrorBoundary extends Component<IProps, IState> {
  public state = {
    error: null,
  };

  public static getDerivedStateFromError(error: null | Error): IState {
    return { error };
  }

  public render(): JSX.Element {
    const { error } = this.state;
    const { ErrorComponent, children } = this.props;

    if (error) {
      return <ErrorComponent error={error} />;
    }
    return children;
  }
}
