import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import type { IUserLoginRequest } from '../../../interfaces/IUser';

import type { IAuthFormProps } from '../Auth';
import { Components } from '../Auth';
import GInput, { InputTypes } from '../../../components/GInput/GInput';
import GButton, { ButtonColors, ButtonTypes } from '../../../components/GButton/GButton';

import { setUser } from '../../../redux/auth/reducer';

import { apiAuth } from '../../../services/api/Auth/ApiAuth';
import ReCaptcha from '../../../components/ReCaptcha/ReCaptcha';

const LoginForm: FC<IAuthFormProps> = ({ setComponent }) => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [isLoginError, setIsLoginError] = useState<boolean>(false);
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const login = useRef<HTMLInputElement>();
  const password = useRef<HTMLInputElement>();
  const recaptchaStatus = useRef<boolean>(false);
  const recaptchaToken = useRef<string>();
  const recaptchaRef = useRef<{ reset: () => void }>();

  const onChangeInput = (): void => {
    setDisabled(!login.current?.value || !password.current?.value || !recaptchaStatus.current);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      if (!recaptchaToken.current) {
        console.error('no token provided');
        return;
      }

      const data: IUserLoginRequest = {
        login: login.current.value,
        password: password.current.value,
        recaptcha_token: recaptchaToken.current,
      };

      const user = await apiAuth.login(data);
      if (typeof user === 'object' && user?.login) {
        dispatch(setUser(user));
        navigate('/menu');
      } else {
        if (user === 'Not exist') {
          setIsLoginError(true);
          setIsPasswordError(false);
        } else {
          setIsPasswordError(true);
          setIsLoginError(false);
        }
        recaptchaRef.current.reset();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form
      className="auth-form"
      action="#"
      method="POST"
      onSubmit={(event) => {
        event.preventDefault();

        void (async () => {
          await handleSubmit();
        })();
      }}
    >
      <div className="auth-form-header">
        <span className="auth-form-header__title">Sign In</span>
        <p className="auth-form-header__description">Please enter your login and password</p>
      </div>

      <div className="auth-form-body">
        <GInput
          reference={login}
          type={InputTypes.TEXT}
          name="login"
          placeholder="Login"
          onChange={onChangeInput}
          isError={isLoginError}
          errorText="This login does not exist."
        />

        <GInput
          reference={password}
          type={InputTypes.PASSWORD}
          name="password"
          placeholder="Password"
          errorText="Wrong password, try again."
          onChange={onChangeInput}
          isError={isPasswordError}
        />
      </div>

      <span
        className="auth-form__under-body-link link"
        onClick={() => {
          setComponent(Components.PASSWORD_RECOVERY);
        }}
      >
        Forgot password?
      </span>

      <ReCaptcha
        class="auth-form-captcha"
        ref={recaptchaRef}
        callback={(state, token) => {
          setDisabled(!login.current?.value || !password.current?.value || !state);
          recaptchaStatus.current = state;

          if (state) {
            recaptchaToken.current = token;
          } else {
            recaptchaToken.current = undefined;
          }
        }}
      />

      <div className="auth-form-controls">
        <GButton
          title="Sign In"
          type={ButtonTypes.SUBMIT}
          color={ButtonColors.BLUE}
          bordered={false}
          filled={true}
          disabled={disabled}
        />

        <GButton
          title="Sign Up"
          type={ButtonTypes.BUTTON}
          color={ButtonColors.BLUE}
          bordered={true}
          filled={false}
          onClick={() => {
            setComponent(Components.REGISTRATION);
          }}
        />
      </div>

      <div style={{ display: 'none' }} className="auth-form-oauth">
        <div className="auth-form-oauth__title">Continue with:</div>

        <div className="auth-form-oauth-buttons">
          <div className="auth-form-oauth-buttons__button">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.3191 14.5044L4.48366 17.6232L1.43016 17.6878C0.517613 15.9952 0 14.0587 0 12.0008C0 10.0109 0.483955 8.13429 1.3418 6.48193H1.34246L4.06093 6.98033L5.25179 9.68249C5.00254 10.4091 4.86669 11.1892 4.86669 12.0008C4.86679 12.8817 5.02636 13.7257 5.3191 14.5044Z"
                fill="#FBBB00"
              />
              <path
                d="M23.7906 9.75879C23.9284 10.4847 24.0003 11.2344 24.0003 12.0006C24.0003 12.8598 23.91 13.6978 23.7379 14.5062C23.1537 17.2571 21.6272 19.6592 19.5127 21.359L19.512 21.3583L16.088 21.1836L15.6034 18.1584C17.0065 17.3356 18.103 16.0478 18.6806 14.5062H12.2637V9.75879H18.7742H23.7906Z"
                fill="#518EF8"
              />
              <path
                d="M19.514 21.358L19.5147 21.3586C17.4582 23.0117 14.8457 24.0007 12.0019 24.0007C7.43186 24.0007 3.45854 21.4463 1.43164 17.6873L5.32058 14.5039C6.33401 17.2086 8.94312 19.134 12.0019 19.134C13.3167 19.134 14.5484 18.7785 15.6053 18.1581L19.514 21.358Z"
                fill="#28B446"
              />
              <path
                d="M19.6593 2.76273L15.7717 5.94547C14.6778 5.26172 13.3848 4.86674 11.9995 4.86674C8.87142 4.86674 6.21351 6.88042 5.25085 9.6821L1.34148 6.48155H1.34082C3.33805 2.63086 7.36148 0 11.9995 0C14.9112 0 17.581 1.03719 19.6593 2.76273Z"
                fill="#F14336"
              />
            </svg>
          </div>

          <div className="auth-form-oauth-buttons__button">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1744_4184)">
                <path
                  d="M21.792 18.7035C21.429 19.542 20.9994 20.3139 20.5016 21.0235C19.8231 21.9908 19.2676 22.6605 18.8395 23.0323C18.1758 23.6426 17.4647 23.9552 16.7032 23.973C16.1566 23.973 15.4973 23.8175 14.73 23.5019C13.9601 23.1878 13.2525 23.0323 12.6056 23.0323C11.9271 23.0323 11.1994 23.1878 10.4211 23.5019C9.64153 23.8175 9.01355 23.9819 8.53342 23.9982C7.80322 24.0293 7.07539 23.7078 6.3489 23.0323C5.88521 22.6279 5.30523 21.9345 4.61043 20.9524C3.86498 19.9035 3.25211 18.6872 2.77198 17.3006C2.25777 15.8029 2 14.3526 2 12.9484C2 11.3401 2.34754 9.95284 3.04367 8.79035C3.59076 7.8566 4.31859 7.12003 5.22953 6.57931C6.14046 6.03858 7.12473 5.76304 8.18469 5.74541C8.76467 5.74541 9.52524 5.92481 10.4704 6.27739C11.4129 6.63116 12.0181 6.81056 12.2834 6.81056C12.4817 6.81056 13.154 6.60079 14.2937 6.18258C15.3714 5.79474 16.281 5.63415 17.0262 5.69741C19.0454 5.86037 20.5624 6.65634 21.5712 8.09037C19.7654 9.18456 18.8721 10.7171 18.8898 12.6831C18.9061 14.2145 19.4617 15.4888 20.5535 16.5006C21.0483 16.9703 21.6009 17.3332 22.2156 17.591C22.0823 17.9776 21.9416 18.348 21.792 18.7035ZM17.161 0.480381C17.161 1.68066 16.7225 2.80135 15.8484 3.83865C14.7937 5.0718 13.5179 5.78437 12.1343 5.67193C12.1167 5.52793 12.1065 5.37638 12.1065 5.21713C12.1065 4.06487 12.6081 2.83172 13.4989 1.82345C13.9436 1.31295 14.5092 0.888472 15.1951 0.54986C15.8796 0.216299 16.5269 0.0318332 17.1358 0.000244141C17.1536 0.160702 17.161 0.32117 17.161 0.480365V0.480381Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1744_4184">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
