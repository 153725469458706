import {
  type Dispatch,
  type FC,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/rootReducer';
import { selectTheme } from 'redux/theme/reducer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  deleteSavedtrategy,
  fetchStrategyData,
  removeSelectedStrategy,
  setMergedStrategies,
  setMergedStrategyData,
  setSelectedStrategyId as reduxSetSelectedStrategyId,
  setStrategies,
  updateSelectedStrategy,
  updateStrategyInStrategies,
} from 'redux/strategy/reducer';
import { getNewChartData, setCharts } from 'redux/chart/reducer';
import { type StrategyModes } from 'interfaces/IStrategy';
import {
  type IStrategyAllData,
  type IStrategyAllResponse,
} from 'services/api/Strategy/types/StrategyResponse';
import { apiStrategy } from 'services/api/Strategy/ApiStrategy';

import StrategyEditorHeader from './components/StrategyEditorHeader/StrategyEditorHeader';
import StrategyEditorList from './components/StrategyEditorList/StrategyEditorList';
import StrategyEditorEmpty from './components/StrategyEditorEmpty/StrategyEditorEmpty';
import StrategyEditorModes from './components/StrategyModes/StrategyEditorModes';
import SavedStrategiesContainer from './components/SaveStrategiesContainer/SavedStrategiesContainer';
import PublishStrategyPopUp from './components/PublishStrategyPopUp/PublishStrategyPopUp';
import { Strategy } from './components/Strategy/Strategy';
import { Components } from './Components';
import StrategyLibrary from './components/StrategyLibrary/StrategyLibrary';
import MergedStrategy from './components/StrategyCreateMergedStrategy/MergedStrategy';
import { LoaderOverlay } from 'components/LoaderOverlay';
import { isHedgeModeStrategy, isOneWayModeStrategy } from 'utils/strategy';
import './StrategyEditor.scss';
import ComeBackArrow from 'assets/images/Strategy/ComeBackArrow.svg';
import useWindowWidth from 'hooks/useWindowWidth';
interface IComponentData {
  title: string;
  node: ReactNode;
  listHidden: boolean;
}

interface IProps {
  strategyEnabled: boolean;
  setStrategyEnabled: Dispatch<SetStateAction<boolean>>;
  collapseConstructor?: () => void;
}

const StrategyEditor: FC<IProps> = ({
  strategyEnabled,
  setStrategyEnabled,
  collapseConstructor,
}) => {
  const [strategyNode, setStrategyNode] = useState<Components | null>(null);
  const [isPublishOpen, setIsPublishOpen] = useState(false);
  const [publishData, setPublishData] = useState<{
    _id: string;
    mode: StrategyModes | null;
    library: any;
  } | null>({ _id: '', mode: null, library: null });
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isChartLoading = useSelector((state) => state.chart.isLoading);
  const strategies = useSelector((state) => state.strategy.strategies);
  const [strategiesHistory, setStrategiesHistory] = useState<IStrategyAllResponse>([]);
  const theme = useSelector(selectTheme);
  const strategy = useSelector((state) => state.strategy);

  const { selectedStrategyId, savedStrategies, mergedStrategyData } = strategy;

  const isMobileVersion = useWindowWidth() < 768;
  const dispatch = useDispatch();

  const setSelectedStrategyId = (value: string | null): void => {
    dispatch(reduxSetSelectedStrategyId(value));
  };
  const addStrategyHistory = (data: IStrategyAllData): void => {
    if (strategiesHistory.find((e) => e._id === data._id)) {
      setStrategiesHistory(strategiesHistory.map((e) => (e._id === data._id ? data : e)));
      return;
    }
    setStrategiesHistory([...strategiesHistory, data]);
  };
  const removeStrategyHistory = (data: IStrategyAllData): void => {
    setStrategiesHistory(strategiesHistory.filter((e) => e._id !== data._id));
    if (mergedStrategyData?._id === data._id) {
      dispatch(setMergedStrategyData(null));
    }
  };
  useEffect(() => {
    apiStrategy.getAllMergedStrategies().then((data) => {
      dispatch(setMergedStrategies(data));
    });
  }, []);
  useEffect(() => {
    const strategyId = selectedStrategyId ?? searchParams.get('id');
    if (strategyId !== null) {
      navigate('/?id=' + strategyId);
      if (!strategyEnabled) {
        setStrategyEnabled(true);
      }
      if (strategies.length === 0) {
        apiStrategy.getStrategies().then((result) => {
          dispatch(setStrategies(result));
          if (
            selectedStrategyId === null &&
            searchParams.get('id') &&
            result.find((e) => e._id === searchParams.get('id'))
          ) {
            addStrategyHistory(result.find((e) => e._id === searchParams.get('id')));
            setSelectedStrategyId(searchParams.get('id'));
            setStrategyNode(Components.CONSTRUCTOR);
          }
        });
        apiStrategy.getAllMergedStrategies().then((result) => {
          if (result.length > 0 && result.find((e) => e._id === strategyId)) {
            const strategy = result.find((e) => e._id === strategyId);
            addStrategyHistory({ _id: strategy._id, name: strategy.name, isMerged: true });
            setSelectedStrategyId(strategy._id);
            setStrategyNode(Components.EDIT_MERGED_STRATEGY);
          }
        });
      }
    } else {
      navigate('/');
    }

    if (strategyId !== null && strategies.find(({ _id }) => _id === strategyId)) {
      if (savedStrategies.findIndex((e) => e._id === strategyId) !== -1) {
        const savedStrategy = savedStrategies.find((e) => e._id === strategyId);
        if (isOneWayModeStrategy(savedStrategy)) {
          const { stats, trades, ...strategyWithoutTradesStats } = savedStrategy;
          dispatch(updateSelectedStrategy(strategyWithoutTradesStats));
        }
        if (isHedgeModeStrategy(savedStrategy)) {
          const { stats, shortTrades, longTrades, ...strategyWithoutTradesStats } = savedStrategy;
          dispatch(updateSelectedStrategy(strategyWithoutTradesStats));
        }
      } else {
        // @ts-expect-errors
        dispatch(fetchStrategyData(strategyId));
      }
    } else if (strategyId === null) {
      dispatch(removeSelectedStrategy());
      dispatch(
        setCharts([
          getNewChartData({
            id: '0',
            ticker: {
              type: 'default',
              baseAsset: 'BTC',
              quoteAsset: 'USDT',
            },
          }),
        ]),
      );
    }
  }, [selectedStrategyId]);
  useEffect(() => {
    if (!searchParams.get('id') && selectedStrategyId) {
      dispatch(deleteSavedtrategy(selectedStrategyId));
      removeStrategyHistory(strategiesHistory.find((e) => e._id === selectedStrategyId));
    }
  }, [searchParams]);
  useEffect(() => {
    if (strategyNode === null) {
      dispatch(removeSelectedStrategy());
    }
  }, [strategyNode]);
  useEffect(() => {
    if (strategiesHistory.length === 0 && strategyNode !== Components.SAVED_STRATEGIES) {
      setStrategyNode(null);
      setSelectedStrategyId(null);
      setStrategyNode(Components.SAVED_STRATEGIES);
      navigate('/');
    } else {
      if (selectedStrategyId && !strategiesHistory.find(({ _id }) => _id === selectedStrategyId)) {
        if (
          strategies.findIndex((e) => e._id === strategiesHistory[0]?._id) === -1 &&
          selectedStrategyId === strategy?.mergedStrategyData?._id
        ) {
          setStrategyNode(Components.EDIT_MERGED_STRATEGY);
          setSelectedStrategyId(strategy?.mergedStrategyData?._id ?? null);
        } else {
          setStrategyNode(Components.CONSTRUCTOR);
        }
        setSelectedStrategyId(strategiesHistory[0]?._id ?? null);
        if (strategiesHistory[0]?._id === strategy?.mergedStrategyData?._id) {
          setStrategyNode(Components.EDIT_MERGED_STRATEGY);
        }
      }
    }
  }, [strategiesHistory]);

  const strategyNodes: Record<Components, IComponentData> = {
    [Components.MODES]: {
      title: 'Strategy manager',
      node: (
        <>
          <div
            className="strategy-editor__back"
            onClick={() => {
              setStrategyNode(null);
            }}
          >
            <img src={ComeBackArrow} alt="<" /> Back
          </div>
          <StrategyEditorModes
            addStrategyHistory={addStrategyHistory}
            setStrategyNode={setStrategyNode}
          />
        </>
      ),
      listHidden: true,
    },
    [Components.CONSTRUCTOR]: {
      title: 'Strategy manager',
      node: (
        <Strategy
          updateHistoryName={(id, name) => {
            const history = strategiesHistory.slice();
            const value = history.find((e) => e._id === id);

            if (value) {
              history[history.indexOf(value)] = value;
              setStrategiesHistory(history);
            }
          }}
          isChartLoading={isChartLoading}
          strategyNames={strategies.filter((e) => e._id !== selectedStrategyId).map((e) => e.name)}
        />
      ),
      listHidden: false,
    },
    [Components.SAVED_STRATEGIES]: {
      title: 'Saved strategies',
      node: (
        <SavedStrategiesContainer
          addStrategyHistory={addStrategyHistory}
          removeStrategyHistory={removeStrategyHistory}
          setStrategyNode={setStrategyNode}
          setIsPublishOpen={setIsPublishOpen}
          isPublishOpen={isPublishOpen}
          setPublishData={setPublishData}
        />
      ),
      listHidden: true,
    },
    [Components.LIBRARY]: {
      title: 'Public library',
      node: (
        <>
          <div
            className="strategy-editor__back"
            onClick={() => {
              setStrategyNode(null);
            }}
          >
            <img src={ComeBackArrow} alt="<" /> Back
          </div>
          <StrategyLibrary
            setStrategyNode={setStrategyNode}
            addStrategyHistory={addStrategyHistory}
            setStrategyEnabled={setStrategyEnabled}
          />
        </>
      ),
      listHidden: true,
    },
    [Components.CREATE_MERGED_STRATEGY]: {
      title: 'New Merge',
      node: (
        <>
          <div
            className="strategy-editor__back"
            onClick={() => {
              setStrategyNode(null);
            }}
          >
            <img src={ComeBackArrow} alt="<" /> Back
          </div>{' '}
          <MergedStrategy
            setStrategyNode={setStrategyNode}
            addStrategyHistory={addStrategyHistory}
          />
        </>
      ),
      listHidden: true,
    },
    [Components.EDIT_MERGED_STRATEGY]: {
      title: 'Strategy manager',
      node: (
        <MergedStrategy addStrategyHistory={addStrategyHistory} setStrategyNode={setStrategyNode} />
      ),
      listHidden: true,
    },
  };

  const strategyNodeData = strategyNodes[strategyNode] ?? null;
  const StrategyNode = strategyNodeData ? strategyNodeData.node : null;

  useEffect(() => {
    if (!strategyEnabled) {
      setStrategyNode(null);
      dispatch(removeSelectedStrategy());
    }
  }, [strategyEnabled]);
  useEffect(() => {
    if (strategies.length === 0) {
      apiStrategy.getStrategies().then((result) => {
        dispatch(setStrategies(result));
      });
    }
  }, []);

  // useEffect(() => {
  //   if (strategyEnabled || strategyNode === Components.SAVED_STRATEGIES) {
  //     apiStrategy.getStrategies().then((result) => {
  //       dispatch(setStrategies(result));
  //     });
  //   }

  //   if (strategyNode !== Components.CONSTRUCTOR && selectedStrategyId !== null) {
  //     // setSelectedStrategyId(null);
  //     // dispatch(setBackTest(true));
  //   }
  // }, [strategyEnabled, strategyNode, setStrategies]);

  return (
    <>
      <LoaderOverlay
        isShow={isChartLoading}
        position="absolute"
        imageWidth={isMobileVersion ? 100 : 0}
      />
      {isPublishOpen && (
        <PublishStrategyPopUp
          onClose={() => {
            setIsPublishOpen(false);
          }}
          onPublish={(library) => {
            console.log('library', library);
            setIsPublishOpen(false);
            apiStrategy
              .updateStrategy(publishData._id, publishData.mode, {
                library,
              })
              .then((r) => {
                if (r) {
                  dispatch(
                    updateStrategyInStrategies({
                      _id: publishData._id,
                      library,
                    }),
                  );
                }
              });
          }}
        />
      )}
      <div style={{ backgroundColor: theme.historyBackground }} className="strategy-editor">
        <StrategyEditorHeader
          title={strategyNodeData ? strategyNodeData.title : 'Strategy manager'}
          strategyEnabled={strategyEnabled}
          setStrategyEnabled={setStrategyEnabled}
          collapseConstructor={collapseConstructor}
        />
        <StrategyEditorList
          strategiesHistory={strategiesHistory}
          hidden={strategyNodeData ? strategyNodeData.listHidden : false}
          strategyEnabled={strategyEnabled}
          setStrategyNode={setStrategyNode}
          removeStrategyHistory={removeStrategyHistory}
        />

        <div
          style={{
            justifyContent: strategyNode ? ' ' : 'center',
            backgroundColor: theme.historyBackground,
          }}
          className="strategy-editor__main"
        >
          {strategyNode ? (
            StrategyNode
          ) : (
            <StrategyEditorEmpty
              strategyEnabled={strategyEnabled}
              setStrategyEnabled={setStrategyEnabled}
              setStrategyNode={setStrategyNode}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StrategyEditor;
