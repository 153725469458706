import { type FC, memo, useEffect, useState } from 'react';
import '../History.module.scss';
import BarChart, { type IBarChartData } from '../../Log/components/BarChart/BarChart';
import LinesChart from '../../LinesChart/LinesChart';
import { ChartTime } from 'enums/History';
import { TIMEFRAMES } from 'configs/chart';
import { type IStatistics } from 'interfaces/IStatistics';
import { type ITradeData } from 'interfaces/ITrade';
import { TradesDirections } from 'interfaces/IStrategy';

interface IProps {
  stats: IStatistics;
  containerSize: {
    height: number;
    width: number;
  };
  trades: ITradeData[];
  chartTime: ChartTime;
  currChartTime: number;
  selectedCharTab: string;
  isConstructorOpen?: boolean;
}

const ChartTimeData: Record<ChartTime, number> = {
  [ChartTime.DAY]: 60 * 60000 * 24,
  [ChartTime.MONTH]: 60 * 60000 * 24 * 30,
  [ChartTime.YEAR]: 60 * 60000 * 24 * 365,
};

export const StrategyTesterContent: FC<IProps> = memo(function Table({
  trades,
  chartTime,
  currChartTime,
  selectedCharTab,
  isConstructorOpen,
}) {
  const [data, setData] = useState<IBarChartData[]>([]);

  useEffect(() => {
    if (trades.length === 0) return;

    const tempData: IBarChartData[] = [];

    switch (chartTime) {
      case ChartTime.DAY: {
        const date = new Date(currChartTime);
        date.setUTCDate(1);
        const firstDayOfMonth = date.getTime();
        date.setUTCMonth(date.getUTCMonth() + 1);
        const lastDayOfMonth = new Date(date.getUTCFullYear(), date.getUTCMonth(), 0).getTime();

        for (
          let i = firstDayOfMonth;
          i <= lastDayOfMonth + TIMEFRAMES['1d'];
          i += TIMEFRAMES['1d']
        ) {
          const startDayTime = new Date(i).setUTCHours(0, 0, 0, 0);
          const endDayTime = new Date(i).setUTCHours(23, 59, 59, 999);

          let shortProfit = 0;
          let longProfit = 0;
          let shortPercent = 0;
          let longPercent = 0;
          let count = 0;

          for (let tradeId = trades.length - 1; tradeId >= 0; tradeId--) {
            const trade = trades[tradeId];

            if (
              trade.exit_time &&
              trade.exit_time >= startDayTime &&
              trade.exit_time <= endDayTime
            ) {
              if (trade.side === TradesDirections.Short) {
                shortProfit += trade.pnl;
                shortPercent += trade.pnl_percent;
              } else {
                longProfit += trade.pnl;
                longPercent += trade.pnl_percent;
              }
              count += 1;
            }
          }
          tempData.push({
            time: i,
            date: new Date(i).toLocaleDateString('en-US', { day: 'numeric' }).slice(0, 3),
            shortProfit,
            longProfit,
            shortPercent,
            longPercent,
            count,
          });
        }
        break;
      }

      case ChartTime.MONTH: {
        const firstOfYear = new Date(new Date(currChartTime).getUTCFullYear(), 0, 1).getTime();
        let isFirstYear = true;

        for (
          let i = firstOfYear + ChartTimeData[ChartTime.YEAR] - ChartTimeData[ChartTime.MONTH];
          i >= firstOfYear - ChartTimeData[ChartTime.YEAR];
          i -= ChartTimeData[ChartTime.MONTH]
        ) {
          const startMonthTime = new Date(new Date(i).setUTCDate(0)).setUTCHours(24, 0, 0, 0);
          const endMonthTime = new Date(
            new Date(new Date(i).setUTCMonth(new Date(i).getUTCMonth() + 1)).setUTCDate(0),
          ).setUTCHours(23, 59, 59, 999);

          let shortProfit = 0;
          let longProfit = 0;
          let shortPercent = 0;
          let longPercent = 0;
          let count = 0;

          for (let tradeId = trades.length - 1; tradeId >= 0; tradeId--) {
            const trade = trades[tradeId];
            if (
              trade.exit_time &&
              trade.exit_time >= startMonthTime &&
              trade.exit_time <= endMonthTime
            ) {
              if (trade.side === TradesDirections.Short) {
                shortProfit += trade.pnl;
                shortPercent += trade.pnl_percent;
              } else {
                longProfit += trade.pnl;
                longPercent += trade.pnl_percent;
              }
              count += 1;
            }
          }

          tempData.unshift({
            time: i,
            date: new Date(i).toLocaleString('en-US', { month: 'long' }).slice(0, 3),
            shortProfit,
            longProfit,
            shortPercent,
            longPercent,
            count,
          });

          if (isFirstYear && new Date(i).getUTCMonth() === 0) {
            tempData.unshift({
              time: i - ChartTimeData[ChartTime.MONTH],
              date: new Date(i).getUTCFullYear().toString(),
              shortProfit: 0,
              longProfit: 0,
              shortPercent: 0,
              longPercent: 0,
              count: 0,
            });

            isFirstYear = false;
          }
        }
        break;
      }

      case ChartTime.YEAR: {
        for (let tradeId = trades.length - 1; tradeId >= 0; tradeId--) {
          const trade = trades[tradeId];
          if (!trade.exit_time) continue;
          const year = new Date(trade.exit_time).getUTCFullYear().toString();
          const elem = tempData.find((e) => e.date === year);

          if (elem) {
            if (trade.side === TradesDirections.Short) {
              elem.shortProfit += trade.pnl;
              elem.shortPercent += trade.pnl_percent;
            } else {
              elem.longProfit += trade.pnl;
              elem.longPercent += trade.pnl_percent;
            }
            elem.count += 1;
          } else {
            const data: IBarChartData = {
              time: new Date(parseInt(year), 0, 1).getTime(),
              date: year,
              shortProfit: 0,
              longProfit: 0,
              shortPercent: 0,
              longPercent: 0,
              count: 1,
            };
            if (trade.side === TradesDirections.Short) {
              data.shortProfit += trade.pnl;
              data.shortPercent += trade.pnl_percent;
            } else {
              data.longProfit += trade.pnl;
              data.longPercent += trade.pnl_percent;
            }
            tempData.unshift(data);
          }
        }
        break;
      }
    }

    setData(tempData);
  }, [trades, chartTime, currChartTime, selectedCharTab]);

  return (
    <div className="mainContent">
      <div className="chartBarWrapper">
        {selectedCharTab === 'bars' && <BarChart data={data} />}
        {selectedCharTab === 'line' && <LinesChart isConstructorOpen={isConstructorOpen} />}
      </div>
    </div>
  );
});
