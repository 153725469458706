import { useEffect, useState } from 'react';
import { selectUserAccounts, switchActiveAccount } from 'redux/account/reducer';
import { useDispatch, useSelector } from 'react-redux';
import './Dropdown.scss';
import Arrow from 'assets/images/MenuPage/DropDownIcon.svg';
import AddIcon from 'assets/images/MenuPage/AddIcon.svg';

const MenuAccountDropDown = (): JSX.Element => {
  const dispatch = useDispatch();
  const accounts = useSelector(selectUserAccounts);
  const [selectedAccount, setSelectedAccount] = useState(accounts[0].name);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (selectedAccount !== accounts[0].name) {
      setSelectedAccount(accounts[0].name);
    }
  }, accounts);

  const selectAccountHandler = (name: string): void => {
    // select account and close dropdown
    setSelectedAccount(name);
    setIsOpened(false);
  };
  return (
    <div className="accountDropdownContainer">
      <div
        className={`accountDropdownContainer__accountDropdown ${
          isOpened ? 'accountDropdownContainer__accountDropdown_opened' : ''
        }`}
      >
        <button
          className={`accountDropdownContainer__dropdownItem accountDropdownContainer__selectedAccount ${
            isOpened ? 'accountDropdownContainer__selectedAccount_active' : ''
          }`}
          onClick={() => {
            setIsOpened(!isOpened);
          }}
          disabled={true}
        >
          {selectedAccount}
          <img src={Arrow} alt="arrow" />
        </button>
        {accounts.map((account: { id: string; name: string }) => (
          <button
            className="accountDropdownContainer__dropdownItem"
            key={account.id}
            onClick={() => {
              selectAccountHandler(account.name);
              dispatch(switchActiveAccount(account.id));
            }}
          >
            {account.name}
          </button>
        ))}
        <button className="accountDropdownContainer__dropdownItem">
          <img src={AddIcon} alt="add icon" />
        </button>
      </div>
    </div>
  );
};

export default MenuAccountDropDown;
