import React, { type FC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetchStrategyData,
  setBackTest,
  setSelectedStrategyId,
  updateSelectedMergedStrategy,
} from 'redux/strategy/reducer';
import { setMobileContent } from 'redux/homePage/reducer';
import { useSelector } from 'redux/rootReducer';
import { setIsPreview } from 'redux/chart/reducer';
import './StrategyViewer.scss';
import styles from '../../../HomePage/HomePageStyles.module.css';
import {
  type ImperativePanelGroupHandle,
  type ImperativePanelHandle,
  Panel,
  PanelGroup,
} from 'react-resizable-panels';
import classNames from 'classnames';
import { Charts } from '../../../HomePage/components/ChartContainer/ChartMainContainer';
import { StrategyMetrics } from '../../../HomePage/components/StrategyMetrics/StrategyMetrics';
import { ResizeHandle } from '../../../../components/ResizeHandle';
import { History } from '../../../HomePage/components/History/History';
import { updateChartsForStrategy } from '../../../HomePage/components/StrategyEditor/components/Strategy/Strategy';
import { HomePageMobileTab } from '../../../HomePage/components/HomePageMobileTab/HomePageMobileTab';
import { LoaderOverlay } from '../../../../components/LoaderOverlay';
import { TradesList } from '../../../HomePage/components/TradesList';
import { StrategyTrades } from '../../../HomePage/components/StrategyMetrics/StrategyTrades';
import { MobileContentSwitcherMenu } from '../../../HomePage/components/MobileContentSwitcherMenu/MobileContentSwitcherMenu';
import PreviewMergeStrategyEditor from 'pages/HomePage/components/PreviewMergeStrategyEditor/PreviewMergeStrategyEditor';
import ChartWrapperHeader from 'pages/HomePage/components/Chart/components/ChartWrapper/components/ChartWrapperHeader';
import { apiStrategy } from 'services/api/Strategy/ApiStrategy';
import useWindowWidth from 'hooks/useWindowWidth';

export const StrategyViewer: FC<{ id?: string }> = ({ id: propsId }) => {
  const { id: paramsId } = useParams();
  const strategyId = propsId ?? paramsId;
  const strategyReducer = useSelector((state) => state.strategy);
  const chartsReducer = useSelector((state) => state.chart);
  const [chartShortTicker, chartLongTicker] = useSelector((state) => [
    state.chart.charts[0]?.ticker,
    state.chart.charts[1]?.ticker,
  ]);
  const mobilePage = useSelector((state) => state.homePage.mobileContent);
  const chartContainerRef = useRef<ImperativePanelHandle>(null);
  const historyContainerRef = useRef<ImperativePanelHandle>(null);
  const groupPanelSection = useRef<ImperativePanelGroupHandle>(null);

  const [isConstructorOpen, setConstructorOpen] = React.useState(true);
  const showMobileVersion = useWindowWidth() < 768;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMobileContent('chart'));
    dispatch(setIsPreview(true));
    apiStrategy.getMergeStrategy(strategyId).then((data) => {
      if (data) {
        dispatch(updateSelectedMergedStrategy(data));
        dispatch(setBackTest(true));
      } else {
        setSelectedStrategyId(strategyId);
        //  @ts-expect-errors
        dispatch(fetchStrategyData(strategyId));
      }
    });

    return () => {
      dispatch(setIsPreview(false));
    };
  }, []);
  const collapseConstructor = (): void => {
    groupPanelSection.current.setLayout([100, 0]);
    setConstructorOpen(false);
  };
  const expandConstructor = (): void => {
    setConstructorOpen(true);
    groupPanelSection.current.setLayout([75, 25]);
  };

  useEffect(() => {
    if (strategyReducer.selectedStrategy) {
      if (strategyReducer.selectedStrategy.mode === 1) {
        updateChartsForStrategy(
          dispatch,
          strategyReducer.selectedStrategy.ticker.id
            ? [
                {
                  _id: strategyReducer.selectedStrategy.ticker.id,
                  name: strategyReducer.selectedStrategy.ticker.name,
                  tickers: strategyReducer.selectedStrategy.ticker.tickers.map((e) => ({
                    baseAsset: e.base_asset,
                    quoteAsset: e.quote_asset,
                    exchange: strategyReducer.selectedStrategy.exchange,
                    percent: e.percent,
                    since: 0,
                  })),
                },
              ]
            : [],
          strategyReducer.selectedStrategy,
          chartShortTicker,
          chartLongTicker,
        );
      } else {
        updateChartsForStrategy(
          dispatch,
          strategyReducer.selectedStrategy.ticker.id
            ? [
                {
                  _id: strategyReducer.selectedStrategy.ticker.id,
                  name: strategyReducer.selectedStrategy.ticker.name,
                  tickers: strategyReducer.selectedStrategy.ticker.tickers.map((e) => ({
                    baseAsset: e.base_asset,
                    quoteAsset: e.quote_asset,
                    exchange: strategyReducer.selectedStrategy.exchange,
                    percent: e.percent,
                    since: 0,
                  })),
                },
              ]
            : [],
          strategyReducer.selectedStrategy,
        );
      }

      dispatch(setBackTest(true));
    }
  }, [strategyReducer.selectedStrategy]);
  return (
    <div className="strategy-viewer">
      {!showMobileVersion && (
        <>
          <PanelGroup direction="horizontal" ref={groupPanelSection}>
            <Panel defaultSize={75}>
              <PanelGroup direction="horizontal">
                <>
                  <Panel className={styles.rowContainer} defaultSize={85}>
                    <PanelGroup direction="vertical" ref={groupPanelSection}>
                      <Panel
                        defaultSize={75}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div>
                          {' '}
                          <ChartWrapperHeader
                            expandConstructor={expandConstructor}
                            isConstructorOpen={isConstructorOpen}
                          />
                        </div>
                        <PanelGroup direction="horizontal" style={{ flex: '1' }}>
                          {!chartsReducer.fullScreenMode && (
                            <Panel
                              style={{
                                maxWidth:
                                  chartsReducer.showStatistics && chartsReducer.isShowStatisticsData
                                    ? '390px'
                                    : '0px',
                                marginRight:
                                  chartsReducer.showStatistics && chartsReducer.isShowStatisticsData
                                    ? '10px'
                                    : '0px',
                              }}
                            >
                              <StrategyMetrics />
                            </Panel>
                          )}

                          <Panel>
                            <PanelGroup direction="vertical">
                              <Panel
                                collapsible={true}
                                className={classNames(styles.rowContainer, 'chart_container')}
                                ref={chartContainerRef}
                                defaultSize={65}
                                minSize={30}
                                id="main"
                              >
                                <Charts
                                  setState={(e) => {
                                    // this.setState({ previousChartsPosition: e });
                                  }}
                                  setHistoryContainerSize={(e) => {
                                    // this.setState({ historyContainerSize: e });
                                  }}
                                  isConstructorOpen={isConstructorOpen}
                                  expandConstructor={expandConstructor}
                                  selectedChart={chartsReducer.selectedChart}
                                />
                              </Panel>
                              {!chartsReducer.fullScreenMode && (
                                <>
                                  <ResizeHandle className={styles.resizeHandleHorizontal} />
                                  <Panel
                                    collapsible
                                    defaultSize={25}
                                    maxSize={100}
                                    minSize={10}
                                    ref={historyContainerRef}
                                  >
                                    <History
                                      historyMode={null}
                                      setHistorySectionMode={() => {
                                        console.log('setHistorySectionMode');
                                      }}
                                      containerSize={{ width: 300, height: 100 }}
                                      isConstructorOpen={isConstructorOpen}
                                    />
                                  </Panel>
                                </>
                              )}
                            </PanelGroup>
                          </Panel>
                        </PanelGroup>
                      </Panel>
                    </PanelGroup>
                  </Panel>
                </>
              </PanelGroup>
            </Panel>
            {strategyReducer.selectedMergedStrategy && isConstructorOpen && (
              <>
                <ResizeHandle style={{ maxWidth: '15px' }} />
                <Panel defaultSize={25}>
                  <PreviewMergeStrategyEditor
                    setStrategyEnabled={(value) => {}}
                    strategyEnabled={true}
                    collapseConstructor={collapseConstructor}
                  />
                </Panel>
              </>
            )}
          </PanelGroup>
        </>
      )}
      {showMobileVersion && (
        <div className={styles.mobileContainer}>
          <div
            className={`${styles.chart_Mobcontainer} ${
              chartsReducer.fullScreenMode ? styles.chart_Mobcontainer_full : ''
            }`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: mobilePage !== 'chart' && '0%',
              overflow: mobilePage !== 'chart' ? 'hidden' : '',
            }}
          >
            <div style={{ backgroundColor: 'red' }}>
              {' '}
              <ChartWrapperHeader
                expandConstructor={expandConstructor}
                isConstructorOpen={isConstructorOpen}
              />
            </div>
            <PanelGroup style={{ flex: '1' }} direction="horizontal">
              <Panel
                collapsible={true}
                className={classNames(styles.rowContainer, 'chart_container')}
                ref={chartContainerRef}
                defaultSize={100}
                minSize={100}
                id="main"
              >
                <Charts
                  setState={(e) => {}}
                  setHistoryContainerSize={(e) => {}}
                  selectedChart={chartsReducer.selectedChart}
                />
              </Panel>
            </PanelGroup>
          </div>
          <div
            style={{
              height: mobilePage !== 'home' && '0%',
              paddingTop: mobilePage !== 'home' && '0px',
            }}
            className={classNames(styles.constructorContent, styles.mobileHomePage)}
          >
            <HomePageMobileTab />
          </div>
          <div
            style={{ height: mobilePage !== 'constructor' && '0%' }}
            className={classNames(styles.constructorContent)}
          >
            <PreviewMergeStrategyEditor
              setStrategyEnabled={(value) => {}}
              strategyEnabled={true}
              collapseConstructor={collapseConstructor}
            />
          </div>
          <div
            style={{
              height: mobilePage !== 'metrics' && '0%',
              overflow: window.innerWidth < 768 && 'scroll',
            }}
            className={classNames(styles.constructorContent)}
          >
            <div className={styles.chartInfoSelectorTicker}>
              {strategyReducer.selectedStrategy?.name}
            </div>
            <History
              historyMode={null}
              setHistorySectionMode={() => {}}
              containerSize={{ width: 300, height: 100 }}
              isConstructorOpen={isConstructorOpen}
            />
            <StrategyMetrics />
          </div>
          <div
            style={{
              height: mobilePage !== 'trades' && '0%',
              overflow: mobilePage !== 'trades' && 'hidden',
            }}
            className={classNames(styles.constructorContent)}
          >
            <LoaderOverlay isShow={chartsReducer.isLoading} position={'absolute'} imageWidth={0} />
            <div className={styles.constructorContentHeader}>Trades list</div>
            <TradesList />
            <StrategyTrades />
          </div>
          <MobileContentSwitcherMenu
            excludes={strategyReducer.mergedStrategyData ? ['home'] : ['home', 'constructor']}
          />
        </div>
      )}
    </div>
  );
};
