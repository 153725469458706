import { ApiServiceBase } from '../../ApiServiceBase';
import {
  type IChartConfig,
  type IHedgeStrategy,
  type IStrategy,
  type StrategyModes,
} from '../../../interfaces/IStrategy';
import { type IStatistics } from '../../../interfaces/IStatistics';
import { type ITradeData } from '../../../interfaces/ITrade';

interface IBacktestDto {
  chart: IChartConfig;
  strategy:
    | IStrategy
    | IHedgeStrategy
    | {
        _id?: string;
        name: string;
        balance: number;
        strategies: IStrategy[];
        aggr_rev_martingale: boolean;
        is_infinite_deposit: boolean;
        is_favourite?: number;
      };
}

interface IBaseBackTestResponse {
  stats: IStatistics;
}

export interface IOneWayBackTestResponse extends IBaseBackTestResponse {
  trades: ITradeData[];
}

export interface IHedgeBackTestResponse extends IBaseBackTestResponse {
  trades: [ITradeData[], ITradeData[]];
}

export interface IMergedStrategyStatistics {
  strategy_id: string;
  net_profit: [number, number];
  open_pnl: number | null;
  trades_count: number;
}

export interface IMergedBackTestStrategy {
  _id: string;
  trades: ITradeData[] | [ITradeData[], ITradeData[]];
  mode: StrategyModes;
  name: string;
  ticker: string;
  stats: IMergedStrategyStatistics;
}

export interface IMergedBackTestResponse extends IBaseBackTestResponse {
  strategies: IMergedBackTestStrategy[];
}

class ApiBacktest extends ApiServiceBase {
  protected baseUrl = '/api/backtest/';

  public async backtest(
    data: IBacktestDto,
  ): Promise<(IOneWayBackTestResponse | IHedgeBackTestResponse | IMergedBackTestResponse) | null> {
    try {
      const response = await this.post<
        IBacktestDto,
        IOneWayBackTestResponse | IHedgeBackTestResponse
      >('query', data);

      return response.data ?? null;
    } catch (_) {
      return null;
    }
  }
}

export const apiBacktest = new ApiBacktest();
