import { ApiServiceBase } from '../../ApiServiceBase';

class ApiChartStyles extends ApiServiceBase {
  protected baseUrl = '/api/chart_styles/';

  public async getChartStylingSettings(): Promise<[]> {
    try {
      if (!localStorage.getItem('user')) return [];
      const response = await this.post('get');
      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async refreshChartStylingSettings(category: string): Promise<[]> {
    try {
      const response = await this.post('/reset', { category });
      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async updateChartStylingProperty(data: { key: string; value: unknown }): Promise<[]> {
    if (window.location.href.includes('strategy')) {
      return;
    }
    try {
      const response = await this.post('update', data);
      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }
}

export const apiChartStyles = new ApiChartStyles();
