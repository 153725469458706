import type { FC } from 'react';
import { useState } from 'react';
import './StrategyMergeElement.scss';
import cn from 'classnames';
import GButton, { ButtonColors, ButtonTypes } from '../GButton/GButton';
import SelectedStarIcon from 'assets/images/Strategy/StrategySelectedStar.svg';
import NotSelectedStarIcon from 'assets/images/Strategy/StrategyNotSelectedStar.svg';
import { apiStrategy } from '../../services/api/Strategy/ApiStrategy';
import type { IMergedStrategy } from '../../services/api/Strategy/types/StrategyResponse';
import {
  setMergedStrategies,
  updateMergedStrategyMergedInStrategies,
} from 'redux/strategy/reducer';
import { useDispatch } from 'react-redux';
import useWindowWidth from 'hooks/useWindowWidth';

interface IStrategyElementProps {
  mergedStrategies?: number;
  strategy: IMergedStrategy;
  removeHandler: (id: string) => void;
  editStrategy: () => void;
  hideDotsStar?: boolean;
}

const StrategyMergeElement: FC<IStrategyElementProps> = ({
  mergedStrategies,
  strategy,
  removeHandler,
  editStrategy,
  hideDotsStar,
}) => {
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);
  const isMobileVersion = useWindowWidth() < 768;
  const dispatch = useDispatch();

  const subTextInfo = (): string => {
    if (mergedStrategies) {
      return `${mergedStrategies} strategies`;
    }
  };
  const isFavouritePropertyToggler = (): void => {
    if (strategy.is_favourite) {
      if (mergedStrategies) {
        apiStrategy
          .removeMergedStrategyFavoriteStatus({
            mergedStrategyId: strategy._id,
          })
          .then(() => {
            apiStrategy.getAllMergedStrategies().then((result) => {
              dispatch(setMergedStrategies(result));
            });
          });
      }
    } else {
      if (mergedStrategies) {
        apiStrategy
          .addMergedStrategyFavoriteStatus({
            mergedStrategyId: strategy._id,
          })
          .then(() => {
            apiStrategy.getAllMergedStrategies().then((result) => {
              dispatch(setMergedStrategies(result));
            });
          });
      }
    }
  };
  return (
    <div
      className="strategy-merge-element"
      tabIndex={0}
      onBlur={() => {
        setOptionsVisible(false);
      }}
      onMouseLeave={() => {
        setOptionsVisible(false);
      }}
    >
      <span className="strategy-merge-element__name">
        {strategy.name} <span className="strategy-merge-element__mode">{subTextInfo()}</span>
      </span>
      <div
        className="strategy-merge-element-controls"
        onClick={() => {
          isMobileVersion && setOptionsVisible(!optionsVisible);
        }}
        onMouseLeave={() => {
          isMobileVersion && setOptionsVisible(false);
        }}
      >
        {!hideDotsStar && (
          <div className="strategy-merge-element-controls-misc">
            {!isMobileVersion && (
              <div
                style={strategy.is_favourite && strategy.is_favourite !== 0 ? { opacity: 1 } : {}}
                className="strategy-merge-element-controls-misc-star"
                onClick={isFavouritePropertyToggler}
              >
                <img src={SelectedStarIcon} alt="star" />
              </div>
            )}
            {isMobileVersion && (
              <div
                className="strategy-merge-element-controls-misc-star"
                onClick={isFavouritePropertyToggler}
              >
                {strategy.is_favourite && strategy.is_favourite !== 0 ? (
                  <img src={SelectedStarIcon} alt="star" />
                ) : (
                  <img src={NotSelectedStarIcon} alt="star" />
                )}
              </div>
            )}
            <div
              className="strategy-merge-element-controls-misc-dots"
              onMouseOver={() => {
                setOptionsVisible(true);
              }}
              onClick={() => {
                setOptionsVisible(true);
              }}
            >
              <div className="strategy-merge-element-controls-misc-dots__dot" />
              <div className="strategy-merge-element-controls-misc-dots__dot" />
              <div className="strategy-merge-element-controls-misc-dots__dot" />
            </div>

            <div
              className={cn(
                'strategy-merge-element-controls-misc-options',
                optionsVisible && 'strategy-merge-element-controls-misc-options_visible',
              )}
            >
              <div
                className="strategy-merge-element-controls-misc-options__option"
                onClick={() => {
                  if (typeof strategy.library !== 'number') {
                    apiStrategy
                      .updateMergedStrategy(strategy._id, {
                        library: 0,
                      })
                      .then((r) => {
                        if (r) {
                          dispatch(
                            updateMergedStrategyMergedInStrategies({
                              _id: strategy._id,
                              library: 0,
                            }),
                          );
                        }
                      });
                  } else {
                    apiStrategy
                      .updateMergedStrategy(strategy._id, {
                        library: null,
                      })
                      .then((r) => {
                        if (r) {
                          dispatch(
                            updateMergedStrategyMergedInStrategies({
                              _id: strategy._id,
                              library: null,
                            }),
                          );
                        }
                      });
                  }
                }}
              >
                {typeof strategy.library !== 'number'
                  ? 'Publish to public library'
                  : 'Unpublish from public library'}
              </div>

              <div
                className="strategy-merge-element-controls-misc-options__option"
                onClick={editStrategy}
              >
                Edit
              </div>
              <div
                className="strategy-merge-element-controls-misc-options__option strategy-merge-element-controls-misc-options__option_red"
                onClick={() => {
                  removeHandler(strategy._id);
                }}
              >
                Delete
              </div>
            </div>
          </div>
        )}

        <GButton
          type={ButtonTypes.BUTTON}
          title="Open"
          color={ButtonColors.VIOLET}
          bordered={false}
          onClick={editStrategy}
          filled={true}
          tiny={true}
          height={28}
        />
      </div>
    </div>
  );
};

export default StrategyMergeElement;
