import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'redux/rootReducer';

import LoginForm from './components/LoginForm';
import RegistrationForm from './components/RegistrationForm';
import TwoFactorForm from './components/TwoFactorForm';
import ExchangeApiForm from './components/ExchangeApiForm';
import PasswordRecovery from './components/PasswordRecovery';
import PasswordRecoverySubmit from './components/PasswordRecoverySubmit';

import cn from 'classnames';
import logo from 'assets/images/auth/logo.svg';

import './auth.scss';

export enum Components {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  PASSWORD_RECOVERY = 'password_recovery',
}

export interface IAuthFormProps {
  setComponent: (component: Components) => void;

  goNext: () => void;

  goBack: () => void;
}

interface IComponentData {
  type: string;
  form: FC<IAuthFormProps>;
  classesList: string;
}

const authForms: Record<Components, Array<IComponentData>> = {
  [Components.LOGIN]: [{ type: 'blue', form: LoginForm, classesList: 'auth' }],
  [Components.REGISTRATION]: [
    {
      type: 'purple',
      form: RegistrationForm,
      classesList: 'auth sign-up',
    },
    {
      type: 'red',
      form: TwoFactorForm,
      classesList: 'auth sign-up',
    },
    {
      type: 'emerald',
      form: ExchangeApiForm,
      classesList: 'auth sign-up',
    },
  ],
  [Components.PASSWORD_RECOVERY]: [
    {
      type: 'gray',
      form: PasswordRecovery,
      classesList: 'auth remember-pass',
    },
    {
      type: 'green',
      form: PasswordRecoverySubmit,
      classesList: 'auth remember-pass remember-pass__step-2',
    },
  ],
};

const Auth: FC = () => {
  const [component, setComponent] = useState<Components>(Components.LOGIN);
  const [formKey, setFormKey] = useState<number>(0);

  const navigate = useNavigate();

  const authStore = useSelector((state) => state.auth);

  useEffect(() => {
    if (authStore.user) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    setFormKey(0);
  }, [component]);

  const goNext = (): void => {
    setFormKey((prev) => {
      if (authForms[component][prev + 1]) {
        return prev + 1;
      }

      return prev;
    });
  };

  const goBack = (): void => {
    setFormKey((prev) => {
      if (authForms[component][prev - 1]) {
        return prev - 1;
      }

      return prev;
    });
  };

  const componentData = authForms[component][formKey];

  const AuthForm = componentData.form;

  return (
    <section className={componentData.classesList}>
      <div className="auth-main">
        <div className="auth-main__logo">
          <img src={logo} alt="ALGO LAB LOGO" />
        </div>

        <AuthForm setComponent={setComponent} goNext={goNext} goBack={goBack} />

        {component === Components.REGISTRATION && (
          <div className="auth-main-steps">
            {authForms.registration.map((_, key) => {
              return (
                <Fragment key={key}>
                  <div
                    className={cn(
                      'auth-main-steps__step',
                      key === formKey && 'auth-main-steps__step_active',
                    )}
                  >
                    {key + 1}
                  </div>

                  <div className="auth-main-steps__line" />
                </Fragment>
              );
            })}
          </div>
        )}

        <a
          href="https://algoth.gitbook.io/docs/~/changes/z9TQdzbzvo1s3PVF6yez/terms-of-use"
          target="_blank"
          rel="noreferrer"
          className="auth-main__terms"
        >
          Terms of use
        </a>
      </div>
    </section>
  );
};

export default Auth;
