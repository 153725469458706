import { type FC, useEffect, useState, useRef, type Dispatch, type SetStateAction } from 'react';
import { IndicatorsList, type ISettingsIndicator } from '../../../../../../interfaces/IStrategy';
import './StrategyConditions.scss';
import { STRATEGY_SETTINGS_CONFIG } from '../../../../../../configs/strategy';
import CancelIcon from '../../../../../../assets/images/Strategy/CancelIcon.svg';
import { StrategySelectorXLSettings } from '../StrategySelectorXLSettings/StrategySelectorXLSettings';

interface IProps {
  list: {
    name: IndicatorsList;
    settings: ISettingsIndicator;
  }[][];

  addNewCond: (groupId: number, strategyType?: 'long' | 'short') => void;
  addNewCondGroup: (strategyType?: 'long' | 'short') => void;
  removeCondGroup: (groupId: number, condId: number, strategyType?: 'long' | 'short') => void;
  removeCond: (condId: number, strategyType?: 'long' | 'short') => void;
  changeCond: (
    groupId: number,
    condId: number,
    value: IndicatorsList,
    strategyType?: 'long' | 'short',
  ) => void;
  openSettings: (groupId: number, condId: number, strategyType?: 'long' | 'short') => void;
  strategyType?: 'long' | 'short';
  openedTabCondition?: number;
  setOpenedTabCondition?: Dispatch<SetStateAction<number>>;
}

const strategyList = [
  {
    id: IndicatorsList.RSI_OverLevels,
    name: 'RSI',
    desc: 'Overbought/Oversold Levels',
  },
  // {
  //   id: IndicatorsList.ATR,
  //   name: 'ATR',s
  // },

  {
    id: IndicatorsList.SMA,
    name: 'MA',
    desc: 'Crossover/crossunder',
  },
];

export const StrategyConditions: FC<IProps> = (props) => {
  const [list, setList] = useState(props.list);
  const listToShow = list.map((e, index) => {
    return { ...e, name: `Condition #${index + 1}`, id: index };
  });
  const [selectedCondition, setSelectedCondition] = useState(
    listToShow[props?.openedTabCondition ?? 0],
  );
  const [deletedCondtitionIndex, setDeletedCondtitionIndex] = useState<number | null>(null);
  const scrollableConditionsRef = useRef(null);
  const scrollableConditionRef = useRef(null);
  useEffect(() => {
    setList(props.list);
  }, [props.list]);
  const prevListLengthRef = useRef(props.list.length);
  useEffect(() => {
    props.setOpenedTabCondition && props?.setOpenedTabCondition(selectedCondition.id);
  }, [selectedCondition]);

  useEffect(() => {
    const prevListLength = prevListLengthRef.current;
    const currentListLength = props.list.length;
    if (prevListLength < currentListLength) {
      setSelectedCondition(listToShow[currentListLength - 1]);
    }

    if (prevListLength > currentListLength) {
      if (
        !selectedCondition ||
        (selectedCondition.id !== 0 &&
          (selectedCondition.id === currentListLength ||
            selectedCondition.id === deletedCondtitionIndex))
      ) {
        setSelectedCondition(listToShow[(selectedCondition?.id ?? 1) - 1]);
      }
    }
    prevListLengthRef.current = currentListLength;
  }, [list.length]);

  useEffect(() => {
    const handleWheel = (event, ref): void => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        // eslint-disable-next-line   @typescript-eslint/restrict-plus-operands
        ref.scrollLeft += event.deltaY;
      }
    };
    const scrollableConditions = scrollableConditionsRef.current;
    const scrollableCondition = scrollableConditionRef.current;
    if (scrollableConditions) {
      scrollableConditions.addEventListener('wheel', () => {
        handleWheel(event, scrollableConditions);
      });
      return () => {
        scrollableConditions.removeEventListener('wheel', () => {
          handleWheel(event, scrollableConditions);
        });
      };
    }
    if (scrollableCondition) {
      scrollableCondition.addEventListener('wheel', () => {
        handleWheel(event, scrollableCondition);
      });
      return () => {
        scrollableCondition.removeEventListener('wheel', () => {
          handleWheel(event, scrollableCondition);
        });
      };
    }
  }, []);
  return (
    <div className="strategy-cond">
      <div className="strategy-cond__menu">
        <div ref={scrollableConditionsRef} className="strategy-cond__menu__conditions">
          {listToShow.map((e, index) => {
            return (
              <div
                className={
                  'strategy-cond__menu__condition' +
                  (e.id === selectedCondition?.id ? ' strategy-cond__menu__condition_selected' : '')
                }
                onClick={() => {
                  setSelectedCondition(e);
                }}
                key={e.id}
              >
                {e.name}
                {e.id !== 0 && (
                  <img
                    src={CancelIcon}
                    alt="X"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeletedCondtitionIndex(index);

                      if (props.strategyType) {
                        props.removeCond(index, props.strategyType);
                      } else {
                        props.removeCond(index);
                      }
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
        <button
          className="strategy-cond__menu__add"
          onClick={() => {
            if (props.strategyType) {
              props.addNewCondGroup(props.strategyType);
            } else {
              props.addNewCondGroup();
            }
          }}
        ></button>
      </div>
      {list.map((e, groupId) => {
        if (groupId !== selectedCondition?.id && list.length > 1) {
          return null;
        }
        return (
          <div className="strategy-cond__group" key={groupId}>
            {e.map((strategy, condId) => {
              const list = [];

              let activeName = '';
              let activeDesc = '';

              for (const groups of STRATEGY_SETTINGS_CONFIG) {
                for (const e of groups.conditions) {
                  if (e.id === strategy.name) {
                    activeName = groups.name;
                    activeDesc = e.name;
                    break;
                  }
                }
              }

              strategyList.forEach((e) => {
                if (e.name === activeName && e.id !== strategy.name) {
                  list.push({
                    id: strategy.name,
                    name: e.name,
                    desc: activeDesc ?? '',
                  });

                  return;
                }

                list.push({
                  id: e.id,
                  name: e.name,
                  desc: e.desc ?? '',
                });
              });

              return (
                <div className="strategy-cond__group__elem" key={condId}>
                  {condId > 0 ? (
                    <StrategySelectorXLSettings
                      options={list}
                      activeOption={strategy.name}
                      onChange={(e) => {
                        if (props.strategyType) {
                          props.changeCond(groupId, condId, e, props.strategyType);
                        } else {
                          props.changeCond(groupId, condId, e);
                        }
                      }}
                      openSettingsHandler={() => {
                        props.openSettings(groupId, condId, props.strategyType);
                      }}
                      deleteOption={() => {
                        if (props.strategyType) {
                          props.removeCondGroup(groupId, condId, props.strategyType);
                        } else {
                          props.removeCondGroup(groupId, condId);
                        }
                      }}
                      width={'100%'}
                      height={48}
                    />
                  ) : (
                    <StrategySelectorXLSettings
                      options={list}
                      activeOption={strategy.name}
                      onChange={(e) => {
                        if (props.strategyType) {
                          props.changeCond(groupId, condId, e, props.strategyType);
                        } else {
                          props.changeCond(groupId, condId, e);
                        }
                      }}
                      openSettingsHandler={() => {
                        props.openSettings(groupId, condId, props.strategyType);
                      }}
                      width={'100%'}
                      height={48}
                    />
                  )}
                </div>
              );
            })}

            <button
              className="strategy-cond__group__add"
              onClick={() => {
                if (props.strategyType) {
                  props.addNewCond(groupId, props.strategyType);
                } else {
                  props.addNewCond(groupId);
                }
              }}
            >
              + Add new filter
            </button>

            <div className="strategy-cond__group__line"></div>
          </div>
        );
      })}
    </div>
  );
};
