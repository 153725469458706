import { type IStatistics } from './IStatistics';
import { type ITradeData } from './ITrade';
import { type PublicLibraryType } from '../enums/Strategy';
import { type IChartConfig as IDateConfig } from './IChart';

export enum Exchanges {
  BinanceFutures,
}

export const ExchangesId: Record<Exchanges, string> = {
  [Exchanges.BinanceFutures]: 'binance_usdm',
};

export enum StrategyModes {
  ONE_SIDE = 0,
  HEDGE = 1,
}

export enum IndicatorsList {
  RSI_OverLevels = 0,
  // temporary fix
  // ATR,
  SMA = 2,
  EMA = 3,
  WSMA = 4,
  RSI_CenterCrossOver = 5,
  RSI_CrossMA = 6,
  RSI_DivergenceConvergence = 7,
  RSI_ExtremeReversal = 8,
}

export enum TradesDirections {
  Short,
  Long,
  Both,
}

export enum IndicatorSource {
  Close,
  Low,
  Open,
  High,
}

export enum OrderSizeType {
  Percent,
  Dollar,
  ReverseMartingale,
}
export enum BaseCurrencyType {
  USDT,
}

export enum OrderTypes {
  Market,
  Limit,
}

export enum MarginType {
  Isolated,
  Cross,
}

export enum MartingaleCycleFor {
  Both,
  Separate,
}

export interface IChartConfig {
  date_from: number;
  date_to: number;
  theme?: any;
}

export interface IBaseStrategySettings {
  deposit: number;
  order_size_type: OrderSizeType;
  order_size_martingale_type: OrderSizeType.Dollar | OrderSizeType.Percent;
  // order_size_dollar: number;
  // order_size_percent: number;
  order_size_dollar: {
    long: number;
    short: number;
  };
  order_size_percent: {
    long: number;
    short: number;
  };
  order_size_martingale: number;
  martingale_cycle: number[];
  martingale_cycle_for: MartingaleCycleFor;
  leverage: number;
  type_closing_orders: OrderTypes;
  commission_limit: number;
  commission_market: number;
  base_currency?: BaseCurrencyType;
  infinite_deposit: boolean;
  reentry_cooldown: {
    long: [number, number];
    short: [number, number];
    cancel: number;
    takeprofit: boolean;
    trailing: boolean;
    liquidation: boolean;
  };
}

export interface IStrategySettings {
  direction: TradesDirections;
  stop_loss?: number;
  take_profits: [number, number, number][]; // Quantity Percent, Length
  average?: {
    orders_type: OrderTypes;
    orders: [number, number][]; // Factor, Length
    _id?: string;
  };
  trailing?: {
    stop_percent: number;
    activate_percent: number;
  };
}

export interface IStrategy {
  _id?: string;
  name: string;
  ticker?: {
    base_asset?: string;
    quote_asset?: string;
    name?: string;
    id?: string;
    tickers?: {
      base_asset: string;
      quote_asset: string;
      percent: number;
    }[];
  };
  timeframe: number;
  exchange: Exchanges;
  indicators: {
    name: IndicatorsList;
    settings: ISettingsIndicator;
  }[][];
  settings: IStrategySettings;
  property: IBaseStrategySettings;
  mode: StrategyModes.ONE_SIDE;
  // strategies: IHedgeStrategiesData;
  alert: boolean;
  channel_alert?: { chat_id: number; thread_id?: number };
  library?: PublicLibraryType;
}
export interface SavedIStrategy extends IStrategy {
  stats: IStatistics;
  trades: ITradeData[];
  chartConfig: IDateConfig;
}

export interface IHedgeStrategyProperties {
  indicators: {
    name: IndicatorsList;
    settings: ISettingsIndicator;
  }[][];
  settings: IStrategySettings;
  timeframe: number;
}

interface IHedgeStrategiesData {
  long: IHedgeStrategyProperties;
  short: IHedgeStrategyProperties;
}

export interface IHedgeStrategy {
  _id?: string;
  name: string;
  ticker?: {
    base_asset?: string;
    quote_asset?: string;
    name?: string;
    id?: string;
    tickers?: {
      base_asset: string;
      quote_asset: string;
      percent: number;
    }[];
  };
  exchange: Exchanges;
  property: IBaseStrategySettings;
  strategies: IHedgeStrategiesData;
  mode: StrategyModes.HEDGE;
  alert: boolean;
  channel_alert?: { chat_id: number; thread_id?: number };
  library?: PublicLibraryType;
}
export interface SavedIHedgeStrategy extends IHedgeStrategy {
  stats: IStatistics;
  shortTrades: ITradeData[];
  longTrades: ITradeData[];
  chartConfig: IDateConfig;
}

export interface ISettingsIndicator {
  interval: number;
  smooth_type?: IndicatorsList.SMA | IndicatorsList.EMA | IndicatorsList.WSMA;
  over_sold?: number;
  over_bought?: number;
  center_line?: number;
  source?: IndicatorSource;
  reverse?: boolean;
  ma_percent?: number;
  ma_type?: IndicatorsList.SMA | IndicatorsList.EMA | IndicatorsList.WSMA;
}
