import { IndicatorsList, IndicatorSource, type ISettingsIndicator } from '../interfaces/IStrategy';

export const STRATEGY_SETTINGS: Record<IndicatorsList, ISettingsIndicator> = {
  [IndicatorsList.RSI_OverLevels]: {
    interval: 12,
    over_sold: 30,
    over_bought: 70,
    source: IndicatorSource.Close,
    smooth_type: IndicatorsList.SMA,
    reverse: false,
  },

  // [IndicatorsList.ATR]: {
  //   interval: 14,
  // },

  [IndicatorsList.SMA]: {
    interval: 14,
    source: IndicatorSource.Close,
    reverse: false,
  },

  [IndicatorsList.EMA]: {
    interval: 14,
    source: IndicatorSource.Close,
    reverse: false,
  },

  [IndicatorsList.WSMA]: {
    interval: 14,
    source: IndicatorSource.Close,
    reverse: false,
  },

  [IndicatorsList.RSI_CenterCrossOver]: {
    interval: 12,
    center_line: 50,
    source: IndicatorSource.Close,
    smooth_type: IndicatorsList.SMA,
    reverse: false,
  },

  [IndicatorsList.RSI_CrossMA]: {
    ma_type: IndicatorsList.SMA,
    ma_percent: 0.1,
    interval: 12,
    source: IndicatorSource.Close,
    smooth_type: IndicatorsList.SMA,
    reverse: false,
  },

  [IndicatorsList.RSI_DivergenceConvergence]: {
    interval: 12,
    source: IndicatorSource.Close,
    smooth_type: IndicatorsList.SMA,
    reverse: false,
  },

  [IndicatorsList.RSI_ExtremeReversal]: {
    interval: 12,
    source: IndicatorSource.Close,
    smooth_type: IndicatorsList.SMA,
    reverse: false,
  },
};

export type StrategySettingsValuesConfig = Partial<
  Record<
    keyof ISettingsIndicator,
    [number, number] | [string, ISettingsIndicator[keyof ISettingsIndicator]][]
  >
>;

export const STRATEGY_VALUES: StrategySettingsValuesConfig = {
  interval: [1, 255],
  over_sold: [0, 100],
  over_bought: [0, 100],
  center_line: [0, 100],
  source: [
    ['Open', IndicatorSource.Open],
    ['High', IndicatorSource.High],
    ['Low', IndicatorSource.Low],
    ['Close', IndicatorSource.Close],
  ],
  smooth_type: [
    ['SMA', IndicatorsList.SMA],
    ['EMA', IndicatorsList.EMA],
    ['WSMA', IndicatorsList.WSMA],
  ],
  ma_type: [
    ['SMA', IndicatorsList.SMA],
    ['EMA', IndicatorsList.EMA],
    ['WSMA', IndicatorsList.WSMA],
  ],
  ma_percent: [0, 100],
};

export const STRATEGY_SETTINGS_CONFIG = [
  {
    name: 'RSI',
    conditions: [
      {
        id: IndicatorsList.RSI_OverLevels,
        name: 'Overbought/Oversold Levels',
        longDesc: 'Enter when RSI crosses below OverSold',
        shortDesc: 'Enter when RSI crosses above OverBought',
      },
      {
        id: IndicatorsList.RSI_CrossMA,
        name: 'Moving Average Crossover',
        longDesc: `Enter when the price crosses above MA from below and moves price n% up wherein RSI > 50`,
        shortDesc: `Enter when the price crosses below MA from above and moves price n% up wherein RSI < 50`,
      },
      {
        id: IndicatorsList.RSI_CenterCrossOver,
        name: 'Centerline Crossover',
        longDesc: 'Enter when RSI crosses above RSI centerline from below',
        shortDesc: 'Enter when RSI crosses below RSI centerline from above',
      },
      {
        id: IndicatorsList.RSI_DivergenceConvergence,
        name: 'Divergence/Convergence',
        longDesc: 'When the price makes a new low, but RSI does not',
        shortDesc: 'When price makes a new high, but RSI does not',
      },
      {
        id: IndicatorsList.RSI_ExtremeReversal,
        name: 'Extreme Reversal',
        longDesc: 'Enter when RSI reaches an extremely oversold level and starts turning upwards',
        shortDesc:
          'Enter when RSI reaches an extremely overbought level and starts turning downwards',
      },
    ],
  },
  {
    name: 'MA',
    conditions: [
      {
        id: IndicatorsList.SMA,
        name: 'SMA',
        longDesc: '',
        shortDesc: '',
      },
      {
        id: IndicatorsList.EMA,
        name: 'EMA',
        longDesc: '',
        shortDesc: '',
      },
      {
        id: IndicatorsList.WSMA,
        name: 'WSMA',
        longDesc: '',
        shortDesc: '',
      },
    ],
  },
  // temporary fix
  // {
  //   name: 'ATR',
  //   conditions: [
  //     {
  //       id: IndicatorsList.ATR,
  //       name: 'ATR',
  //       longDesc: '',
  //       shortDesc: '',
  //     },
  //   ],
  // },
];
