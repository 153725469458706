import { type ThemeState } from '../../redux/theme/reducer';
import { type HistorySectionModes } from '../../enums/History';
// import { type IStatistics } from '../../interfaces/IStatistics';

export interface IHomePageState {
  historyContainerSize: {
    height: number;
    width: number;
  };
  historySectionMode: HistorySectionModes | null;
  // stats: IStatistics;
  strategyOn: boolean;
  isFirstCreateStrategy: boolean;
  theme: ThemeState;
  currentStrategyType?: string;
  previousChartsPosition?: number[];
}

export const DefaultHomePageState: IHomePageState = {
  historyContainerSize: {
    height: 500,
    width: 300,
  },
  historySectionMode: null,
  // stats: {
  //   balance: 0,
  //   initial_balance: 0,
  //   net_profit: [0, 0], // USD, Percent
  //   trades_count: [0, 0], // Short, Long
  //   commission_paid: [0, 0], // Short, Long
  //   profit_percent: [0, 0, 0], // Short, Long, Both
  //   pnl_trades: [0, 0],
  //   liquidation_count: [0, 0, 0],
  //   max_drawdown: [
  //     [0, 0], // Short
  //     [0, 0], // Long
  //     [0, 0], // Both
  //   ],
  //   max_balance_drawdown: [0, 0],
  //   stoploss_in_row: [0, 0, 0],
  //   avg_trade_profit: [
  //     [0, 0], // Short
  //     [0, 0], // Long
  //     [0, 0], // Both
  //   ],
  //   open_pnl: [null, null],
  // },
  strategyOn: false,
  isFirstCreateStrategy: true,
  theme: {
    homeBackground: '#0a081a',
    homeHeaderBackground: '#0a081a',
    textColor: 'white',
    chartBackground: '#0c1122',
    chartHeaderBakcground: '#0a081a',
    chartAsideMenuBackground: '#0a081a',
    chartAsideMenuIconFill: 'white',
    historyBackground: 'rgba(19, 14, 45, 1)',
  },
  currentStrategyType: '',
};
