import { useState, type FC } from 'react';
import './CustomMultiLineDropDown.scss';
import Arrow from 'assets/images/Strategy/DropDownTriangle.svg';
interface IProps {
  options: {
    id: unknown;
    name?: string;
    desc?: string;
  }[];
  activeOption: {
    id: unknown;
    name?: string;
    desc?: string;
  };
  onChange: (id: unknown) => void;
  nameFontWeight?: string;
  largeList?: boolean;
  numbersOnly?: boolean;
}
const CustomDropDown: FC<IProps> = ({
  options,
  onChange,
  activeOption,
  nameFontWeight,
  largeList,
  numbersOnly,
}) => {
  const [isOpened, setOpened] = useState(false);
  return (
    <div
      className={'custom-dropdown' + (numbersOnly ? ' custom-dropdown__numbersOnly' : '')}
      tabIndex={0}
      onBlur={() => {
        setOpened(false);
      }}
    >
      <div
        className={
          'custom-dropdown__selected' +
          (numbersOnly ? ' custom-dropdown__selected__numbersOnly' : '')
        }
        onClick={() => {
          setOpened(!isOpened);
        }}
      >
        <div
          style={{
            fontWeight: nameFontWeight,
            fontSize: String(activeOption?.name).length > 18 ? '10px' : '',
          }}
          className="custom-dropdown__selected_name"
        >
          {activeOption.name}
        </div>
        <div
          style={{
            fontSize: activeOption.name ? '' : '12px',
          }}
          className="custom-dropdown__selected_desc"
        >
          {activeOption.desc}
        </div>
        <img
          className={
            'custom-dropdown__selected_arrow' +
            (isOpened ? ' custom-dropdown__selected_arrowOpened' : '')
          }
          src={Arrow}
          alt=">"
        />
      </div>

      {isOpened && largeList && (
        <div className="custom-dropdown__itemsContainer">
          {options.map((option, index) => (
            <div
              style={{
                borderTopLeftRadius: index === 0 ? '4px' : '',
                borderTopRightRadius: index === 0 ? '4px' : '',
              }}
              className={
                'custom-dropdown__item' + (numbersOnly ? ' custom-dropdown__item__numbersOnly' : '')
              }
              key={index}
              onClick={() => {
                setOpened(false);
                onChange(option.id);
              }}
            >
              <div style={{ fontWeight: nameFontWeight }} className="custom-dropdown__item_name">
                {option.name}
              </div>
              <div
                style={{ fontSize: option.name ? '' : '12px' }}
                className="custom-dropdown__item_desc"
              >
                {option.desc}
              </div>
            </div>
          ))}{' '}
        </div>
      )}
      {isOpened &&
        !largeList &&
        options.map((option, index) => (
          <div
            style={{
              borderTopLeftRadius: index === 0 ? '4px' : '',
              borderTopRightRadius: index === 0 ? '4px' : '',
              borderBottomLeftRadius: index === options.length - 1 ? '4px' : '',
              borderBottomRightRadius: index === options.length - 1 ? '4px' : '',
            }}
            className={
              'custom-dropdown__item' + (numbersOnly ? ' custom-dropdown__item__numbersOnly' : '')
            }
            key={index}
            onClick={() => {
              setOpened(false);
              onChange(option.id);
            }}
          >
            <div
              style={{
                fontWeight: nameFontWeight,
                fontSize: String(option?.name).length > 18 ? '10px' : '',
              }}
              className="custom-dropdown__item_name"
            >
              {option.name}
            </div>
            <div
              style={{ fontSize: option.name ? '' : '12px' }}
              className="custom-dropdown__item_desc"
            >
              {option.desc}
            </div>
          </div>
        ))}
    </div>
  );
};

export default CustomDropDown;
