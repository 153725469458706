import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import routes from './configs/routes';
import { useSelector } from './redux/rootReducer';
import { apiProfile } from './services/api/Profile/ApiProfile';

function App(): JSX.Element {
  const router = createBrowserRouter(routes);
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    setInterval(() => {
      if (user?.id) {
        apiProfile.updateOnline();
      }
    }, 60000);
  }, []);
  useEffect(() => {
    if (user?.id) {
      apiProfile.updateOnline();
    }
  }, [user]);

  return <RouterProvider router={router} />;
}
export default App;
