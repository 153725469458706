import type { IProfileStrategy } from '../interfaces/interfaces';
import BinanceIcon from 'assets/images/Profile/BinanceIcon.svg';
import UnLikedIcon from 'assets/images/Profile/UnLikedIcon.svg';
import LikedIcon from 'assets/images/Profile/LikedIcon.svg';
import { apiStrategy } from '../../../services/api/Strategy/ApiStrategy';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'redux/rootReducer';

interface IProps {
  strategy: IProfileStrategy;
  updatePublishedStrategy: (id: string, data: Partial<IProfileStrategy>) => void;
}

const PublishedStrategy = ({ strategy, updatePublishedStrategy }: IProps): JSX.Element => {
  const selfLogin = useSelector((state) => state.auth.user?.login);
  const { id: login } = useParams();
  const isSelfProfile = selfLogin === login;
  const navigate = useNavigate();
  const [copied, setCopied] = useState(false);
  return (
    <div className="publishedStrategiesContainer_list_item" key={strategy.id}>
      <div className="publishedStrategiesContainer_list_item_row">
        <div className="publishedStrategiesContainer_list_item_row_name">{strategy.name}</div>{' '}
        <div className="publishedStrategiesContainer_list_item_row_type">
          {strategy.isPublic ? 'Public' : 'Private'}
        </div>
      </div>
      <div className="publishedStrategiesContainer_list_item_row">
        <div className="publishedStrategiesContainer_list_item_row_mode">
          {strategy.mode === 0 ? 'Hedge' : 'One-way mode'}
        </div>
      </div>
      <div className="publishedStrategiesContainer_list_item_row">
        <div className="publishedStrategiesContainer_list_item_row_pair">
          {' '}
          Pair:<span> {strategy.pair}</span>
        </div>
      </div>
      <div className="publishedStrategiesContainer_list_item_row">
        <div>Exchange:</div>{' '}
        <div className="publishedStrategiesContainer_list_item_row_exchange">
          <img src={BinanceIcon} alt="" />
          {strategy.exchange}
        </div>
      </div>
      <div className="publishedStrategiesContainer_list_item_row">
        <div
          className="publishedStrategiesContainer_list_item_row_likes"
          onClick={() => {
            if (strategy.isLiked) {
              apiStrategy.unlikeStrategy(strategy.id, strategy.mode).then((r) => {
                if (r) {
                  updatePublishedStrategy(strategy.id, {
                    isLiked: false,
                    likes: strategy.likes - 1,
                  });
                }
              });
            } else {
              apiStrategy.likeStrategy(strategy.id, strategy.mode).then((r) => {
                if (r) {
                  updatePublishedStrategy(strategy.id, {
                    isLiked: true,
                    likes: strategy.likes + 1,
                  });
                }
              });
            }
          }}
        >
          <img src={strategy.isLiked ? LikedIcon : UnLikedIcon} alt="" /> {strategy.likes}{' '}
        </div>{' '}
        {!strategy.isPublic ? (
          <div
            className="publishedStrategiesContainer_list_item_row_openButton"
            onClick={() => {
              navigate('/strategy/' + strategy.id);
            }}
          >
            Preview
          </div>
        ) : (
          <div className="publishedStrategiesContainer_list_item_row_buttons">
            <div
              className={`publishedStrategiesContainer_list_item_row_copyButton ${
                isSelfProfile
                  ? 'publishedStrategiesContainer_list_item_row_copyButton_disabled'
                  : ''
              } `}
              onClick={() => {
                if (copied || isSelfProfile) return;
                setCopied(true);
                apiStrategy.copyStrategy(strategy.id, strategy.mode).then((r) => {
                  if (!r) {
                    setCopied(false);
                  }
                });
              }}
            >
              {copied ? 'Copied' : 'Copy'}
            </div>
            <div
              className="publishedStrategiesContainer_list_item_row_openButton2"
              onClick={() => {
                navigate('/strategy/' + strategy.id);
              }}
            >
              Preview
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublishedStrategy;
