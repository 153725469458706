import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import './ColorPicker.scss';
import { COLORS } from 'configs/chart';
import useWindowWidth from 'hooks/useWindowWidth';

export const CustomColorPicker = ({
  onAddColor,
  defaultColor = COLORS.LIGHT_VIOLET,
  showGradient = false,
  name,
  hideGradientAngle = true,
}: {
  onAddColor?: (
    color:
      | string
      | {
          color: string;
          gradientData: {
            isGradient: boolean;
            gradientType: string | undefined;
            degrees: string;
            colors: { value: string; left: number }[];
          };
        },
  ) => void;
  defaultColor: string;
  showGradient?: boolean;
  name?: string;
  hideGradientAngle?: boolean;
}): JSX.Element => {
  const [isOpened, setIsOpened] = useState(false);
  const isMobileVersion = useWindowWidth() < 768;
  const [color, setColor] = useState(defaultColor);
  const [pickerPosition, setPickerPosition] = useState({ top: 0, left: 0 });
  const { getGradientObject } = useColorPicker(color, setColor);
  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    const width = event.currentTarget.clientWidth;
    // const height = event.currentTarget.clientHeight;

    const { clientX } = event;
    const left = clientX - width / 2;
    // const top = clientY - height / 2;
    // if (window.innerHeight - top > 587) {
    //   setPickerPosition({ top, left });
    // } else {
    //   setPickerPosition({ top: window.innerHeight - 700, left });
    // }
    !isMobileVersion && setPickerPosition({ top: window.innerHeight - 700, left });
    if (isOpened) {
      setColor(defaultColor);
    }
    setIsOpened(!isOpened);
  };

  return (
    <div className="mainContainer">
      {name && <div className="name">{name}</div>}
      <div className="colorContainer" onClick={handleClick}>
        {' '}
        {color.includes('gradient') && (
          <div
            className="colorContainer__color"
            style={{
              backgroundImage: `${color}`,
            }}
          ></div>
        )}
        {!color.includes('gradient') && (
          <div
            className="colorContainer__color"
            style={{
              backgroundColor: color,
            }}
          ></div>
        )}
      </div>
      {isOpened &&
        ReactDOM.createPortal(
          <div
            className="pickerContainer"
            onClick={() => {
              setColor(defaultColor);
              setIsOpened(false);
            }}
          >
            <div
              className="picker"
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={
                !isMobileVersion
                  ? {
                      top: pickerPosition.top + 30,
                      left: pickerPosition.left + 30,
                    }
                  : {}
              }
            >
              <ColorPicker
                width={285}
                style={{ backgroundColor: '#130E2D' }}
                value={color}
                hideEyeDrop
                hideColorGuide
                hideGradientAngle={hideGradientAngle}
                hideGradientType
                hideGradientControls={!showGradient}
                hideControls={!showGradient}
                onChange={(e) => {
                  setColor(e.toLowerCase());
                }}
              />
              <div className="buttonContainer">
                <button
                  className="buttonContainer_button"
                  onClick={() => {
                    setIsOpened(false);
                    const gradientData = getGradientObject();
                    if (showGradient) {
                      onAddColor({
                        color,
                        gradientData,
                      });
                    } else {
                      onAddColor(color);
                    }
                  }}
                >
                  Add color
                </button>
              </div>
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
};
