const MenuItem = ({ itemName, itemIcon, menuItem, setMenuItem }): JSX.Element => {
  return (
    <div
      style={{ backgroundColor: menuItem === itemName && '#9747ff' }}
      className="container__content_main_menu_item"
      onClick={() => {
        setMenuItem(itemName);
      }}
    >
      <img src={itemIcon} alt={itemName} />
      {itemName}
    </div>
  );
};

export default MenuItem;
