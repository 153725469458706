import { type SavedIStrategy, type SavedIHedgeStrategy } from './../interfaces/IStrategy';
import {
  type IHedgeStrategy,
  type IStrategy,
  type IStrategySettings,
  StrategyModes,
} from '../interfaces/IStrategy';
import { type IChartDataState } from '../redux/chart/types';
import { useSelector } from '../redux/rootReducer';
import { useChartSelector } from '../redux/chart/reducer';
import _ from 'lodash';

export const compareStrategyHeaderData = (
  strategy: IStrategy | IHedgeStrategy | SavedIStrategy | SavedIHedgeStrategy,
  chartData: IChartDataState,
  selectedChartId: string,
): boolean => {
  if (chartData.ticker.type === 'custom') {
    if (strategy.mode === StrategyModes.ONE_SIDE) {
      return (
        chartData.ticker._id !== strategy.ticker?.id ||
        chartData.exchange !== strategy.exchange ||
        chartData.timeframe !== strategy.timeframe ||
        chartData.ticker._id !== strategy.ticker?.id
      );
    }
    if (strategy.mode === StrategyModes.HEDGE) {
      return (
        chartData.timeframe !== strategy.strategies.long.timeframe ||
        chartData.timeframe !== strategy.strategies.short.timeframe ||
        chartData.ticker._id !== strategy.ticker?.id
      );
    }
  } else {
    let returnValue = 0;
    if (chartData.ticker.baseAsset !== strategy.ticker?.base_asset) {
      returnValue++;
    }
    if (chartData.ticker.quoteAsset !== strategy.ticker?.quote_asset) {
      returnValue++;
    }
    if (strategy.mode === StrategyModes.ONE_SIDE) {
      chartData.timeframe !== strategy.timeframe && returnValue++;
    } else {
      if (selectedChartId === '1' && chartData.timeframe !== strategy.strategies.long.timeframe) {
        returnValue++;
      }
      if (selectedChartId === '0' && chartData.timeframe !== strategy.strategies.short.timeframe) {
        returnValue++;
      }
    }
    return returnValue > 0;
  }
};

export const getActiveSettingsStrategy = (): IStrategySettings => {
  const { selectedChart } = useSelector((state) => state.chart);
  const chartType = useChartSelector(selectedChart).chartType;
  const strategy = useSelector((state) => state.strategy);
  return strategy.selectedStrategy.mode === StrategyModes.HEDGE
    ? chartType === 'LONG'
      ? strategy.selectedStrategy.strategies.long.settings
      : strategy.selectedStrategy.strategies.short.settings
    : strategy.selectedStrategy.settings;
};

export const isOneWayModeStrategy = (obj: any): obj is IStrategy => {
  return typeof obj?.timeframe === 'number';
};
export const isHedgeModeStrategy = (obj: any): obj is IHedgeStrategy => {
  return obj?.strategies;
};

export const useIsSavedEqualToSelected = (
  selectedStrategy: IStrategy | IHedgeStrategy,
  savedStrategyToCompare: SavedIStrategy | SavedIHedgeStrategy,
): boolean => {
  if (isOneWayModeStrategy(savedStrategyToCompare)) {
    const { stats, trades, ...strategyWithoutTradesStats } = savedStrategyToCompare;
    // const getDifferences = (obj1, obj2): any => {
    //   const changes = {};

    //   const compare = (o1, o2, basePath = ''): void => {
    //     Object.keys(o1).forEach((key) => {
    //       const path = basePath ? `${basePath}.${key}` : key;
    //       if (!_.isEqual(o1[key], o2[key])) {
    //         if (_.isObject(o1[key]) && _.isObject(o2[key])) {
    //           compare(o1[key], o2[key], path);
    //         } else {
    //           changes[path] = { before: o1[key], after: o2[key] };
    //         }
    //       }
    //     });
    //   };

    //   compare(obj1, obj2);
    //   compare(obj2, obj1);

    //   return changes;
    // };
    // const differences = getDifferences(strategyWithoutTradesStats, selectedStrategy);
    return _.isEqual(strategyWithoutTradesStats, selectedStrategy);
  }

  if (isHedgeModeStrategy(savedStrategyToCompare)) {
    const { stats, shortTrades, longTrades, ...strategyWithoutTradesStats } =
      savedStrategyToCompare;
    // const getDifferences = (obj1, obj2): any => {
    //   const changes = {};

    //   const compare = (o1, o2, basePath = ''): void => {
    //     Object.keys(o1).forEach((key) => {
    //       const path = basePath ? `${basePath}.${key}` : key;
    //       if (!_.isEqual(o1[key], o2[key])) {
    //         if (_.isObject(o1[key]) && _.isObject(o2[key])) {
    //           compare(o1[key], o2[key], path);
    //         } else {
    //           changes[path] = { before: o1[key], after: o2[key] };
    //         }
    //       }
    //     });
    //   };

    //   compare(obj1, obj2);
    //   compare(obj2, obj1);

    //   return changes;
    // };
    // const differences = getDifferences(strategyWithoutTradesStats, selectedStrategy);
    return _.isEqual(strategyWithoutTradesStats, selectedStrategy);
  }

  return false;
};
export function areStrategiesArraysEqual(array1: string[], array2: string[]): boolean {
  if (!array1 || !array2) return false;
  if (array1.length !== array2.length) {
    return false;
  }
  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();
  const string1 = JSON.stringify(sortedArray1);
  const string2 = JSON.stringify(sortedArray2);

  return string1 === string2;
}
