import { type FC } from 'react';
import { chartEditorIcons } from 'configs/chartEditorIcons';
import { AsidePopper } from './AsidePopper';
import { type ChartEditorFigure } from 'enums/ChartEditorFigure';
import styles from '../ChartWrapper.module.css';
import classNames from 'classnames';

interface IProps {
  editorKey: ChartEditorFigure;
  selectedEditor: ChartEditorFigure | null;
  selectEditor: (prevEditor: ChartEditorFigure) => (newEditor: ChartEditorFigure) => void;
  selectCurrentEditor: (currEditor: ChartEditorFigure) => () => void;
}

export const AsideInteractiveItem: FC<IProps> = (props) => {
  const { editorKey, selectEditor, selectCurrentEditor, selectedEditor } = props;
  const { icon: Component, children } = chartEditorIcons[editorKey];
  const isSelected = selectedEditor === editorKey;
  return (
    <li className={styles.asideListItem}>
      <div
        className={classNames(styles.asideIconWrapper, {
          [styles.asideIconWrapperSelected]: isSelected,
        })}
        onClick={selectCurrentEditor(editorKey)}
      >
        {<Component className={styles.svg} />}
      </div>
      <AsidePopper editorKeys={children} onUpdateEditor={selectEditor(editorKey)} />
    </li>
  );
};
