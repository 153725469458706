import { type Dispatch, type SetStateAction } from 'react';
import { type MenuItemType } from '../../UserSettings';

const MenuItem = ({
  itemName,
  itemIcon,
  menuItem,
  setMenuItem,
  disabled,
}: {
  itemName: MenuItemType;
  itemIcon: string;
  menuItem: string;
  setMenuItem: Dispatch<SetStateAction<MenuItemType>>;
  disabled?: boolean;
}): JSX.Element => {
  return (
    <div
      style={{
        backgroundColor: menuItem === itemName && '#9747ff',
        fontWeight: menuItem === itemName && 700,
      }}
      className={
        'container__content_main_menu_item' +
        (disabled ? ' container__content_main_menu_item_disabled' : '')
      }
      onClick={() => {
        !disabled && setMenuItem(itemName);
      }}
    >
      <img src={itemIcon} alt={itemName} />
      {itemName}
    </div>
  );
};

export default MenuItem;
