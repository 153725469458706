import './Info.scss';
import { type FC } from 'react';
import { useSelector } from 'redux/rootReducer';
import { selectTheme } from 'redux/theme/reducer';
import { type IStatistics } from 'interfaces/IStatistics';
import { STATS_ELEMENTS } from './pages';

export const Info: FC<IStatistics> = (props) => {
  const theme = useSelector(selectTheme);

  return (
    <div className="info">
      {STATS_ELEMENTS.map((Element, i) => (
        <Element key={i} data={props} theme={theme} />
      ))}
    </div>
  );
};
