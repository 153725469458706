import { TradesDirections } from './../interfaces/IStrategy';
export const TIMEFRAMES: Record<string, number> = {
  '1s': 1000,
  '5s': 5000,
  '15s': 15000,
  '30s': 30000,
  '1m': 60000,
  '3m': 3 * 60000,
  '5m': 5 * 60000,
  '15m': 15 * 60000,
  '30m': 30 * 60000,
  '1h': 60 * 60000,
  '4h': 4 * 60 * 60000,
  '1d': 24 * 60 * 60000,
  '1w': 7 * 24 * 60 * 60000,
};

export const GET_TIMEFRAME_NAME = (tf: number): string | null => {
  for (const i in TIMEFRAMES) {
    if (TIMEFRAMES[i] === tf) return i;
  }
  return null;
};
export const COLORS = {
  VIOLET: 'rgba(151, 71, 255, 1)',
  LIGHT_VIOLET: '#694EF0',
  GREEN: '#00F90A',
  RED: '#F90000',
};
export const TRADES_COLORS = {
  BACKGROUND: {
    [TradesDirections.Short]: 'rgba(255,28,28,0.35)',
    [TradesDirections.Long]: 'rgba(92,254,0,0.35)',
  },
  TRIANGLE: '#d501f8',
  STOPLOSS_LINE: 'rgb(183,7,7)',
  TRAILING_STOPLOSS_LINE: '#FFB800',
  LIQUIDATION_LINE: '#d501f8',
  OPEN_TRADE: '#2962ff',
  AVERAGE_LINE: '#FF8A00',
  TAKEPROFIT_LINE: 'rgb(68,183,7)',
  center_line: '#323232',
};
export const TRADES_CONFIG = {
  TRIANGLE: {
    width: 12.5,
    lineWidth: 4,
    lineHeight: 10,
  },
  STATS: {
    width: 244,
    y: 25,
    padding: 12.5,
  },
};
