import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ProfilePageMobileTab.module.css';
import classNames from 'classnames';
import NavigationArrow from 'assets/images/NavigationArrow.svg';
import BellIcon from 'assets/images/BellIcon.svg';
import DocsIcon from 'assets/images/Profile/DocsIcon.svg';
import ProfileMenuIcon from 'assets/images/Profile/ProfileMenuIcon.svg';

import LeaderProfileIcon2 from 'assets/images/Profile/LeaderProfileIcon2.svg';
import TradeProfileIcon from 'assets/images/Profile/TradeProfileIcon.svg';
import { useDispatch } from 'react-redux';
import { setMobileContent } from 'redux/homePage/reducer';
import { ProfileContentSwitcherMenu } from '../ProfileContentSwitcherMenu/ProfileContentSwitcherMenu';
import { setProfilePage } from 'redux/profile/reducer';

export const ProfilePageMobileTab: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <div className={styles.mobileHomePageContainer}>
      <div onClick={() => {}} className={styles.mobileHomePageHeader}>
        <Link className={styles.mobileHomePageHeaderText} to="/menu">
          <img src={NavigationArrow} alt="navigationArrow" />
          <div>Main menu</div>
        </Link>
        <img src={BellIcon} alt="navigationArrow" />
      </div>
      <div
        className={styles.mobileHomePageHeaderNavItem}
        onClick={() => {
          dispatch(setProfilePage('profile'));
        }}
      >
        <div className={styles.mobileHomePageHeaderNavItemName}>
          {' '}
          <img src={ProfileMenuIcon} alt="ProfileMenuIcon" />
          Profile
        </div>
        <img src={NavigationArrow} alt="navigationArrow" />
      </div>
      <Link to={`/`}>
        <div
          className={styles.mobileHomePageHeaderNavItem}
          onClick={() => {
            dispatch(setMobileContent('chart'));
          }}
        >
          <div className={styles.mobileHomePageHeaderNavItemName}>
            {' '}
            <img src={TradeProfileIcon} alt="chart" />
            Trading Panel
          </div>
          <img src={NavigationArrow} alt="navigationArrow" />
        </div>
      </Link>
      <div
        className={styles.mobileHomePageHeaderNavItem}
        onClick={() => {
          dispatch(setMobileContent('metrics'));
        }}
      >
        <div className={classNames(styles.mobileHomePageHeaderNavItemName)}>
          {' '}
          <img src={LeaderProfileIcon2} alt="" />
          Leaderboard
        </div>
        <img src={NavigationArrow} alt="navigationArrow" />
      </div>
      <a href="https://algoth.gitbook.io/docs" className={styles.mobileHomePageHeaderNavItem}>
        <div className={classNames(styles.mobileHomePageHeaderNavItemName)}>
          {' '}
          <img src={DocsIcon} alt="" />
          Docs
        </div>
        <img src={NavigationArrow} alt="navigationArrow" />
      </a>
      <ProfileContentSwitcherMenu fixed />
    </div>
  );
};
