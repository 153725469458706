import { type FC } from 'react';
import CustomNumberInput from '../CustomElements/CustomInput/CustomInput';
import ClosedLock from 'assets/images/Strategy/ClosedLock.svg';
import RedClosedLock from 'assets/images/Strategy/ClosedLockRed.svg';
import OpenedLock from 'assets/images/Strategy/OpenedLock.svg';

interface IProps {
  prefix: string;
  secondColumn: string;
  list: [number, number, number][] | [number, number][];
  changeData: (e: string, takeIndex: number, dataIndex: number, min: number, max: number) => void;
  lockData?: boolean[];
  toggleLockValue?: (i: number) => void;
  locksHighlighted?: boolean;
  highLightLocks?: () => void;
}

export const StrategyTypeSettingsTable: FC<IProps> = (props) => {
  return (
    <div className="strategy-type-settings__content__take-profits">
      {props.list.map((e: number[], i: number) => (
        <div
          key={i}
          style={props.prefix.includes('DCA') ? { justifyContent: 'space-between' } : {}}
          className="strategy-type-settings__content__take-profits__item"
        >
          <div className="strategy-type-settings__content__take-profits__itemHeader">
            {' '}
            {props.prefix}
            {i + 1}
          </div>
          <div
            className={
              props.prefix.includes('DCA')
                ? 'strategy-type-settings__content__take-profits__DCAInputs'
                : 'strategy-type-settings__content__take-profits__TPInputs'
            }
          >
            <div className="strategy-type-settings__content__take-profits__itemInput">
              <span>Length(%)</span>
              <CustomNumberInput
                value={e[0]}
                step={0.1}
                errorValue={!e[0] || e[0] < 0.01}
                onChange={(inputValue) => {
                  props.changeData(inputValue.target.value, i, 0, 0, 1000);
                }}
              />
            </div>
            <div className="strategy-type-settings__content__take-profits__itemInput">
              <span>{props.secondColumn}</span>
              <CustomNumberInput
                value={Number(e[1].toFixed(3))}
                step={0.1}
                onChange={(e) => {
                  const falseIndexesNoTakeIndex = props.lockData.reduce((acc, value, index) => {
                    if (value && index !== i) {
                      acc.push(index);
                    }
                    return acc;
                  }, []);
                  const sumOfFalseIndexValuesNoTakeIndex = falseIndexesNoTakeIndex.reduce(
                    (acc: number, index) => {
                      if (index >= 0 && index < props.list.length) {
                        acc += Number(props.list[index][1]);
                      }
                      return acc;
                    },
                    0,
                  );
                  const newMaxValue = 100 - sumOfFalseIndexValuesNoTakeIndex;
                  const firstFalseIndex = props.lockData.findIndex((value, index) => {
                    return !value && index !== i;
                  });
                  if (newMaxValue > 0) {
                    if (props.lockData.length !== 1 ? firstFalseIndex !== -1 : true) {
                      props.changeData(
                        e.target.value,
                        i,
                        1,
                        props.prefix.includes('DCA') ? 0 : 1,
                        newMaxValue,
                      );
                    } else {
                      props.highLightLocks();
                    }
                  }
                }}
                min={props.prefix.includes('DCA') ? 0 : 1}
              />
              {!props.prefix.includes('DCA') && props.lockData.length !== 1 && (
                <img
                  className="strategy-type-settings__content__take-profits__itemInput__lock"
                  style={{
                    color: props.locksHighlighted ? 'yellow' : '',
                    fill: props.locksHighlighted ? 'yellow' : '',
                    stroke: props.locksHighlighted ? 'yellow' : '',
                  }}
                  src={
                    props.locksHighlighted
                      ? RedClosedLock
                      : props.lockData?.[i]
                      ? ClosedLock
                      : OpenedLock
                  }
                  onClick={() => {
                    props.toggleLockValue?.(i);
                  }}
                  alt="S"
                />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
