import { type FC, useState, useEffect } from 'react';
import { useSelector } from 'redux/rootReducer';
import { useDispatch } from 'react-redux';
import { setIsShowStatisticsData, setShowStatistics } from 'redux/chart/reducer';
import { Info } from '../Log/components/Info';
import './StrategyMetrics.scss';
import { LoaderOverlay } from 'components/LoaderOverlay';
import { StrategyTrades } from './StrategyTrades';
import CoefficientVariation from './CoefficientVariation';
import RecoveryFactor from './RecoveryFactor';
import SharpFactor from './SharpFactor';
import hiddenIcon from 'assets/icons/strategy-close.svg';
import Arrow from 'assets/images/Strategy/RateLine.svg';
import useWindowWidth from 'hooks/useWindowWidth';

export const StrategyMetrics: FC = () => {
  const [trades, setTrades] = useState(true);
  const isMobileVersion = useWindowWidth() < 768;
  const { isLoading, showStatistics, stats } = useSelector((state) => state.chart);
  const { selectedStrategy, selectedMergedStrategy: mergedStrategy } = useSelector(
    (state) => state.strategy,
  );
  const [infoType, setInfoType] = useState<'Basic metrics' | 'Performance metrics'>(
    'Basic metrics',
  );
  const dispatch = useDispatch();
  const overAllFactor =
    stats.overall_factor > 6 ? 6 : stats.overall_factor < 0 ? 0 : stats.overall_factor * 6;
  const toggleTrades = (): void => {
    setTrades(!trades);
  };
  const getColor = (value): string => {
    if (value <= 50) {
      const red = 255;
      const green = Math.round((value / 50) * 255);
      return `rgb(${red}, ${green}, 0)`;
    } else {
      const green = 255;
      const red = Math.round(((100 - value) / 50) * 255);
      return `rgb(${red}, ${green}, 0)`;
    }
  };
  const getStrategyRate = (value: number): string => {
    if (value > 85) return 'Excellent strategy';
    if (value > 70 && value < 85) return 'Low-risk strategy';
    if (value > 50 && value < 70) return 'Moderate strategy';
    if (value > 30 && value < 50) return 'Risky strategy';
    if (value < 30) return 'Very risky strategy';
  };

  const showMetricsData =
    stats && (stats?.trades_count[0] !== 0 || stats?.trades_count[1] !== 0) && showStatistics;
  useEffect(() => {
    if ((selectedStrategy !== null || mergedStrategy !== null) && showMetricsData) {
      dispatch(setIsShowStatisticsData(true));
    } else {
      dispatch(setIsShowStatisticsData(false));
    }
  }, [selectedStrategy, mergedStrategy, stats, showStatistics]);
  if ((selectedStrategy !== null || mergedStrategy !== null) && showMetricsData) {
    return (
      <>
        <div className={'strategy-metrics'}>
          <LoaderOverlay isShow={isLoading} position={'absolute'} imageWidth={0} />
          <div className="strategy-metrics__head">
            <h5 className="strategy-metrics__heading">
              <div className="strategy-metrics_btns">
                <button
                  className="strategy-metrics_btn"
                  style={{
                    backgroundColor: infoType === 'Basic metrics' && 'rgba(105, 78, 240, 1)',
                  }}
                  onClick={() => {
                    setInfoType('Basic metrics');
                  }}
                >
                  Basic metrics
                </button>
                <button
                  className="strategy-metrics_btn"
                  style={{
                    backgroundColor: infoType === 'Performance metrics' && 'rgba(105, 78, 240, 1)',
                  }}
                  onClick={() => {
                    setInfoType('Performance metrics');
                  }}
                >
                  Performance metrics
                </button>
              </div>
              <img
                onClick={() => {
                  dispatch(setShowStatistics(false));
                }}
                src={hiddenIcon}
                alt=""
              />
            </h5>
            {infoType === 'Basic metrics' && <Info {...stats} />}
            {infoType === 'Performance metrics' && (
              <div className="info">
                <CoefficientVariation cvFactor={stats.cv_factor} />
                <RecoveryFactor recoveryFactor={stats.recovery_factor} />
                <SharpFactor recoveryFactor={stats.sharp_factor} />
                <div className="strategy-metrics_perfomanceItem strategy-metrics_perfomanceItem_overall">
                  <div className="strategy-metrics_perfomanceItem_text">
                    <div> Overall effeciency </div>
                    <span style={{ color: getColor(Number(overAllFactor * 1.65)) }}>
                      {' '}
                      {overAllFactor.toFixed(2)}
                    </span>
                  </div>
                  <div className="strategy-metrics_perfomanceItem_progressFoto">
                    <div className="strategy-metrics_perfomanceItem_progressFoto_arrowContainer">
                      <div className="strategy-metrics_perfomanceItem_progressFoto_arrowContainer_circle">
                        <div className="strategy-metrics_perfomanceItem_progressFoto_arrowContainer_circle_inner"></div>
                      </div>
                      <img
                        className={
                          'strategy-metrics_perfomanceItem_progressFoto_arrowContainer_line'
                        }
                        src={Arrow}
                        alt=""
                        style={{
                          transform: `translate(calc(-50% + 45px), -50%)  rotate(${
                            -180 + Number(overAllFactor * 1.8 * 1.65)
                          }deg)`,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="strategy-metrics_perfomanceItem_title"
                    style={{ color: getColor(Number(overAllFactor * 1.65)) }}
                  >
                    {getStrategyRate(Number(overAllFactor * 1.65))}
                  </div>
                  <div className="strategy-metrics_perfomanceItem_info">
                    <span>Overall strategy rating:</span> 88 place out of 354 strategies
                  </div>
                </div>
              </div>
            )}
            {!trades && (
              <button className="strategy-metrics__hide-trade" onClick={toggleTrades}>
                Trade
                <img src={hiddenIcon} alt="" />
              </button>
            )}
          </div>
          {!isMobileVersion && <StrategyTrades />}
        </div>
      </>
    );
  }
};
