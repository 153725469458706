import { ApiServiceBase } from '../../ApiServiceBase';
import { type ITelegramInfo } from './types';

class ApiTelegram extends ApiServiceBase {
  protected baseUrl = '/api/telegram/';

  public async getData(): Promise<ITelegramInfo | null> {
    try {
      const response = await this.get('get');

      return response.data?.data ? response.data.data : null;
    } catch (_) {
      return null;
    }
  }

  public async updateData(key: string, value: boolean): Promise<boolean> {
    try {
      const response = await this.post('update', {
        key,
        value,
      });

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async removeData(): Promise<boolean> {
    try {
      const response = await this.post('remove');

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }
}

export const apiTelegram = new ApiTelegram();
