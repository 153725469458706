import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setInititalGridSettings } from 'redux/chartGridSettings/reducer';
import { setInititalXAxisSettings } from 'redux/chartXAxisSettings/reducer';
import { setInititalYAxisSettings } from 'redux/chartYAxisSettings/reducer';
import { setInititalCandleSettings } from 'redux/chartCandleSettings/reducer';
import { setInititalCrosshairSettings } from 'redux/chartCrosshairSettings/reducer';
import { setInititalTradeSettings } from 'redux/chartTradeSettings/reducer';
import { setInititalBackgroundSettings } from 'redux/chartBackgroundSettings/reducer';

import { apiChartStyles } from '../services/api/ApiChartStyles/ApiChartStyles';

const useChartStylingSettings = (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchChartStylingSettings = async (): Promise<void> => {
      try {
        const result: any = await apiChartStyles.getChartStylingSettings();
        dispatch(setInititalCandleSettings(result.candle));
        dispatch(setInititalCrosshairSettings(result.crosshair));
        dispatch(setInititalGridSettings(result.grid));
        dispatch(setInititalXAxisSettings(result.xAxis));
        dispatch(setInititalYAxisSettings(result.yAxis));
        dispatch(setInititalTradeSettings(result.trades));
        dispatch(setInititalBackgroundSettings(result.background));
      } catch (error) {
        console.error('Error fetching chart styling settings:', error);
      }
    };

    fetchChartStylingSettings();
  }, [dispatch]);
};

export default useChartStylingSettings;
