import { generateUniqueId } from 'services/utils/generateId';
import { type IFollowing } from '../interfaces/interfaces';
import { useSelector } from '../../../redux/rootReducer';
import { apiProfile } from '../../../services/api/Profile/ApiProfile';
import { useDispatch } from 'react-redux';
import { setFollowings } from '../../../redux/profile/reducer';
import { useNavigate } from 'react-router-dom';
import { avatarUrl, rolesNames } from '../../../configs/user';
import UserIcon from '../../../assets/images/default_avatar.png';

interface IProps {
  item: IFollowing;
}
const FollowingItem = ({ item }: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selfId = useSelector((state) => state.auth.user?.id);
  const followings = useSelector((state) => state.profile.followings);
  const isFollowed = selfId && !!followings.find((e) => e.id === item.id);

  return (
    <div key={generateUniqueId()} className="FFContainer_list_item">
      <div className="FFContainer_list_item_info">
        <object className="FFContainer_list_item_img" data={avatarUrl + item.id} type="image/png">
          <img className="FFContainer_list_item_img" src={UserIcon} alt="" />
        </object>
        <div
          className="FFContainer_list_item_name"
          onClick={() => {
            navigate('/profile/' + item.name);
          }}
        >
          {item.name}
        </div>
        <div className="FFContainer_list_item_status">{rolesNames[item.role]}</div>
      </div>

      {selfId !== item.id &&
        (isFollowed ? (
          <button
            onClick={() => {
              apiProfile.unfollow(item.id).then((e) => {
                if (e) {
                  dispatch(setFollowings(followings.filter((e) => e.id !== item.id)));
                }
              });
            }}
            className="FFContainer_list_item_unfollowButton"
          >
            Unfollow
          </button>
        ) : (
          <button
            onClick={() => {
              if (!selfId) {
                navigate('/auth');
                return;
              }
              apiProfile.follow(item.id).then((e) => {
                if (e) {
                  dispatch(setFollowings([...followings, { ...item }]));
                }
              });
            }}
            className="FFContainer_list_item_followButton"
          >
            Follow
          </button>
        ))}
    </div>
  );
};

export default FollowingItem;
