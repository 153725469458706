import { type FC, Fragment, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/rootReducer';
import {
  setFigureDestroy,
  setIsLose,
  changeFigureVisible,
  setSelectedEditor,
  updateListEditor,
  toggleSideMenu as updateSideMenu,
  setShowStatistics,
  updateChart,
  useChartSelector,
} from 'redux/chart/reducer';
import { selectTheme } from 'redux/theme/reducer';
import { fetchCustomTickers } from 'redux/customTicker/reducer';

import { AsideInteractiveItem } from '../AsideInteractiveItem';
import { updateChartsForStrategy } from '../../../StrategyEditor/components/Strategy/Strategy';

import styles from '../../ChartWrapper.module.css';
import classNames from 'classnames';

import { apiStrategy } from 'services/api/Strategy/ApiStrategy';
import { type ITradeData } from 'interfaces/ITrade';
import { ChartLockCloseIcon, ChartLockOpenIcon } from 'assets/icons/ChartLocktIcon';
import { ChartInvisibleIcon, ChartVisibleIcon } from 'assets/icons/ChartVisibleIcon';
import { ChartTrashIcon } from 'assets/icons/ChartTrashIcon';
import strategyMetricsIcon from 'assets/icons/strategy-metrics.svg';
import { type ChartEditorFigure } from 'enums/ChartEditorFigure';
import useChartStylingSettings from 'hooks/useChartStylingSettings';
import { ChartSideMenuIcon } from 'assets/icons/ChartSideMenuIcon';
import { setSelectedMergedStrategyIndex } from 'redux/strategy/reducer';

export interface IChartWrapperProps {
  children?: JSX.Element;
  updateChartsSize: () => void;
  expandConstructor: () => void;
  isConstructorOpen: boolean;
}

export const ChartWrapper: FC<IChartWrapperProps> = (props) => {
  const {
    selectedChart: id,
    isSideMenu,
    showStatistics,
    charts,
    fullScreenMode,
  } = useSelector((state) => state.chart);
  const { selectedStrategy, mergedStrategyData, selectedStrategyId } = useSelector(
    (state) => state.strategy,
  );
  const mergedStrategy = useSelector((state) => state.strategy.selectedMergedStrategy);
  const { figuresList, selectedEditor, isFigureLock, isFigureVisible, strategyId } =
    useChartSelector(id);
  const theme = useSelector(selectTheme);
  const { createdTickers: customTickers } = useSelector((e) => e.customTickers);
  const selectedMergedStrategyIndex = useSelector(
    (state) => state.strategy.selectedMergedStrategyIndex,
  );
  const chartShortTicker = useSelector((state) => state.chart.charts[0]?.ticker);
  const chartLongTicker = useSelector((state) => state.chart.charts[1]?.ticker);
  const { selectedChart: chartId } = useSelector((state) => state.chart);
  const isMobileVersion = window.innerWidth < 768;
  const scrollableDivRef = useRef(null);
  const dispatch = useDispatch();
  const selectEditor =
    (prevEditor: ChartEditorFigure) =>
    (newEditor: ChartEditorFigure): void => {
      dispatch(setSelectedEditor({ id, data: newEditor }));
      dispatch(updateListEditor({ id, data: { prevEditor, newEditor } }));
    };

  const selectCurrentEditor = (currEditor: ChartEditorFigure) => (): void => {
    dispatch(setSelectedEditor({ id, data: currEditor }));
  };

  const toggleLock = (): void => {
    dispatch(setIsLose());
  };

  const toggleVisible = (): void => {
    dispatch(changeFigureVisible());
  };

  const destroy = (): void => {
    dispatch(setFigureDestroy());
  };
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    dispatch(fetchCustomTickers() as any); // Dispatch the fetchCustomTickers action
  }, [dispatch]);
  useEffect(() => {
    if (isMobileVersion) {
      dispatch(updateSideMenu(true));
    }
  }, []);
  useChartStylingSettings();
  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    const handleWheel = (event, ref): void => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        // eslint-disable-next-line   @typescript-eslint/restrict-plus-operands
        ref.scrollLeft += event.deltaY;
      }
    };

    if (scrollableDiv) {
      scrollableDiv.addEventListener('wheel', (e) => {
        handleWheel(e, scrollableDiv);
      });
      return () => {
        scrollableDiv.removeEventListener('wheel', (e) => {
          handleWheel(e, scrollableDiv);
        });
      };
    }
  }, []);

  useEffect(() => {
    const _ = async (): Promise<void> => {
      if (mergedStrategy) {
        const value = mergedStrategy.strategies[selectedMergedStrategyIndex];
        if (!value) {
          dispatch(setSelectedMergedStrategyIndex(0));
          return;
        }
        if (value === strategyId) return;
        updateChartsForStrategy(
          dispatch,
          customTickers,
          await apiStrategy.getStrategy(value),
          chartShortTicker,
          chartLongTicker,
        );
      }
    };
    _();
  }, [mergedStrategy, selectedMergedStrategyIndex]);
  const toggleSideMenu = (): void => {
    dispatch(updateSideMenu());
  };
  useEffect(() => {
    // place to check when adding chart updates
    const selectedStrategyID = mergedStrategy?.strategies[selectedMergedStrategyIndex];
    const data = mergedStrategyData?.backtestData.find((e) => e._id === selectedStrategyID);
    if (data && !selectedStrategy) {
      // place to check when adding chart updates merged');
      if (Array.isArray(data.trades[0])) {
        if (
          charts.length < 2 ||
          (charts[0].trades.length === data.trades[0].length &&
            charts[0].trades[charts[0].trades.length - 1]?.pnl ===
              (data.trades[0][data.trades[0].length - 1] as ITradeData)?.pnl &&
            charts[1].trades.length === (data.trades[1] as ITradeData[]).length &&
            charts[1].trades[charts[1].trades.length - 1]?.pnl ===
              (data.trades[1][(data.trades[1] as ITradeData[]).length - 1] as ITradeData)?.pnl)
        ) {
          return;
        }
        dispatch(
          updateChart({
            id: charts[0].id,
            data: {
              trades: data.trades[0] as ITradeData[],
            },
          }),
        );
        dispatch(
          updateChart({
            id: charts[1].id,
            data: {
              trades: data.trades[1] as ITradeData[],
            },
          }),
        );
        return;
      }
      if (
        (charts[0].trades.length !== data.trades.length ||
          charts[0].trades[charts[0].trades.length - 1]?.pnl !==
            (data.trades[data.trades.length - 1] as ITradeData)?.pnl) &&
        selectedStrategyId === mergedStrategyData._id
      ) {
        dispatch(
          updateChart({
            id,
            data: {
              trades: data.trades as ITradeData[],
            },
          }),
        );
      }
    }
  }, [charts, mergedStrategyData]);
  // const fakeScrollBarRef = useFakeScrollBar(contentWrapperRef);
  const { ticker } = useChartSelector(chartId);
  const isCustomTicker = ticker.type === 'custom';
  const { selectedMergedStrategy } = useSelector((state) => state.strategy);
  useEffect(() => {
    props.updateChartsSize();
  }, [fullScreenMode]);

  return (
    <div className={`${styles.chartWrapper}`}>
      <div className={styles.chartMain}>
        <div
          style={{ backgroundColor: theme.chartAsideMenuBackground }}
          className={classNames(styles.chartAside, {
            [styles.chartAsideActive]: isSideMenu,
          })}
        >
          <div
            style={{
              backgroundColor: theme.chartAsideMenuBackground,
              fill: theme.chartAsideMenuIconFill,
              borderRight: !isSideMenu ? '1px solid white ' : 'none',
              marginLeft: !isSideMenu ? '0px' : '1px',
            }}
            className={classNames(
              styles.chartSidebarIconWrapper,
              !fullScreenMode
                ? {
                    [styles.chartSidebarIconWrapperCustomIndex]: isCustomTicker,
                    [styles.chartSidebarIconWrapperMerged]:
                      !selectedStrategy &&
                      selectedMergedStrategy?.strategies.length > 1 &&
                      !isCustomTicker,
                    [styles.chartSidebarIconWrapperMergedCustomIndex]:
                      !selectedStrategy &&
                      selectedMergedStrategy?.strategies.length > 1 &&
                      isCustomTicker,
                  }
                : '',
            )}
          >
            <ChartSideMenuIcon
              onClick={toggleSideMenu}
              className={classNames(styles.asideIcon, {
                [styles.asideIconActive]: isSideMenu,
              })}
            />
          </div>
          <ul
            style={{
              backgroundColor: theme.chartAsideMenuBackground,
              fill: theme.chartAsideMenuIconFill,
            }}
            className={styles.asideList}
          >
            {figuresList.map((editorKey, idx) => (
              <Fragment key={editorKey}>
                {idx === 5 && (
                  <li className={styles.asideListItem}>
                    <span className={styles.brLine}></span>
                  </li>
                )}
                <AsideInteractiveItem
                  {...{ editorKey, selectCurrentEditor, selectEditor, selectedEditor }}
                />
              </Fragment>
            ))}
            <li
              style={{
                backgroundColor: theme.chartAsideMenuBackground,
                fill: theme.chartAsideMenuIconFill,
              }}
              className={styles.asideListItem}
            >
              <div
                style={{
                  backgroundColor: theme.chartAsideMenuBackground,
                  fill: theme.chartAsideMenuIconFill,
                }}
                className={styles.asideIconWrapper}
                onClick={toggleLock}
              >
                {!isFigureLock ? (
                  <ChartLockOpenIcon className={styles.svg} />
                ) : (
                  <ChartLockCloseIcon className={styles.svg} />
                )}
              </div>
            </li>
            <li
              style={{
                backgroundColor: theme.chartAsideMenuBackground,
                fill: theme.chartAsideMenuIconFill,
              }}
              className={styles.asideListItem}
            >
              <div className={styles.asideIconWrapper} onClick={toggleVisible}>
                {isFigureVisible ? (
                  <ChartVisibleIcon className={styles.svg} />
                ) : (
                  <ChartInvisibleIcon className={styles.svg} />
                )}
              </div>
            </li>
            <li
              style={{
                backgroundColor: theme.chartAsideMenuBackground,
                fill: theme.chartAsideMenuIconFill,
              }}
              className={styles.asideListItem}
            >
              <span className={styles.brLine}></span>
            </li>
            <li className={styles.asideListItem}>
              <div className={styles.asideIconWrapper} onClick={destroy}>
                <ChartTrashIcon className={styles.svg} />
              </div>
            </li>
          </ul>
        </div>
        <div
          className={classNames(styles.chartSection, {
            [styles.chartSectionActive]: isSideMenu,
          })}
        >
          {props.children}
          {/* {props.enableScrollToReal && (
            <div className={styles.chartRollRealtime} onClick={props.scrollToReal}>
              <img src={ChartArrow} alt="<-" />
            </div>
          )} */}
          {!fullScreenMode && !showStatistics && (
            <button
              className={styles.strategyBtn}
              onClick={() => {
                dispatch(setShowStatistics(true));
              }}
              style={{ left: isSideMenu ? '0px' : '' }}
            >
              Strategy metrics
              <img src={strategyMetricsIcon} alt="" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
