import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import MenuPlatformAccount from '../MenuPlatformAccount';

import './MenuPlatform.scss';
import ConnectPlatformIcon from 'assets/images/MenuPage/connectPlatform.svg';
import BinanceIcon from 'assets/images/MenuPage/BinanceIcon.svg';
import WhiteBitIcon from 'assets/images/MenuPage/WhiteBitIcon.svg';
import CoinBase from 'assets/images/MenuPage/CoinBaseIcon.svg';
import SettingsIcon from 'assets/images/MenuPage/SettingsIcon.svg';
import { selectBinanceAccounts } from '../../../../redux/account/reducer';
import AddAccountPopUp from '../AddAccountPopUp';
import PlatformDropDown from '../MenuPlatformDropdown';
import AddAccount from '../MenuPlatformAddAccount';

const PlatformPopUp = (): JSX.Element => {
  const [isOpened, setIsOpened] = useState(false);
  const [iscreatePlatformAccountOpened, setIscreatePlatformAccountOpened] = useState(false);
  const binanceAccounts = useSelector(selectBinanceAccounts);
  const scrollableDivRef = useRef(null);
  const scrollableContainerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event, ref): void => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        // eslint-disable-next-line   @typescript-eslint/restrict-plus-operands
        ref.scrollLeft += event.deltaY;
      }
    };
    const scrollableDiv = scrollableDivRef.current;
    const scrollableContainer = scrollableContainerRef.current;

    if (scrollableDiv) {
      scrollableDiv.addEventListener('wheel', (e) => {
        handleWheel(e, scrollableDiv);
      });
      return () => {
        scrollableDiv.removeEventListener('wheel', (e) => {
          handleWheel(e, scrollableDiv);
        });
      };
    }

    if (scrollableContainer) {
      scrollableContainer.addEventListener('wheel', (e) => {
        handleWheel(e, scrollableContainer);
      });
      return () => {
        scrollableContainer.removeEventListener('wheel', (e) => {
          handleWheel(e, scrollableContainer);
        });
      };
    }
  }, []);

  if (isOpened && iscreatePlatformAccountOpened && binanceAccounts.length === 0) {
    return (
      <AddAccountPopUp
        closePopUp={() => {
          setIscreatePlatformAccountOpened((prev) => !prev);
        }}
      />
    );
  }
  if (isOpened && binanceAccounts.length !== 0) {
    return ReactDOM.createPortal(
      <div className="popUpContainer">
        <div ref={scrollableDivRef} className="popUp">
          <button
            className="popUp__closeButton"
            onClick={() => {
              setIsOpened(false);
            }}
          ></button>
          <div className="popUp__header">Connected Accounts</div>
          <div className="popUp__platformContainer">
            <div className={'popUp__platform popUp__platform__selected'}>
              <img src={BinanceIcon} alt="BinanceIcon" />
              Binance
            </div>
            <div className={'popUp__platform popUp__platform__disabled'}>
              <img src={WhiteBitIcon} alt="WhiteBitIcon" />
              WhiteBit
            </div>
            <div className={'popUp__platform popUp__platform__disabled'}>
              <img src={CoinBase} alt="CoinBase" />
              CoinBase
            </div>
          </div>
          <div className="popUp__table">
            <div className={'popUp__tableRow popUp__tableHeader'}>
              <div>№</div>
              <div>Name</div>
              <div>Status</div>
              <div>Future</div>
              <div>Spot</div>
              <div>Only reading</div>
              <div>Settings</div>
            </div>
            {binanceAccounts.map((account, index) => {
              return (
                <MenuPlatformAccount key={account.id} account={account} index={String(index)} />
              );
            })}
          </div>
          <AddAccount />
        </div>
      </div>,
      document.body,
    );
  }
  // showing connection indicator and platform
  return (
    <button
      onClick={() => {
        if (process.env.NODE_ENV === 'development' && binanceAccounts.length === 0) {
          setIsOpened(true);
          setIscreatePlatformAccountOpened(true);
        }
      }}
      style={process.env.NODE_ENV !== 'development' ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
      className="new-btn"
    >
      <div className="button__connectionIndicator">
        {binanceAccounts.length > 0 ? (
          <>
            {' '}
            Connected{' '}
            <div className="button__connectionIndicator__circle button__connectionIndicator__circle_connected"></div>
          </>
        ) : (
          <>
            {' '}
            No connection{' '}
            <div className="button__connectionIndicator__circle button__connectionIndicator__circle_notConnected"></div>
          </>
        )}
      </div>
      {binanceAccounts.length > 0 ? (
        <PlatformDropDown />
      ) : (
        <>
          <img src={ConnectPlatformIcon} alt="AddIcon" /> Connect platform{' '}
        </>
      )}
      <img
        src={SettingsIcon}
        alt="SettingsIcon"
        onClick={() => {
          setIsOpened((prev) => !prev);
        }}
        className="button__settingsIndicatior"
      />
    </button>
  );
};

export default PlatformPopUp;
