import Axios, { type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import { type IApiRouterParams, type TAnyObject } from './types';
import { getApiRouter } from './utils/getApiRouter';

export interface IAxiosRequestConfig extends Omit<AxiosRequestConfig, 'params'>, IApiRouterParams {}

export const apiAxiosInstance = Axios.create({
  timeout: 60000 * 5,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

apiAxiosInstance.interceptors.request.use((request) => {
  // TODO logic authorization
  return request;
});

apiAxiosInstance.interceptors.response.use((response) => {
  // TODO logic authorization
  return response;
});

export abstract class ApiServiceBase {
  protected baseUrl = '';
  private readonly axios: AxiosInstance = apiAxiosInstance;
  private readonly domain = 'https://beta.cr-algo.com';

  protected get<Response extends TAnyObject>(
    url = '',
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<AxiosResponse<Response>> {
    return this.axios.get(
      getApiRouter(this.domain + this.baseUrl + url, { params, query }),
      config,
    );
  }

  protected post<Request extends TAnyObject, Response extends TAnyObject = TAnyObject>(
    url = '',
    data?: Request,
    { params, query, ...config }: IAxiosRequestConfig = {},
  ): Promise<AxiosResponse<Response>> {
    return this.axios.post(
      getApiRouter(this.domain + this.baseUrl + url, { params, query }),
      data,
      config,
    );
  }
}
