import { type FC, useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  setStrategies as setReduxStrategies,
  updateStrategyInStrategies,
} from 'redux/strategy/reducer';
import { apiStrategy } from '../../services/api/Strategy/ApiStrategy';
import type { IStrategyAllData } from 'services/api/Strategy/types/StrategyResponse';
import {
  type IBaseStrategySettings,
  type IHedgeStrategy,
  type IHedgeStrategyProperties,
  type IStrategy,
  StrategyModes,
} from '../../interfaces/IStrategy';
import GButton, { ButtonColors, ButtonTypes } from '../GButton/GButton';
import cn from 'classnames';
import './StrategyElement.scss';
import SelectedStarIcon from 'assets/images/Strategy/StrategySelectedStar.svg';
import NotSelectedStarIcon from 'assets/images/Strategy/StrategyNotSelectedStar.svg';
import CopyIcon from 'assets/icons/CopyIcon2.svg';
import useWindowWidth from 'hooks/useWindowWidth';

interface IStrategyElementProps {
  mergedStrategies?: number;
  strategy: IStrategyAllData;
  removeHandler: (id: string) => void;
  editStrategy: () => void;
  hideDotsStar?: boolean;
  setPublishOpen?: (value: boolean) => void;
  setPublishData?: (value: any) => void;
}

const StrategyElement: FC<IStrategyElementProps> = ({
  strategy,
  removeHandler,
  editStrategy,
  hideDotsStar,
  setPublishOpen,
  setPublishData,
}) => {
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false);
  const [copyOptionsVisible, setCopyOptionsVisible] = useState<boolean>(false);
  const copyDataType = localStorage.getItem('strategy_buffer_type');
  const copyStrategyType = localStorage.getItem('strategy_buffer_strategy_type');
  const isMobileVersion = useWindowWidth() < 768;
  const intervalRef = useRef(null);
  const dispatch = useDispatch();
  const startInterval = (): void => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setCopyOptionsVisible(false);
      }, 1000);
    }
  };
  const stopInterval = (): void => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };
  const subTextInfo = (): string => {
    if (strategy.mode) {
      return strategy.mode === 1 ? 'Hedge mode' : 'One-way mode';
    } else {
      return 'One-way mode';
    }
  };
  const duplicateStrategy = (): void => {
    apiStrategy.duplicateStrategy(strategy._id, strategy.mode).then((res) => {
      if (res) {
        apiStrategy.getStrategies().then((result) => {
          dispatch(setReduxStrategies(result));
        });
      }
    });
  };

  const isFavouritePropertyToggler = (): void => {
    if (strategy.is_favourite) {
      apiStrategy
        .removeStrategyFavoriteStatus({
          strategyId: strategy._id,
          mode: strategy.mode,
        })
        .then(() => {
          apiStrategy.getStrategies().then((result) => {
            dispatch(setReduxStrategies(result));
          });
        });
    } else {
      apiStrategy
        .addStrategyFavoriteStatus({
          strategyId: strategy._id,
          mode: strategy.mode,
        })
        .then(() => {
          apiStrategy.getStrategies().then((result) => {
            dispatch(setReduxStrategies(result));
          });
        });
    }
  };

  const pasteSettings = (type: 'short' | 'long' | 'both' | 'property'): void => {
    const dataStr = localStorage.getItem('strategy_buffer_' + type);

    if (!dataStr) return;
    const data: IHedgeStrategyProperties | IBaseStrategySettings = JSON.parse(dataStr);
    let dto: object = {};

    switch (type) {
      case 'short': {
        dto['strategies.short'] = data;
        break;
      }
      case 'long': {
        dto['strategies.long'] = data;
        break;
      }
      case 'both': {
        dto = data;
        break;
      }
      case 'property': {
        dto = {
          property: data,
        };
      }
    }
    apiStrategy.updateStrategy(strategy._id, strategy.mode, dto).then((res) => {
      if (res) {
        console.log('success pasted');
      }
    });
  };

  const copySettings = (type: 'short' | 'long' | 'both' | 'property'): void => {
    apiStrategy.getStrategy(strategy._id).then((res) => {
      if (!res) return;
      let data: string;

      if (strategy.mode === StrategyModes.HEDGE) {
        const strategy = res as IHedgeStrategy;
        data = JSON.stringify(
          type === 'property'
            ? strategy.property
            : type === 'short'
            ? strategy.strategies.short
            : strategy.strategies.long,
        );
      } else {
        const strategy = res as IStrategy;
        data = JSON.stringify(
          type === 'property'
            ? strategy.property
            : {
                indicators: strategy.indicators,
                settings: strategy.settings,
                timeframe: strategy.timeframe,
              },
        );
      }
      localStorage.setItem('strategy_buffer_' + type, data);
      localStorage.setItem('strategy_buffer_type', type);
      localStorage.setItem('strategy_buffer_strategy_type', String(res.mode));
      setTimeout(() => {
        if (localStorage.getItem('strategy_buffer_' + type) === data) {
          localStorage.removeItem('strategy_buffer_' + type);
          localStorage.removeItem('strategy_buffer_type');
          localStorage.removeItem('strategy_buffer_strategy_type');
        }
      }, 60000);
      setCopyOptionsVisible(false);
    });
  };
  const onPublishStrategy = (): void => {
    if (typeof strategy.library !== 'number') {
      // setOptionsVisible(false)
      setPublishOpen(true);
      setPublishData({
        _id: strategy._id,
        mode: strategy.mode,
      });
    } else {
      apiStrategy
        .updateStrategy(strategy._id, strategy.mode, {
          library: null,
        })
        .then((r) => {
          if (r) {
            dispatch(
              updateStrategyInStrategies({
                _id: strategy._id,
                library: null,
              }),
            );
          }
        });
    }
  };

  useEffect(() => {
    if (!optionsVisible) {
      setCopyOptionsVisible(false);
    }
  }, [optionsVisible]);

  return (
    <div
      className="strategy-element"
      tabIndex={0}
      onBlur={() => {
        setOptionsVisible(false);
      }}
      onMouseLeave={() => {
        setOptionsVisible(false);
      }}
    >
      <span className="strategy-element__name">
        {strategy.name} <span className="strategy-element__mode">{subTextInfo()}</span>
      </span>
      <div
        className="strategy-element-controls"
        onClick={() => {
          isMobileVersion && setOptionsVisible(!optionsVisible);
        }}
        onMouseLeave={() => {
          isMobileVersion && setOptionsVisible(false);
        }}
      >
        {!hideDotsStar && (
          <div className="strategy-element-controls-misc">
            {!isMobileVersion && (
              <div
                style={strategy.is_favourite && strategy.is_favourite !== 0 ? { opacity: 1 } : {}}
                className="strategy-element-controls-misc-star"
                onClick={isFavouritePropertyToggler}
              >
                <img src={SelectedStarIcon} alt="star" />
              </div>
            )}
            {isMobileVersion && (
              <div
                className="strategy-element-controls-misc-star"
                onClick={isFavouritePropertyToggler}
              >
                {strategy.is_favourite && strategy.is_favourite !== 0 ? (
                  <img src={SelectedStarIcon} alt="star" />
                ) : (
                  <img src={NotSelectedStarIcon} alt="star" />
                )}
              </div>
            )}
            <div
              className="strategy-element-controls-misc-dots"
              onMouseOver={() => {
                setOptionsVisible(true);
              }}
              onClick={() => {
                setOptionsVisible(true);
              }}
            >
              <div className="strategy-element-controls-misc-dots__dot" />
              <div className="strategy-element-controls-misc-dots__dot" />
              <div className="strategy-element-controls-misc-dots__dot" />
            </div>

            <div
              className={cn(
                'strategy-element-controls-misc-options',
                optionsVisible && 'strategy-element-controls-misc-options_visible',
              )}
              style={{
                left: copyOptionsVisible ? (isMobileVersion ? '-175px' : '-124px') : '',
              }}
            >
              <div className={'strategy-element-controls-misc-options_container'}>
                <div
                  className="strategy-element-controls-misc-options__option"
                  onClick={onPublishStrategy}
                >
                  {typeof strategy.library !== 'number'
                    ? 'Publish to public library'
                    : 'Unpublish from public library'}
                </div>
                <div
                  className="strategy-element-controls-misc-options__optionCopy"
                  onClick={() => {
                    setCopyOptionsVisible(true);
                  }}
                  onMouseEnter={() => {
                    setCopyOptionsVisible(true);
                    stopInterval();
                  }}
                  onMouseLeave={() => {
                    startInterval();
                  }}
                >
                  Copy
                  <img src={CopyIcon} alt="copy" />
                </div>
                {strategy.mode === 1 && (
                  <>
                    {copyDataType === 'long' && (
                      <div
                        className="strategy-element-controls-misc-options__option"
                        onClick={() => {
                          pasteSettings('long');
                        }}
                      >
                        Paste long settings
                      </div>
                    )}
                    {copyDataType === 'short' && (
                      <div
                        className="strategy-element-controls-misc-options__option"
                        onClick={() => {
                          pasteSettings('short');
                        }}
                      >
                        Paste short settings
                      </div>
                    )}
                    {copyDataType === 'property' && copyStrategyType === '1' && (
                      <div
                        className="strategy-element-controls-misc-options__option"
                        onClick={() => {
                          pasteSettings('property');
                        }}
                      >
                        Paste properties settings
                      </div>
                    )}
                  </>
                )}
                {strategy.mode === 0 && (
                  <>
                    {copyDataType === 'both' && (
                      <div
                        className="strategy-element-controls-misc-options__option"
                        onClick={() => {
                          pasteSettings('both');
                        }}
                      >
                        Paste long/short settings
                      </div>
                    )}
                    {copyDataType === 'property' && copyStrategyType === '0' && (
                      <div
                        className="strategy-element-controls-misc-options__option"
                        onClick={() => {
                          pasteSettings('property');
                        }}
                      >
                        Paste properties settings
                      </div>
                    )}
                  </>
                )}
                <div
                  className="strategy-element-controls-misc-options__option"
                  onClick={editStrategy}
                >
                  Edit
                </div>
                <div
                  className="strategy-element-controls-misc-options__option"
                  onClick={duplicateStrategy}
                >
                  Duplicate
                </div>
                <div
                  className="strategy-element-controls-misc-options__option strategy-element-controls-misc-options__option_red"
                  onClick={() => {
                    removeHandler(strategy._id);
                  }}
                >
                  Delete
                </div>
                {copyOptionsVisible && strategy.mode === 1 && (
                  <div
                    onMouseLeave={() => {
                      startInterval();
                    }}
                    onMouseEnter={() => {
                      stopInterval();
                    }}
                    className={'strategy-element-controls-misc-options-copyMenuHedge'}
                  >
                    <div
                      className="strategy-element-controls-misc-options__option"
                      onClick={() => {
                        copySettings('long');
                      }}
                    >
                      Copy long settings
                    </div>
                    <div
                      className="strategy-element-controls-misc-options__option"
                      onClick={() => {
                        copySettings('short');
                      }}
                    >
                      Copy short settings
                    </div>
                    <div
                      className="strategy-element-controls-misc-options__option"
                      onClick={() => {
                        copySettings('property');
                      }}
                    >
                      Copy properties settings
                    </div>
                  </div>
                )}
                {copyOptionsVisible && strategy.mode === 0 && (
                  <div
                    onMouseLeave={() => {
                      startInterval();
                    }}
                    onMouseEnter={() => {
                      stopInterval();
                    }}
                    className={'strategy-element-controls-misc-options-copyMenuOneWay'}
                  >
                    <div
                      className="strategy-element-controls-misc-options__option"
                      onClick={() => {
                        copySettings('both');
                      }}
                    >
                      Copy long/short settings
                    </div>
                    <div
                      className="strategy-element-controls-misc-options__option"
                      onClick={() => {
                        copySettings('property');
                      }}
                    >
                      Copy properties settings
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <GButton
          type={ButtonTypes.BUTTON}
          title="Open"
          color={ButtonColors.VIOLET}
          bordered={false}
          onClick={editStrategy}
          filled={true}
          tiny={true}
          height={28}
        />
      </div>
    </div>
  );
};

export default StrategyElement;
