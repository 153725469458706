import styles from './Header.module.css';
import Logo from '../../../../assets/images/HeaderLogo.svg';
import BellTestIcon from '../../../../assets/images/BellTestIcon.svg';
import HeaderPlatformDropDown from './PlatformDropdown';
import ModeSwitcher from './ModeSwitcher/ModeSwitcher';
// import Navigation from './Navigation/Navigation';
import DemoBalance from './DemoBalance/DemoBalance';
import { useFakeScrollBar } from '../Chart/customHooks/useFakeScrollBar';
import { useRef, useState } from 'react';
import { LoaderOverlay } from 'components/LoaderOverlay';
import { useSelector } from 'redux/rootReducer';
import { useNavigate } from 'react-router-dom';
import NavigationMenu from './NavigationMenu/NavigationMenu';
import { avatarUrl } from '../../../../configs/user';
import UserIcon from 'assets/images/default_avatar.png';

const Header = (): JSX.Element => {
  const isChartLoading = useSelector((state) => state.chart.isLoading);
  const { login, id } = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const contentWrapperRef = useRef(null);
  const fakeScrollBarRef = useFakeScrollBar(contentWrapperRef);
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div
      style={{
        height: '100%',
      }}
      ref={contentWrapperRef}
      className={styles.headerContainer}
    >
      <NavigationMenu isOpened={isOpened} setIsOpened={setIsOpened} />
      {!isOpened && (
        <>
          <LoaderOverlay position={'absolute'} isShow={isChartLoading} imageWidth={0} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            <img className={styles.logo} src={Logo} />
            <HeaderPlatformDropDown />
          </div>

          {/* <Navigation /> */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ModeSwitcher />
            <DemoBalance />
            <img
              style={{ marginLeft: '24px', marginRight: '24px' }}
              className={styles.bellIcon}
              src={BellTestIcon}
            />
            <button
              className={styles.headerAvatar}
              onClick={() => {
                navigate('/profile/' + login);
              }}
            >
              <img
                onClick={() => {
                  navigate(`/profile/${login}`);
                }}
                className={styles.headerAvatar}
                src={`${avatarUrl}${id}`}
                alt="User Avatar"
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null;
                  target.src = UserIcon;
                }}
              />
            </button>{' '}
          </div>
          <div className={styles.fakeChartScrollBar} ref={fakeScrollBarRef}></div>
        </>
      )}
    </div>
  );
};

export default Header;
