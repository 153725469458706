import { type FC, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';

import PlatformDropDown from '../MenuPlatformDropdown';

import { createPlatformAccount } from '../../../../redux/account/reducer';
import { type IMenuPageErrors } from '../../../../interfaces/IMenuPage';

import './MenuAddAccount.scss';
interface AddAccountPopUpProps {
  closePopUp: () => void;
}
const AddAccountPopUp: FC<AddAccountPopUpProps> = ({ closePopUp }) => {
  // handling inputs
  const [accountInputs, setAccountInputs] = useState({
    name: '',
    apiKey: '',
    secretKey: '',
  });
  const [errors, setErrors] = useState<IMenuPageErrors>({
    name: false,
    apiKey: false,
    secretKey: false,
  });

  const dispatch = useDispatch();
  const handleInputChange = (field: string, value: string): void => {
    // setting values and errors to show
    setAccountInputs((prevAccount) => ({
      ...prevAccount,
      [field]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [field]: false,
    }));
    if (value.length < 30 && (field === 'apiKey' || field === 'secretKey')) {
      setErrors((prev) => ({
        ...prev,
        [field]: true,
      }));
    }
  };
  const handleConnectButton = (e): void => {
    e.preventDefault();
    if (
      accountInputs.name &&
      accountInputs.apiKey.length > 30 &&
      accountInputs.secretKey.length > 30
    ) {
      document.documentElement.style.setProperty('zoom', '100%');
      dispatch(createPlatformAccount(accountInputs));
      setAccountInputs({
        name: '',
        apiKey: '',
        secretKey: '',
      });
      closePopUp();
    }
    const newErrors = { ...errors };
    // checking if there are errors and show them
    if (accountInputs.name === '') {
      newErrors.name = true;
    }
    if (accountInputs.apiKey.length < 30) {
      newErrors.apiKey = true;
    }
    if (accountInputs.secretKey.length < 30) {
      newErrors.secretKey = true;
    }
    setErrors(newErrors);
  };

  return ReactDOM.createPortal(
    <div className="addAccountPopUpContainer">
      <div className="addAccountPopUp">
        <button
          className="addAccountPopUp__closeButton"
          onClick={() => {
            closePopUp();
          }}
        ></button>
        <div className="addAccountPopUp__header">
          New connection:
          <PlatformDropDown />
        </div>
        <form className="addAccountPopUp__form">
          <input
            className={`${errors.name && 'addAccountPopUp__errorInput'}`}
            value={accountInputs.name}
            onChange={(e) => {
              handleInputChange('name', e.target.value);
            }}
            type="text"
            placeholder="Name"
            maxLength={255}
          ></input>
          {errors.name && <p className="addAccountPopUp__error">Name is required</p>}
          <input
            className={`${errors.apiKey && 'addAccountPopUp__errorInput'}`}
            value={accountInputs.apiKey}
            onChange={(e) => {
              handleInputChange('apiKey', e.target.value);
            }}
            type="text"
            placeholder="API key"
          ></input>
          {errors.apiKey && (
            <p className="addAccountPopUp__error">Invalid API. Please check and repeat</p>
          )}
          <input
            className={`${errors.secretKey && 'addAccountPopUp__errorInput'}`}
            value={accountInputs.secretKey}
            onChange={(e) => {
              handleInputChange('secretKey', e.target.value);
            }}
            type="text"
            placeholder="Secret"
            maxLength={255}
          ></input>
          {errors.secretKey && (
            <p className="addAccountPopUp__error">Invalid Secret key. Please check and repeat</p>
          )}
          <button
            type="submit"
            className="addAccountPopUp__connectButton"
            onClick={handleConnectButton}
          >
            Connect
          </button>
        </form>
      </div>
    </div>,
    document.body,
  );
};

export default AddAccountPopUp;
