import { type FC, useRef, useState, type ReactNode, useEffect } from 'react';
import {
  DayPicker as DatePicker,
  type Matcher,
  type SelectSingleEventHandler,
} from 'react-day-picker';
import { format } from 'date-fns';
import styles from './DayPicker.module.css';
import { usePopper } from 'react-popper';
import { useClickAway } from 'hooks/useClickAway';
import classNames from 'classnames';
import { PortalWrapper } from 'components/PortalWrapper';
// import 'react-day-picker/dist/style.css';

interface IProps {
  toDate?: Date;
  fromDate?: Date;
  fromMonth?: Date;
  toMonth?: Date;
  fromYear?: number;
  toYear?: number;
  onSelect: (data: Date) => void;
  value: Date | null;
  disabled?: Matcher | Matcher[] | undefined;
  footer?: ReactNode;
  placeholder?: string;
}

export const DayPicker: FC<IProps> = (props) => {
  const { onSelect, value, placeholder, ...restProps } = props;
  const [isShow, setIsShow] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const popperRef = useRef<HTMLDivElement>(null);
  const referenceRef = useClickAway<HTMLDivElement>((event) => {
    const element = popperRef.current;
    if (element && !element.contains(event.target as Node)) {
      setIsShow(false);
    }
  });
  const [month, setMonth] = useState<Date | undefined>(undefined);
  const { styles: poperStyles, attributes } = usePopper(referenceRef.current, popperRef.current, {
    placement: 'bottom-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'right', 'left', 'bottom'],
        },
      },
    ],
  });

  useEffect(() => {
    setMonth(value ?? undefined);
  }, [value]);

  const onClick = (): void => {
    setIsShow((prevState) => !prevState);
  };

  const convertTime = value ? format(value, 'dd/MM/yyyy') : '';

  const selectHandler: SelectSingleEventHandler = (day) => {
    onSelect(day);
    setIsShow(false);
  };

  return (
    <>
      <div
        ref={referenceRef}
        className={classNames(styles.dayPickerWrapper, {
          [styles.dayPickerWrapperBorder]: isFocus,
        })}
      >
        <input
          placeholder={placeholder}
          onFocus={() => {
            setIsFocus(true);
          }}
          onBlur={() => {
            setIsFocus(false);
          }}
          className={styles.input}
          type="text"
          onClick={onClick}
          defaultValue={convertTime}
        />
      </div>
      <PortalWrapper wrapperId="date-portal">
        <div
          className={classNames(styles.poperBody, { [styles.poperBodyShow]: isShow })}
          ref={popperRef}
          style={poperStyles.popper}
          {...attributes.popper}
        >
          <DatePicker
            month={month}
            captionLayout="dropdown-buttons"
            selected={value}
            onSelect={selectHandler}
            classNames={styles}
            onMonthChange={setMonth}
            mode="single"
            {...restProps}
          />
        </div>
      </PortalWrapper>
    </>
  );
};
