import { useState, type FC } from 'react';
import '../MergedStrategy.scss';
import Arrow from '../../../../../../../assets/images/Strategy/DropDownTriangle.svg';
import SelectedMergeIcon from '../../../../../../../assets/images/Strategy/SelectedMergeIcon.svg';
import NotSelectedMergeIcon from '../../../../../../../assets/images/Strategy/NoSelectedMergeIcon.svg';
import {
  type IMergedStrategy,
  type IStrategyAllData,
  type IStrategyAllResponse,
} from '../../../../../../../services/api/Strategy/types/StrategyResponse';
import { apiStrategy } from '../../../../../../../services/api/Strategy/ApiStrategy';
import { useDispatch } from 'react-redux';
import {
  setBackTest,
  updateSelectedMergedStrategy,
} from '../../../../../../../redux/strategy/reducer';
interface IProps {
  onClose: () => void;
  strategy: IMergedStrategy;
  updateStrategy: (strategy: IMergedStrategy) => void;
  strategies: IStrategyAllResponse;
}
const MergedStrategyEditor: FC<IProps> = ({
  onClose,
  updateStrategy,
  strategy,
  strategies,
}): JSX.Element => {
  const [currentStrategy, setCurrentStrategy] = useState({
    ...strategy,
  });
  const dispatch = useDispatch();

  return (
    <div className="merged-strategy-selector">
      <div className="merged-strategy-selector-container">
        <div className="merged-strategy-selector-container__header">
          Choose strategies <img src={Arrow} alt="Arrow" />
        </div>
        <div className="merged-strategy-selector-container__list">
          {' '}
          {strategies.map((strategy: IStrategyAllData) => (
            <div
              className="merged-strategy-selector-container__list_item"
              key={strategy._id}
              onClick={() => {
                if (!currentStrategy.strategies.map((e) => e).includes(strategy._id)) {
                  setCurrentStrategy({
                    ...currentStrategy,
                    strategies: currentStrategy.strategies.concat(strategy._id),
                  });
                } else {
                  setCurrentStrategy({
                    ...currentStrategy,
                    strategies: currentStrategy.strategies.filter((e) => e !== strategy._id),
                  });
                }
              }}
            >
              <div className="merged-strategy-selector-container__list_item_info">
                <div className="merged-strategy-selector-container__list_item_info_name">
                  {strategy.name}
                </div>
                <div className="merged-strategy-selector-container__list_item_info_currrency">
                  {strategy.ticker}, {strategy.mode === 1 ? 'Hedge' : 'One-way'}
                </div>
              </div>
              {currentStrategy.strategies.includes(strategy._id) ? (
                <img src={SelectedMergeIcon} alt="Selected" />
              ) : (
                <img src={NotSelectedMergeIcon} alt="NotSelected" />
              )}
            </div>
          ))}
        </div>
        <div className="merged-strategy-selector-container__buttonsContainer">
          <button
            className="merged-strategy-selector-container__buttonsContainer__back"
            onClick={onClose}
          >
            Back
          </button>
          <button
            className="merged-strategy-selector-container__buttonsContainer__add"
            style={
              currentStrategy.strategies.length < 2 ? { opacity: 0.5, cursor: 'not-allowed' } : null
            }
            onClick={() => {
              if (currentStrategy.strategies.length < 2) return;
              onClose();
              updateStrategy(currentStrategy);
              if (!currentStrategy._id) {
                apiStrategy
                  .createMergedStrategy(currentStrategy)
                  .then((data) => {
                    dispatch(updateSelectedMergedStrategy(data));
                  })
                  .then(() => {
                    dispatch(setBackTest(true));
                  });
              } else {
                apiStrategy
                  .saveMergedStrategy(currentStrategy)
                  .then((data) => {
                    dispatch(updateSelectedMergedStrategy(data));
                  })
                  .then(() => {
                    dispatch(setBackTest(true));
                  });
              }
            }}
          >
            Add strategies
          </button>
        </div>
      </div>
    </div>
  );
};

export default MergedStrategyEditor;
