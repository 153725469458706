import { ApiServiceBase } from '../../ApiServiceBase';
import { type IProfileSelf, type IProfileView } from './types';
import { type IFollowing } from '../../../pages/UserProfile/interfaces/interfaces';

class ApiProfile extends ApiServiceBase {
  protected baseUrl = '/api/profile/';

  public async getProfile(login: string): Promise<IProfileSelf | IProfileView | null> {
    try {
      const response = await this.get(`get/${login}`);

      return response.data?.data ? response.data.data : null;
    } catch (_) {
      return null;
    }
  }

  public async createProfile(name: string): Promise<boolean> {
    try {
      const response = await this.post('create', {
        name,
      });

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async updateOnline(): Promise<boolean> {
    try {
      const response = await this.post('online');

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async changeDescription(description: string): Promise<boolean> {
    try {
      const response = await this.post('change_description', {
        description,
      });

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async changeSocial(
    social: 'telegram' | 'twitter' | 'instagram',
    value: string,
  ): Promise<boolean> {
    try {
      const response = await this.post('change_social', {
        social,
        value,
      });

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async changeShowOnline(value: boolean): Promise<boolean> {
    try {
      const response = await this.post('change_show_online', {
        value,
      });

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async getFollowers(id: string): Promise<IFollowing[]> {
    try {
      const response = await this.get('followers/get_followers/' + id);

      return response.data?.data ?? false;
    } catch (_) {
      return [];
    }
  }

  public async getFollowing(id: string): Promise<IFollowing[]> {
    try {
      const response = await this.get('followers/get_following/' + id);

      return response.data?.data ?? false;
    } catch (_) {
      return [];
    }
  }

  public async follow(targetId: string): Promise<boolean> {
    try {
      const response = await this.post('followers/subscribe', {
        target_id: targetId,
      });

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async unfollow(targetId: string): Promise<boolean> {
    try {
      const response = await this.post('followers/unsubscribe', {
        target_id: targetId,
      });

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async uploadAvatar(file: File): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.post('upload_avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async deleteAvatar(): Promise<boolean> {
    try {
      const response = await this.post('delete_avatar');

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  }
}

export const apiProfile = new ApiProfile();
