import { type FC } from 'react';
import Settings from '../../../../../../assets/images/Strategy/SettingsIcon.svg';
import Delete from '../../../../../../assets/images/Strategy/DeleteIcon.svg';
import './StrategySelector.scss';
import CustomDropDownXL from '../CustomElements/MultiLineDropDownXL/CustomMultiLineDropDownXL';

interface IProps {
  options: {
    id: number;
    name: string;
    desc?: string;
  }[];
  activeOption: number;
  onChange: (id: number) => void;
  openSettings?: boolean;
  openSettingsHandler?: () => void;
  deleteOption?: () => void;
  width?: string;
  height?: number;
  border?: string;
  background?: string;
  disabled?: boolean;
}

export const StrategySelectorXL: FC<IProps> = (props) => {
  return (
    <div
      className="strategy-selectorXL"
      style={{
        height: props.height && `${props.height}px`,
        maxHeight: props.height && `${props.height}px`,
        cursor: props.disabled ? 'not-allowed' : 'pointer',
      }}
    >
      <div
        className="strategy-selectorXL__select"
        style={{
          width: props.width ? props.width : '',
          cursor: props.disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <CustomDropDownXL
          options={props.options ?? []}
          onChange={props.onChange}
          height={props.height}
          disabled={props.disabled}
          activeOption={props.options.find((e) => e.id === props.activeOption) ?? props.options[0]}
        />
      </div>
      {props.openSettings && (
        <button onClick={props.openSettingsHandler} className="strategy-selectorXL__settings">
          <img src={Settings} alt="settings" />
        </button>
      )}
      {props.deleteOption && (
        <button onClick={props.deleteOption} className="strategy-selectorXL__delete">
          <img src={Delete} alt="S" />
        </button>
      )}
    </div>
  );
};
