import { type FC, memo } from 'react';
import { useDispatch } from 'react-redux';
import { updateStrategyProperty } from 'redux/strategy/reducer';
import CustomTextInput from '../ReversedMartingaleSettings/components/CustomInput/CustomTextInput';
import CustomNumberInput from '../../../../CustomElements/CustomInput/CustomInput';
import { type IBaseStrategySettings, type IStrategy } from 'interfaces/IStrategy';
import CheckBoxChecked from 'assets/images/Strategy/CheckboxChecked.svg';
import CheckBox from 'assets/images/Strategy/Checkbox.svg';
import './style.scss';
import { useSelector } from 'redux/rootReducer';

interface IProps {
  onClose?: () => void;
  strategyProperty: IBaseStrategySettings;
  setProperty: (property: IStrategy['property']) => void;
  onRunBackTest: () => void;
}

const ReEntryCooldownSettings: FC<IProps> = ({
  strategyProperty,
  setProperty,
  onRunBackTest,
  ...props
}) => {
  const isMobileVersion = window.innerWidth < 768;
  const dispatch = useDispatch();
  let data = useSelector((state) => state.strategy.selectedStrategy.property.reentry_cooldown);

  if (!data) {
    data = {
      long: [0, 0],
      short: [0, 0],
      cancel: 0,
      takeprofit: false,
      trailing: false,
      liquidation: false,
    };
  }

  const update = (value: Partial<IBaseStrategySettings['reentry_cooldown']>): void => {
    dispatch(
      updateStrategyProperty({
        reentry_cooldown: {
          ...data,
          ...value,
        },
      }),
    );
  };
  return (
    <div className="reentry">
      <div className="reentry__header">
        <div className="reentry__header__back" onClick={props?.onClose}>
          <div className="reentry__header__back_img"></div>Back
        </div>
        ReEntry cooldown
      </div>
      <div className="reentry_title">Limitation for re-entering a trade after getting the SL </div>

      <div className="reentry_item">
        LONG ReEntry min. UP
        <div className="reentry_input">
          {' '}
          <CustomNumberInput
            min={0}
            max={100}
            step={1}
            maxHeight={38}
            value={data.long[1]}
            showMobileArrows={isMobileVersion}
            onChange={(ev) => {
              let value = parseFloat(ev.target.value);
              if (/^\d*\.?\d*$/.test(String(value))) {
                if (value < 0) value = 0;
                if (value > 100) value = 100;
              } else {
                if (isNaN(value)) value = 0;
              }

              update({ long: [data.long[0], value] });
            }}
          />
          %
        </div>
      </div>
      <div className="reentry_item">
        LONG ReEntry min. DOWN
        <div className="reentry_input">
          {' '}
          <CustomNumberInput
            min={0}
            max={100}
            step={1}
            maxHeight={38}
            value={data.long[0]}
            showMobileArrows={isMobileVersion}
            onChange={(ev) => {
              let value = parseFloat(ev.target.value);
              if (/^\d*\.?\d*$/.test(String(value))) {
                if (value < 0) value = 0;
                if (value > 100) value = 100;
              } else {
                if (isNaN(value)) value = 0;
              }

              update({ long: [value, data.long[1]] });
            }}
          />
          %
        </div>
      </div>
      <div className="reentry_item">
        SHORT ReEntry min. UP
        <div className="reentry_input">
          {' '}
          <CustomNumberInput
            min={0}
            max={100}
            step={1}
            maxHeight={38}
            value={data.short[1]}
            showMobileArrows={isMobileVersion}
            onChange={(ev) => {
              let value = parseFloat(ev.target.value);
              if (/^\d*\.?\d*$/.test(String(value))) {
                if (value < 0) value = 0;
                if (value > 100) value = 100;
              } else {
                if (isNaN(value)) value = 0;
              }

              update({ short: [data.short[0], value] });
            }}
          />
          %
        </div>
      </div>
      <div className="reentry_item">
        SHORT ReEntry min. DOWN
        <div className="reentry_input">
          {' '}
          <CustomNumberInput
            min={0}
            max={100}
            step={1}
            maxHeight={38}
            value={data.short[0]}
            showMobileArrows={isMobileVersion}
            onChange={(ev) => {
              let value = parseFloat(ev.target.value);
              if (/^\d*\.?\d*$/.test(String(value))) {
                if (value < 0) value = 0;
                if (value > 100) value = 100;
              } else {
                if (isNaN(value)) value = 0;
              }

              update({ short: [value, data.short[1]] });
            }}
          />
          %
        </div>
      </div>
      <div className="reentry_item">
        Cancel in
        <CustomTextInput
          maxHeight={38}
          showMobileArrows={isMobileVersion}
          step={1}
          value={data.cancel}
          setValue={(data) => {
            update({ cancel: data });
          }}
        />
      </div>
      <div className="reentry_item">
        After TP
        <img
          onClick={() => {
            update({ takeprofit: !data.takeprofit });
          }}
          src={data.takeprofit ? CheckBoxChecked : CheckBox}
          alt=""
        />
      </div>
      <div className="reentry_item">
        After Trailing
        <img
          onClick={() => {
            update({ trailing: !data.trailing });
          }}
          src={data.trailing ? CheckBoxChecked : CheckBox}
          alt=""
        />
      </div>
      <div className="reentry_item">
        After trade liquidation
        <img
          onClick={() => {
            update({ liquidation: !data.liquidation });
          }}
          src={data.liquidation ? CheckBoxChecked : CheckBox}
          alt=""
        />
      </div>
      <div className="strategy__saveButtonContainer">
        <div
          style={{ color: 'white' }}
          className="strategy__saveButton"
          onClick={() => {
            onRunBackTest();
          }}
        >
          Save
        </div>
      </div>
    </div>
  );
};
export default memo(ReEntryCooldownSettings);
