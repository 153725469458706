import { type FC } from 'react';
import { TabSwitcher } from '../../../../../components/TabSwitcher';
import '../History.module.scss';
import './FullPageChart.scss';
import { ChartTabs, ChartTime } from '../../../../../enums/History';
import { DatePicker } from './DatePicker/DatePicker';
import CustomDropDown from './DateDropDown/CustomStyleSettingsDropDown';

// import { stepPeriods } from '../../../../../configs/chart';

interface IProps {
  selectedCharTab: ChartTabs;
  selectedCharTimeTab: ChartTime;
  currChartTime: number;
  setCurrChartTime: (value: number) => void;
  changeChatTap: (mode: ChartTabs) => void;
  changeCharTimeTab: (mode: ChartTime) => void;
}

export const StrategyTesterExtraTabs: FC<IProps> = (props) => {
  const {
    selectedCharTab,
    selectedCharTimeTab,
    changeChatTap,
    changeCharTimeTab,
    currChartTime,
    setCurrChartTime,
  } = props;
  const isMobileVersion = window.innerWidth < 768;

  return (
    <div className="strategyTesterExtraTabs">
      {selectedCharTab === ChartTabs.BARS && !isMobileVersion && (
        <>
          <DatePicker
            period={selectedCharTimeTab}
            value={currChartTime}
            setValue={(e) => {
              changeCharTimeTab(selectedCharTimeTab);
              setCurrChartTime(e);
            }}
          />
          <TabSwitcher
            className="chartTimeTab"
            defaultTab={selectedCharTimeTab}
            onChange={(tabKey: ChartTime) => {
              setCurrChartTime(Date.now());
              changeCharTimeTab(tabKey);
            }}
            options={[
              { tabId: ChartTime.DAY, tabLabel: 'Day' },
              { tabId: ChartTime.MONTH, tabLabel: 'Month' },
              { tabId: ChartTime.YEAR, tabLabel: 'Year' },
            ]}
          />
        </>
      )}
      {selectedCharTab === ChartTabs.BARS && isMobileVersion && (
        <div className="datePickerMobContainer">
          <DatePicker
            period={selectedCharTimeTab}
            value={currChartTime}
            setValue={setCurrChartTime}
          />
        </div>
      )}
      <div className="tabsContainer">
        {' '}
        <TabSwitcher
          className="chartTab"
          defaultTab={selectedCharTab}
          onChange={(tabKey: ChartTabs) => {
            changeChatTap(tabKey);
          }}
          options={[
            { tabId: ChartTabs.BARS, tabLabel: 'Bars' },
            { tabId: ChartTabs.LINES, tabLabel: 'Line' },
          ]}
        />
        {isMobileVersion && selectedCharTab === ChartTabs.BARS && (
          <CustomDropDown
            options={[
              { tabId: ChartTime.DAY, tabLabel: 'Day' },
              { tabId: ChartTime.MONTH, tabLabel: 'Month' },
              { tabId: ChartTime.YEAR, tabLabel: 'Year' },
            ]}
            activeOption={
              selectedCharTimeTab === ChartTime.DAY
                ? { tabId: ChartTime.DAY, tabLabel: 'Day' }
                : selectedCharTimeTab === ChartTime.MONTH
                ? { tabId: ChartTime.MONTH, tabLabel: 'Month' }
                : { tabId: ChartTime.YEAR, tabLabel: 'Year' }
            }
            onChange={(value: ChartTime) => {
              setCurrChartTime(Date.now());
              changeCharTimeTab(value);
            }}
          />
        )}
      </div>
    </div>
  );
};
