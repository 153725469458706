import { type FC, type MutableRefObject, useEffect, useId, useRef, useState } from 'react';
import cn from 'classnames';

import './RoundGTumbler.scss';

interface IGCTumblerProps {
  size?: 'small' | 'large' | null;
  reference?: MutableRefObject<HTMLInputElement>;
  name: string;
  onToggle?: (state: boolean) => void;
  checked?: boolean;
  lightBackground?: boolean;
}

const RoundGTumbler: FC<IGCTumblerProps> = (props) => {
  const id = useId();

  const ref = props.reference ?? useRef<HTMLInputElement>();

  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.checked = props.checked;
      setChecked(props.checked);
    }
  }, [ref.current, props.checked]);

  return (
    <div
      style={props.lightBackground && { backgroundColor: 'rgba(69, 65, 84, 1)' }}
      className={cn(
        'round-g-tumbler',
        props.size === 'small' && 'round-g-tumbler_small',
        props.size === 'large' && 'round-g-tumbler_large',
        checked && 'round-g-tumbler_checked',
      )}
    >
      <input
        ref={ref}
        id={id}
        name={props.name}
        checked={checked}
        type="checkbox"
        onChange={(event) => {
          if (props.onToggle) {
            props.onToggle(event.target.checked);
          }

          setChecked(event.target.checked);
        }}
      />

      <label
        htmlFor={id}
        className={cn(
          'round-g-tumbler-view',
          props.size === 'small' && 'round-g-tumbler-view_small',
          props.size === 'large' && 'round-g-tumbler-view_large',
        )}
      >
        <div
          style={{
            backgroundColor: checked ? '' : props.lightBackground ? 'rgb(141, 137, 162)' : '',
          }}
          className={cn(
            'round-g-tumbler-view__circle',
            props.size === 'small' && 'round-g-tumbler-view__circle_small',
            props.size === 'large' && 'round-g-tumbler-view__circle_large',
          )}
        />
      </label>
    </div>
  );
};

export default RoundGTumbler;
