import type { IAllPublishedStrategy } from 'pages/UserProfile/interfaces/interfaces';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSelector } from 'redux/rootReducer';
import { apiStrategy } from 'services/api/Strategy/ApiStrategy';
import { generateUniqueId } from 'services/utils/generateId';
import { Components } from '../../Components';
import {
  fetchStrategyData,
  setSelectedStrategyId,
  setStrategies as setReduxStrategies,
} from 'redux/strategy/reducer';

const PublishedStrategyElement = ({
  strategy,
  isMerge,
  setStrategyNode,
  addStrategyHistory,
  setStrategyEnabled,
}: {
  strategy: IAllPublishedStrategy;
  isMerge?: boolean;
  setStrategyNode?: (value: Components | null) => void;
  addStrategyHistory?: (value: { _id: string; name: string; isMerged?: boolean }) => void;
  setStrategyEnabled?: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  // const strategies = useSelector((state) => state.strategy.strategies);
  // const mergedStrategies = useSelector((state) => state.strategy.mergedStrategies);
  // const notMerge = mergedStrategies.findIndex((e) => e._id === strategy.id) === -1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.auth.user?.login);
  const myStrategy = strategy?.author?.login === login;

  const [isCopied, setIsCopied] = useState(false);
  return (
    <div key={generateUniqueId()} className="strategy-element">
      {' '}
      <div className="strategy-element_info">
        <div className="strategy-element_info_name"> {strategy.name}</div>
        <a
          className="strategy-element_info_status"
          target="_blank"
          rel="noreferrer"
          href={'/profile/' + strategy?.author?.login}
        >
          {' '}
          {isMerge && <>{strategy.strategies} strategies, </>}
          {!isMerge && <> {strategy.isPublic ? 'Public' : 'Private'}, </>} Author:{' '}
          <span>{strategy?.author?.login} </span>
        </a>
      </div>
      <div className="strategy-element_buttons">
        {!isMerge && (
          <>
            {strategy.isPublic && (
              <button
                style={{ opacity: myStrategy && 0.5, cursor: myStrategy && 'not-allowed' }}
                className="strategy-element_buttons_copy"
                onClick={() => {
                  if (myStrategy) return;
                  apiStrategy.copyStrategy(strategy.id, strategy.mode).then((r) => {
                    if (!r) {
                      setIsCopied(false);
                    } else {
                      apiStrategy
                        .getStrategies()
                        .then((result) => {
                          dispatch(setReduxStrategies(result));
                          const createdstrategy = result.find((e) => e._id === r);
                          return createdstrategy;
                        })
                        .then((strategy) => {
                          setStrategyEnabled(true);
                          //  @ts-expect-errors
                          dispatch(fetchStrategyData(strategy._id));
                          setSelectedStrategyId(strategy._id);
                          setStrategyNode(Components.CONSTRUCTOR);
                          addStrategyHistory({
                            _id: strategy._id,
                            name: strategy.name,
                            isMerged: false,
                          });
                          setTimeout(() => {
                            navigate(`/?id=${strategy._id}`);
                          }, 500);
                        });
                    }
                  });
                }}
              >
                {isCopied ? 'Copied' : 'Copy'}
              </button>
            )}
          </>
        )}
        <button
          onClick={() => window.open('/strategy/' + strategy.id, '_blank')}
          className="strategy-element_buttons_preview"
        >
          Preview
        </button>
      </div>
    </div>
  );
};

export default PublishedStrategyElement;
