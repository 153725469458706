import type { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'redux/rootReducer';

import GButton, { ButtonColors, ButtonTypes } from 'components/GButton/GButton';
import { Components } from '../../Components';
import './strategyEditorEmpty.scss';
import onImg from 'assets/images/Strategy/NoStrategy.svg';
import offImg from 'assets/images/Strategy/DisabledConstructor.svg';

interface IStrategyEditorEmptyProps {
  strategyEnabled: boolean;
  setStrategyEnabled: Dispatch<SetStateAction<boolean>>;
  setStrategyNode: Dispatch<SetStateAction<Components | null>>;
}

const StrategyEditorEmpty: FC<IStrategyEditorEmptyProps> = (props) => {
  const strategies = useSelector((state) => state.strategy.strategies);
  return (
    <div className="strategy-editor-empty">
      <img
        className="strategy-editor-empty__icon"
        src={props.strategyEnabled ? onImg : offImg}
        alt="Strategy editor"
      />

      <span className="strategy-editor-empty__title">
        {props.strategyEnabled ? 'You no have strategy!' : 'Strategy manager disabled'}
      </span>
      <p
        style={{ width: props.strategyEnabled ? '84%' : '' }}
        className="strategy-editor-empty__description"
      >
        {props.strategyEnabled
          ? 'Select an existing or create a new strategy'
          : 'Please turn on or press button for open the strategy!'}
      </p>

      <div className="strategy-editor-empty-actions">
        {props.strategyEnabled ? (
          <>
            <GButton
              type={ButtonTypes.BUTTON}
              title="Create strategy"
              color={ButtonColors.VIOLET}
              disabled={strategies.length >= 25}
              bordered={false}
              filled={true}
              height={44}
              onClick={() => {
                props.setStrategyNode(Components.MODES);
              }}
            />
            <GButton
              type={ButtonTypes.BUTTON}
              title="Create a new merge"
              color={ButtonColors.PURPLE}
              bordered={false}
              filled={true}
              height={44}
              onClick={() => {
                props.setStrategyNode(Components.CREATE_MERGED_STRATEGY);
              }}
            />

            <GButton
              type={ButtonTypes.BUTTON}
              title="Saved strategies"
              color={ButtonColors.GRAY}
              bordered={false}
              filled={true}
              height={44}
              onClick={() => {
                props.setStrategyNode(Components.SAVED_STRATEGIES);
              }}
            />
            {/* 
            <GButton
              type={ButtonTypes.BUTTON}
              title="Merged strategies"
              color={ButtonColors.GRAY}
              bordered={false}
              filled={true}
              height={44}
              onClick={() => {
                props.setStrategyNode(Components.MERGED_STRATEGIES);
              }}
            /> */}

            <GButton
              type={ButtonTypes.BUTTON}
              title="Public library"
              color={ButtonColors.GRAY}
              bordered={false}
              filled={true}
              height={44}
              onClick={() => {
                props.setStrategyNode(Components.LIBRARY);
              }}
            />
          </>
        ) : (
          <GButton
            type={ButtonTypes.BUTTON}
            title="Enable strategy manager"
            color={ButtonColors.VIOLET}
            bordered={false}
            filled={true}
            width={170}
            onClick={() => {
              props.setStrategyEnabled(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default StrategyEditorEmpty;
