import { useRef, useEffect, useState, type FC } from 'react';
import {
  createChart,
  type IChartApi,
  type Time,
  type ChartOptions,
  type DeepPartial,
  ColorType,
} from 'lightweight-charts';

import './LinesChart.scss';
import { useSelector } from 'redux/rootReducer';
import { type ITradeData } from 'interfaces/ITrade';
import useWindowWidth from 'hooks/useWindowWidth';
interface IProps {
  isConstructorOpen?: boolean;
}
const LinesChart: FC<IProps> = ({ isConstructorOpen }) => {
  const deposit = useSelector((state) => state?.strategy?.selectedStrategy?.property?.deposit);
  const { allTrades, showStatistics: isMetrics } = useSelector((state) => state.chart);
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chart = useRef<IChartApi | null>(null);
  const resizeObserver = useRef<ResizeObserver | null>(null);
  const baselineSeries = useRef<any>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [trades, setTrades] = useState<ITradeData[]>([]);
  const width = useWindowWidth();

  const updateChartData = (): void => {
    let cumulativePnl = 0;
    const formattedData = [{ time: 0, value: 0, pnl: 0 }];

    trades.forEach((item, index) => {
      if (!item.exit_time) return;
      cumulativePnl += item.pnl;
      formattedData.push({
        time: formattedData.length,
        value: cumulativePnl,
        pnl: item.pnl,
      });
    });

    const currData = baselineSeries.current.data();
    const dataMismatch =
      currData.length !== formattedData.length ||
      currData.some((e: { value: number }, i: number) => e.value !== formattedData[i].value);

    if (dataMismatch) {
      baselineSeries.current.setData(formattedData);
      chart.current.applyOptions({
        localization: {
          timeFormatter: (time: Time) => {
            const formattedItem = formattedData[+time];
            if (!formattedItem) {
              return `${+time}: undefined pnl`;
            }
            return `${+time}: ${formattedItem.pnl.toFixed(2)}$`;
          },
        },
      });
      chart.current.timeScale().fitContent();
    }
  };
  const resetChart = (): void => {
    if (!chart.current) return;
    chart.current.timeScale().setVisibleLogicalRange({ from: 0, to: trades.length });
    updateChartData();
    setButtonVisible(false);
  };
  const backgroundColor = windowWidth < 768 ? 'rgb(10, 7, 26)' : 'rgb(19, 14, 45)';
  useEffect(() => {
    setTrades(
      allTrades
        .slice()
        .sort((a, b) => (a.exit_time ?? Number.MAX_VALUE) - (b.exit_time ?? Number.MAX_VALUE)),
    );
  }, [allTrades, deposit]);

  useEffect(() => {
    if (chart.current && baselineSeries.current) {
      updateChartData();
    }
  }, [trades]);

  const visibleButton = (): void => {
    setButtonVisible(true);
  };

  useEffect(() => {
    if (chartContainerRef.current) {
      const chartOptions: DeepPartial<ChartOptions> = {
        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        // timeScaleOptions: {
        //   right: 1,
        // },
        layout: {
          textColor: 'white',
          background: {
            type: ColorType.Solid,
            color: backgroundColor,
          },
        },
        grid: {
          vertLines: {
            color: 'rgba(255, 255, 255, 0.2)',
          },
          horzLines: {
            color: 'rgba(255, 255, 255, 0.2)',
          },
        },
        timeScale: {
          timeVisible: false,
          minBarSpacing: 0.01,
          // lockVisibleTicks: false,
          tickMarkFormatter: (time: number) => `${time}`,
        },
        crosshair: {
          vertLine: {
            labelBackgroundColor: '#694EF0',
            labelVisible: true,
            // labelFontColor: (time) => (data[+time].pnl > 0 ? 'green' : 'red'),
          },
          horzLine: {
            labelBackgroundColor: '#694EF0',
          },
        },
        localization: {
          timeFormatter: (time: Time) => {
            let cumulativePnl = 0;
            const formattedData = [{ time: 0, value: 0, pnl: 0 }];
            trades.forEach((item, index) => {
              if (!item.exit_time) return;
              cumulativePnl += item.pnl;
              formattedData.push({
                time: index + 1,
                value: cumulativePnl,
                pnl: item.pnl,
              });
            });
            return `${+time}: ${formattedData[+time]?.pnl.toFixed(2)}$`;
          },
        },
      };
      chart.current = createChart(chartContainerRef.current, chartOptions);

      baselineSeries.current = chart.current.addBaselineSeries({
        baseValue: { type: 'price', price: 0 },
        topLineColor: '#30D95F',
        topFillColor1: 'rgba(48, 217, 95, 0.28)',
        topFillColor2: 'rgba(48, 217, 95, 0.05)',
        bottomLineColor: '#F60707',
        bottomFillColor1: 'rgba(246, 7, 7, 0.05)',
        bottomFillColor2: 'rgba(246, 7, 7, 0.28)',
      });

      resizeObserver.current = new ResizeObserver(() => {
        chart.current?.applyOptions({
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
        });
      });

      resizeObserver.current.observe(chartContainerRef.current);

      updateChartData();

      const handleResize = (): void => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        resizeObserver.current?.disconnect();
        chart.current?.remove();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [width, isMetrics, isConstructorOpen]);
  return (
    <>
      <div
        ref={chartContainerRef}
        onClick={visibleButton}
        onTouchStart={visibleButton}
        className="chart-container"
      />
      {buttonVisible && (
        <button className="reset-chart" onClick={resetChart}>
          default position
        </button>
      )}
    </>
  );
};

export default LinesChart;
