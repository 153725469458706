import {
  CandleType,
  LineType,
  PolygonType,
  TooltipShowRule,
  TooltipShowType,
} from '@basttyy/klinecharts';

export const fontWeightTypes = [
  { id: 1, name: 'light' },
  { id: 2, name: 'normal' },
  { id: 3, name: 'medium' },
  { id: 4, name: 'semi-bold' },
  { id: 5, name: 'bold' },
];
export const numberSizes = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
  { id: 6, name: '6' },
  { id: 7, name: '7' },
  { id: 8, name: '8' },
  { id: 9, name: '9' },
  { id: 10, name: '10' },
  { id: 11, name: '11' },
  { id: 12, name: '12' },
  { id: 13, name: '13' },
  { id: 14, name: '14' },
  { id: 15, name: '15' },
];
export const positions = [
  { id: 1, name: 'right' },
  { id: 2, name: 'left' },
];
export const lineStyleTypes = [
  { id: 1, name: LineType.Dashed },
  { id: 2, name: LineType.Solid },
];
export const textStyleTypes = [
  { id: 1, name: PolygonType.Fill },
  { id: 2, name: PolygonType.Stroke },
  { id: 3, name: PolygonType.StrokeFill },
];
export const candleTypes = [
  { id: 1, name: CandleType.CandleSolid },
  { id: 2, name: CandleType.CandleStroke },
  { id: 4, name: CandleType.CandleDownStroke },
  { id: 5, name: CandleType.Ohlc },
  { id: 6, name: CandleType.Area },
];
export const candleTooltipShowRuleTypes = [
  { id: 1, name: TooltipShowRule.Always },
  { id: 2, name: TooltipShowRule.FollowCross },
  { id: 3, name: TooltipShowRule.None },
];
export const candleTooltipShowTypes = [
  { id: 1, name: TooltipShowType.Standard },
  { id: 2, name: TooltipShowType.Rect },
];
export const candleTooltipRectPositions = [
  { id: 1, name: 'fixed' },
  { id: 2, name: 'pointer' },
];
export const fontFamilyTypes = [
  { id: 1, name: 'Helvetica Neue' },
  { id: 2, name: 'Nunito' },
  { id: 3, name: 'Inter' },
  { id: 4, name: 'Michroma' },
  { id: 5, name: 'sans-serif' },
];
