import type { MutableRefObject, ReactElement, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import cn from 'classnames';

import './GSelect.scss';

export interface IGSelectElement {
  name: string;
  icon?: string;
  disabled?: boolean;
}

interface IGSelectProps<T = [], E = never> {
  reference: MutableRefObject<E>;
  source: T;
  label?: string;
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void;
}

function GSelect<T, E extends IGSelectElement>(props: IGSelectProps<T, E>): ReactElement {
  const source: Array<E> = Array.isArray(props.source) ? props.source : Object.values(props.source);

  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [chosen, setChosen] = useState<E>(source[0]);

  useEffect(() => {
    props.reference.current = chosen;
  }, [chosen]);

  return (
    <div className={cn('g-select', collapsed && 'g-select_collapsed')}>
      {props.label && <div className="g-select__label">{props.label}</div>}

      <div
        className="g-select-input"
        onClick={() => {
          setCollapsed((prev) => !prev);
        }}
      >
        <div className="g-select-input-choosen">
          <div className="g-select-input-choosen-content g-select-element">
            {chosen.icon && (
              <div className="g-select-element__icon">
                <img src={chosen.icon} alt={chosen.name} />
              </div>
            )}

            <span className="g-select-element__name">{chosen.name}</span>
          </div>

          <svg
            className="g-select-input__expand"
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7 7L13 0.999999"
              stroke="#0049FF"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </div>

        <div className="g-select-input-list">
          <div className="g-select-input-list__wrapper">
            {source.map((el) => {
              return (
                <div
                  key={el.name}
                  className={cn(
                    'g-select-input-list-element g-select-element',
                    el.disabled && 'g-select-element_disabled',
                  )}
                  onClick={() => {
                    if (!el.disabled) {
                      setChosen(el);
                    }
                  }}
                >
                  {el.icon && (
                    <div className="g-select-element__icon">
                      <img src={el.icon} alt={el.name} />
                    </div>
                  )}

                  <span className="g-select-element__name">{el.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GSelect;
