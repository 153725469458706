import { Roles } from '../enums/User';

export const rolesArray: Roles[] = [Roles.User, Roles.BetaTester];

export const rolesNames: Record<Roles, string> = {
  [Roles.User]: 'USER',
  [Roles.BetaTester]: 'BETA TESTER',
};
export const avatarUrl = 'https://algoth-avatar.s3.eu-west-3.amazonaws.com/';
export const minTimeForChangeLogin = 31 * 24 * 60 * 60000;
