import { useState } from 'react';
import './ModeSwitcher.css';

const ModeSwitcher = (): JSX.Element => {
  const [mode, setMode] = useState(1);
  return (
    <div className="modeSwitcher">
      <div
        className="modeSwitcher-backtest-button"
        onClick={() => {
          setMode(1);
        }}
        style={{
          backgroundColor: mode === 1 && 'rgba(105, 78, 240, 1)',
          fontWeight: mode === 1 && 600,
        }}
      >
        Backtest
      </div>
      <div
        className="modeSwitcher-realtrade-button"
        style={{
          backgroundColor: mode === 0 && 'rgba(105, 78, 240, 1)',
          fontWeight: mode === 0 && 600,
          opacity: 0.5,
          cursor: 'not-allowed',
        }}
        onClick={() => {
          // setMode(0);
        }}
      >
        Live trading
      </div>
    </div>
  );
};

export default ModeSwitcher;
