import { type FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { type IMenuPlatformAccountProps } from '../../../../interfaces/IMenuPage';

import './MenuPlatformAccount.scss';
import GapIcon from 'assets/images/MenuPage/GapIcon.svg';
import DaggerIcon from 'assets/images/MenuPage/DaggerIcon.svg';
import ReloadIcon from 'assets/images/MenuPage/ReloadIcon.svg';
import {
  deletePlatformAccount,
  editPlatformAccount,
  savePlatformAccountChanges,
  switchActivePlatformAccount,
} from '../../../../redux/account/reducer';

const MenuPlatformAccount: FC<IMenuPlatformAccountProps> = ({ account, index }) => {
  const [secretKeyState, setSecretKeyState] = useState({
    value: account.secretKey,
    defaultValue: account.secretKey,
    error: '',
  });
  const [publicKeyState, setPublicKeyState] = useState({
    value: account.apiKey,
    defaultValue: account.apiKey,
    error: '',
  });
  const hideMiddleCharacters = (value: string): string => {
    if (value.length < 4) {
      return value;
    }
    const firstTwoCharacters = value.slice(0, 2);
    const lastTwoCharacters = value.slice(-2);

    return firstTwoCharacters + '*'.repeat(value.length - 4) + lastTwoCharacters;
  };
  const [nameState, setNameState] = useState({
    name: account.name,
    error: '',
  });

  const dispatch = useDispatch();
  const handleNameChange = (value: string): void => {
    setNameState((prev) => ({ ...prev, name: value, error: '' }));
    if (value === '') {
      setNameState((prev) => ({ ...prev, error: 'Invalid name. Please check and repeat' }));
    } else {
      setNameState((prev) => ({ ...prev, error: '' }));
    }
  };
  const handleSaveButton = (e): void => {
    e.preventDefault();
    if (publicKeyState.value.length < 30) {
      setPublicKeyState((prev) => ({ ...prev, error: 'Invalid API. Please check and repeat' }));
    }
    if (nameState.error === '' && publicKeyState.error === '' && secretKeyState.error === '') {
      // saving the acc
      const payload = {
        id: account.id,
        name: nameState.name,
        apiKey: publicKeyState.value,
        secretKey: secretKeyState.value,
      };
      document.documentElement.style.setProperty('zoom', '100%');
      dispatch(savePlatformAccountChanges(payload));
      setPublicKeyState((prev) => ({ ...prev, defaultValue: publicKeyState.value }));
      setSecretKeyState((prev) => ({ ...prev, defaultValue: secretKeyState.value }));
    }
  };
  const handleswitchActivePlatformAccount = (): void => {
    dispatch(switchActivePlatformAccount(account.id));
  };
  const handleeditPlatformAccount = (): void => {
    dispatch(editPlatformAccount(account.id));
  };
  const handledeletePlatformAccount = (): void => {
    document.documentElement.style.setProperty('zoom', '100%');
    dispatch(deletePlatformAccount(account.id));
  };
  const handleSecretInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const nextValue = newValue.substring(secretKeyState.defaultValue.length);
    if (newValue.length < 30) {
      setSecretKeyState((prev) => ({
        ...prev,
        error: 'Invalid Secret key. Please check and repeat',
      }));
    } else {
      setSecretKeyState((prev) => ({ ...prev, error: '' }));
    }

    if (secretKeyState.value === secretKeyState.defaultValue) {
      setSecretKeyState((prev) => ({
        ...prev,
        value: nextValue,
        defaultValue: '',
        error: nextValue.length < 30 ? 'Invalid API. Please check and repeat' : '',
      }));
    } else {
      if (event.target.value === account.secretKey) {
        setSecretKeyState((prev) => ({
          ...prev,
          error: 'Invalid Secret key. Please check and repeat',
        }));
      } else {
        newValue.length > 30 && setSecretKeyState((prev) => ({ ...prev, error: '' }));
      }
      setSecretKeyState((prev) => ({ ...prev, defaultValue: '', value: newValue }));
    }
  };
  const handlePublicInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const nextValue = newValue.substring(publicKeyState.defaultValue.length);
    if (newValue.length < 30) {
      setPublicKeyState((prev) => ({
        ...prev,
        error: 'Invalid API. Please check and repeat',
      }));
    } else {
      setPublicKeyState((prev) => ({ ...prev, error: '' }));
    }

    if (publicKeyState.value === publicKeyState.defaultValue) {
      setPublicKeyState((prev) => ({
        ...prev,
        value: nextValue,
        defaultValue: '',
        error: nextValue.length < 30 ? 'Invalid API. Please check and repeat' : '',
      }));
    } else {
      if (event.target.value === account.apiKey) {
        setPublicKeyState((prev) => ({
          ...prev,
          error: 'Invalid API. Please check and repeat',
        }));
      } else {
        newValue.length > 30 && setPublicKeyState((prev) => ({ ...prev, error: '' }));
      }
      setPublicKeyState((prev) => ({ ...prev, defaultValue: '', value: newValue }));
    }
  };
  if (account.isEdit) {
    return (
      <form key={index} className="accountsPopUp__tableEditPlatformAccountRow">
        <div>{Number(index) + 1}</div>
        <div>
          <input
            className={`${nameState.error !== '' && 'accountsPopUp__errorInput'}`}
            value={nameState.name}
            onChange={(e) => {
              handleNameChange(e.target.value);
            }}
            type="text"
            placeholder="Name"
          ></input>
          {nameState.error !== '' && <p className="accountsPopUp__error">Name is required</p>}
          <input
            className={`${publicKeyState.error !== '' && 'accountsPopUp__errorInput'}`}
            value={
              publicKeyState.defaultValue !== account.apiKey
                ? publicKeyState.value
                : hideMiddleCharacters(publicKeyState.value)
            }
            onChange={(e) => {
              handlePublicInputChange(e);
            }}
            maxLength={255}
            type="text"
            placeholder="API key"
          ></input>
          {publicKeyState.error !== '' && (
            <p className="accountsPopUp__error">{publicKeyState.error}</p>
          )}
        </div>
        <div>
          <input
            className={`${secretKeyState.error && 'accountsPopUp__errorInput'}`}
            value={
              secretKeyState.defaultValue !== account.secretKey
                ? secretKeyState.value
                : hideMiddleCharacters(secretKeyState.value)
            }
            onChange={(e) => {
              handleSecretInputChange(e);
            }}
            type="text"
            placeholder="Secret"
            maxLength={255}
          ></input>
          {secretKeyState.error !== '' && (
            <p className="accountsPopUp__error">{secretKeyState.error}</p>
          )}
        </div>
        <div>
          <div>
            <button
              className="accountsPopUp__button accountsPopUp__button_reload"
              onClick={() => {}}
            >
              <img src={ReloadIcon} alt="ReloadIcon" />
            </button>
            <button
              className="accountsPopUp__button accountsPopUp__button_save"
              onClick={handleSaveButton}
            >
              Save
            </button>
            {account.status && (
              <div className="accountsPopUp__button accountsPopUp__button_active">Active</div>
            )}
          </div>
          <button
            className="accountsPopUp__button accountsPopUp__button_delete"
            onClick={handledeletePlatformAccount}
          >
            Delete
          </button>
        </div>
      </form>
    );
  }
  return (
    <>
      <div key={index} className="accountsPopUp__tableAccountRow">
        <div>{Number(index) + 1}</div>
        <div>{account.name}</div>
        <div>
          {account.status ? (
            <div className="accountsPopUp__connectedCircle"></div>
          ) : (
            <div className="accountsPopUp__notConnectedCircle"></div>
          )}
        </div>
        <div>
          <div>Future:</div>
          {account.future ? (
            <img src={GapIcon} alt="success" />
          ) : (
            <img src={DaggerIcon} alt="fail" />
          )}
        </div>
        <div>
          <div>Spot:</div>
          {account.spot ? <img src={GapIcon} alt="success" /> : <img src={DaggerIcon} alt="fail" />}
        </div>
        <div>
          <div>Only reading:</div>
          {account.readOnly ? (
            <img src={GapIcon} alt="success" />
          ) : (
            <img src={DaggerIcon} alt="fail" />
          )}
        </div>
        <div>
          <img src={ReloadIcon} alt="ReloadIcon" />
          <button
            className="accountsPopUp__button accountsPopUp__button_edit"
            onClick={handleeditPlatformAccount}
          >
            Edit
          </button>
          {account.status ? (
            <div className="accountsPopUp__button accountsPopUp__button_active">Active</div>
          ) : (
            <button
              className="accountsPopUp__button accountsPopUp__button_switch"
              onClick={handleswitchActivePlatformAccount}
            >
              Switch
            </button>
          )}
        </div>
      </div>
      <ul className="accountsPopUp__mobile">
        <li key={index}>
          <div className="wrap">
            <div className="number">{Number(index) + 1}</div>
            <div className="name">{account.name}</div>
            <div className="status">
              {account.status ? (
                <div className="accountsPopUp__connectedCircle"></div>
              ) : (
                <div className="accountsPopUp__notConnectedCircle"></div>
              )}
            </div>
            <div className="buttons">
              <button
                className="accountsPopUp__button accountsPopUp__button_edit"
                onClick={handleeditPlatformAccount}
              >
                Edit
              </button>
              {account.status ? (
                <div className="accountsPopUp__button accountsPopUp__button_active">Active</div>
              ) : (
                <button
                  className="accountsPopUp__button accountsPopUp__button_switch"
                  onClick={handleswitchActivePlatformAccount}
                >
                  Switch
                </button>
              )}
            </div>
          </div>
          <div className="info">
            <div className="info-col">
              <div>Future:</div>
              {account.future ? (
                <img src={GapIcon} alt="success" />
              ) : (
                <img src={DaggerIcon} alt="fail" />
              )}
            </div>
            <div className="info-col">
              <div>Spot:</div>
              {account.spot ? (
                <img src={GapIcon} alt="success" />
              ) : (
                <img src={DaggerIcon} alt="fail" />
              )}
            </div>
            <div className="info-col">
              <div>Only reading:</div>
              {account.readOnly ? (
                <img src={GapIcon} alt="success" />
              ) : (
                <img src={DaggerIcon} alt="fail" />
              )}
            </div>
          </div>
        </li>
        <li>123</li>
      </ul>
    </>
  );
};

export default MenuPlatformAccount;
