import { type FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import MenuItem from './Components/MenuItem/MenuItem';
import CloseSettingsIcon from 'assets/images/Strategy/CloseSettingsIcon.svg';
import SettingsIcon from 'assets/images/SettingsIcon.svg';
import EditProfileIcon from 'assets/images/Profile/EditProfileIcon.svg';
import StatisticIcon from 'assets/images/Profile/StatisticIcon.svg';
import ApiIcon from 'assets/images/Profile/ApiIcon.svg';

import './UserSettings.scss';
import { useSelector } from 'redux/rootReducer';
import { EditProfileSettings } from './Components/SettingsComponents/EditeProfileSettings/EditProfileSettings';
import { StatisticSettings } from './Components/SettingsComponents/StatisticSettings/StatisticSettings';
import { ApiSettings } from './Components/SettingsComponents/ApiSettings/ApiSettings';
import useWindowWidth from 'hooks/useWindowWidth';

export type MenuItemType = 'Edit profile' | 'Visibility of statistics' | 'API keys' | null;

const UserSettings: FC = () => {
  const [isOpened, setOpened] = useState(false);
  const mobilePage = useSelector((state) => state.homePage.mobileContent);
  const isMobileVersion = useWindowWidth() < 768;
  const [menuItem, setMenuItem] = useState<MenuItemType>(isMobileVersion ? null : 'Edit profile');
  useEffect(() => {
    if (mobilePage !== 'chart') {
      setOpened(false);
    }
  }, [mobilePage]);
  useEffect(() => {
    const mainProfileContainer = document.getElementById('mainProfileContainer');
    if (isOpened) {
      mainProfileContainer?.classList.add('mainConatiner_noScroll');
    } else {
      mainProfileContainer?.classList.remove('mainConatiner_noScroll');
    }
  }, [isOpened]);

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          setOpened(true);
        }}
        className="settings"
      >
        <img src={SettingsIcon} alt="settings icon" />
      </div>

      {isOpened &&
        createPortal(
          <div
            className={'container'}
            onClick={() => {
              setOpened(false);
            }}
          >
            <div
              className="container__content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {!isMobileVersion && (
                <div className="container__content_header">
                  <div className="container__content_header_text">Profile Settings</div>
                  <img
                    className="container__content_header_icon"
                    onClick={() => {
                      setOpened(false);
                    }}
                    src={CloseSettingsIcon}
                    alt="close"
                  />
                </div>
              )}
              {isMobileVersion && (
                <div
                  className={`container__content_header ${
                    menuItem ? 'container__content_header_light' : ''
                  }`}
                >
                  <div className="container__content_header_text">
                    {menuItem || 'Profile Settings'}
                  </div>
                  <img
                    className="container__content_header_icon"
                    onClick={() => {
                      if (menuItem) {
                        setMenuItem(null);
                      } else {
                        setOpened(false);
                      }
                    }}
                    src={CloseSettingsIcon}
                    alt="close"
                  />
                </div>
              )}
              <div className="container__content_main">
                {((isMobileVersion && menuItem === null) || !isMobileVersion) && (
                  <div className="container__content_main_menu">
                    <MenuItem
                      itemName="Edit profile"
                      itemIcon={EditProfileIcon}
                      menuItem={menuItem}
                      setMenuItem={setMenuItem}
                    />
                    <MenuItem
                      itemName="Visibility of statistics"
                      itemIcon={StatisticIcon}
                      menuItem={menuItem}
                      setMenuItem={setMenuItem}
                      disabled
                    />
                    <MenuItem
                      itemName="API keys"
                      itemIcon={ApiIcon}
                      menuItem={menuItem}
                      setMenuItem={setMenuItem}
                      disabled
                    />
                  </div>
                )}

                {((isMobileVersion && menuItem !== null) || !isMobileVersion) && (
                  <div className="container__content_main_content">
                    {menuItem === 'Edit profile' && <EditProfileSettings />}
                    {menuItem === 'Visibility of statistics' && <StatisticSettings />}
                    {menuItem === 'API keys' && <ApiSettings />}
                  </div>
                )}
              </div>
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

export default UserSettings;
