import { type FC } from 'react';
import './StrategySettingsHeader.scss';

interface IProps {
  name: string;
  strategyType?: 'long' | 'short';
  conditionName?: string;
  onClose: () => void;
}

export const StrategySettingsHeader: FC<IProps> = (props) => {
  return (
    <div className="strategy-settings-header">
      <button onClick={props.onClose} className="strategy-settings-header__button">
        <div className="strategy-settings-header__button_img"></div>Back
      </button>
      <h3>{props.name}</h3>
      {props.strategyType && (
        <div
          className="strategy-settings-header__type"
          style={{ color: props.strategyType === 'long' ? 'rgb(0, 249, 10)' : 'rgb(249, 0, 0)' }}
        >
          {props.strategyType.toLocaleUpperCase()}
        </div>
      )}

      {props.conditionName && (
        <div className="strategy-settings-header__condition">{props.conditionName}</div>
      )}
    </div>
  );
};
