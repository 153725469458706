import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../HomePageStyles.module.css';
import classNames from 'classnames';
import NavigationArrow from 'assets/images/NavigationArrow.svg';
import ArrowDisabled from 'assets/images/ArrowDisabled.svg';
import BellIcon from 'assets/images/BellIcon.svg';
import ChartIcon from 'assets/images/Strategy/MobileContent/ChartIcon.svg';
import MetricsIcon from 'assets/images/Strategy/MobileContent/MetricsIcon.svg';
import TradesIcon from 'assets/images/Strategy/MobileContent/TradesIcon.svg';
import ConstructorIcon from 'assets/images/Strategy/MobileContent/ConstructorIcon.svg';
import { useSelector } from 'redux/rootReducer';
import { useDispatch } from 'react-redux';
import { setMobileContent } from 'redux/homePage/reducer';

// const RENDER_TRADES_LIMIT = 100;
export const HomePageMobileTab: React.FC = () => {
  const VIOLET = 'rgba(105, 78, 240, 1)';
  const [switcherMode, setSwitcherMode] = useState(0);
  const allTrades = useSelector((state) => state.chart.allTrades);
  const stats = useSelector((state) => state.chart.stats);
  const showStatistics = useSelector((state) => state.chart.showStatistics);
  const dispatch = useDispatch();
  const showMetricsData =
    stats && (stats?.trades_count[0] !== 0 || stats?.trades_count[1] !== 0) && showStatistics;
  return (
    <>
      <div onClick={() => {}} className={styles.mobileHomePageHeader}>
        <Link className={styles.mobileHomePageHeaderText} to="/menu">
          <img src={NavigationArrow} alt="navigationArrow" />
          <div>Main menu</div>
        </Link>
        <img src={BellIcon} alt="navigationArrow" />
      </div>
      <div className={styles.homeSwitcher}>
        <div
          className={styles.homeSwitcherOption}
          style={{ backgroundColor: switcherMode === 0 ? VIOLET : '' }}
          onClick={() => {
            setSwitcherMode(0);
          }}
        >
          Backtest mode
        </div>
        <div
          className={styles.homeSwitcherOption}
          style={{ backgroundColor: switcherMode === 1 ? VIOLET : '' }}
          onClick={() => {
            // setSwitcherMode(1);
          }}
        >
          Live trading mode
        </div>
      </div>
      <div
        className={styles.mobileHomePageHeaderNavItem}
        onClick={() => {
          dispatch(setMobileContent('constructor'));
        }}
      >
        <div className={styles.mobileHomePageHeaderNavItemName}>
          {' '}
          <img src={ConstructorIcon} alt="constructor" />
          Strategy Manager
        </div>
        <img src={NavigationArrow} alt="navigationArrow" />
      </div>
      <div
        className={styles.mobileHomePageHeaderNavItem}
        onClick={() => {
          dispatch(setMobileContent('chart'));
        }}
      >
        <div className={styles.mobileHomePageHeaderNavItemName}>
          {' '}
          <img src={ChartIcon} alt="chart" />
          Chart
        </div>
        <img src={NavigationArrow} alt="navigationArrow" />
      </div>
      <div
        className={styles.mobileHomePageHeaderNavItem}
        onClick={() => {
          showMetricsData && dispatch(setMobileContent('metrics'));
        }}
      >
        <div
          className={classNames(styles.mobileHomePageHeaderNavItemName, {
            [styles.mobileHomePageHeaderNavItemNameDisabled]: !showMetricsData,
          })}
        >
          {' '}
          <img src={MetricsIcon} alt="" />
          Metrics
        </div>
        <img src={showMetricsData ? NavigationArrow : ArrowDisabled} alt="navigationArrow" />
      </div>
      <div
        className={styles.mobileHomePageHeaderNavItem}
        onClick={() => {
          allTrades.length > 0 && dispatch(setMobileContent('trades'));
        }}
      >
        <div
          className={classNames(styles.mobileHomePageHeaderNavItemName, {
            [styles.mobileHomePageHeaderNavItemNameDisabled]: allTrades.length === 0,
          })}
        >
          {' '}
          <img src={TradesIcon} alt="" />
          Trades
        </div>
        <img src={allTrades.length > 0 ? NavigationArrow : ArrowDisabled} alt="navigationArrow" />
      </div>
    </>
  );
};
