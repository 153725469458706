import React from 'react';
import styles from './ProfileContentSwitcherMenu.module.css';
import { useSelector } from '../../../../redux/rootReducer';
import { useDispatch } from 'react-redux';
import HomeMenuIcon from 'assets/images/Profile/HomeMenuIcon.svg';
import DashboardMenuIcon from 'assets/images/Profile/DashboardMenuIcon.svg';
import ProfileMenuIcon from 'assets/images/Profile/ProfileMenuIcon.svg';
import LeaderMenuIcon from 'assets/images/Profile/LeaderMenuIcon.svg';
import { setProfilePage } from 'redux/profile/reducer';
import useWindowWidth from 'hooks/useWindowWidth';

interface MobileContentSwitcherMenuProps {
  settingsVersion?: boolean;
  fixed?: boolean;
}
export const ProfileContentSwitcherMenu: React.FC<MobileContentSwitcherMenuProps> = ({
  settingsVersion,
  fixed,
}) => {
  const contentStateValue = useSelector((state) => state.homePage.mobileContent);
  const dispatch = useDispatch();
  const isMobile = useWindowWidth() < 768;
  if (isMobile) {
    return (
      <div
        className={styles.mobileContentSwitcher}
        style={{
          position: fixed ? 'fixed' : settingsVersion ? 'absolute' : 'relative',
          bottom: 0,
          left: fixed ? 0 : 'unset',
        }}
      >
        <button
          className={`${styles.mobileContentSwitcherItem} ${
            contentStateValue === 'home' && styles.mobileContentSwitcherItemActive
          }`}
          onClick={() => {
            dispatch(setProfilePage('home'));
          }}
        >
          <img src={HomeMenuIcon} alt="home" />
          Home
        </button>
        <button className={`${styles.mobileContentSwitcherItem}`} onClick={() => {}}>
          <img src={DashboardMenuIcon} alt="DashboardMenuIcon" />
          Dashboard
        </button>
        <button
          className={`${styles.mobileContentSwitcherItem}`}
          onClick={() => {
            dispatch(setProfilePage('profile'));
          }}
        >
          <img src={ProfileMenuIcon} alt="Profile" />
          Profile
        </button>
        <button className={`${styles.mobileContentSwitcherItem}`} onClick={() => {}}>
          <img src={LeaderMenuIcon} alt=" Leader" />
          Leader B..
        </button>
      </div>
    );
  }
};
