// themeReducer.ts
import { type IRootState } from '../rootReducer';

export const TOGGLE_THEME = 'TOGGLE_THEME';

export const toggleTheme = (): { type: string } => ({
  type: TOGGLE_THEME,
});
export interface ThemeState {
  homeBackground: string;
  homeHeaderBackground: string;
  textColor: string;
  chartHeaderBakcground: string;
  chartAsideMenuBackground: string;
  chartAsideMenuIconFill: string;
  historyBackground: string;
  chartBackground: string;
}

const darkTheme: ThemeState = {
  homeBackground: '#0a081a',
  homeHeaderBackground: '#0a081a',
  textColor: 'white',
  chartBackground: '#0c1122',
  chartHeaderBakcground: '#0a081a',
  chartAsideMenuBackground: '#0a081a',
  chartAsideMenuIconFill: 'white',
  historyBackground: 'rgba(19, 14, 45, 1)',
};

const lightTheme: ThemeState = {
  homeBackground: 'white',
  homeHeaderBackground: '#e2e2e2',
  textColor: 'black',
  chartBackground: '#e2e2e2',
  chartHeaderBakcground: '#f4f4f4',
  chartAsideMenuBackground: 'white',
  chartAsideMenuIconFill: '#051441',
  historyBackground: '#e2e2e2',
};

const initialState: ThemeState = darkTheme;

const themeReducer = (state = initialState, action: any): ThemeState => {
  switch (action.type) {
    case TOGGLE_THEME:
      return state === darkTheme ? lightTheme : darkTheme;
    default:
      return state;
  }
};

export const selectTheme = (state: IRootState): ThemeState => state.theme;
export default themeReducer;
