import { type MutableRefObject, useEffect, useLayoutEffect, useRef } from 'react';

type TCallback = (event?: Event) => void;

export const useClickAway = <R extends HTMLElement>(cb: TCallback): MutableRefObject<R> => {
  const ref = useRef<R>(null);
  const refCb = useRef<TCallback>(cb);

  useLayoutEffect(() => {
    refCb.current = cb;
  });

  useEffect(() => {
    const handler = (e: Event): void => {
      const element = ref.current;
      if (element && !element.contains(e.target as Node)) {
        refCb.current(e);
      }
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  return ref;
};
