import classNames from 'classnames';
import { Component, type ReactNode } from 'react';

import styles from './Button.module.css';

export enum ButtonVariants {
  PRIMARY_LIGHT = 'primaryLight',
  PRIMARY_DARK = 'primaryDark',
}

interface Props {
  variant?: ButtonVariants;
  children?: ReactNode;
  leftIcon?: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export class Button extends Component<Props> {
  public render(): ReactNode {
    const {
      children,
      className,
      leftIcon,
      disabled,
      variant = ButtonVariants.PRIMARY_LIGHT,
      onClick,
    } = this.props;
    return (
      <button
        disabled={disabled}
        className={classNames(className, styles.root, styles[variant])}
        onClick={onClick}
      >
        {leftIcon}
        {children}
      </button>
    );
  }
}
