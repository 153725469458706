import { useState, type FC } from 'react';
import './CustomStyleSettingsDropDown.scss';
import Arrow from 'assets/images/Strategy/DropDownTriangle.svg';
interface IProps {
  options: {
    id: number;
    name?: string;
    desc?: string;
  }[];
  activeOption: {
    id: number;
    name?: string;
    desc?: string;
  };
  onChange: (id: number) => void;
  nameFontWeight?: string;
  largeList?: boolean;
  numbersOnly?: boolean;
}
const CustomDropDown: FC<IProps> = ({
  options,
  onChange,
  activeOption,
  nameFontWeight,
  largeList,
  numbersOnly,
}) => {
  const [isOpened, setOpened] = useState(false);
  return (
    <div
      className={
        'custom-style-dropdown' + (numbersOnly ? ' custom-style-dropdown__numbersOnly' : '')
      }
      tabIndex={0}
      onBlur={() => {
        setOpened(false);
      }}
    >
      <div
        className={
          'custom-style-dropdown__selected' +
          (numbersOnly ? ' custom-style-dropdown__selected__numbersOnly' : '')
        }
        onClick={() => {
          setOpened(!isOpened);
        }}
      >
        <div
          style={{ fontWeight: nameFontWeight }}
          className="custom-style-dropdown__selected_name"
        >
          {activeOption.name.includes('_')
            ? activeOption.name
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
            : activeOption.name.charAt(0).toUpperCase() + activeOption.name.slice(1)}
        </div>
        <div
          style={{
            fontSize: activeOption.name ? '' : '12px',
          }}
          className="custom-style-dropdown__selected_desc"
        >
          {activeOption.desc}
        </div>
        <img
          className={
            'custom-style-dropdown__selected_arrow' +
            (isOpened ? ' custom-style-dropdown__selected_arrowOpened' : '')
          }
          src={Arrow}
          alt=">"
        />
      </div>

      {isOpened && largeList && (
        <div
          style={{ minHeight: options.length * 36 < 180 && `${options.length * 36}px ` }}
          className="custom-style-dropdown__itemsContainer"
        >
          {options.map((option, index) => (
            <div
              style={{
                borderTopLeftRadius: index === 0 ? '4px' : '',
                borderTopRightRadius: index === 0 ? '4px' : '',
              }}
              className={
                'custom-style-dropdown__item' +
                (numbersOnly ? ' custom-style-dropdown__item__numbersOnly' : '')
              }
              key={index}
              onClick={() => {
                setOpened(false);
                onChange(option.id);
              }}
            >
              <div
                style={{ fontWeight: nameFontWeight }}
                className="custom-style-dropdown__item_name"
              >
                {option.name.includes('_')
                  ? option.name
                      .split('_')
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')
                  : option.name.charAt(0).toUpperCase() + option.name.slice(1)}
              </div>
              <div
                style={{ fontSize: option.name ? '' : '12px' }}
                className="custom-style-dropdown__item_desc"
              >
                {option.desc}
              </div>
            </div>
          ))}{' '}
        </div>
      )}
      {isOpened &&
        !largeList &&
        options.map((option, index) => (
          <div
            style={{
              borderTopLeftRadius: index === 0 ? '4px' : '',
              borderTopRightRadius: index === 0 ? '4px' : '',
              borderBottomLeftRadius: index === options.length - 1 ? '4px' : '',
              borderBottomRightRadius: index === options.length - 1 ? '4px' : '',
            }}
            className={
              'custom-style-dropdown__item' +
              (numbersOnly ? ' custom-style-dropdown__item__numbersOnly' : '')
            }
            key={index}
            onClick={() => {
              setOpened(false);
              onChange(option.id);
            }}
          >
            <div
              style={{ fontWeight: nameFontWeight }}
              className="custom-style-dropdown__item_name"
            >
              {option.name}
            </div>
            <div
              style={{ fontSize: option.name ? '' : '12px' }}
              className="custom-style-dropdown__item_desc"
            >
              {option.desc}
            </div>
          </div>
        ))}
    </div>
  );
};

export default CustomDropDown;
