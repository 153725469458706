import { type FC } from 'react';
import { useSelector } from 'redux/rootReducer';
import { type ITradeData } from 'interfaces/ITrade';
import hiddenIcon from 'assets/icons/strategy-close.svg';

interface StrategyMetricsItemProps {
  el: ITradeData;
  index: number;
  toggleTrades: () => void;
}

const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');

  return `${day}.${month}.${year} ${hour}:${minute}`;
};
export const StrategyMetricsItem: FC<StrategyMetricsItemProps> = ({ el, toggleTrades }) => {
  const { qtyPrecision: qtyPrecisionData, pricePrecision: pricePrecisionData } = useSelector(
    (state) => state.chart,
  );
  const pricePrecision = el.ticker ? pricePrecisionData[el.ticker] : pricePrecisionData;
  const qtyPrecision = el.ticker ? qtyPrecisionData[el.ticker] : qtyPrecisionData;
  return (
    <>
      <div className="strategy-metrics__title">
        Trade #{el.id}
        <span>
          <span>{el.averages ? 'DCA' : 'Fixed Risk'}</span>
          <img className="strategy-metrics_icon" src={hiddenIcon} onClick={toggleTrades} alt="" />
        </span>
      </div>
      <div className="strategy-metrics__tab">
        <div className="strategy-metrics__date">{formatDate(el.time)}</div>
        <ul className="strategy-metrics__list">
          {el.ticker && (
            <li>
              Ticker
              <span>{el.ticker}</span>
            </li>
          )}
          <li>
            Status
            <span>
              {el.exit_time && <span>Closed</span>}
              {!el.exit_time && <span>Open</span>}
            </span>
          </li>
          <li>
            Direction
            <span>
              {el.side === 0 && <span className="strategy-metrics__red">Short</span>}
              {el.side === 1 && <span className="strategy-metrics__green">Long</span>}
            </span>
          </li>
          <li>
            Entry price <span>{el.average_enter.toFixed(pricePrecision)}$</span>
          </li>
          <li>
            Avg. exit price
            {el.exit_time && <span>{el.average_exit.toFixed(pricePrecision)}$</span>}
            {!el.exit_time && <span>-</span>}
          </li>
          <li>
            Quantity <span>{el.qty.toFixed(qtyPrecision)}</span>
          </li>
          {el.exit_time && (
            <li>
              Profit
              {el.exit_time && (
                <span className={`strategy-metrics__${el.pnl >= 0 ? 'green' : 'red'}`}>
                  {el.pnl.toFixed(2)}$
                </span>
              )}
            </li>
          )}
          {!el.exit_time && (
            <li>
              Open PNL
              <span
                key={el.pnl.toFixed(2)}
                className={`strategy-metrics__${el.pnl >= 0 ? 'green' : 'red'}`}
              >
                {el.pnl.toFixed(2)}$({el.pnl_percent.toFixed(2)}%)
              </span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
