import type { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { useSelector } from '../redux/rootReducer';
import { setTelegram, setUser } from '../redux/auth/reducer';

import { apiAuth } from '../services/api/Auth/ApiAuth';
import { apiTelegram } from '../services/api/Telegram/ApiTelegram';
import { apiProfile } from '../services/api/Profile/ApiProfile';

const ProtectedLayout: FC = () => {
  const dispatch = useDispatch();

  const authStore = useSelector((state) => state.auth);

  useQuery({
    queryKey: ['auth'],
    queryFn: apiAuth.getUser,
    onSuccess: (user) => {
      dispatch(setUser(user));

      if (!authStore.telegram) {
        apiTelegram.getData().then((data) => {
          if (data) {
            dispatch(setTelegram(data));
          }
        });
      }

      apiProfile.updateOnline();
    },
    onError: () => {
      localStorage.removeItem('user');
      window.location.href = '/auth';
    },
    staleTime: 120 * 60 * 1000,
    cacheTime: 120 * 60 * 1000,
    retry: false,
  });

  if (!authStore.user) {
    return <Navigate to="/auth" />;
  }

  return <Outlet />;
};

export default ProtectedLayout;
