import { type FC, useEffect, useRef, useState } from 'react';
import Minus from 'assets/images/Strategy/NumberInputArrow.svg';
import './CustomInput.scss';

interface IProps {
  step: number;
  value: number;
  setValue: (e: number) => void;
  onBlur?: (e?: any) => void;
  min?: number;
  max?: number;
  showMobileArrows?: boolean;
  errorValue?: boolean;
  maxWidth?: number;
  maxHeight?: number;
  title?: string;
}
const values = Array.from({ length: 48 }, (_, i) => {
  const hours = Math.floor((i + 1) / 2);
  const minutes = (i + 1) % 2 === 0 ? '00m' : '30m';
  return { number: i + 1, text: hours ? `${hours}h ${minutes}`.trim() : minutes };
});

const CustomTextInput: FC<IProps> = ({
  step,
  value,
  setValue,
  onBlur,
  min,
  max,
  showMobileArrows,
  errorValue,
  maxWidth,
  maxHeight,
  title,
}): JSX.Element => {
  const inputRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    values.find((e) => e.number === value) ?? values[0],
  );
  const isMobileVersion = window.innerWidth < 768;

  const handleStepDown = (): void => {
    selectedValue.number > 1 && setSelectedValue(values[values.indexOf(selectedValue) - 1]);
  };

  const handleStepUp = (): void => {
    selectedValue.number < values[values.length - 1].number &&
      setSelectedValue(values[values.indexOf(selectedValue) + 1]);
  };

  useEffect(() => {
    setValue(selectedValue.number);
  }, [selectedValue]);
  return (
    <div
      className="input-text-container"
      onMouseEnter={() => {
        !isMobileVersion && setIsVisible(true);
      }}
      onMouseLeave={() => {
        !isMobileVersion && setIsVisible(false);
      }}
    >
      {(isMobileVersion ? showMobileArrows : isVisible) && (
        <>
          <button onClick={handleStepUp} className={showMobileArrows ? 'plus-mob' : 'plusButton'}>
            <img src={Minus} alt="+" />
          </button>
          <button
            onClick={handleStepDown}
            className={showMobileArrows ? 'minus-mob' : 'minusButton'}
          >
            <img src={Minus} alt="-" />
          </button>
        </>
      )}
      <input
        tabIndex={0}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        className="input"
        type="text"
        value={selectedValue.text}
        step={step}
        min={min}
        max={max}
        readOnly={true}
        style={{
          maxWidth: maxWidth && `${maxWidth}px`,
          maxHeight: maxHeight && `${maxHeight}px`,
          marginRight: showMobileArrows ? '30px' : '',
          userSelect: showMobileArrows ? 'none' : 'auto',
          border: errorValue ? '1px solid red' : '',
        }}
        ref={inputRef}
      />
      {title && <div className="input-title">{title}</div>}
    </div>
  );
};

export default CustomTextInput;
