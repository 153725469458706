import { type FC, memo, useState } from 'react';
import { useSelector } from 'redux/rootReducer';
import { OrderSizeType, type IBaseStrategySettings, type IStrategy } from 'interfaces/IStrategy';
import './style.scss';
import { replaceText } from 'utils/string';
import { StrategySelector } from '../../../../StrategySelector/StrategySelector';
import { generateUniqueId } from 'services/utils/generateId';

interface IProps {
  onClose?: () => void;
  strategyProperty: IBaseStrategySettings;
  setProperty: (property: IStrategy['property']) => void;
  onRunBackTest: () => void;
  openReversedMartingaleSettings: () => void;
  isMartingaleOpened: boolean;
}
const entryProperties = [
  {
    key: 'order_size_type',
    settingType: 'LSSettings',
    name: 'Entry size type',
    type: 'select',
    options: [
      {
        id: OrderSizeType.Dollar,
        name: 'Fixed %quote_asset%',
      },
      {
        id: OrderSizeType.Percent,
        name: 'C.I. %',
      },
      {
        id: OrderSizeType.ReverseMartingale,
        name: 'Rev. martin',
      },
    ],
  },
  {
    key: 'martingale_cycle',
    name: 'Reverse martingale cycle',
    type: 'settings',
    settingType: 'LSSettings',
    filter: (property: IStrategy['property']) =>
      property.order_size_type === OrderSizeType.ReverseMartingale,
    generalSettings: false,
  },
  {
    key: '',
    keys: ['order_size_dollar.long', 'order_size_dollar.short'],
    name: 'Entry size($)',
    settingType: 'LSSettings', // LONG SHORT SETTINGS
    type: 'number',
    trade: 0,
    min: 0,
    max: 1000000,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Dollar,
  },
  {
    key: 'order_size_dollar.long',
    name: 'Entry size: Long trades (%quote_asset%)',
    type: 'number',
    trade: 0,
    min: 0,
    max: 1000000,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Dollar,
  },
  {
    key: 'order_size_dollar.short',
    name: 'Entry size: Short trades (%quote_asset%)',
    type: 'number',
    trade: 1,
    min: 0,
    max: 1000000,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Dollar,
  },
  {
    key: '',
    keys: ['order_size_percent.long', 'order_size_percent.short'],
    settingType: 'LSSettings', // LONG SHORT SETTINGS
    name: 'Entry size (%)',
    type: 'number',
    trade: 0,
    min: 0.01,
    max: 100,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Percent,
  },

  {
    key: 'order_size_percent.long',
    name: 'Entry size: Long trades (%quote_asset%)',
    type: 'number',
    trade: 0,
    min: 0.01,
    max: 100,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Percent,
  },
  {
    key: 'order_size_percent.short',
    name: 'Entry size: Short trades (%)',
    type: 'number',
    trade: 1,
    min: 0.01,
    max: 100,
    filter: (property: IStrategy['property']) => property.order_size_type === OrderSizeType.Percent,
  },
];

const EntrySizeSettings: FC<IProps> = ({
  strategyProperty,
  setProperty,
  onRunBackTest,
  onClose,
  openReversedMartingaleSettings,
  isMartingaleOpened,
}) => {
  const strategy = useSelector((state) => state.strategy.selectedStrategy);
  const [direction, setDirection] = useState<'both' | 'separate'>('both');
  console.log('strategyProperty', strategyProperty);
  const quoteAsset =
    (strategy.ticker.quote_asset ?? 'USDT') === 'USDT' ? '$' : strategy.ticker.quote_asset;
  const getValue = (key: string): string | number => {
    let val: unknown = strategyProperty;

    key.split('.').forEach((e) => {
      val = val[e];
    });
    if (key === 'martingale_cycle') {
      return strategyProperty.martingale_cycle.length;
    }

    return val as string;
  };
  const getValidPropertyValue = (key: string, min: number, max: number): string | number => {
    let val: unknown = strategyProperty;

    key.split('.').forEach((e) => {
      val = val[e];
    });
    // Special case for 'martingale_cycle'
    if (key === 'martingale_cycle') {
      val = strategyProperty.martingale_cycle.length;
    }
    // Ensure the value is a number and within bounds
    let value = parseFloat(val as string);
    if (isNaN(value)) value = min;
    if (value < min) value = min;
    if (value > max) value = max;

    return value;
  };

  return (
    <>
      <div
        className="entrySize"
        style={{
          display: isMartingaleOpened && 'none',
        }}
      >
        <div className="entrySize__header">
          <div className="entrySize__header__back" onClick={onClose}>
            <div className="entrySize__header__back_img"></div>Back
          </div>
          Entry size settings
        </div>
        <div className="entrySize_switcher">
          <div
            className="entrySize_switcher_item"
            onClick={() => {
              setDirection('both');
            }}
            style={{ backgroundColor: direction === 'both' && 'rgba(105, 78, 240, 1)' }}
          >
            Both directions
          </div>
          <div
            className="entrySize_switcher_item"
            onClick={() => {
              setDirection('separate');
            }}
            style={{ backgroundColor: direction === 'separate' && 'rgba(105, 78, 240, 1)' }}
          >
            Separate
          </div>
        </div>

        {direction === 'both' &&
          entryProperties.map(
            (e) =>
              (e.filter?.(strategyProperty) ?? true) &&
              e.settingType === 'LSSettings' && (
                <>
                  <div
                    key={generateUniqueId()}
                    className="entrySize_settingItem"
                    style={{ marginTop: '12px' }}
                  >
                    <h4>{replaceText(e.name, ['quote_asset', quoteAsset])}</h4>
                    {e.type === 'select' && (
                      <StrategySelector
                        key={strategyProperty[e.key]}
                        width="100px"
                        options={e.options.map((e) => ({
                          id: e.id,
                          name: replaceText(e.name, ['quote_asset', quoteAsset]),
                        }))}
                        activeOption={strategyProperty[e.key] ?? e.options[0].id}
                        onChange={(value) => {
                          switch (e.key) {
                            case 'base_currency':
                              return;
                            default: {
                              setProperty({ ...strategyProperty, [e.key]: value });
                            }
                          }
                        }}
                      />
                    )}
                    {e.type === 'number' && (
                      <>
                        <input
                          type="text"
                          className="strategy__main__inline-input"
                          value={e.keys ? e.min : `${getValue(e.key)}`}
                          onChange={(ev) => {
                            const temp = { ...strategyProperty };
                            const value = getValidPropertyValue(e.key, e.min, e.max);
                            if (e.keys) {
                              for (let i = 0; i < e.keys.length; i++) {
                                const PropertyKey = e.keys[i];
                                const keys = PropertyKey.split('.');
                                if (keys.length === 2) {
                                  const elem = { ...temp[keys[0]] };
                                  elem[keys[1]] = value;
                                  temp[keys[0]] = elem;
                                  console.log('1');
                                } else {
                                  console.log('2');
                                  temp[keys[0]] = value;
                                }
                              }
                              setProperty(temp);
                            } else {
                              const PropertyKey = e.key;
                              const temp = { ...strategyProperty };
                              const keys = PropertyKey.split('.');

                              if (keys.length === 2) {
                                const elem = { ...temp[keys[0]] };
                                elem[keys[1]] = value;
                                temp[keys[0]] = elem;
                              } else {
                                temp[keys[0]] = value;
                              }
                              setProperty(temp);
                            }
                          }}
                        />
                      </>
                    )}
                    {e.type === 'settings' && (
                      <div
                        className="strategy__settingsIcon"
                        onClick={openReversedMartingaleSettings}
                      ></div>
                    )}
                  </div>
                </>
              ),
          )}

        {direction === 'separate' && (
          <>
            <div className="entrySize_settingHeader">Long trades:</div>
            {entryProperties.map(
              (e) =>
                (e.filter?.(strategyProperty) ?? true) &&
                e.settingType === 'LSSettings' && (
                  <>
                    <div key={generateUniqueId()} className="entrySize_settingItem">
                      <h4>{replaceText(e.name, ['quote_asset', quoteAsset])}</h4>
                      {e.type === 'select' && (
                        <StrategySelector
                          key={strategyProperty[e.key]}
                          width="100px"
                          options={e.options.map((e) => ({
                            id: e.id,
                            name: replaceText(e.name, ['quote_asset', quoteAsset]),
                          }))}
                          activeOption={strategyProperty[e.key] ?? e.options[0].id}
                          onChange={(value) => {
                            switch (e.key) {
                              case 'base_currency':
                                return;
                              default: {
                                setProperty({ ...strategyProperty, [e.key]: value });
                              }
                            }
                          }}
                        />
                      )}
                      {e.type === 'number' && (
                        <>
                          <input
                            type="text"
                            className="strategy__main__inline-input"
                            value={e.keys ? `${getValue(e.keys[0])}` : `${getValue(e.key)}`}
                            onChange={(ev) => {
                              const temp = { ...strategyProperty };
                              const value = getValidPropertyValue(e.key, e.min, e.max);
                              if (e.keys) {
                                for (let i = 0; i < e.keys.length; i++) {
                                  const PropertyKey = e.keys[i];
                                  const keys = PropertyKey.split('.');
                                  if (keys.length === 2) {
                                    const elem = { ...temp[keys[0]] };
                                    elem[keys[1]] = value;
                                    temp[keys[0]] = elem;
                                    console.log('1');
                                  } else {
                                    console.log('2');
                                    temp[keys[0]] = value;
                                  }
                                }
                                setProperty(temp);
                              } else {
                                const PropertyKey = e.key;
                                const temp = { ...strategyProperty };
                                const keys = PropertyKey.split('.');

                                if (keys.length === 2) {
                                  const elem = { ...temp[keys[0]] };
                                  elem[keys[1]] = value;
                                  temp[keys[0]] = elem;
                                } else {
                                  temp[keys[0]] = value;
                                }
                                setProperty(temp);
                              }
                            }}
                          />
                        </>
                      )}
                      {e.type === 'settings' && (
                        <div
                          className="strategy__settingsIcon"
                          onClick={openReversedMartingaleSettings}
                        ></div>
                      )}
                    </div>
                  </>
                ),
            )}
            <div className="entrySize_settingHeader">Short trades:</div>
            {entryProperties.map(
              (e) =>
                (e.filter?.(strategyProperty) ?? true) &&
                e.settingType === 'LSSettings' && (
                  <>
                    <div key={generateUniqueId()} className="entrySize_settingItem">
                      <h4>{replaceText(e.name, ['quote_asset', quoteAsset])}</h4>
                      {e.type === 'select' && (
                        <StrategySelector
                          key={strategyProperty[e.key]}
                          width="100px"
                          options={e.options.map((e) => ({
                            id: e.id,
                            name: replaceText(e.name, ['quote_asset', quoteAsset]),
                          }))}
                          activeOption={strategyProperty[e.key] ?? e.options[0].id}
                          onChange={(value) => {
                            switch (e.key) {
                              case 'base_currency':
                                return;
                              default: {
                                setProperty({ ...strategyProperty, [e.key]: value });
                              }
                            }
                          }}
                        />
                      )}
                      {e.type === 'number' && (
                        <>
                          <input
                            type="text"
                            className="strategy__main__inline-input"
                            value={e.keys ? `${getValue(e.keys[0])}` : `${getValue(e.key)}`}
                            onChange={(ev) => {
                              const temp = { ...strategyProperty };
                              const value = getValidPropertyValue(e.key, e.min, e.max);
                              if (e.keys) {
                                for (let i = 0; i < e.keys.length; i++) {
                                  const PropertyKey = e.keys[i];
                                  const keys = PropertyKey.split('.');
                                  if (keys.length === 2) {
                                    const elem = { ...temp[keys[0]] };
                                    elem[keys[1]] = value;
                                    temp[keys[0]] = elem;
                                    console.log('1');
                                  } else {
                                    console.log('2');
                                    temp[keys[0]] = value;
                                  }
                                }
                                setProperty(temp);
                              } else {
                                const PropertyKey = e.key;
                                const temp = { ...strategyProperty };
                                const keys = PropertyKey.split('.');

                                if (keys.length === 2) {
                                  const elem = { ...temp[keys[0]] };
                                  elem[keys[1]] = value;
                                  temp[keys[0]] = elem;
                                } else {
                                  temp[keys[0]] = value;
                                }
                                setProperty(temp);
                              }
                            }}
                          />
                        </>
                      )}
                      {e.type === 'settings' && (
                        <div
                          className="strategy__settingsIcon"
                          onClick={openReversedMartingaleSettings}
                        ></div>
                      )}
                    </div>
                  </>
                ),
            )}
          </>
        )}
      </div>
      <div className="strategy__saveButtonContainer">
        <div
          style={{ color: 'white' }}
          className="strategy__saveButton"
          onClick={() => {
            // setProperty(currentProperty);
            // onRunBackTest();
          }}
        >
          Save
        </div>
      </div>
    </>
  );
};
export default memo(EntrySizeSettings);
