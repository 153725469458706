import { type FC, type MutableRefObject, useEffect, useId, useRef, useState } from 'react';
import cn from 'classnames';

import './GTumbler.scss';

interface IGCTumblerProps {
  size?: 'small' | 'large' | null;
  reference?: MutableRefObject<HTMLInputElement>;
  name: string;
  onToggle?: (state: boolean) => void;
  checked?: boolean;
  lightBackground?: boolean;
  disabled?: boolean;
}

const GTumbler: FC<IGCTumblerProps> = (props) => {
  const id = useId();

  const ref = props.reference ?? useRef<HTMLInputElement>();

  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.checked = props.checked;
      setChecked(props.checked);
    }
  }, [ref.current, props.checked]);

  return (
    <div
      style={
        props.lightBackground && {
          backgroundColor: 'rgba(69, 65, 84, 1)',
          cursor: props.disabled ? 'not-allowed' : 'pointer',
        }
      }
      className={cn(
        'g-tumbler',
        props.size === 'small' && 'g-tumbler_small',
        props.size === 'large' && 'g-tumbler_large',
        checked && 'g-tumbler_checked',
      )}
    >
      <input
        ref={ref}
        id={id}
        name={props.name}
        checked={checked}
        type="checkbox"
        style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
        onChange={(event) => {
          if (!props.disabled) {
            if (props.onToggle) {
              props.onToggle(event.target.checked);
            }

            setChecked(event.target.checked);
          }
        }}
      />

      <label
        htmlFor={id}
        style={{
          cursor: props.disabled ? 'not-allowed' : 'pointer',
          opacity: props.disabled ? '0.5' : '1',
        }}
        className={cn(
          'g-tumbler-view',
          props.size === 'small' && 'g-tumbler-view_small',
          props.size === 'large' && 'g-tumbler-view_large',
        )}
      >
        <div
          style={{
            backgroundColor: checked
              ? ''
              : props.lightBackground
              ? 'rgb(141, 137, 162)'
              : 'rgba(69, 65, 84, 1)',
          }}
          className={cn(
            'g-tumbler-view__circle',
            props.size === 'small' && 'g-tumbler-view__circle_small',
            props.size === 'large' && 'g-tumbler-view__circle_large',
          )}
        />
      </label>
    </div>
  );
};

export default GTumbler;
