import { useEffect, useState } from 'react';
import { generateUniqueId } from 'services/utils/generateId';
import SearchIcon from 'assets/images/Profile/SearchIcon.svg';
import FollowerItem from './FollowerItem';
import FollowingItem from './FollowingItem';
import { type IFollowing } from '../interfaces/interfaces';
import { apiProfile } from '../../../services/api/Profile/ApiProfile';
import { setFollowings as setFollowingsRedux } from '../../../redux/profile/reducer';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../redux/rootReducer';

const FFContainer = ({
  id,
  followers,
  followings,
  setFollowers,
  setFollowings,
}: {
  id: string;
  followers: IFollowing[];
  followings: IFollowing[];
  setFollowers: (followers: IFollowing[]) => void;
  setFollowings: (followings: IFollowing[]) => void;
}): JSX.Element => {
  const [FFType, setFFType] = useState('Followers');
  const [searchValue, setSearchValue] = useState('');
  const selfId = useSelector((state) => state.auth.user?.id);
  const dispatch = useDispatch();
  useEffect(() => {
    apiProfile.getFollowers(id).then((e) => {
      setFollowers(e);
    });
    apiProfile.getFollowing(id).then((e) => {
      if (selfId === id) {
        dispatch(setFollowingsRedux(e));
      }
      setFollowings(e);
    });

    if (selfId && selfId !== id) {
      apiProfile.getFollowing(selfId).then((e) => {
        dispatch(setFollowingsRedux(e));
      });
    }
  }, [FFType]);

  return (
    <div className="FFContainer">
      <div className="FFContainer_header">
        <div className="FFContainer_header_searchContainer">
          <img className="FFContainer_header_searchContainer_icon" src={SearchIcon} alt="search" />
          <input
            type="text"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder="Search"
          />
        </div>
      </div>
      <div className="FFContainer_navigation">
        <div
          className={`FFContainer_navigation_item ${
            FFType === 'Followers' && 'FFContainer_navigation_item_active'
          }`}
          onClick={() => {
            setFFType('Followers');
          }}
        >
          Followers
        </div>
        <div
          className={`FFContainer_navigation_item ${
            FFType === 'Following' && 'FFContainer_navigation_item_active'
          }`}
          onClick={() => {
            setFFType('Following');
          }}
        >
          Following
        </div>
      </div>
      <div className="FFContainer_list">
        {FFType === 'Following' &&
          followings
            .filter((item) => searchValue.length === 0 || item.name.includes(searchValue))
            .map((item) => <FollowingItem key={generateUniqueId()} item={item} />)}
        {FFType === 'Followers' &&
          followers
            .filter((item) => searchValue.length === 0 || item.name.includes(searchValue))
            .map((item) => <FollowerItem key={generateUniqueId()} item={item} />)}
      </div>
    </div>
  );
};

export default FFContainer;
