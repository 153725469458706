import { type FC, useEffect } from 'react';
import { useState } from 'react';
import cn from 'classnames';

import type { IAuthFormProps } from '../Auth';
import GButton, { ButtonColors, ButtonTypes } from '../../../components/GButton/GButton';

import googleAuth from '../../../assets/images/google-auth.svg';
import smsAuth from '../../../assets/images/sms-auth.svg';

enum TwoFactorAuthTypes {
  GOOGLE = 'google',
  PHONE = 'phone',
}

const TwoFactorForm: FC<IAuthFormProps> = ({ goBack, goNext }) => {
  const [authType, setAuthType] = useState<TwoFactorAuthTypes>();
  const [showText, setShowText] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowText(false);
    }, 3000);
  });

  return (
    <form
      className="auth-form"
      action="#"
      method="POST"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      {showText && <div className="auth-form_text">User was created successfully!</div>}
      <div className="auth-form__back" onClick={goBack}>
        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.66699 7.00008L0.959885 6.29297L0.252779 7.00008L0.959885 7.70719L1.66699 7.00008ZM17.667 8.00008C18.2193 8.00008 18.667 7.55237 18.667 7.00008C18.667 6.4478 18.2193 6.00008 17.667 6.00008V8.00008ZM6.29322 0.959641L0.959885 6.29297L2.3741 7.70719L7.70743 2.37385L6.29322 0.959641ZM0.959885 7.70719L6.29322 13.0405L7.70743 11.6263L2.3741 6.29297L0.959885 7.70719ZM1.66699 8.00008H17.667V6.00008H1.66699V8.00008Z"
            fill="#288DF5"
          />
        </svg>

        <span>Back</span>
      </div>

      <div className="auth-form-header">
        <span className="auth-form-header__title">2FA Authentication</span>
        <p className="auth-form-header__description">Please select an authentication option</p>
      </div>

      <div className="auth-form-authenticators">
        <div
          className={cn(
            'auth-form-authenticators-element',
            authType === TwoFactorAuthTypes.GOOGLE && 'auth-form-authenticators-element_active',
          )}
          onClick={() => {
            setAuthType(TwoFactorAuthTypes.GOOGLE);
          }}
        >
          <img src={googleAuth} alt="Google Auth" />

          <div className="auth-form-authenticators-element__title">
            <span>Google</span>
            <span>Authenticator</span>
          </div>
        </div>

        <div
          className={cn(
            'auth-form-authenticators-element',
            authType === TwoFactorAuthTypes.PHONE && 'auth-form-authenticators-element_active',
          )}
          // @ts-expect-error
          disabled={true}
          onClick={() => {
            // setAuthType(TwoFactorAuthTypes.PHONE);
          }}
        >
          <img src={smsAuth} alt="SMS Message" />

          <div className="auth-form-authenticators-element__title">
            <span>SMS</span>
            <span>Message</span>
          </div>
        </div>
      </div>

      <div className="auth-form-controls auth-form-controls_half">
        <GButton
          title="Next"
          type={ButtonTypes.BUTTON}
          color={ButtonColors.BLUE}
          bordered={false}
          filled={true}
          disabled={!authType}
        />
      </div>

      <div className="auth-form__later" onClick={goNext}>
        <span>Set up later</span>

        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.334 7.00016L18.0411 7.70727L18.7482 7.00016L18.0411 6.29306L17.334 7.00016ZM1.33398 6.00016C0.781701 6.00016 0.333985 6.44788 0.333985 7.00016C0.333985 7.55245 0.781701 8.00016 1.33398 8.00016L1.33398 6.00016ZM12.7078 13.0406L18.0411 7.70727L16.6269 6.29306L11.2935 11.6264L12.7078 13.0406ZM18.0411 6.29306L12.7078 0.959722L11.2935 2.37394L16.6269 7.70727L18.0411 6.29306ZM17.334 6.00016L1.33398 6.00016L1.33398 8.00016L17.334 8.00016L17.334 6.00016Z"
            fill="#B4B4B4"
          />
        </svg>
      </div>
    </form>
  );
};

export default TwoFactorForm;
