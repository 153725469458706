import { type FC, useState } from 'react';
import './PublishStrategyPopUp.scss';
import WarningIcon from 'assets/icons/Warning.svg';
import { PublicLibraryType } from '../../../../../../enums/Strategy';

interface IProps {
  onClose: () => void;
  onPublish: (mode: PublicLibraryType) => void;
}

const PublishStrategyPopUp: FC<IProps> = ({ onClose, onPublish }) => {
  const [type, setType] = useState<PublicLibraryType>(PublicLibraryType.Public);
  return (
    <div className="publishStrategyPopUpContainer">
      <div className="publishStrategyPopUp">
        <div className="publishStrategyPopUp_header">Choose a publishing method</div>
        <div
          className="publishStrategyPopUp_typeContainer"
          style={{ marginBottom: type === PublicLibraryType.Private ? '32px' : '' }}
        >
          <div
            style={{
              backgroundColor: type === PublicLibraryType.Private && 'rgba(105, 78, 240, 1)',
              fontWeight: type === PublicLibraryType.Private && 600,
            }}
            className="publishStrategyPopUp_typeContainer_type"
            onClick={() => {
              setType(PublicLibraryType.Private);
            }}
          >
            Private
          </div>
          <div
            style={{
              backgroundColor: type === PublicLibraryType.Public && 'rgba(105, 78, 240, 1)',
              fontWeight: type === PublicLibraryType.Public && 600,
            }}
            className="publishStrategyPopUp_typeContainer_type"
            onClick={() => {
              setType(PublicLibraryType.Public);
            }}
          >
            Public
          </div>
        </div>
        {type === PublicLibraryType.Public && (
          <>
            <img className="publishStrategyPopUp_image" src={WarningIcon} alt="WarningIcon" />
            <div className="publishStrategyPopUp_title">Warning!</div>
          </>
        )}
        <div className="publishStrategyPopUp_text">
          Your strategy will be visible in the public strategy library, but the settings will be
          hidden, users will only see the result (trades and metrics).
        </div>
        <div className="publishStrategyPopUp_buttonsContainer">
          <button
            className="publishStrategyPopUp_buttonsContainer_button"
            style={{ backgroundColor: '#694EF0' }}
            onClick={onClose}
          >
            Back
          </button>
          <button
            style={{ backgroundColor: '#9747FF' }}
            className="publishStrategyPopUp_buttonsContainer_button"
            onClick={() => {
              onPublish(type);
            }}
          >
            Publish
          </button>
        </div>
      </div>
    </div>
  );
};
export default PublishStrategyPopUp;
