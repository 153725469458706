import {
  MartingaleCycleFor,
  OrderSizeType,
  type IBaseStrategySettings,
  type IStrategy,
} from 'interfaces/IStrategy';
import { useState, type FC } from 'react';
import CustomNumberInput from '../../../../CustomElements/CustomInput/CustomInput';
import { StrategySelector } from '../../../../StrategySelector/StrategySelector';
import './style.scss';

interface IProps {
  onClose?: () => void;
  strategyProperty: IBaseStrategySettings;
  setProperty: (property: IStrategy['property']) => void;
  onRunBackTest: () => void;
}

const ReversedMartingaleSettings: FC<IProps> = ({
  strategyProperty,
  setProperty,
  onRunBackTest,
  ...props
}) => {
  const [currentProperty, setCurrentProperty] = useState(strategyProperty);
  const temp = { ...currentProperty };
  const isMobileVersion = window.innerWidth < 768;
  const loopDependencyOptions = [
    {
      id: MartingaleCycleFor.Both,
      name: 'Both strat.',
    },
    {
      id: MartingaleCycleFor.Separate,
      name: 'Separately',
    },
  ];
  const firstEntrySizeTypeOptions = [
    {
      id: OrderSizeType.Dollar,
      name: 'Fixed $',
    },
    {
      id: OrderSizeType.Percent,
      name: 'C.I. %',
    },
  ];

  return (
    <div className="reversed">
      <div className="reversed__header">
        <div className="reversed__header__back" onClick={props?.onClose}>
          <div className="reversed__header__back_img"></div>Back
        </div>
        <div>Reversed martingale cycle</div>
      </div>
      <div className="reversed__title">
        Cycle length
        <CustomNumberInput
          value={currentProperty.martingale_cycle.length}
          step={1}
          min={1}
          max={10}
          showMobileArrows={isMobileVersion}
          onChange={(ev) => {
            const value = parseFloat(ev.target.value);
            let cycle = temp.martingale_cycle.slice();
            if (value > temp.martingale_cycle.length) {
              for (let i = 0; i < value - cycle.length; i++) {
                cycle.push(2);
              }
            } else {
              cycle = cycle.slice(0, value);
            }
            temp.martingale_cycle = cycle;
            setCurrentProperty(temp);
          }}
        />
      </div>
      <div className="reversed__container">
        {currentProperty.martingale_cycle.map((e, i) => (
          <>
            <CustomNumberInput
              value={e}
              key={i}
              step={1}
              maxWidth={60}
              min={1}
              max={512}
              title={`R`}
              showMobileArrows={isMobileVersion}
              onChange={(ev) => {
                let value = parseFloat(ev.target.value);
                if (isNaN(value)) value = 1;
                if (value < 1) value = 1;
                if (value > 512) value = 512;
                const temp = { ...currentProperty };
                const cycleTemp = temp.martingale_cycle.slice();
                cycleTemp[i] = value;
                temp.martingale_cycle = cycleTemp;
                setCurrentProperty(temp);
              }}
            />
          </>
        ))}
      </div>
      <div className="reversed__title">
        Loop Dependency
        <StrategySelector
          width="100px"
          // height={4}
          options={loopDependencyOptions}
          activeOption={currentProperty.martingale_cycle_for}
          onChange={(value) => {
            setCurrentProperty({ ...currentProperty, martingale_cycle_for: value });
          }}
        />
      </div>

      <div className="reversed__title">
        First Entry size type
        <StrategySelector
          width="100px"
          // height={4}
          options={firstEntrySizeTypeOptions}
          activeOption={currentProperty.order_size_martingale_type}
          onChange={(value) => {
            if (value === 0) {
              setCurrentProperty({
                ...currentProperty,
                order_size_martingale_type: value,
                order_size_martingale: 1,
              });
            } else {
              setCurrentProperty({
                ...currentProperty,
                order_size_martingale_type: value,
                order_size_martingale: 100,
              });
            }
          }}
        />
      </div>
      {currentProperty.order_size_type === OrderSizeType.ReverseMartingale &&
        currentProperty.order_size_martingale_type !== OrderSizeType.Percent && (
          <div className="reversed__title">
            First Entry size($)
            <input
              type="text"
              className="strategy__main__inline-input"
              value={currentProperty.order_size_martingale}
              onChange={(ev) => {
                let value = parseFloat(ev.target.value);
                if (isNaN(value)) value = 10;
                if (value < 10) value = 10;
                if (value > 1000000) value = 1000000;
                const temp = { ...currentProperty };
                const keys = 'order_size_martingale'.split('.');
                temp[keys[0]] = value;
                setCurrentProperty(temp);
              }}
            />
          </div>
        )}
      {currentProperty.order_size_type === OrderSizeType.ReverseMartingale &&
        currentProperty.order_size_martingale_type === OrderSizeType.Percent && (
          <div className="reversed__title">
            First Entry size (%)
            <input
              type="text"
              className="strategy__main__inline-input"
              value={currentProperty.order_size_martingale}
              onChange={(ev) => {
                let value = parseFloat(ev.target.value);
                if (isNaN(value)) value = 0.1;
                if (value < 0.1) value = 0.1;
                if (value > 100) value = 100;
                const temp = { ...currentProperty };
                const keys = 'order_size_martingale'.split('.');
                temp[keys[0]] = value;
                setCurrentProperty(temp);
              }}
            />
          </div>
        )}
      <div className="strategy__saveButtonContainer">
        <div
          style={{ color: 'white' }}
          className="strategy__saveButton"
          onClick={() => {
            setProperty(currentProperty);
            onRunBackTest();
          }}
        >
          Save
        </div>
      </div>
    </div>
  );
};
export default ReversedMartingaleSettings;
