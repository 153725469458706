import { type FC, useState, useEffect } from 'react';
import { useSelector } from 'redux/rootReducer';
import { useDispatch } from 'react-redux';
import { updateChart, useChartSelector } from 'redux/chart/reducer';
import { StrategyMetricsItem } from './StrategyMetricsItem';
import './StrategyMetrics.scss';
import arrowIcon from 'assets/icons/strategy-metrics-icon.svg';
import hiddenIcon from 'assets/icons/strategy-close.svg';

export const StrategyTrades: FC = () => {
  const [trades, setTrades] = useState(true);
  const chartState = useSelector((state) => state.chart);
  const isTradeStatisticsVisible = useSelector(
    (state) => state.chartTradeSettings.tradeStatisticsVisible,
  );
  const chart = useChartSelector(chartState.selectedChart);
  const allTrades = chartState.allTrades;
  const activeTradeId = chart.selectedTradeId ?? allTrades[allTrades.length - 1]?.id;
  const tradeToDisplay = allTrades.find((el) => el.id === activeTradeId);
  const dispatch = useDispatch();

  const toggleTrades = (): void => {
    setTrades(!trades);
  };
  const prevTrade = (): void => {
    if (activeTradeId > 0) {
      dispatch(updateChart({ id: chart.id, data: { selectedTradeId: activeTradeId - 1 } }));
    }
  };
  const nextTrade = (): void => {
    if (activeTradeId <= allTrades.length - 1) {
      dispatch(updateChart({ id: chart.id, data: { selectedTradeId: activeTradeId + 1 } }));
    }
  };
  useEffect(() => {
    setTrades(isTradeStatisticsVisible);
  }, [isTradeStatisticsVisible]);
  if (!isTradeStatisticsVisible) return;
  if (!trades) {
    return (
      <div className="strategy-metrics__openTradesBtnContainer__btn" onClick={toggleTrades}>
        <img src={hiddenIcon} alt="" />
        Trades table
      </div>
    );
  }

  return (
    trades && (
      <div className="strategy-metrics__bottom">
        <div className="strategy-metrics__tabs">
          {tradeToDisplay && (
            <StrategyMetricsItem
              key={tradeToDisplay.id}
              el={tradeToDisplay}
              index={tradeToDisplay.id}
              toggleTrades={toggleTrades}
            />
          )}
        </div>
        {chart.trades.length > 0 && (
          <div className="strategy-metrics__nav">
            <div
              className={`strategy-metrics__prev ${activeTradeId === 0 ? 'disabled' : ''}`}
              onClick={prevTrade}
            >
              <img src={arrowIcon} alt="" />
            </div>
            <div
              className={`strategy-metrics__next ${
                activeTradeId > allTrades.length - 1 ? 'disabled' : ''
              }`}
              onClick={nextTrade}
            >
              <img src={arrowIcon} alt="" />
            </div>
          </div>
        )}
      </div>
    )
  );
};
