import { type FC } from 'react';
import { type ThemeState } from 'redux/theme/reducer';
import { type IStatistics } from 'interfaces/IStatistics';
import { generateUniqueId } from 'services/utils/generateId';

type PageFC = FC<{ data: IStatistics; theme: ThemeState }>;

const RED = 'rgba(255, 0, 0, 1)';
const GREEN = 'rgba(48, 217, 95, 1)';

const getNumberColor = (num: number, theme: ThemeState): string => {
  if (num === 0) return theme.textColor;
  return num > 0 ? GREEN : RED;
};

export const STATS_ELEMENTS: PageFC[] = [
  // Net Profit + Trades Count
  ({ data: { net_profit: netProfit, pnl_trades: pnlTrades }, theme }) => {
    const id = generateUniqueId();
    const id2 = generateUniqueId();
    const id3 = generateUniqueId();

    return (
      <div className="info__elements">
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor, fontSize: '14px' }}>
            Net Profit:
          </h3>
          <p
            className="info__element-number"
            style={{ color: netProfit[0] > 0 ? GREEN : RED, fontSize: '14px', textAlign: 'right' }}
          >
            {netProfit[0]?.toFixed(2)}$/
            <span key={id}>{netProfit[1]?.toFixed(2)}%</span>
          </p>
        </div>
        <div className="info__element">
          <div className="info__subElement">
            <h3 className="info__element-subname">Long:</h3>
            <p
              className="info__element-number"
              style={{ color: pnlTrades[1] > 0 ? GREEN : RED }}
              key={id2}
            >
              {pnlTrades[1].toFixed(2)}$
            </p>
          </div>
          <div className="info__subElement">
            <h3 className="info__element-subname">Short:</h3>
            <p
              className="info__element-number"
              style={{ color: pnlTrades[0] > 0 ? GREEN : RED }}
              key={id3}
            >
              {pnlTrades[0].toFixed(2)}$
            </p>
          </div>
        </div>
      </div>
    );
  },
  ({ data: { trades_count: tradesCount }, theme }) => {
    const id = generateUniqueId();
    const id2 = generateUniqueId();
    const id3 = generateUniqueId();

    return (
      <div className="info__elements">
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor }}>
            Total closed trades:
          </h3>
          <p
            className="info__element-number"
            style={{ fontWeight: 700, color: theme.textColor, textAlign: 'right' }}
            key={id}
          >
            {tradesCount[0] + tradesCount[1]}
          </p>
        </div>
        <div className="info__element">
          <div className="info__subElement">
            <h3 className="info__element-subname">Long:</h3>
            <p className="info__element-number" style={{ color: theme.textColor }} key={id2}>
              {tradesCount[1]}
            </p>
          </div>
          <div className="info__subElement">
            <h3 className="info__element-subname">Short:</h3>
            <p className="info__element-number" style={{ color: theme.textColor }} key={id3}>
              {tradesCount[0]}
            </p>
          </div>
        </div>
      </div>
    );
  },

  ({ data: { open_pnl: openPnL, initial_balance: balance }, theme }) => {
    const id = generateUniqueId();
    const id2 = generateUniqueId();
    const id3 = generateUniqueId();
    const id4 = generateUniqueId();
    const id5 = generateUniqueId();
    const id6 = generateUniqueId();

    return (
      <div className="info__elements">
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor }}>
            Open PNL:
          </h3>
          <p
            className="info__element-number"
            style={{
              fontWeight: 700,
              color: getNumberColor((openPnL[0] ?? 0) + (openPnL[1] ?? 0), theme),
              textAlign: 'right',
            }}
            key={id}
          >
            {(openPnL[0] ?? 0) + (openPnL[1] ?? 0) !== 0 ? (
              <>
                {((openPnL[0] ?? 0) + (openPnL[1] ?? 0)).toFixed(2)}$/
                <span key={id2}>
                  {((((openPnL[0] ?? 0) + (openPnL[1] ?? 0)) / balance) * 100).toFixed(2)}%
                </span>
              </>
            ) : (
              'N/A'
            )}
          </p>
        </div>
        <div className="info__element">
          <div className="info__subElement">
            <h3 className="info__element-subname">Long:</h3>
            <p
              className="info__element-number"
              style={{
                color: getNumberColor(openPnL[1] ?? 0, theme),
              }}
              key={id3}
            >
              {openPnL[1] ? (
                <>
                  {openPnL[1].toFixed(2)}$/
                  <span key={id4}>{((openPnL[1] / balance) * 100).toFixed(2)}%</span>
                </>
              ) : (
                'N/A'
              )}
            </p>
          </div>
          <div className="info__subElement">
            <h3 className="info__element-subname">Short:</h3>
            <p
              className="info__element-number"
              style={{
                color: getNumberColor(openPnL[0] ?? 0, theme),
              }}
              key={id5}
            >
              {openPnL[0] ? (
                <>
                  {openPnL[0].toFixed(2)}$/
                  <span key={id6}>{((openPnL[0] / balance) * 100).toFixed(2)}%</span>
                </>
              ) : (
                'N/A'
              )}
            </p>
          </div>
        </div>
      </div>
    );
  },
  // Max Drawdown1
  ({ data: { max_drawdown: maxDrawdown, initial_balance: balance }, theme }) => {
    const id = generateUniqueId();
    const id2 = generateUniqueId();
    const id3 = generateUniqueId();
    const id4 = generateUniqueId();

    return (
      <div className="info__elements">
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor }}>
            Max drawdown per trade:
          </h3>
          <p className="info__element-number" style={{ color: RED, textAlign: 'right' }} key={id}>
            {maxDrawdown[2][0].toFixed(2)}$/
            <span key={id2}>{((maxDrawdown[2][0] / balance) * 100).toFixed(2)}%</span>
          </p>
        </div>
        <div className="info__element">
          <h3 className="info__element-subname">Long:</h3>
          <p className="info__element-number" style={{ color: RED }}>
            {maxDrawdown[1][0].toFixed(2)}$/
            <span>{((maxDrawdown[1][0] / balance) * 100).toFixed(2)}%</span>
          </p>
          <h3 className="info__element-subname">Short:</h3>
          <p className="info__element-number" style={{ color: RED }} key={id3}>
            {maxDrawdown[0][0].toFixed(2)}$/
            <span key={id4}>{((maxDrawdown[0][0] / balance) * 100).toFixed(2)}%</span>
          </p>
        </div>
      </div>
    );
  },

  // Max Balance Drawdown
  ({ data: { max_balance_drawdown: maxBalanceDrawdown }, theme }) => {
    const id = generateUniqueId();
    const id2 = generateUniqueId();

    return (
      <div className="info__elements" style={{ justifyContent: 'center' }}>
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor }}>
            Max balance drawdown:
          </h3>
          <p className="info__element-number" style={{ color: RED, textAlign: 'right' }} key={id}>
            {maxBalanceDrawdown[0]}$/
            <span key={id2}>{maxBalanceDrawdown[1]}%</span>
          </p>
        </div>
      </div>
    );
  },
  // Avg Trade Profit123
  ({ data: { avg_trade_profit: avgTradeProfit }, theme }) => {
    const id = generateUniqueId();
    const id2 = generateUniqueId();
    const id3 = generateUniqueId();
    const id4 = generateUniqueId();
    const id5 = generateUniqueId();
    const id6 = generateUniqueId();

    return (
      <div className="info__elements">
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor }}>
            Avg Trade Profit:
          </h3>
          <p
            className="info__element-number"
            style={{ color: avgTradeProfit[2][0] > 0 ? GREEN : RED, textAlign: 'right' }}
            key={id}
          >
            {avgTradeProfit[2][0].toFixed(2)}$/
            <span key={id2}>{avgTradeProfit[2][1].toFixed(2)}%</span>
          </p>
        </div>
        <div className="info__element">
          <div className="info__subElement">
            <h3 className="info__element-subname">Long:</h3>
            <p
              className="info__element-number"
              style={{ color: avgTradeProfit[1][0] > 0 ? GREEN : RED }}
              key={id3}
            >
              {avgTradeProfit[1][0].toFixed(2)}$/
              <span key={id4}>{avgTradeProfit[1][1].toFixed(2)}%</span>
            </p>
          </div>
          <div className="info__subElement">
            <h3 className="info__element-subname">Short:</h3>
            <p
              className="info__element-number"
              style={{ color: avgTradeProfit[0][0] > 0 ? GREEN : RED }}
              key={id5}
            >
              {avgTradeProfit[0][0].toFixed(2)}$/
              <span key={id6}>{avgTradeProfit[0][1].toFixed(2)}%</span>
            </p>
          </div>
        </div>
      </div>
    );
  },
  // ({ data: { avg_trade_profit: avgTradeProfit } }) => {

  //   return (
  //     <div className="info__element">
  //       <div className="info__subElement">
  //         <h3 className="info__element-subname">Long:</h3>
  //         <p
  //           className="info__element-number"
  //           style={{ color: avgTradeProfit[1][0] > 0 ? GREEN : RED }}
  //           key={id}
  //         >
  //           {avgTradeProfit[1][0].toFixed(2)}$/
  //           <span key={id2}>{avgTradeProfit[1][1].toFixed(2)}%</span>
  //         </p>
  //       </div>
  //       <div className="info__subElement">
  //         <h3 className="info__element-subname">Short:</h3>
  //         <p
  //           className="info__element-number"
  //           style={{ color: avgTradeProfit[0][0] > 0 ? GREEN : RED }}
  //           key={id3}
  //         >
  //           {avgTradeProfit[0][0].toFixed(2)}$/
  //           <span key={id4}>{avgTradeProfit[0][1].toFixed(2)}%</span>
  //         </p>
  //       </div>
  //     </div>
  //   );
  // },
  // Percent Profitable
  ({ data: { profit_percent: profitPercent }, theme }) => {
    const id = generateUniqueId();
    const id2 = generateUniqueId();
    const id3 = generateUniqueId();

    return (
      <div className="info__elements">
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor }}>
            Win Rate:
          </h3>
          <p
            className="info__element-number"
            style={{
              fontWeight: 700,
              color: (profitPercent[2] ?? 0) > 0 ? GREEN : RED,
              textAlign: 'right',
            }}
            key={id}
          >
            {profitPercent[2]?.toFixed(2)}%
          </p>
        </div>
        <div className="info__element">
          <div className="info__subElement">
            <h3 className="info__element-subname">Long:</h3>
            <p
              className="info__element-number"
              style={{ color: (profitPercent[1] ?? 0) > 0 ? GREEN : RED }}
              key={id2}
            >
              {(profitPercent[1] ?? 0).toFixed(2)}%
            </p>
          </div>
          <div className="info__subElement">
            <h3 className="info__element-subname">Short:</h3>
            <p
              className="info__element-number"
              style={{ color: (profitPercent[0] ?? 0) > 0 ? GREEN : RED }}
              key={id3}
            >
              {(profitPercent[0] ?? 0).toFixed(2)}%
            </p>
          </div>
        </div>
      </div>
    );
  },
  // Stoploss in ro
  ({ data: { stoploss_in_row: stoplossInRow }, theme }) => {
    const id = generateUniqueId();
    const id2 = generateUniqueId();
    const id3 = generateUniqueId();

    return (
      <div className="info__elements">
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor }}>
            Max Number of SL in a row:
          </h3>
          <p
            className="info__element-number"
            style={{ fontWeight: 700, color: theme.textColor, textAlign: 'right' }}
            key={id}
          >
            {stoplossInRow[2]}
          </p>
        </div>
        <div className="info__element">
          <h3 className="info__element-subname">Long:</h3>
          <p style={{ color: theme.textColor }} key={id2}>
            {stoplossInRow[1]}
          </p>
          <h3 className="info__element-subname">Short:</h3>
          <p style={{ color: theme.textColor }} key={id3}>
            {stoplossInRow[0]}
          </p>
        </div>
      </div>
    );
  },
  // Commission Paid
  ({ data: { commission_paid: commissionPaid }, theme }) => {
    const id = generateUniqueId();
    const id2 = generateUniqueId();
    const id3 = generateUniqueId();

    return (
      <div className="info__elements">
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor }}>
            Commission paid:
          </h3>
          <p
            className="info__element-number"
            style={{ fontWeight: 700, color: theme.textColor, textAlign: 'right' }}
            key={id}
          >
            {(commissionPaid[0] + commissionPaid[1]).toFixed(2)}$
          </p>
        </div>
        <div className="info__element">
          <div className="info__subElement">
            <h3 className="info__element-subname">Long:</h3>
            <p className="info__element-number" style={{ color: theme.textColor }} key={id2}>
              {commissionPaid[1].toFixed(2)}$
            </p>
          </div>
          <div className="info__subElement">
            <h3 className="info__element-subname">Short:</h3>
            <p className="info__element-number" style={{ color: theme.textColor }} key={id3}>
              {commissionPaid[0].toFixed(2)}$
            </p>
          </div>
        </div>
      </div>
    );
  },
  // Trades Liquidation
  ({ theme, data: { liquidation_count: liquidationCount } }) => {
    const id = generateUniqueId();
    const id2 = generateUniqueId();
    const id3 = generateUniqueId();

    return (
      <div className="info__elements">
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor }}>
            Trades Liquidation:
          </h3>
          <p
            className="info__element-number"
            style={{ fontWeight: 700, color: theme.textColor, textAlign: 'right' }}
            key={id}
          >
            {liquidationCount[0] + liquidationCount[1]}
          </p>
        </div>
        <div className="info__element">
          <div className="info__subElement">
            <h3 className="info__element-subname">Long:</h3>
            <p className="info__element-number" style={{ color: theme.textColor }} key={id2}>
              {liquidationCount[1]}
            </p>
          </div>
          <div className="info__subElement">
            <h3 className="info__element-subname">Short:</h3>
            <p className="info__element-number" style={{ color: theme.textColor }} key={id3}>
              {liquidationCount[0]}
            </p>
          </div>
        </div>
      </div>
    );
  },
  // Liquidation Deposit
  ({ theme, data: { liquidation_count: liquidationCount } }) => {
    const id = generateUniqueId();

    return (
      <div className="info__elements" style={{ justifyContent: 'center' }}>
        <div className="info__element">
          <h3 className="info__element-name" style={{ color: theme.textColor }}>
            Deposit Liquidation:
          </h3>
          <p
            className="info__element-number"
            style={{ fontWeight: 700, color: theme.textColor, textAlign: 'right' }}
            key={id}
          >
            {liquidationCount[2]}
          </p>
        </div>
      </div>
    );
  },
];
