import { useState } from 'react';
import type { NavigationType } from '../interfaces/interfaces';
import '../UserProfile.scss';
import PublishedBot from './PublishedBot';
const PublishedBots = (): JSX.Element => {
  const [botType, setBotType] = useState<NavigationType>('All');
  // const filterBots = (bot: IBot, botType: NavigationType): boolean => {
  //   return (
  //     botType === 'All' ||
  //     (botType === 'Public' && bot.isPublic) ||
  //     (botType === 'Private' && !bot.isPublic)
  //   );
  // };
  const filteredBots = []; // publishedBots.filter((bot) => filterBots(bot, botType));

  return (
    <div className="publishedBotsContainer">
      <div className="publishedBotsContainer_navigation">
        <div
          className={`publishedBotsContainer_navigation_item ${
            botType === 'All' && 'publishedBotsContainer_navigation_item_active'
          }`}
          onClick={() => {
            setBotType('All');
          }}
        >
          All
        </div>
        <div
          className={`publishedBotsContainer_navigation_item ${
            botType === 'Public' && 'publishedBotsContainer_navigation_item_active'
          }`}
          onClick={() => {
            setBotType('Public');
          }}
        >
          Public
        </div>
        <div
          className={`publishedBotsContainer_navigation_item ${
            botType === 'Private' && 'publishedBotsContainer_navigation_item_active'
          }`}
          onClick={() => {
            setBotType('Private');
          }}
        >
          Private
        </div>
      </div>
      <div className="publishedBotsContainer_list">
        {filteredBots.map((bot) => (
          <PublishedBot key={bot.id} bot={bot} />
        ))}
      </div>
    </div>
  );
};

export default PublishedBots;
