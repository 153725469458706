import React, { useRef, useEffect, type MutableRefObject, useState } from 'react';
import Chart from 'chart.js/auto';

import './MergedStrategiesListChart.scss';

interface ChartData {
  labels: number[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string | CanvasGradient;
    borderWidth: number;
    borderRadius: number;
    borderColor: string | CanvasGradient;
    fill: boolean;
    lineTension: number;
    pointRadius: number;
  }[];
}

interface ChartOptions {
  responsive: boolean;
  maintainAspectRatio: boolean;
  plugins: {
    legend: {
      display: boolean;
    };
    tooltip: {
      intersect: boolean;
      enabled: boolean;
      callbacks: {
        title: (tooltipItems: any) => string;
        footer: (tooltipItems: any) => string;
        label: (context: any) => any;
      };
    };
  };
  scales: {
    y: {
      beginAtZero: boolean;
      suggestedMin: number;
      suggestedMax: number;
      title: {
        display: boolean;
      };
      ticks: {
        display: boolean;
      };
      grid: {
        display: boolean;
      };
      border: {
        display: boolean;
      };
    };
    x: {
      beginAtZero: boolean;
      ticks: {
        display: boolean;
      };
      grid: {
        display: boolean;
      };
      border: {
        display: boolean;
      };
    };
  };
}

interface IProps {
  values: number[];
  maxPnl: number;
  minPnl: number;
}

const getChartData = (
  pnlValues: number[],
  lineColorRef: MutableRefObject<CanvasGradient>,
): ChartData => {
  return {
    labels: pnlValues,
    datasets: [
      {
        label: '',
        data: pnlValues,
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderRadius: 2,
        fill: true,
        borderColor: lineColorRef.current,
        lineTension: 0.4,
        pointRadius: 0,
      },
    ],
  };
};

const MergedStrategiesListChart: React.FC<IProps> = ({ values: pnlValuesProp, maxPnl, minPnl }) => {
  const strategyChartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const lineColorRef = useRef<CanvasGradient>();
  const [pnlValues, setPnlValues] = useState<number[]>([]);

  const CHART_OPTIONS: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        intersect: true,
        enabled: true,
        callbacks: {
          title: () => '',
          footer: (tooltipItems) => {
            const index: number = Number(tooltipItems[0].dataIndex);
            const pnlData = pnlValues[index].toFixed(2);
            return `${index}: ${pnlData}$`;
          },
          label: (): number | string => {
            return '';
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: minPnl ?? 1,
        suggestedMax: maxPnl ?? 42,
        title: { display: false },
        ticks: { display: false },
        grid: { display: false },
        border: { display: false },
      },
      x: {
        beginAtZero: false,
        ticks: { display: false },
        grid: { display: false },
        border: { display: false },
      },
    },
  };

  useEffect(() => {
    if (pnlValues.length !== pnlValuesProp.length) {
      setPnlValues(pnlValuesProp);
    }
  }, [pnlValuesProp]);

  useEffect(() => {
    if (!chartInstance.current && strategyChartRef.current) {
      const ctx = strategyChartRef.current.getContext('2d');
      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: getChartData(pnlValues, lineColorRef),
        options: CHART_OPTIONS,
      });
    }
  }, [strategyChartRef, chartInstance]);

  useEffect(() => {
    for (let i = 0; i < 2; i++) {
      if (chartInstance.current) {
        const chartHeight = strategyChartRef.current.clientHeight;
        const ctx = strategyChartRef.current.getContext('2d');
        ctx.translate(0.5, 0.5);

        const pixelsForZero = chartInstance.current.scales.y.getPixelForValue(0);
        // @ts-expect-error
        const canvasHeight = chartInstance.current.scales.y._maxLength;
        let centerPosition = pixelsForZero / canvasHeight;
        if (isNaN(centerPosition) || centerPosition < 0 || centerPosition > 1) centerPosition = 0.5;
        const lineColor = ctx.createLinearGradient(0, 0, 0, chartHeight);
        const greenColor = minPnl === 0 && maxPnl === 0 ? '#666666' : '#30D960';
        const redColor = minPnl === 0 && maxPnl === 0 ? '#666666' : '#FF0000';
        lineColor.addColorStop(0, greenColor);
        lineColor.addColorStop(centerPosition, greenColor);
        lineColor.addColorStop(centerPosition, redColor);
        lineColor.addColorStop(1, redColor);
        lineColorRef.current = lineColor;
        chartInstance.current.data = getChartData(pnlValues, lineColorRef);
        chartInstance.current.data.datasets[0].borderColor = lineColorRef.current;
        chartInstance.current.options = CHART_OPTIONS;
        chartInstance.current.update();
      }
    }
  }, [pnlValues]);

  return (
    <div className="StrategiesChart">
      <canvas ref={strategyChartRef} width="100%" />
    </div>
  );
};

export default MergedStrategiesListChart;
