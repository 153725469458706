import { useState, type FC } from 'react';
import './CustomStyleSettingsDropDown.scss';
import Arrow from 'assets/images/Strategy/DropDownTriangle.svg';
interface IProps {
  options: {
    tabId: string;
    tabLabel?: string;
  }[];
  activeOption: {
    tabId: string;
    tabLabel?: string;
  };
  onChange: (tabId: string) => void;
  nameFontWeight?: string;
  largeList?: boolean;
  numbersOnly?: boolean;
}
const CustomDropDown: FC<IProps> = ({
  options,
  onChange,
  activeOption,
  nameFontWeight,
  largeList,
  numbersOnly,
}) => {
  const [isOpened, setOpened] = useState(false);
  return (
    <div
      className={'date-dropdown' + (numbersOnly ? ' date-dropdown__numbersOnly' : '')}
      tabIndex={0}
      onBlur={() => {
        setOpened(false);
      }}
    >
      <div
        className={
          'date-dropdown__selected' + (numbersOnly ? ' date-dropdown__selected__numbersOnly' : '')
        }
        onClick={() => {
          setOpened(!isOpened);
        }}
      >
        <div style={{ fontWeight: nameFontWeight }} className="date-dropdown__selected_name">
          {activeOption.tabLabel.includes('_')
            ? activeOption.tabLabel
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
            : activeOption.tabLabel.charAt(0).toUpperCase() + activeOption.tabLabel.slice(1)}
        </div>
        <img
          className={
            'date-dropdown__selected_arrow' +
            (isOpened ? ' date-dropdown__selected_arrowOpened' : '')
          }
          src={Arrow}
          alt=">"
        />
      </div>

      {isOpened && largeList && (
        <div
          style={{ minHeight: options.length * 36 < 180 && `${options.length * 36}px ` }}
          className="date-dropdown__itemsContainer"
        >
          {options.map((option, index) => (
            <div
              style={{
                borderTopLeftRadius: index === 0 ? '4px' : '',
                borderTopRightRadius: index === 0 ? '4px' : '',
              }}
              className={
                'date-dropdown__item' + (numbersOnly ? ' date-dropdown__item__numbersOnly' : '')
              }
              key={index}
              onClick={() => {
                setOpened(false);
                onChange(option.tabId);
              }}
            >
              <div style={{ fontWeight: nameFontWeight }} className="date-dropdown__item_name">
                {option.tabLabel.includes('_')
                  ? option.tabLabel
                      .split('_')
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')
                  : option.tabLabel.charAt(0).toUpperCase() + option.tabLabel.slice(1)}
              </div>
            </div>
          ))}{' '}
        </div>
      )}
      {isOpened &&
        !largeList &&
        options.map((option, index) => (
          <div
            style={{
              borderTopLeftRadius: index === 0 ? '4px' : '',
              borderTopRightRadius: index === 0 ? '4px' : '',
              borderBottomLeftRadius: index === options.length - 1 ? '4px' : '',
              borderBottomRightRadius: index === options.length - 1 ? '4px' : '',
            }}
            className={
              'date-dropdown__item' + (numbersOnly ? ' date-dropdown__item__numbersOnly' : '')
            }
            key={index}
            onClick={() => {
              setOpened(false);
              onChange(option.tabId);
            }}
          >
            <div style={{ fontWeight: nameFontWeight }} className="date-dropdown__item_name">
              {option.tabLabel}
            </div>
          </div>
        ))}
    </div>
  );
};

export default CustomDropDown;
