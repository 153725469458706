import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/rootReducer';
import { CustomColorPicker } from '../../ColorPicker/ColorPicker';
import { type ColorDataSettings } from '../../../Interfaces';
import {
  refreshBackgroundSettings,
  updateBackgroundProperty,
} from 'redux/chartBackgroundSettings/reducer';
import { apiChartStyles } from '../../../../../../../services/api/ApiChartStyles/ApiChartStyles';

export const BackgroundSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const settings: ColorDataSettings = useSelector((state) => state.chartBackgroundSettings);
  return (
    <div className="propertyMainContainer">
      <div className="container__content_main_content_header">
        BACKGROUND SETTINGS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>

      <div className="propertyContainer">
        Chart background color
        <CustomColorPicker
          defaultColor={settings.color}
          showGradient
          hideGradientAngle={false}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'background',
              value: color,
            });
            dispatch(
              updateBackgroundProperty({
                value: color,
              }),
            );
          }}
        />
      </div>
      <button
        className="container__content_main_content_refresh"
        onClick={() => {
          apiChartStyles.refreshChartStylingSettings('background');
          dispatch(refreshBackgroundSettings());
        }}
      >
        {' '}
        Refresh settings
      </button>
    </div>
  );
};
