import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setUser } from 'redux/auth/reducer';
import { Provider, useDispatch } from 'react-redux';
import { store, useSelector } from 'redux/rootReducer';
import { updateSelectedAccountName } from 'redux/account/reducer';
import MenuAccountDropDown from './components/MenuAccountDropdown';
import PlatformPopUp from './components/MenuPlatformPopUp';
import TgBotConnection from './components/TelegramBot/TgBotConnection/TgBotConnection';
import './MenuPageStyles.scss';
import Logo from 'assets/images/MenuPage/Logo.svg';
import ProfileIcon from 'assets/images/MenuPage/ProfileIcon.svg';
import TradingPanelIcon from 'assets/images/MenuPage/TradingIcon.svg';
import LeaderBoardIcon from 'assets/images/MenuPage/LeaderBoardIcon.svg';
import PricingIcon from 'assets/images/MenuPage/PricingIcon.svg';
import DocsIcon from 'assets/images/MenuPage/DocsIcon.svg';
import LogoutIcon from 'assets/images/MenuPage/LogOutIcon.svg';
import TelegramIcon from 'assets/images/MenuPage/TelegramIcon.svg';
import InstagramIcon from 'assets/images/MenuPage/InstagramIcon.svg';
import FacebookIcon from 'assets/images/MenuPage/FacebookIcon.svg';
import DialogIcon from 'assets/images/MenuPage/DialogIcon.svg';

const MenuPage = (): JSX.Element => {
  document.documentElement.style.setProperty('zoom', '100%');
  const login = useSelector((state) => state.auth.user?.login);
  const dispatch = useDispatch();
  useEffect(() => {
    if (login) {
      dispatch(updateSelectedAccountName(login));
    }
  }, [login]);

  return (
    <Provider store={store}>
      <div className="pageContainer">
        <div className="pageContainer__logo">
          <img src={Logo} alt="logo" />
        </div>
        <MenuAccountDropDown />
        <div className="pageContainer__menu">
          <Link to={`/profile/${login}`}>
            <button className="pageContainer__menuItem">
              <img src={ProfileIcon} alt="profile" />
              <div>Profile</div>
            </button>
          </Link>
          <Link to="/">
            <button className="pageContainer__menuItem">
              <img src={TradingPanelIcon} alt="trading" />
              <div>Trading panel</div>
            </button>
          </Link>

          <PlatformPopUp />
          <button className="pageContainer__menuItem pageContainer__menuItem_disabled">
            <img src={LeaderBoardIcon} alt="leaderboard" />
            <div>Leaderboard</div>
          </button>
          <button className="pageContainer__menuItem pageContainer__menuItem_disabled">
            <img src={PricingIcon} alt="pricing" />
            <div>Pricing</div>
          </button>
          <a href="https://algoth.gitbook.io/docs" className="pageContainer__menuItem">
            <img src={DocsIcon} alt="docs" />
            <div>Docs</div>
          </a>
          <TgBotConnection />
          <button className="pageContainer__helperDialog">
            <img src={DialogIcon} alt="dialog icon" />
            <div>Need help?</div>
          </button>
        </div>
        <button
          className="pageContainer__logoutButton"
          onClick={() => {
            dispatch(setUser(null));
          }}
        >
          <img src={LogoutIcon} alt="log out" />
          <div>Log out</div>
        </button>
        <div className="pageContainer__socialMedias">
          <a href="https://t.me/+1ed0Vg3LMmtkNDk0" className="pageContainer__socialMediaLink">
            <img src={TelegramIcon} alt="telegram" />
            <div>Telegram</div>
          </a>
          <a
            href="https://www.instagram.com/algoth.ai?igsh=Z3hmdzhybzZvMHR0"
            className="pageContainer__socialMediaLink"
          >
            <img src={InstagramIcon} alt="instagram" />
            <div>Instagram</div>
          </a>
          <a className="pageContainer__socialMediaLink">
            <img src={FacebookIcon} alt="facebook" />
            <div>Facebook</div>
          </a>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://algoth.gitbook.io/docs/~/changes/z9TQdzbzvo1s3PVF6yez/terms-of-use"
          className="pageContainer__terms"
        >
          Terms of use
        </a>
      </div>
    </Provider>
  );
};

export default MenuPage;
