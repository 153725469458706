import { type Dispatch, type SetStateAction } from 'react';
import type {
  IMergedStrategy,
  IStrategyAllData,
} from 'services/api/Strategy/types/StrategyResponse';
import type { Components } from '../../Components';
import SavedStrategies from '../SavedStrategies/SavedStrategies';
import ComeBackArrow from 'assets/images/Strategy/ComeBackArrow.svg';
interface IProps {
  setStrategyNode: (value: Components | null) => void;
  addStrategyHistory: (value: { _id: string; name: string; isMerged?: boolean }) => void;
  removeStrategyHistory: Dispatch<SetStateAction<IStrategyAllData | IMergedStrategy>>;
  isPublishOpen: boolean;
  setIsPublishOpen: Dispatch<SetStateAction<boolean>>;
  setPublishData: Dispatch<SetStateAction<any>>;
}

const SavedStrategiesContainer = ({
  setStrategyNode,
  addStrategyHistory,
  removeStrategyHistory,
  setIsPublishOpen,
  setPublishData,
}: IProps): JSX.Element => {
  return (
    <>
      <div
        className="strategy-editor__back"
        onClick={() => {
          setStrategyNode(null);
        }}
      >
        <img src={ComeBackArrow} alt="<" /> Back
      </div>
      <SavedStrategies
        setStrategyNode={setStrategyNode}
        addStrategyHistory={addStrategyHistory}
        removeStrategyHistory={removeStrategyHistory}
        setPublishOpen={setIsPublishOpen}
        setPublishData={setPublishData}
      />
    </>
  );
};

export default SavedStrategiesContainer;
