import GTumbler from 'components/GTumbler/GTumbler';
import './TgBotConnectionInfo.scss';
import { apiTelegram } from '../../../../../services/api/Telegram/ApiTelegram';
import { useSelector } from '../../../../../redux/rootReducer';
import { type ITelegramInfo } from '../../../../../services/api/Telegram/types';
import { useDispatch } from 'react-redux';
import { setTelegram } from '../../../../../redux/auth/reducer';
interface IProps {
  setIsCheckConnectionOpened: React.Dispatch<React.SetStateAction<boolean>>;
}
const TgBotConnectionInfo = ({ setIsCheckConnectionOpened }: IProps): JSX.Element => {
  const connectionInfo = useSelector((state) => state?.auth?.telegram);
  const dispatch = useDispatch();
  const setConnectionInfo = (data: ITelegramInfo | null): void => {
    dispatch(setTelegram(data));
  };

  return (
    <div className="tgConnectionInfoContainer">
      <div className="tgConnectionInfo">
        <button
          className="tgConnectionInfo__closeButton"
          onClick={() => {
            setIsCheckConnectionOpened(false);
          }}
        ></button>
        <div className="tgConnectionInfo__header">AlgoTH telegram bots</div>
        <div className="tgConnectionInfo__status">
          <div className="tgConnectionInfo__statusName">Status: </div>
          <div className="tgConnectionInfo__statusValue">
            <div
              style={{
                background: connectionInfo ? 'rgba(48, 217, 95, 1)' : 'rgb(230,34,34)',
              }}
            ></div>
            {connectionInfo ? 'Connected' : 'Disconnected'}
          </div>
        </div>
        <div className="tgConnectionInfo__settings">
          <div className="tgConnectionInfo__settingsName">
            Receive login and account <br></br> activity alerts{' '}
          </div>
          <GTumbler
            name="tg"
            size="large"
            checked={connectionInfo?.receive_auth ?? true}
            onToggle={(e) => {
              if (!connectionInfo) return;
              apiTelegram.updateData('receive_auth', e).then((data) => {
                if (data) {
                  setConnectionInfo({
                    ...connectionInfo,
                    receive_auth: e,
                  });
                }
              });
            }}
          />
        </div>
        <div className="tgConnectionInfo__settings">
          <div className="tgConnectionInfo__settingsName">Receive important updates</div>
          <GTumbler
            name="tg"
            size="large"
            checked={connectionInfo?.receive_updates ?? true}
            onToggle={(e) => {
              if (!connectionInfo) return;
              apiTelegram.updateData('receive_updates', e).then((data) => {
                if (data) {
                  setConnectionInfo({
                    ...connectionInfo,
                    receive_updates: e,
                  });
                }
              });
            }}
          />
        </div>
        {connectionInfo && (
          <button
            className="tgConnectionInfo__disconnectButton"
            onClick={() => {
              apiTelegram.removeData().then((data) => {
                if (data) {
                  setConnectionInfo(null);
                }
              });
            }}
          >
            Disconnect
          </button>
        )}
      </div>
    </div>
  );
};

export default TgBotConnectionInfo;
