import { createSlice } from '@reduxjs/toolkit';
import type { State } from './types';
const initialState: State = {
  mobileContent: 'home',
};

const homePageReducer = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    setMobileContent: (state, action) => {
      state.mobileContent = action.payload;
    },
  },
});

export const { setMobileContent } = homePageReducer.actions;

export default homePageReducer.reducer;
