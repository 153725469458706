import { useCallback, useState, type FC } from 'react';
import { useSelector } from 'redux/rootReducer';
import { useDispatch } from 'react-redux';
import { deleteSavedtrategy, setBackTest, updateSelectedStrategy } from 'redux/strategy/reducer';
import { StrategySelector } from '../../StrategySelector/StrategySelector';
import { BaseCurrencyType, type IStrategy, MarginType, OrderTypes } from 'interfaces/IStrategy';
import GTumbler from 'components/GTumbler/GTumbler';
import ReEntryCooldownSettings from './components/ReEntryCooldownSettings/ReEntryCooldownSettings';
import ReversedMartingaleSettings from './components/ReversedMartingaleSettings/ReversedMartingaleSettings';
import { replaceText } from 'utils/string';
import useWindowWidth from 'hooks/useWindowWidth';
import { generateUniqueId } from 'services/utils/generateId';
import EntrySizeSettings from './components/EntrySizeSettings/EntrySizeSettings';

const martingaleCycles = {
  0: [1, 2, 2, 4, 8],
};

const martingaleCyclesKeys: Record<string, number> = {};

for (const i in martingaleCycles)
  martingaleCyclesKeys[martingaleCycles[i].toString()] = parseInt(i);

const properties = [
  {
    key: 'deposit',
    name: 'Deposit',
    type: 'number',
    min: 10,
    max: 1000000,
  },
  {
    key: 'leverage',
    name: 'Leverage',
    suffix: 'X',
    type: 'number',
    min: 1,
    max: 200,
  },
  {
    key: 'entry_size_settings',
    name: 'Entry size settings',
    type: 'entrySizeSettings',
  },
  {
    key: 'reentry_cooldown',
    name: 'ReEntry Cooldown',
    type: 'reEntryCooldown',
  },
  {
    key: 'base_currency',
    name: 'Base currency',
    type: 'select',
    options: [
      {
        id: BaseCurrencyType.USDT,
        name: '%quote_asset%',
      },
    ],
  },
  {
    key: 'type_closing_orders',
    name: 'Type of closing orders',
    type: 'select',
    options: [
      {
        id: OrderTypes.Market,
        name: 'Market',
      },
      {
        id: OrderTypes.Limit,
        name: 'Limit',
      },
    ],
  },
  {
    key: 'commission_limit',
    name: 'Commission (Limit)',
    type: 'number',
    min: 0,
    max: 100,
    filter: (property: IStrategy['property']) => property.type_closing_orders === OrderTypes.Limit,
  },
  {
    key: 'commission_market',
    name: 'Commission (Market)',
    type: 'number',
    min: 0,
    max: 100,
    filter: (property: IStrategy['property']) => property.type_closing_orders === OrderTypes.Market,
  },
  {
    key: 'margin_type',
    name: 'Margin Mode',
    type: 'select',
    options: [
      {
        id: MarginType.Isolated,
        name: 'Isolated',
      },
      {
        id: MarginType.Cross,
        name: 'Cross',
      },
    ],
  },
  {
    key: 'infinite_deposit',
    name: 'Infinite Balance',
    type: 'switch',
  },
];

export const StrategyProperties: FC = () => {
  const { selectedStrategy: strategy } = useSelector((state) => state.strategy);
  const strategyProperty = strategy.property;
  const isMobileVersion = useWindowWidth() < 768;
  const [isMartingaleOpened, setIsMartingaleOpened] = useState(false);
  const [isReEntryCooldownOpened, setIsReEntryCooldownOpened] = useState(false);
  const [isEntrySizeOpened, setIsEntrySizeOpened] = useState(false);
  const dispatch = useDispatch();
  if (!strategyProperty) return;
  const quoteAsset =
    (strategy.ticker.quote_asset ?? 'USDT') === 'USDT' ? '$' : strategy.ticker.quote_asset;

  const setProperty = useCallback(
    (property: IStrategy['property']): void => {
      dispatch(updateSelectedStrategy({ property }));
    },
    [dispatch], // Add any other dependencies if needed
  );
  const runBackTest = useCallback((): void => {
    dispatch(deleteSavedtrategy(strategy._id));
    dispatch(setBackTest(true));
  }, [dispatch, strategy._id]);
  const closeReEntryCooldownSettings = useCallback((): void => {
    setIsReEntryCooldownOpened(false);
  }, []);
  const closeEntrySizeSettings = useCallback((): void => {
    setIsEntrySizeOpened(false);
  }, []);
  const openReversedMartingaleSettings = useCallback((): void => {
    console.log('openReversedMartingaleSettings');
    setIsMartingaleOpened(true);
  }, []);
  const getValue = (key: string): string => {
    let val: unknown = strategyProperty;

    key.split('.').forEach((e) => {
      val = val[e];
    });

    return val as string;
  };
  if (isMartingaleOpened) {
    <ReversedMartingaleSettings
      strategyProperty={strategyProperty}
      setProperty={setProperty}
      onClose={() => {
        setIsMartingaleOpened(false);
      }}
      onRunBackTest={runBackTest}
    />;
  }
  if (isEntrySizeOpened) {
    return (
      <>
        <EntrySizeSettings
          isMartingaleOpened={isMartingaleOpened}
          strategyProperty={strategyProperty}
          setProperty={setProperty}
          onClose={closeEntrySizeSettings}
          onRunBackTest={runBackTest}
          openReversedMartingaleSettings={openReversedMartingaleSettings}
        />
        {isMartingaleOpened && (
          <ReversedMartingaleSettings
            strategyProperty={strategyProperty}
            setProperty={setProperty}
            onClose={() => {
              setIsMartingaleOpened(false);
            }}
            onRunBackTest={runBackTest}
          />
        )}
      </>
    );
  }
  if (isReEntryCooldownOpened) {
    return (
      <ReEntryCooldownSettings
        strategyProperty={strategyProperty}
        setProperty={setProperty}
        onClose={closeReEntryCooldownSettings}
        onRunBackTest={runBackTest}
      />
    );
  }

  return (
    <>
      {properties.map(
        (e) =>
          (e.filter?.(strategyProperty) ?? true) &&
          (e.key === 'martingale_cycle_for' ? strategy.mode === 1 : true) && (
            <>
              <div
                key={generateUniqueId()}
                className="strategy__main__inline"
                style={{
                  marginTop: (e.type === 'settings' || e.type === 'reEntryCooldown') && '10px',
                }}
              >
                <h4>{replaceText(e.name, ['quote_asset', quoteAsset])}</h4>
                {e.type === 'switch' && (
                  <GTumbler
                    size={isMobileVersion ? 'large' : 'small'}
                    name={e.name}
                    checked={strategyProperty[e.key]}
                    onToggle={(value) => {
                      setProperty({
                        ...strategyProperty,
                        [e.key]: value,
                      });
                    }}
                  />
                )}
                {e.type === 'select' && (
                  <StrategySelector
                    key={strategyProperty[e.key]}
                    width="100px"
                    options={e.options.map((e) => ({
                      id: e.id,
                      name: replaceText(e.name, ['quote_asset', quoteAsset]),
                    }))}
                    activeOption={strategyProperty[e.key] ?? e.options[0].id}
                    onChange={(value) => {
                      switch (e.key) {
                        case 'base_currency':
                          return;
                        default: {
                          setProperty({ ...strategyProperty, [e.key]: value });
                        }
                      }
                    }}
                  />
                )}
                {e.type === 'number' && (
                  <>
                    <input
                      type="text"
                      className="strategy__main__inline-input"
                      value={
                        e.key === 'martingale_cycle'
                          ? strategyProperty.martingale_cycle.length
                          : `${getValue(e.key)}` + (e.suffix ?? '')
                      }
                      onChange={(ev) => {
                        let value = parseFloat(ev.target.value);
                        if (isNaN(value)) value = e.min;
                        if (value < e.min) value = e.min;
                        if (value > e.max) value = e.max;
                        const temp = { ...strategyProperty };
                        const keys = e.key.split('.');

                        if (keys.length === 2) {
                          const elem = { ...temp[keys[0]] };
                          elem[keys[1]] = value;
                          temp[keys[0]] = elem;
                        } else {
                          temp[keys[0]] = value;
                        }
                        setProperty(temp);
                      }}
                    />
                  </>
                )}
                {e.type === 'settings' && (
                  <div
                    className="strategy__settingsIcon"
                    onClick={() => {
                      setIsMartingaleOpened(true);
                    }}
                  ></div>
                )}
                {e.type === 'reEntryCooldown' && (
                  <div
                    className="strategy__settingsIcon"
                    onClick={() => {
                      setIsReEntryCooldownOpened(true);
                    }}
                  ></div>
                )}
                {e.type === 'entrySizeSettings' && (
                  <div
                    className="strategy__settingsIcon"
                    onClick={() => {
                      setIsEntrySizeOpened(true);
                    }}
                  ></div>
                )}
              </div>
            </>
          ),
      )}
      <div className="strategy__saveButtonContainer">
        <div style={{ color: 'white' }} className="strategy__saveButton" onClick={runBackTest}>
          Save
        </div>
      </div>
    </>
  );
};
