import React, { useMemo, useState } from 'react';
import MergedStrategiesListChart from './MergedStrategiesListChart';

import './MergedStrategiesList.scss';
import { type IMergedBackTestStrategy } from '../../../../../../../../services/api/Backtest/ApiBacktest';
import { StrategyModes } from '../../../../../../../../interfaces/IStrategy';
import type { ITradeData } from '../../../../../../../../interfaces/ITrade';
import { COLORS } from '../../../../../../../../configs/chart';

export interface IProps {
  strategyData: IMergedBackTestStrategy;
  balance: number;
}

type StrategyType = 'Fixed Risk' | 'DCA' | 'Fixed risk/DCA';

const getStrategyTypes = (data: IMergedBackTestStrategy): StrategyType => {
  if (Array.isArray(data.trades[0])) {
    if (data.trades[0][0]?.averages && data.trades[1][0]?.averages) return 'DCA';
    if (!data.trades[0][0]?.averages && data.trades[1][0]?.averages) return 'Fixed risk/DCA';
    return 'Fixed Risk';
  }
  return data?.trades[0]?.averages ? 'DCA' : 'Fixed Risk';
};
const PERIODS = ['1D', '7D', '1M'];
const PERIODS_TIME: Record<string, () => number> = {
  '1D': () => new Date().setUTCHours(0, 0, 0, 0),
  '7D': () => {
    const date = new Date();
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff)).setUTCHours(0, 0, 0, 0);
  },
  '1M': () => new Date(new Date().setUTCHours(0, 0, 0, 0)).setUTCDate(1),
};

const getColorValue = (value: number): string => {
  if (value === 0) return '#FFFFFF';
  if (value > 0) return '#30D960';
  return '#AA1B2C';
};

const MergedStrategiesList: React.FC<IProps> = ({ strategyData, balance }) => {
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [pnlValues, pnlPeriod, minPnl, maxPnl] = useMemo(() => {
    const values: number[] = [0];
    let sum = 0;
    let min: number | null = null;
    let max: number | null = null;

    const trades =
      strategyData.mode === StrategyModes.ONE_SIDE
        ? (strategyData.trades as ITradeData[])
        : (strategyData.trades[0] as ITradeData[])
            .slice()
            .concat((strategyData.trades[1] as ITradeData[]) ?? [])
            .sort((a, b) => a.id - b.id);
    const stopTime = PERIODS_TIME[PERIODS[selectedPeriod]]();

    for (let i = trades.length - 1; i >= 0; i--) {
      const trade = trades[i];
      if (!trade.exit_time) continue;
      if (trade.exit_time <= stopTime) break;

      const value = i === trades.length - 1 ? trade.pnl : values[values.length - 1] + trade.pnl;
      values.push(value);
      sum += trade.pnl;
      if (min === null || value < min) min = value;
      if (max === null || value > max) max = value;
    }
    return [values.length <= 1 ? [0, values[1] ?? 0] : values, sum, min ?? 0, max ?? 0];
  }, [selectedPeriod, strategyData.trades]);

  return (
    <div
      className={`strategies ${
        pnlPeriod > 0 ? 'green' : pnlPeriod === 0 ? 'gray' : 'red'
      }-gradient`}
    >
      <div className="strategies__head">
        <h5 className="strategies__head__name">{strategyData.name ?? 'Unknown Strategy'}</h5>
        <div className="strategies__head__periods">
          {PERIODS.map((e, i) => (
            <div
              key={i}
              className="strategies__head__periods__item"
              style={{
                background: selectedPeriod === i ? COLORS.LIGHT_VIOLET : '#262336',
                cursor: selectedPeriod !== i ? 'pointer' : 'default',
              }}
              onClick={() => {
                setSelectedPeriod(i);
              }}
            >
              {e}
            </div>
          ))}
        </div>
      </div>
      <MergedStrategiesListChart values={pnlValues} maxPnl={maxPnl} minPnl={minPnl} />
      <p className="strategies__price" style={{ color: getColorValue(pnlPeriod) }}>
        {pnlPeriod > 0 && '+'}
        {pnlPeriod.toFixed(2)}$&nbsp;
        <span>{((pnlPeriod / balance) * 100).toFixed(2)}%</span>
      </p>
      <ul className="strategies__list">
        <li>
          Amount of trades:
          <span>{pnlValues[1] === 0 ? 0 : pnlValues.length - 1}</span>
        </li>
        <li>
          Strategy <br />
          type:
          <span>{getStrategyTypes(strategyData)}</span>
        </li>
        <li>
          Strategy <br />
          mode:
          <span>{strategyData.mode === StrategyModes.HEDGE ? 'Hedge mode' : 'One-way'}</span>
        </li>
        <li>
          All time PNL:
          <span style={{ color: getColorValue(strategyData.stats.net_profit[0]) }}>
            {strategyData.stats.net_profit[0].toFixed(2)}$
          </span>
        </li>
        <li>
          Pair:
          <span>{strategyData.ticker}</span>
        </li>
        <li>
          Open PNL:
          <span style={{ color: getColorValue(strategyData.stats.open_pnl ?? 0) }}>
            {strategyData.stats.open_pnl?.toFixed(2) ?? 0}$
          </span>
        </li>
      </ul>
    </div>
  );
};

export default MergedStrategiesList;
