import type { Dispatch, SetStateAction } from 'react';
import GTumbler from 'components/GTumbler/GTumbler';
import { StrategySelectorXL } from '../StrategySelectorXL/StrategySelectorXL';
import './StrategyOtherFilters.scss';
import Settings from 'assets/images/Strategy/SettingsIcon.svg';
import useWindowWidth from 'hooks/useWindowWidth';

const StrategyOtherFilters = ({
  setIsOtherFilters,
}: {
  setIsOtherFilters: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const isMobileVersion = useWindowWidth() < 768;
  return (
    <div className="strategy-other-filters">
      <div className="strategy-other-filters__title">Other Filters</div>
      <div className="strategy-other-filters__content">
        <GTumbler disabled={true} size={isMobileVersion ? 'large' : null} name="strategyTumbler" />
        <div className="strategy-other-filters__selector">
          <StrategySelectorXL
            options={[{ id: 0, name: 'Sessions filter' }]}
            activeOption={0}
            disabled={true}
            onChange={() => {}}
          />
        </div>
        <button
          style={{ opacity: 0.5, cursor: 'not-allowed' }}
          onClick={() => {
            // setIsOtherFilters(true);
          }}
        >
          <img src={Settings} alt="S" />
        </button>
      </div>
    </div>
  );
};

export default StrategyOtherFilters;
