import { type Dispatch, type FC, type SetStateAction, useEffect, useState } from 'react';
import { StrategySwitcher } from '../StrategySwitcher/StrategySwitcher';
import { StrategyConditions } from '../StrategyConditions/StrategyConditions';
import {
  type IndicatorsList,
  StrategyModes,
  TradesDirections,
} from '../../../../../../interfaces/IStrategy';
import { StrategyType } from '../../../../../../enums/Strategy';
import { useSelector } from '../../../../../../redux/rootReducer';
import { useChartSelector } from '../../../../../../redux/chart/reducer';
import { useDispatch } from 'react-redux';
import { setBackTest, updateSelectedStrategy } from '../../../../../../redux/strategy/reducer';
import { StrategySelectorXLSettings } from '../StrategySelectorXLSettings/StrategySelectorXLSettings';

const tradesDirections = [
  {
    name: 'Only Long',
    id: TradesDirections.Long,
  },
  {
    name: 'Both',
    id: TradesDirections.Both,
  },
  {
    name: 'Only Short',
    id: TradesDirections.Short,
  },
];

const strategyTypes = [
  {
    name: 'DCA',
    id: StrategyType.DCA,
  },
  {
    name: 'Fixed Risk',
    id: StrategyType.FixedRisk,
  },
];

const entryConditionTypes = [
  {
    name: 'Indicators',
    id: 0,
  },
  {
    name: 'Custom events',
    id: 1,
    disabled: true,
  },
];

interface IProps {
  strategyType: StrategyType;
  strategyTypeLong?: StrategyType;
  strategyTypeShort?: StrategyType;
  setStrategyTypeSettings: Dispatch<SetStateAction<boolean>>;
  setStrategyTypeSettingsLong?: Dispatch<SetStateAction<boolean>>;
  setStrategyTypeSettingsShort?: Dispatch<SetStateAction<boolean>>;
  setStrategyType: Dispatch<SetStateAction<StrategyType>>;
  setStrategyTypeLong?: Dispatch<SetStateAction<StrategyType>>;
  setStrategyTypeShort?: Dispatch<SetStateAction<StrategyType>>;
  addNewCond: (groupId: number) => void;
  addNewCondGroup: () => void;
  removeCondGroup: (groupId: number, condId: number) => void;
  removeCond: (condId: number) => void;
  changeCond: (groupId: number, condId: number, value: IndicatorsList) => void;
  openSettings: (groupId: number, condId: number, strategyType?: 'long' | 'short') => void;
  isOpenedLongSettingsTab: [number, number];
  isOpenedShortSettingsTab: [number, number];
  openedLongTabCondition: number;
  setOpenedLongTabCondition: Dispatch<SetStateAction<number>>;
  openedShortTabCondition: number;
  setOpenedShortTabCondition: Dispatch<SetStateAction<number>>;
}

export const StrategyConstructor: FC<IProps> = (props) => {
  const [activeEntryCondPage, setActiveEntryCondPage] = useState(0);
  const [isStrategyUpdated, setStrategyIsUpdated] = useState(false);
  const chartState = useSelector((state) => state.chart);
  const chartData = useChartSelector(chartState.selectedChart);
  const openedChart = chartData.chartType;
  const strategy = useSelector((state) => state.strategy).selectedStrategy;
  const dispatch = useDispatch();
  useEffect(() => {
    if (isStrategyUpdated) {
      dispatch(setBackTest(true));
      setStrategyIsUpdated(false);
    }
  }, [isStrategyUpdated]);

  if (!strategy) return;

  return (
    <>
      {strategy.mode === StrategyModes.ONE_SIDE && (
        <div className="strategy__main__elem">
          <div className="strategy__main__elem__title">Trades Direction</div>
          <StrategySwitcher
            options={tradesDirections}
            activeOption={strategy.settings.direction}
            onChange={(value) => {
              dispatch(
                updateSelectedStrategy({
                  settings: {
                    ...strategy.settings,
                    direction: value,
                  },
                }),
              );
              setStrategyIsUpdated(true);
            }}
            height={25}
            width={100}
            activeColor={'#9747FF'}
          />
        </div>
      )}
      {strategy.mode === StrategyModes.ONE_SIDE ? (
        <div style={{ alignItems: 'flex-start' }} className="strategy__main__elem">
          <div className="strategy__main__elem__title">Type of strategy</div>
          <StrategySelectorXLSettings
            key={props.strategyType}
            options={strategyTypes}
            activeOption={props.strategyType}
            onChange={(e) => {
              props.setStrategyType(e);
            }}
            openSettingsHandler={() => {
              props.setStrategyTypeSettings(true);
            }}
            width={'100%'}
          />
        </div>
      ) : (
        <>
          <div style={{ alignItems: 'flex-start' }} className="strategy__main__elem">
            <div className="strategy__main__elem__title">Type of strategy</div>
            {openedChart === 'LONG' ? (
              <>
                <StrategySelectorXLSettings
                  options={strategyTypes}
                  key={props.strategyTypeLong}
                  activeOption={props.strategyTypeLong}
                  onChange={(e) => {
                    props.setStrategyTypeLong(e);
                  }}
                  openSettingsHandler={() => {
                    props.setStrategyTypeSettingsLong(true);
                  }}
                  width={'100%'}
                />
              </>
            ) : (
              <StrategySelectorXLSettings
                options={strategyTypes}
                key={props.strategyTypeShort}
                activeOption={props.strategyTypeShort}
                onChange={(e) => {
                  props.setStrategyTypeShort(e);
                }}
                openSettingsHandler={() => {
                  props.setStrategyTypeSettingsShort(true);
                }}
                width={'100%'}
              />
            )}
          </div>
        </>
      )}
      <div className="strategy__main__elem">
        <div className="strategy__main__elem__title">Entry Conditions</div>
        <StrategySwitcher
          options={entryConditionTypes}
          activeOption={activeEntryCondPage}
          onChange={(value) => {
            setActiveEntryCondPage(value);
          }}
          height={25}
          activeColor={'#9747FF'}
        />
      </div>
      {strategy.mode === StrategyModes.ONE_SIDE && (
        <StrategyConditions
          list={strategy.indicators}
          addNewCond={props.addNewCond}
          addNewCondGroup={props.addNewCondGroup}
          removeCondGroup={props.removeCondGroup}
          removeCond={props.removeCond}
          changeCond={props.changeCond}
          openSettings={props.openSettings}
        />
      )}
      {strategy.mode === StrategyModes.HEDGE && openedChart === 'LONG' && (
        <StrategyConditions
          list={strategy.strategies.long.indicators}
          strategyType={'long'}
          addNewCond={props.addNewCond}
          addNewCondGroup={props.addNewCondGroup}
          removeCondGroup={props.removeCondGroup}
          removeCond={props.removeCond}
          changeCond={props.changeCond}
          openSettings={props.openSettings}
          setOpenedTabCondition={props.setOpenedLongTabCondition}
          openedTabCondition={props.openedLongTabCondition}
        />
      )}
      {strategy.mode === StrategyModes.HEDGE && openedChart === 'SHORT' && (
        <StrategyConditions
          list={strategy.strategies.short.indicators}
          strategyType={'short'}
          addNewCond={props.addNewCond}
          addNewCondGroup={props.addNewCondGroup}
          removeCondGroup={props.removeCondGroup}
          removeCond={props.removeCond}
          changeCond={props.changeCond}
          openSettings={props.openSettings}
          setOpenedTabCondition={props.setOpenedShortTabCondition}
          openedTabCondition={props.openedShortTabCondition}
        />
      )}
    </>
  );
};
