import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { State } from './types';
import type { IUser } from '../../interfaces/IUser';
import { type ITelegramInfo } from '../../services/api/Telegram/types';

const initialState: State = {
  user: JSON.parse(localStorage.getItem('user')) ?? null,
  telegram: null,
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      if (!action.payload) {
        state.user = null;
        localStorage.removeItem('user');
        return;
      }

      localStorage.setItem('user', JSON.stringify(action.payload));

      state.user = action.payload;
    },
    setTelegram: (state, action: PayloadAction<ITelegramInfo | null>) => {
      state.telegram = action.payload;
    },
    updateLogin: (state, action: PayloadAction<string>) => {
      state.user.login = action.payload;
    },
  },
});

export const { setUser, setTelegram, updateLogin } = authReducer.actions;
export default authReducer.reducer;
