import { StrategyModes, type IStrategy, type IHedgeStrategy } from 'interfaces/IStrategy';
import { ApiServiceBase } from '../../ApiServiceBase';
import {
  type IStrategyAllResponse,
  type IStrategyTickerData,
  type IMergedStrategy,
} from './types/StrategyResponse';
import {
  type IAllPublishedStrategy,
  type IProfileStrategy,
} from '../../../pages/UserProfile/interfaces/interfaces';

class ApiStrategy extends ApiServiceBase {
  protected baseUrl = '/api/strategy/';

  public async getStrategies(): Promise<IStrategyAllResponse> {
    try {
      const response = await this.get('get_all');

      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async addStrategyFavoriteStatus({
    strategyId,
    mode,
  }: {
    strategyId: string;
    mode: StrategyModes;
  }): Promise<IStrategyAllResponse> {
    try {
      const response = await this.post('add_favourite', {
        strategy_id: strategyId,
        mode,
      });

      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async removeStrategyFavoriteStatus({
    strategyId,
    mode,
  }: {
    strategyId: string;
    mode: StrategyModes;
  }): Promise<IStrategyAllResponse> {
    try {
      const response = await this.post('remove_favourite', {
        strategy_id: strategyId,
        mode,
      });

      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async getStrategy(strategyId: string): Promise<(IStrategy | IHedgeStrategy) | null> {
    try {
      const response = await this.post('get', { _id: strategyId });

      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }

  public async likeStrategy(strategyId: string, mode: StrategyModes): Promise<boolean> {
    try {
      const response = await this.post('like', { strategy_id: strategyId, mode });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async unlikeStrategy(strategyId: string, mode: StrategyModes): Promise<boolean> {
    try {
      const response = await this.post('unlike', { strategy_id: strategyId, mode });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async getPublishedStrategies(userId: string): Promise<IProfileStrategy[]> {
    try {
      const response = await this.get('get_published/' + userId);

      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async getAllPublishedStrategies(): Promise<
    Record<StrategyModes, IAllPublishedStrategy[]>
  > {
    try {
      const response = await this.get('get_all_published');

      return (
        response.data.data ?? {
          [StrategyModes.ONE_SIDE]: [],
          [StrategyModes.HEDGE]: [],
        }
      );
    } catch (_) {
      return {
        [StrategyModes.ONE_SIDE]: [],
        [StrategyModes.HEDGE]: [],
      };
    }
  }

  public async getAllPublishedMergedStrategies(): Promise<IAllPublishedStrategy[]> {
    try {
      const response = await this.get('merged/get_all_published');

      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async likeMergedStrategy(strategyId: string, mode: StrategyModes): Promise<boolean> {
    try {
      const response = await this.post('merged/like', { strategy_id: strategyId, mode });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async unlikeMergedStrategy(strategyId: string, mode: StrategyModes): Promise<boolean> {
    try {
      const response = await this.post('merged/unlike', { strategy_id: strategyId, mode });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async getPublishedMergedStrategies(userId: string): Promise<IProfileStrategy[]> {
    try {
      const response = await this.get('merged/get_published/' + userId);

      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async getMergeStrategy(strategyId: string): Promise<IMergedStrategy | null> {
    try {
      const response = await this.post('merged/get', { _id: strategyId });

      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }

  public async saveStrategy(data: IStrategy | IHedgeStrategy): Promise<boolean> {
    try {
      const response = await this.post('save', data);

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async updateStrategy(
    id: string,
    mode: StrategyModes,
    data: Partial<IStrategy | IHedgeStrategy>,
  ): Promise<boolean> {
    try {
      const response = await this.post('update', { strategy_id: id, mode, data });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async updateMergedStrategy(id: string, data: Partial<IMergedStrategy>): Promise<boolean> {
    try {
      const response = await this.post('merged/update', { strategy_id: id, data });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async addStrategy(
    modeValue: StrategyModes.ONE_SIDE | StrategyModes.HEDGE,
  ): Promise<IStrategy | null> {
    try {
      const response = await this.post('add', { mode: modeValue });

      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }

  public async copyStrategy(strategyId: string, mode: StrategyModes): Promise<string | null> {
    try {
      const response = await this.post('copy', { strategy_id: strategyId, mode });

      return response.data.data ?? false;
    } catch (_) {
      return null;
    }
  }

  public async duplicateStrategy(strategyId: string, mode: StrategyModes): Promise<boolean> {
    try {
      const response = await this.post('duplicate', { strategy_id: strategyId, mode });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async copyMergedStrategy(strategyId: string): Promise<boolean> {
    try {
      const response = await this.post('merged/copy', { strategy_id: strategyId });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async removeStrategy(mode: StrategyModes, strategyId: string): Promise<boolean> {
    try {
      const response = await this.post('remove', { mode, strategy_id: strategyId });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async getAllMergedStrategies(): Promise<IMergedStrategy[]> {
    try {
      const response = await this.get('merged/get_all');
      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }

  public async createMergedStrategy(mergedStrategy: any): Promise<IStrategyTickerData> {
    try {
      const response = await this.post('merged/create', { data: { ...mergedStrategy } });
      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }

  public async saveMergedStrategy(mergedStrategy: any): Promise<IStrategyTickerData> {
    try {
      const response = await this.post('merged/save', { data: { ...mergedStrategy } });
      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }

  public async removeMergedStrategy(mergedStrategyId: string): Promise<boolean> {
    try {
      const response = await this.post('merged/remove', { strategy_id: mergedStrategyId });

      return response.data.data ?? false;
    } catch (_) {
      return false;
    }
  }

  public async addMergedStrategyFavoriteStatus({
    mergedStrategyId,
  }: {
    mergedStrategyId: string;
  }): Promise<IStrategyAllResponse> {
    try {
      const response = await this.post('merged/add_favourite', {
        strategy_id: mergedStrategyId,
      });

      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async removeMergedStrategyFavoriteStatus({
    mergedStrategyId,
  }: {
    mergedStrategyId: string;
  }): Promise<IStrategyAllResponse> {
    try {
      const response = await this.post('merged/remove_favourite', {
        strategy_id: mergedStrategyId,
      });

      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }
}

export const apiStrategy = new ApiStrategy();
