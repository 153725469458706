import { useDispatch } from 'react-redux';
import { type IRootState, useSelector } from 'redux/rootReducer';
import { refreshCandleSettings, updateCandleProperty } from 'redux/chartCandleSettings/reducer';

import GTumbler from 'components/GTumbler/GTumbler';
import { CustomColorPicker } from '../../ColorPicker/ColorPicker';
import CustomDropDown from '../../DropDownComponents/CustomMultiLineDropDown/CustomStyleSettingsDropDown';
import {
  candleTooltipRectPositions,
  candleTooltipShowRuleTypes,
  candleTooltipShowTypes,
  candleTypes,
  fontFamilyTypes,
  fontWeightTypes,
  lineStyleTypes,
  numberSizes,
  textStyleTypes,
} from '../../../Data/dropdownData';
import { type CandleStyle } from '@basttyy/klinecharts';
import useWindowWidth from 'hooks/useWindowWidth';
import { apiChartStyles } from '../../../../../../../services/api/ApiChartStyles/ApiChartStyles';

export const CandleSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const isMobileVersion = useWindowWidth() < 768;
  const settings: CandleStyle = useSelector((state: IRootState) => state.chartCandleSettings);
  return (
    <div className="propertyMainContainer">
      <div className="container__content_main_content_header">
        CANDLE TYPE
        <div className="propertyContainer__candleTypeDropDown">
          <CustomDropDown
            largeList
            options={candleTypes}
            activeOption={{
              id: candleTypes.findIndex((item) => item.name === settings.type),
              name: settings.type,
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.type',
                value: candleTypes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'type',
                  value: candleTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Body color{' '}
        <div className="propertyContainer__colorsContainer">
          <CustomColorPicker
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.bar.upColor',
                value: color,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'bar',
                  propertyNode2: 'upColor',
                  value: color,
                }),
              );
            }}
            defaultColor={settings.bar.upColor}
            name="Bar Up"
          />
          <CustomColorPicker
            defaultColor={settings.bar.downColor}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.bar.downColor',
                value: color,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'bar',
                  propertyNode2: 'downColor',
                  value: color,
                }),
              );
            }}
            name="Bar Down"
          />
        </div>
      </div>
      <div className="propertyContainer">
        Border color{' '}
        <div className="propertyContainer__colorsContainer">
          <CustomColorPicker
            defaultColor={settings.bar.upBorderColor}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.bar.upBorderColor',
                value: color,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'bar',
                  propertyNode2: 'upBorderColor',
                  value: color,
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.bar.downBorderColor}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.bar.downBorderColor',
                value: color,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'bar',
                  propertyNode2: 'downBorderColor',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Wick color{' '}
        <div className="propertyContainer__colorsContainer">
          <CustomColorPicker
            defaultColor={settings.bar.upWickColor}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.bar.upWickColor',
                value: color,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'bar',
                  propertyNode2: 'upWickColor',
                  value: color,
                }),
              );
            }}
          />
          <CustomColorPicker
            defaultColor={settings.bar.downWickColor}
            onAddColor={(color) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.bar.downWickColor',
                value: color,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'bar',
                  propertyNode2: 'downWickColor',
                  value: color,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="container__content_main_content_header">BAR NO-CHANGE</div>
      <div className="propertyContainer">
        Color{' '}
        <CustomColorPicker
          defaultColor={settings.bar.noChangeColor}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.bar.noChangeColor',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'bar',
                propertyNode2: 'noChangeColor',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Border color{' '}
        <CustomColorPicker
          defaultColor={settings.bar.noChangeBorderColor}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.bar.noChangeBorderColor',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'bar',
                propertyNode2: 'noChangeBorderColor',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Wick color{' '}
        <CustomColorPicker
          defaultColor={settings.bar.noChangeWickColor}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.bar.noChangeWickColor',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'bar',
                propertyNode2: 'noChangeWickColor',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="container__content_main_content_header">AREA SETTINGS</div>
      <div className="propertyContainer">
        Area line color{' '}
        <CustomColorPicker
          defaultColor={settings.area.lineColor}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.area.lineColor',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'area',
                propertyNode2: 'lineColor',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Area line size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.area.lineSize),
              name: settings.area.lineSize.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.area.lineSize',
                value,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'area',
                  propertyNode2: 'lineSize',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="container__content_main_content_header">PRICEMARK HIGH SETTINGS</div>
      <div className="propertyContainer">
        Show Highest Price
        <GTumbler
          checked={settings.priceMark.high.show}
          size={isMobileVersion ? 'large' : null}
          name="candle.priceMark.high"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.high.show',
              value,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'high',
                propertyNode3: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Pricemark High textsize
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.priceMark.high.textSize),
              name: settings.priceMark.high.textSize.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.priceMark.high.textSize',
                value,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'priceMark',
                  propertyNode2: 'high',
                  propertyNode3: 'textSize',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Pricemark High color{' '}
        <CustomColorPicker
          defaultColor={settings.priceMark.high.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.high.color',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'high',
                propertyNode3: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Pricemark High Font Family
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={fontFamilyTypes}
            activeOption={{
              id: Number(
                fontFamilyTypes.findIndex(
                  (item) => item.name === settings.priceMark.high.textFamily,
                ),
              ),
              name: settings.priceMark.high.textFamily.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.priceMark.high.textFamily',
                value: fontFamilyTypes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'priceMark',
                  propertyNode2: 'high',
                  propertyNode3: 'textFamily',
                  value: fontFamilyTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Pricemark High Font weight
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={fontWeightTypes}
            activeOption={{
              id: Number(
                fontWeightTypes.findIndex(
                  (item) => item.name === settings.priceMark.high.textWeight,
                ),
              ),
              name: settings.priceMark.high.textWeight.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.priceMark.high.textWeight',
                value: fontWeightTypes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'priceMark',
                  propertyNode2: 'high',
                  propertyNode3: 'textWeight',
                  value: fontWeightTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="container__content_main_content_header">PRICEMARK LOW SETTINGS</div>
      <div className="propertyContainer">
        Show Lowest Price
        <GTumbler
          checked={settings.priceMark.low.show}
          size={isMobileVersion ? 'large' : null}
          name="candle.priceMark.high"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.low.show',
              value,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'low',
                propertyNode3: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Pricemark Low textsize
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.priceMark.low.textSize),
              name: settings.priceMark.low.textSize.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.priceMark.low.textSize',
                value,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'priceMark',
                  propertyNode2: 'low',
                  propertyNode3: 'textSize',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Pricemark Low color{' '}
        <CustomColorPicker
          defaultColor={settings.priceMark.low.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.low.color',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'low',
                propertyNode3: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Pricemark Low Font Family
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={fontFamilyTypes}
            activeOption={{
              id: Number(
                fontFamilyTypes.findIndex(
                  (item) => item.name === settings.priceMark.low.textFamily,
                ),
              ),
              name: settings.priceMark.low.textFamily.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.priceMark.low.textFamily',
                value: fontFamilyTypes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'priceMark',
                  propertyNode2: 'low',
                  propertyNode3: 'textFamily',
                  value: fontFamilyTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Pricemark Low Font weight
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={fontWeightTypes}
            activeOption={{
              id: Number(
                fontWeightTypes.findIndex(
                  (item) => item.name === settings.priceMark.low.textWeight,
                ),
              ),
              name: settings.priceMark.low.textWeight.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.priceMark.low.textWeight',
                value: fontWeightTypes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'priceMark',
                  propertyNode2: 'low',
                  propertyNode3: 'textWeight',
                  value: fontWeightTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="container__content_main_content_header">PRICEMARK LAST SETTINGS</div>
      <div className="propertyContainer">
        Show Pricemark last
        <GTumbler
          checked={settings.priceMark.last.show}
          size={isMobileVersion ? 'large' : null}
          name="candle.priceMark.high"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.last.show',
              value,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'last',
                propertyNode3: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Pricemark High color{' '}
        <CustomColorPicker
          defaultColor={settings.priceMark.last.upColor}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.last.upColor',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'last',
                propertyNode3: 'upColor',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Pricemark Low color{' '}
        <CustomColorPicker
          defaultColor={settings.priceMark.last.downColor}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.last.downColor',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'last',
                propertyNode3: 'downColor',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Pricemark No-Change color{' '}
        <CustomColorPicker
          defaultColor={settings.priceMark.last.noChangeColor}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.last.noChangeColor',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'last',
                propertyNode3: 'noChangeColor',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="container__content_main_content_header">PRICEMARK LASTLINE SETTINGS</div>
      <div className="propertyContainer">
        Show Pricemark last line
        <GTumbler
          checked={settings.priceMark.last.line.show}
          size={isMobileVersion ? 'large' : null}
          name="candle.priceMark.high"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.last.line.show',
              value,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'last',
                propertyNode3: 'line',
                propertyNode4: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Pricemark last line style
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={lineStyleTypes}
            activeOption={{
              id: Number(
                lineStyleTypes.findIndex(
                  (item) => item.name === settings.priceMark.last.line.style,
                ),
              ),
              name: settings.priceMark.last.line.style.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.priceMark.last.line.style',
                value: lineStyleTypes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'priceMark',
                  propertyNode2: 'last',
                  propertyNode3: 'line',
                  propertyNode4: 'style',
                  value: lineStyleTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Pricemark last line size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.priceMark.last.line.size),
              name: settings.priceMark.last.line.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.priceMark.last.line.size',
                value: numberSizes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'priceMark',
                  propertyNode2: 'last',
                  propertyNode3: 'line',
                  propertyNode4: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="container__content_main_content_header">PRICEMARK LAST TEXT SETTINGS</div>
      <div className="propertyContainer">
        Show Pricemark last text
        <GTumbler
          checked={settings.priceMark.last.text.show}
          size={isMobileVersion ? 'large' : null}
          name="candle.priceMark.high"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.last.text.show',
              value,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'last',
                propertyNode3: 'text',
                propertyNode4: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Pricemark last text style
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={textStyleTypes}
            activeOption={{
              id: Number(
                textStyleTypes.findIndex(
                  (item) => item.name === settings.priceMark.last.text.style,
                ),
              ),
              name: settings.priceMark.last.text.style.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.priceMark.last.text.style',
                value: textStyleTypes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'priceMark',
                  propertyNode2: 'last',
                  propertyNode3: 'text',
                  propertyNode4: 'style',
                  value: textStyleTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Pricemark last text size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.priceMark.last.text.size),
              name: settings.priceMark.last.text.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.priceMark.last.text.size',
                value: numberSizes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'priceMark',
                  propertyNode2: 'last',
                  propertyNode3: 'text',
                  propertyNode4: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Pricemark last text color{' '}
        <CustomColorPicker
          defaultColor={settings.priceMark.last.text.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.priceMark.last.text.color',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'priceMark',
                propertyNode2: 'last',
                propertyNode3: 'text',
                propertyNode4: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="container__content_main_content_header">TOOLTIP SETTINGS</div>
      <div className="propertyContainer">
        Candle Tooltip
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={candleTooltipShowRuleTypes}
            activeOption={{
              id: Number(
                candleTooltipShowRuleTypes.findIndex(
                  (item) => item.name === settings.tooltip.showRule,
                ),
              ),
              name: settings.tooltip.showRule.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.tooltip.showRule',
                value: candleTooltipShowRuleTypes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'tooltip',
                  propertyNode2: 'showRule',
                  value: candleTooltipShowRuleTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Tooltip showtype
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={candleTooltipShowTypes}
            activeOption={{
              id: Number(
                candleTooltipShowTypes.findIndex((item) => item.name === settings.tooltip.showType),
              ),
              name: settings.tooltip.showType.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.tooltip.showType',
                value: candleTooltipShowTypes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'tooltip',
                  propertyNode2: 'showType',
                  value: candleTooltipShowTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Tooltip rect position
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={candleTooltipRectPositions}
            activeOption={{
              id: Number(
                candleTooltipRectPositions.findIndex(
                  (item) => item.name === settings.tooltip.rect.position,
                ),
              ),
              name: settings.tooltip.rect.position.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.tooltip.rect.position',
                value: candleTooltipRectPositions[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'tooltip',
                  propertyNode2: 'rect',
                  propertyNode3: 'position',
                  value: candleTooltipRectPositions[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Tooltip rect color{' '}
        <CustomColorPicker
          defaultColor={String(settings.tooltip.rect.color)}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.tooltip.rect.color',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'tooltip',
                propertyNode2: 'rect',
                propertyNode3: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        і Tooltip rect border - color{' '}
        <CustomColorPicker
          defaultColor={settings.tooltip.rect.borderColor}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.tooltip.rect.borderColor',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'tooltip',
                propertyNode2: 'rect',
                propertyNode3: 'borderColor',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Tooltip text size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.tooltip.text.size),
              name: settings.tooltip.text.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.tooltip.text.size',
                value: numberSizes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'tooltip',
                  propertyNode2: 'text',
                  propertyNode3: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Tooltip text weight
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={fontWeightTypes}
            activeOption={{
              id: Number(
                fontWeightTypes.findIndex((item) => item.name === settings.tooltip.text.weight),
              ),
              name: settings.tooltip.text.weight.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'candle.tooltip.text.weight',
                value: fontWeightTypes[value - 1].name,
              });
              dispatch(
                updateCandleProperty({
                  propertyNode1: 'tooltip',
                  propertyNode2: 'text',
                  propertyNode3: 'weight',
                  value: fontWeightTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Tooltip text color{' '}
        <CustomColorPicker
          defaultColor={settings.tooltip.text.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'candle.tooltip.text.color',
              value: color,
            });
            dispatch(
              updateCandleProperty({
                propertyNode1: 'tooltip',
                propertyNode2: 'text',
                propertyNode3: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>
      <button
        className="container__content_main_content_refresh"
        onClick={() => {
          apiChartStyles.refreshChartStylingSettings('candle');
          dispatch(refreshCandleSettings());
        }}
      >
        Refresh settings
      </button>
    </div>
  );
};
