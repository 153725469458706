import { useDispatch } from 'react-redux';
import {
  deleteTicker,
  setQualPercentsTicker,
  updateTicker,
} from '../../../redux/customTicker/reducer';
import styles from '../TickerSelector.module.css';
import CustomIndex from '../../../assets/images/CustomIndexIcon.svg';
import DotsIcon from '../../../assets/images/DotsIcon.svg';
import CancelIcon from '../../../assets/images/CancelIcon.svg';
import EditTickerIcon from '../../../assets/images/EditTickerIcon.svg';
import DeleteTickerIcon from '../../../assets/images/DeleteTickerIcon.svg';
import { useState } from 'react';
import { parseTicker } from '../../../utils/parseTicker';
import { updateChart } from '../../../redux/chart/reducer';
import { type IStrategyTickerData } from '../../../services/api/Strategy/types/StrategyResponse';
import { useSelector } from '../../../redux/rootReducer';
import ClosedLock from '../../../assets/images/Strategy/ClosedLock.svg';
import RedClosedLock from '../../../assets/images/Strategy/ClosedLockRed.svg';
import OpenedLock from '../../../assets/images/Strategy/OpenedLock.svg';
import { apiTicker } from '../../../services/api/Ticker/ApiTicker';
const SavedTickerItem = ({
  onClose,
  item,
}: {
  onClose: () => void;
  item: IStrategyTickerData;
}): JSX.Element => {
  const chart = useSelector((state) => state.chart);
  const [isOpened, setIsOpened] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  const [newHeader, setNewHeader] = useState(item.name);
  const [currentItemState, setCurrentItemState] = useState(item);

  const [inputsLockData, setInputsLockData] = useState<boolean[]>(
    new Array(currentItemState.tickers.length).fill(false),
  );
  const [locksHighlighted, setLocksHighlighted] = useState(false);
  const arr = [...currentItemState.tickers.map((el) => [el.percent])].slice();
  const changeData = (
    e: string,
    takeIndex: number,
    min: number,
    max: number,
    element?: any,
  ): void => {
    let value = parseFloat(e);
    const firstFalseIndex = inputsLockData.findIndex((value, index) => {
      return !value && index !== takeIndex;
    });
    const falseIndexes = inputsLockData.reduce((acc, value, index) => {
      if (value || index === takeIndex) {
        acc.push(index);
      }
      return acc;
    }, []);
    const falseIndexesNoTakeIndex = inputsLockData.reduce((acc, value, index) => {
      if (value && index !== takeIndex) {
        acc.push(index);
      }
      return acc;
    }, []);
    const sumOfFalseIndexValuesNoTakeIndex = falseIndexesNoTakeIndex.reduce(
      (acc: number, index) => {
        if (index >= 0 && index < arr.length) {
          acc += Number(arr[index][0]);
        }
        return acc;
      },
      0,
    );
    const sumOfFalseIndexValues = falseIndexes.reduce((acc: number, index) => {
      if (index >= 0 && index < arr.length) {
        acc += Number(arr[index][0]);
      }
      return acc;
    }, 0);

    const newMaxValue = 100 - sumOfFalseIndexValuesNoTakeIndex;
    if (isNaN(value) || value < min) {
      value = min;
    } else if (value > max) {
      value = newMaxValue;
    }

    const modifiedElem = arr[takeIndex].slice();
    if (!modifiedElem) return;

    modifiedElem[0] = value;
    arr[takeIndex] = modifiedElem as [number];

    arr.forEach((e: number[], i: number) => {
      if (i === takeIndex) return;
      e = e.slice();

      if (e[0] < 0) {
        e[0] = 0;
      } else {
        const sum = arr.reduce((a: number, b: number[]) => a + b[0], 0);

        if (sum > 100) {
          if (firstFalseIndex === -1 || sumOfFalseIndexValues > 100) {
            return;
          }
          if (firstFalseIndex !== -1) {
            if (!inputsLockData[i]) {
              e[0] -= sum - 100;
              if (e[0] < 0) e[0] = 0;
            }
          }
        } else if (sum < 100) {
          if (firstFalseIndex === -1 || sumOfFalseIndexValues > 100) {
            return;
          }
          if (!inputsLockData[i]) {
            e[0] += 100 - sum;
          }
        }
      }

      arr[i] = e as [number];
    });
    if (element) {
      const updatedElement = {
        ...element,
        tickers: element.tickers.map((item, index) => ({
          ...item,
          percent: arr[index][0],
        })),
      };
      setCurrentItemState(updatedElement);
    }
  };
  const highLightLocks = (): void => {
    setLocksHighlighted(true);
    setTimeout(() => {
      setLocksHighlighted(false);
    }, 1000);
  };

  const updateStrategyTicker = (): void => {
    dispatch(
      updateChart({
        id: chart.selectedChart,
        data: {
          ticker: {
            type: 'custom',
            _id: item._id,
            name: newHeader,
            tickers: currentItemState.tickers,
          },
        },
      }),
    );
  };
  return (
    <span
      className={styles.savedTicker}
      style={
        !isOpened
          ? { height: '48px', maxHeight: '48px', overflow: 'visible' }
          : {
              background: '#130E2D',
            }
      }
    >
      <span
        className={styles.savedTickerHeader}
        onClick={() => {
          if (!isOpened && !isMenuOpened) {
            updateStrategyTicker();
          }
          onClose();
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setIsMenuOpened(false);
          }, 2500);
        }}
      >
        <span className={styles.savedTickerHeaderName} style={isOpened ? { width: '75%' } : {}}>
          <img src={CustomIndex} alt="custom" />
          {isOpened ? (
            <input
              type="text"
              style={{ height: '90%', minHeight: '90%', maxHeight: '90%' }}
              value={newHeader}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                setNewHeader(e.target.value);
              }}
            />
          ) : (
            <span>{item.name}</span>
          )}
        </span>
        <span
          className={styles.savedTickerHeaderOpenButton}
          onClick={(e) => {
            e.stopPropagation();
            if (isOpened) {
              setIsOpened(false);
              setIsMenuOpened(false);
            } else {
              setIsMenuOpened(!isMenuOpened);
            }
          }}
        >
          <img src={isOpened ? CancelIcon : DotsIcon} alt="dots" />
          <div
            className={styles.savedTickerMenu}
            style={!isMenuOpened ? { width: 0, height: 0, overflow: 'hidden' } : {}}
          >
            <button
              onClick={() => {
                setIsOpened(true);
                setIsMenuOpened(false);
                setIsEditMode(true);
              }}
              tabIndex={0}
              onBlur={() => {
                setIsMenuOpened(false);
              }}
              className={styles.savedTickerMenuEditButton}
            >
              Edit <img src={EditTickerIcon} alt="edit" />
            </button>
            <button
              className={styles.savedTickerMenuDeleteButton}
              onClick={() => {
                apiTicker.deleteCustomTicker(item._id).then((isDeleted) => {
                  if (isDeleted) {
                    dispatch(deleteTicker(item._id));
                  }
                });
              }}
            >
              Delete
              <img src={DeleteTickerIcon} alt="delete" />
            </button>
          </div>
        </span>
      </span>
      {isOpened && (
        <>
          {currentItemState.tickers.map((ticker, tickerIndex) => {
            const name = parseTicker(ticker.exchange, `${ticker.baseAsset}${ticker.quoteAsset}`);
            return (
              <div key={tickerIndex} className={styles.savedTickerListItem} onClick={() => {}}>
                <span className={styles.savedTickerListItem_name}>
                  <img
                    className={styles.tickerSelectorContentImage}
                    src={`${process.env.PUBLIC_URL}/coins/${ticker.baseAsset}.png`}
                    alt=""
                  />
                  <span>
                    {name} | From {new Date(ticker.since).toLocaleDateString()}
                  </span>
                </span>
                <span className={styles.tickerSettingsTickersContentItemInput}>
                  <input
                    type="number"
                    style={{
                      border: Number(ticker.percent.toFixed(0)) === 0 ? '1px solid red' : 'none',
                    }}
                    value={Number(ticker.percent).toFixed(
                      ticker.percent.toString().includes('.') ? 2 : 0,
                    )}
                    step={0.1}
                    onChange={(e) => {
                      const falseIndexesNoTakeIndex = inputsLockData.reduce((acc, value, index) => {
                        if (value && index !== tickerIndex) {
                          acc.push(index);
                        }
                        return acc;
                      }, []);
                      const sumOfFalseIndexValuesNoTakeIndex = falseIndexesNoTakeIndex.reduce(
                        (acc: number, index) => {
                          if (index >= 0 && index < arr.length) {
                            acc += Number(arr[index][0]);
                          }
                          return acc;
                        },
                        0,
                      );
                      const newMaxValue = 100 - sumOfFalseIndexValuesNoTakeIndex;
                      const firstFalseIndex = inputsLockData.findIndex((value, index) => {
                        return !value && index !== tickerIndex;
                      });
                      if (newMaxValue > 0) {
                        if (inputsLockData.length !== 1 ? firstFalseIndex !== -1 : true) {
                          changeData(e.target.value, tickerIndex, 1, newMaxValue, item);
                        } else {
                          highLightLocks();
                        }
                      }
                    }}
                  />
                  %
                  <img
                    className={styles.tickerSettingsTickersContentItemLockImage}
                    src={
                      locksHighlighted
                        ? RedClosedLock
                        : inputsLockData?.[tickerIndex]
                        ? ClosedLock
                        : OpenedLock
                    }
                    onClick={() => {
                      const newArr = [...inputsLockData];
                      newArr[tickerIndex] = !newArr[tickerIndex];
                      setInputsLockData(newArr);
                    }}
                    alt="S"
                  />
                </span>
              </div>
            );
          })}
          <span className={styles.savedTickerButtonsContainer}>
            <div>
              <button
                className={styles.savedTickerCancelButton}
                onClick={() => {
                  setIsOpened(false);
                  setCurrentItemState(item);
                }}
              >
                Cancel
              </button>
              <button
                className={styles.savedTickerSaveButton}
                onClick={() => {
                  if (currentItemState.tickers.every((item) => item.percent !== 0)) {
                    setIsOpened(false);
                    apiTicker
                      .saveCustomTicker({
                        _id: item._id,
                        name: newHeader,
                        tickers: currentItemState.tickers,
                      })
                      .then((isTickerUpdated) => {
                        if (isTickerUpdated) {
                          dispatch(
                            updateTicker({
                              _id: currentItemState._id,
                              ticker: { ...currentItemState, name: newHeader },
                            }),
                          );

                          updateStrategyTicker();
                        }
                      });
                  }
                }}
              >
                Save
              </button>
            </div>
            <button
              className={styles.savedTickerSeyEqual}
              onClick={() => {
                dispatch(setQualPercentsTicker({ id: currentItemState._id }));
                setCurrentItemState(item);
              }}
            >
              Set equally
            </button>
          </span>
        </>
      )}
    </span>
  );
};

export default SavedTickerItem;
