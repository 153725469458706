import MergedStrategiesListChart from 'pages/HomePage/components/StrategyEditor/components/StrategyCreateMergedStrategy/components/MergedStrategiesList/MergedStrategiesListChart';
import CloseIcon from 'assets/images/Profile/CloseIcon.svg';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { TabSwitcher } from 'components/TabSwitcher';
import { ChartTabs } from 'enums/History';
import CandleStick from 'assets/icons/candlestick_light.svg';
import LineUp from 'assets/icons/line_up_light.svg';

const PublishedBot = ({ bot }): JSX.Element => {
  const [isOpened, setIsOpened] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [selectedPopUpPeriod, setSelectedPopUpPeriod] = useState(0);
  const [selectedChartTab, setSelectedChartTab] = useState(ChartTabs.LINES);
  return (
    <>
      <div className="publishedBotsContainer_list_item" key={bot.id}>
        <div className="publishedBotsContainer_list_item_row_name">{bot.name}</div>
        <div className="publishedBotsContainer_list_item_row_status">
          Status:{' '}
          <span style={{ color: bot.status ? '#30D960' : 'red' }}>
            {bot.status ? 'Active' : 'Inactive'}
          </span>
        </div>
        <div className="publishedBotsContainer_list_item_row">
          Followers: <span>{bot.followers}</span>
        </div>
        <div className="publishedBotsContainer_list_item_row">
          Active strategies:<span> {bot.strategies}</span>
        </div>
        <div className="publishedBotsContainer_list_item_row">
          AOM: <span>{bot.aom}$</span>
        </div>
        <div className="publishedBotsContainer_list_item_row">
          Realized PNL:{' '}
          <span style={{ color: bot.realizedPnl > 0 ? '#30D960' : 'red' }}>
            {' '}
            {bot.realizedPnl}$
          </span>
        </div>
        <div className="publishedBotsContainer_list_item_row">
          Open PNL:{' '}
          <span style={{ color: bot.openPnl > 0 ? '#30D960' : 'red' }}>{bot.openPnl}$</span>
        </div>
        <div className="publishedBotsContainer_list_item_chart">
          <div className="publishedBotsContainer_list_item_chart_header">
            <div className="publishedBotsContainer_list_item_chart_header_value">
              234$ <span>5%</span>
            </div>
            <div className="publishedBotsContainer_list_item_chart_header_periods">
              <span
                className="publishedBotsContainer_list_item_chart_header_period"
                style={{ background: selectedPeriod === 0 && '#694EF0' }}
                onClick={() => {
                  setSelectedPeriod(0);
                }}
              >
                1Y
              </span>
              <span
                style={{ background: selectedPeriod === 1 && '#694EF0' }}
                className="publishedBotsContainer_list_item_chart_header_period"
                onClick={() => {
                  setSelectedPeriod(1);
                }}
              >
                1M
              </span>
              <span
                style={{ background: selectedPeriod === 2 && '#694EF0' }}
                className="publishedBotsContainer_list_item_chart_header_period"
                onClick={() => {
                  setSelectedPeriod(2);
                }}
              >
                7D
              </span>
              <span
                style={{ background: selectedPeriod === 3 && '#694EF0' }}
                className="publishedBotsContainer_list_item_chart_header_period"
                onClick={() => {
                  setSelectedPeriod(3);
                }}
              >
                1D
              </span>
            </div>
          </div>
          <div className="publishedBotsContainer_list_item_chart_content">
            <MergedStrategiesListChart
              values={[2, 4, -333323, 34, 222234, 423, 1, 2]}
              maxPnl={222234}
              minPnl={2}
            />
          </div>
        </div>
        <div className="publishedBotsContainer_list_item_buttonsContainer">
          <button
            className="publishedBotsContainer_list_item_buttonsContainer_details"
            onClick={() => {
              setIsOpened(true);
            }}
          >
            {' '}
            Details
          </button>
          <button className="publishedBotsContainer_list_item_buttonsContainer_copy"> Copy</button>
        </div>
      </div>
      {isOpened &&
        ReactDOM.createPortal(
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="publishedBotsContainer_botPopUpContainer"
          >
            <div className="publishedBotsContainer_botPopUpContainer_content">
              <div className="publishedBotsContainer_botPopUpContainer_content_title">
                {bot.name}{' '}
                <img
                  onClick={() => {
                    setIsOpened(false);
                  }}
                  src={CloseIcon}
                  alt=""
                />
              </div>
              <div className="publishedBotsContainer_botPopUpContainer_content_chartNavigation">
                <div className="publishedBotsContainer_botPopUpContainer_content_chartNavigation_periods">
                  <span
                    style={{ background: selectedPopUpPeriod === 0 && '#694EF0' }}
                    onClick={() => {
                      setSelectedPopUpPeriod(0);
                    }}
                    className="publishedBotsContainer_botPopUpContainer_content_chartNavigation_period"
                  >
                    7 days
                  </span>
                  <span
                    style={{ background: selectedPopUpPeriod === 1 && '#694EF0' }}
                    onClick={() => {
                      setSelectedPopUpPeriod(1);
                    }}
                    className="publishedBotsContainer_botPopUpContainer_content_chartNavigation_period"
                  >
                    30 days
                  </span>
                  <span
                    style={{ background: selectedPopUpPeriod === 2 && '#694EF0' }}
                    onClick={() => {
                      setSelectedPopUpPeriod(2);
                    }}
                    className="publishedBotsContainer_botPopUpContainer_content_chartNavigation_period"
                  >
                    90 days
                  </span>
                  <span
                    style={{ background: selectedPopUpPeriod === 3 && '#694EF0' }}
                    onClick={() => {
                      setSelectedPopUpPeriod(3);
                    }}
                    className="publishedBotsContainer_botPopUpContainer_content_chartNavigation_period"
                  >
                    All time
                  </span>
                </div>
                <TabSwitcher
                  className="publishedBotsContainer_botPopUpContainer_content_chartNavigation_tabs chartTab"
                  defaultTab={selectedChartTab}
                  onChange={(tabKey: ChartTabs) => {
                    setSelectedChartTab(tabKey);
                  }}
                  options={[
                    { tabId: ChartTabs.BARS, tabLabel: 'Bars' },
                    { tabId: ChartTabs.LINES, tabLabel: 'Line' },
                  ]}
                />
                <div className="publishedBotsContainer_botPopUpContainer_content_chartNavigation_mobTabs">
                  <div
                    className="publishedBotsContainer_botPopUpContainer_content_chartNavigation_mobTab"
                    style={{ background: selectedChartTab === ChartTabs.BARS ? '#694EF0' : '' }}
                    onClick={() => {
                      setSelectedChartTab(ChartTabs.BARS);
                    }}
                  >
                    <img src={CandleStick} alt="" />
                  </div>
                  <div
                    className="publishedBotsContainer_botPopUpContainer_content_chartNavigation_mobTab"
                    style={{ background: selectedChartTab === ChartTabs.LINES ? '#694EF0' : '' }}
                    onClick={() => {
                      setSelectedChartTab(ChartTabs.LINES);
                    }}
                  >
                    <img src={LineUp} alt="" />
                  </div>
                </div>
              </div>
              <div className="publishedBotsContainer_list_item_row">
                PNL:{' '}
                <span style={{ color: bot.openPnl > 0 ? '#30D960' : 'red' }}>{bot.openPnl}$</span>
              </div>
              <div className="publishedBotsContainer_list_item_row">
                Trades: <span>{bot.trades}</span>
              </div>
              <div className="publishedBotsContainer_botPopUpContainer_content_chart">
                <MergedStrategiesListChart
                  values={[2, 4, -333323, 34, 222234, 423, 1, 2]}
                  maxPnl={222234}
                  minPnl={2}
                />
              </div>
              <div className="publishedBotsContainer_list_item_row_status">
                Status:{' '}
                <span style={{ color: bot.status ? '#30D960' : 'red' }}>
                  {bot.status ? 'Active' : 'Inactive'}
                </span>
              </div>
              <div className="publishedBotsContainer_list_item_row">
                Active strategies:<span> {bot.strategies}</span>
              </div>
              <div className="publishedBotsContainer_list_item_row">
                Subscribers: <span>{bot.subscribers}</span>
              </div>
              <div className="publishedBotsContainer_list_item_row">
                AOM: <span>{bot.aom}$</span>
              </div>
              <div className="publishedBotsContainer_list_item_row">
                Open PNL:{' '}
                <span style={{ color: bot.openPnl > 0 ? '#30D960' : 'red' }}>{bot.openPnl}$</span>
              </div>
              <button className="publishedBotsContainer_list_item_buttonsContainer_copy">
                {' '}
                Copy
              </button>
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

export default PublishedBot;
