import { createSlice } from '@reduxjs/toolkit';

import { YAxisType, YAxisPosition, type YAxisStyle } from '@basttyy/klinecharts';
import { type IRootState } from '../rootReducer';
import { getUpdatePath } from '../updateProperty';

const initialState: YAxisStyle = {
  show: true,
  size: 'auto',
  position: YAxisPosition.Right,
  type: YAxisType.Normal,
  inside: false,
  reverse: false,
  axisLine: {
    show: true,
    color: '#888888',
    size: 1,
  },
  tickText: {
    show: true,
    color: '#D9D9D9',
    family: 'Helvetica Neue',
    weight: 'normal',
    size: 12,
    marginStart: 4,
    marginEnd: 4,
  },
  tickLine: {
    show: true,
    size: 1,
    length: 3,
    color: '#888888',
  },
};

const chartYAxisSettingsReducer = createSlice({
  name: 'chartYAxisSettings',
  initialState,
  reducers: {
    updateYAxisProperty: getUpdatePath<YAxisStyle>(),
    setInititalYAxisSettings: (state, action) => {
      return action.payload;
    },
    refreshYAxisSettings: (state) => {
      return initialState;
    },
  },
});
export const selectChartYAxisSettings = (state: IRootState): YAxisStyle => state.chartYAxisSettings;

export const { updateYAxisProperty, refreshYAxisSettings, setInititalYAxisSettings } =
  chartYAxisSettingsReducer.actions;

export default chartYAxisSettingsReducer.reducer;
