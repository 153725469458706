import { TabSwitcher } from 'components/TabSwitcher';
import { type FC } from 'react';

import './NonStrategy.scss';
enum HistoryTabs {
  STRATEGY_TESTER = 'strategyTester',
  POSITION = 'position',
  TRADES = 'trades',
  AUTOMATED_STRATEGIES = 'automatedStrategies',
}
export const NonStrategy: FC = () => {
  const selectedMainTab = HistoryTabs.STRATEGY_TESTER;
  return (
    <div className="non-strategy" onClick={() => {}}>
      <TabSwitcher
        disabled={true}
        className=" non-strategy__bg"
        defaultTab={selectedMainTab}
        onChange={(tabKey: HistoryTabs) => {}}
        options={[
          { tabId: HistoryTabs.STRATEGY_TESTER, tabLabel: 'Backtest diagrams' },
          { tabId: HistoryTabs.TRADES, tabLabel: 'Trades list' },
          // { tabId: HistoryTabs.AUTOMATED_STRATEGIES, tabLabel: 'Automated Strategies' },
        ]}
      />
      <h2 className="non-strategy__title">No data</h2>
      <p className="non-strategy__descr">
        Create a new strategy or select a saved strategy to display data
      </p>
    </div>
  );
};
