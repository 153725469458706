import { createSlice } from '@reduxjs/toolkit';
import { type ColorDataSettings } from '../../pages/HomePage/components/ChartStyleSettings/Interfaces';
import { type IRootState } from '../rootReducer';
import { getUpdatePath } from '../updateProperty';
const initialState: ColorDataSettings = {
  color: 'rgb(10, 8, 26)',
  gradientData: {
    isGradient: false,
    gradientType: null,
    degrees: null,
    colors: [{ value: 'rgb(10, 8, 26)' }],
  },
};

const chartBackgroundSettingsReducer = createSlice({
  name: 'chartBackgroundSettings',
  initialState,
  reducers: {
    updateBackgroundProperty: getUpdatePath<ColorDataSettings>(),
    setInititalBackgroundSettings: (state, action) => {
      return action.payload;
    },
    refreshBackgroundSettings: () => {
      return initialState;
    },
  },
});
export const selectChartBackgroundSettings = (state: IRootState): ColorDataSettings =>
  state.chartBackgroundSettings;

export const {
  updateBackgroundProperty,
  refreshBackgroundSettings,
  setInititalBackgroundSettings,
} = chartBackgroundSettingsReducer.actions;

export default chartBackgroundSettingsReducer.reducer;
