import { type CaseReducer } from '@reduxjs/toolkit';
export const getUpdatePath = <T>(): CaseReducer<T, { payload: any; type: string }> => {
  return (state, action) => {
    const { propertyNode1, propertyNode2, propertyNode3, propertyNode4, value } = action.payload;
    const upDatedState = state;
    if (propertyNode4) {
      upDatedState[propertyNode1][propertyNode2][propertyNode3][propertyNode4] = value;
      return upDatedState;
    }
    if (propertyNode3) {
      upDatedState[propertyNode1][propertyNode2][propertyNode3] = value;
      return upDatedState;
    }

    if (propertyNode2) {
      upDatedState[propertyNode1][propertyNode2] = value;
      return upDatedState;
    }
    if (propertyNode1) {
      upDatedState[propertyNode1] = value;
      return upDatedState;
    }

    return value;
  };
};
