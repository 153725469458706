import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { setSelectedStrategyId } from 'redux/strategy/reducer';
import { getNewChartData, setCharts } from 'redux/chart/reducer';

import {
  setSelectedProfile,
  setFollowings as setFollowingsRedux,
} from '../../redux/profile/reducer';
import { useSelector } from 'redux/rootReducer';
import { ChartTabs } from 'enums/History';
import { avatarUrl, rolesNames } from '../../configs/user';
import { apiStrategy } from '../../services/api/Strategy/ApiStrategy';
import { type IFollowing, type IProfileStrategy } from './interfaces/interfaces';
import PublishedStrategies from './components/PublishedStrategies';
import PublishedBots from './components/PublishedBots';
import { TabSwitcher } from 'components/TabSwitcher';
import UserSettings from './components/UserSettings/UserSettings';
import MergedStrategiesListChart from 'pages/HomePage/components/StrategyEditor/components/StrategyCreateMergedStrategy/components/MergedStrategiesList/MergedStrategiesListChart';
import FollowersFollowingContainer from './components/FollowersFollowingContainer';
import { apiProfile } from '../../services/api/Profile/ApiProfile';
import AdditionalInfoTextArea from './components/UserSettings/Components/AdditionalInfoTextArea/AdditionalInfoTextArea';
import { ProfileContentSwitcherMenu } from './components/ProfileContentSwitcherMenu/ProfileContentSwitcherMenu';
import { ProfilePageMobileTab } from './components/ProfilePageMobileTab/ProfilePageMobileTab';
import BarChart from 'pages/HomePage/components/Log/components/BarChart/BarChart';
import useWindowWidth from 'hooks/useWindowWidth';
import './UserProfile.scss';
import cn from 'classnames';
import CandleStick from 'assets/icons/candlestick_light.svg';
import LineUp from 'assets/icons/line_up_light.svg';
import HeaderLogo from 'assets/images/Profile/HeaderLogo.svg';
import HeaderLogoMobile from 'assets/images/Profile/HeaderMobLogo.svg';
import UserIcon from 'assets/images/default_avatar.png';

const UserProfile = (): JSX.Element => {
  const { id: login } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { login: selfLogin, id: selfId } = useSelector((state) => state.auth.user);
  const data = useSelector((state) => state.profile.selectedProfile);
  const profilePage = useSelector((state) => state.profile.page);

  const [navigationValue, setNavigationValue] = useState(0);
  const [selectedPopUpPeriod, setSelectedPopUpPeriod] = useState(0);
  const [selectedChartTab, setSelectedChartTab] = useState(ChartTabs.LINES);
  const [publishedStrategies, setPublishedStrategies] = useState<IProfileStrategy[]>([]);
  const [followings, setFollowings] = useState<IFollowing[]>([]);
  const [followers, setFollowers] = useState<IFollowing[]>([]);

  const joinedAt = new Date(data?.created_at);

  const isSelfProfile = selfLogin === login;
  const isFollowing = selfId && followers.find((e) => e.id === selfId);

  const updatePublishedStrategy = (id: string, data: Partial<IProfileStrategy>): void => {
    const published = publishedStrategies.slice();
    const index = published.findIndex((p) => p.id === id);
    if (index !== -1) {
      published[index] = {
        ...published[index],
        ...data,
      };
    }
    setPublishedStrategies(published);
  };
  useEffect(() => {
    dispatch(setSelectedStrategyId(null));
    dispatch(setCharts([getNewChartData({ id: '0' })]));
  }, []);
  useEffect(() => {
    const fetchProfile = (): void => {
      apiProfile.getProfile(login).then((data) => {
        if (!data) {
          if (login === selfLogin) {
            setTimeout(() => {
              apiProfile.createProfile(selfLogin).then((e) => {
                fetchProfile();
              });
            }, 1500);
          } else {
            navigate('/menu');
          }
        } else {
          dispatch(setSelectedProfile(data));
        }
      });
    };

    fetchProfile();
  }, [login]);

  useEffect(() => {
    if (data?.id) {
      apiStrategy.getPublishedStrategies(data.id).then((e) => {
        setPublishedStrategies(e);
      });
      apiProfile.getFollowers(data.id).then((e) => {
        setFollowers(e);
      });
      apiProfile.getFollowing(data.id).then((e) => {
        if (data.id === selfId) {
          dispatch(setFollowingsRedux(e));
        }
        setFollowings(e);
      });
    }
  }, [data]);

  return (
    <>
      <div
        id="mainProfileContainer"
        // style={{ display: profilePage !== 'profile' ? 'none' : '' }}
        className={cn('mainConatiner', profilePage !== 'profile' && 'mainConatiner_hidden')}
      >
        <div className="mainConatiner_header">
          <img className="mainConatiner_header_logo" src={HeaderLogo} alt="" />
          <img className="mainConatiner_header_logoMobile" src={HeaderLogoMobile} alt="" />
          <div className="mainConatiner_nav">
            <Link to="/menu">
              <div className="mainConatiner_nav_item">Main menu</div>
            </Link>
            <Link to={selfLogin ? `/profile/${selfLogin}` : '/auth'}>
              <div className="mainConatiner_nav_item">Profile</div>
            </Link>
            <Link to="/">
              <div className="mainConatiner_nav_item">Trading Panel</div>
            </Link>
            <div className="mainConatiner_nav_item mainConatiner_nav_item_disabled">
              Leaderboard
            </div>
            <div className="mainConatiner_nav_item">Docs</div>
          </div>
        </div>
        <div
          className="mainConatiner_content"
          style={{ minHeight: useWindowWidth() > 1024 ? (isSelfProfile ? '394px' : '459px') : '' }}
        >
          <div className="mainConatiner_content_info">
            <div className="mainConatiner_content_info_profileContainer">
              <div className="mainConatiner_content_info_profileContainer_general">
                {/* <object
                  className="mainConatiner_content_info_profileContainer_general_img"
                  data={avatarUrl + data?.id}
                  type="image/png"
                > */}
                <img
                  className="mainConatiner_content_info_profileContainer_general_img"
                  src={data?.id && avatarUrl ? avatarUrl + data?.id : UserIcon}
                  alt=""
                />
                {/* </object> */}
                <div className="mainConatiner_content_info_profileContainer_general_userContainer">
                  <div className="mainConatiner_content_info_profileContainer_general_userContainer_NameStatusContainer">
                    <div className="mainConatiner_content_info_profileContainer_general_userContainer_NameStatus">
                      <div className="mainConatiner_content_info_profileContainer_general_userContainer_NameStatus_name">
                        {login}
                      </div>
                      <div className="mainConatiner_content_info_profileContainer_general_userContainer_NameStatus_status">
                        <div
                          className={cn(
                            data?.online &&
                              'mainConatiner_content_info_profileContainer_general_userContainer_NameStatus_status_online',
                            !data?.online &&
                              'mainConatiner_content_info_profileContainer_general_userContainer_NameStatus_status_offline',
                          )}
                        ></div>
                        {data?.online ? 'online' : 'offline'}
                      </div>
                    </div>
                    <div className="mainConatiner_content_info_profileContainer_general_userContainer_TypeData">
                      {data?.roles.map((e, i) => (
                        <div
                          className="mainConatiner_content_info_profileContainer_general_userContainer_TypeData_type"
                          key={i}
                        >
                          {rolesNames[e]}
                        </div>
                      ))}
                      <div className="mainConatiner_content_info_profileContainer_general_userContainer_TypeData_data">
                        joined {joinedAt.getDay()}/{joinedAt.getMonth()}/{joinedAt.getFullYear()}
                      </div>
                    </div>
                  </div>
                  <div className="mainConatiner_content_info_profileContainer_general_userContainer_Settings">
                    {isSelfProfile && <UserSettings />}
                  </div>
                </div>
              </div>
              <div className="mainConatiner_content_info_profileContainer_statistics">
                <div className="mainConatiner_content_info_profileContainer_statistics_item">
                  <div className="mainConatiner_content_info_profileContainer_statistics_item_value">
                    {data?.followers}
                  </div>
                  <div className="mainConatiner_content_info_profileContainer_statistics_item_text">
                    Followers
                  </div>
                </div>

                <div className="mainConatiner_content_info_profileContainer_statistics_item">
                  <div className="mainConatiner_content_info_profileContainer_statistics_item_value">
                    {data?.trades}
                  </div>
                  <div className="mainConatiner_content_info_profileContainer_statistics_item_text">
                    Closed trades
                  </div>
                </div>
                <div className="mainConatiner_content_info_profileContainer_statistics_item">
                  <div className="mainConatiner_content_info_profileContainer_statistics_item_value">
                    {data?.volume}
                  </div>
                  <div className="mainConatiner_content_info_profileContainer_statistics_item_text">
                    Trading volume(USDT)
                  </div>
                </div>
              </div>
              {!isSelfProfile && selfLogin && (
                <button
                  className={cn(
                    'mainConatiner_content_info_profileContainer__subscribe',
                    isFollowing &&
                      'mainConatiner_content_info_profileContainer__subscribe_isFollowing',
                  )}
                  // className="mainConatiner_content_info_profileContainer__subscribe"
                  // style={{
                  //   background: isFollowing ? '#A700F6' : 'none',
                  // }}
                  onClick={() => {
                    if (!selfId) {
                      navigate('/auth');
                      return;
                    }

                    if (isFollowing) {
                      apiProfile.unfollow(data.id).then((r) => {
                        if (r) {
                          dispatch(
                            setSelectedProfile({
                              ...data,
                              followers: data.followers - 1,
                            }),
                          );
                        }
                      });
                    } else {
                      apiProfile.follow(data.id).then((r) => {
                        if (r) {
                          dispatch(
                            setSelectedProfile({
                              ...data,
                              followers: data.followers + 1,
                            }),
                          );
                        }
                      });
                    }
                  }}
                >
                  {!isFollowing ? 'Follow' : 'Unfollow'}
                </button>
              )}
            </div>
            <AdditionalInfoTextArea
              value={data?.description}
              disabled={!isSelfProfile}
              setValue={(data) => {
                apiProfile.changeDescription(data);
              }}
            />
          </div>
          <div className="mainConatiner_content_statistics">
            <div className="mainConatiner_content_statistics_chartNavigation">
              <div className="mainConatiner_content_statistics_chartNavigation_header">
                <div className="mainConatiner_content_statistics_chartNavigation_header_text">
                  Statistics
                </div>
                <div className="mainConatiner_content_statistics_chartNavigation_header_textMobile">
                  Stats
                </div>
                <div className="mainConatiner_content_statistics_chartNavigation_header_subText">
                  Acumulative profit($)
                </div>
              </div>
              <div className="mainConatiner_content_statistics_chartNavigation_headerMob">
                <div>
                  {' '}
                  <div className="mainConatiner_content_statistics_chartNavigation_header_text">
                    Statistics
                  </div>
                  <div className="mainConatiner_content_statistics_chartNavigation_header_textMobile">
                    Stats
                  </div>
                  <div className="mainConatiner_content_statistics_chartNavigation_header_subText">
                    Acumulative profit($)
                  </div>
                </div>
                <div className="publishedBotsContainer_botPopUpContainer_content_chartNavigation_mobTabs">
                  <div
                    className={cn(
                      'publishedBotsContainer_botPopUpContainer_content_chartNavigation_mobTab',
                      selectedChartTab === ChartTabs.LINES &&
                        'publishedBotsContainer_botPopUpContainer_content_chartNavigation_mobTab_active',
                    )}
                    onClick={() => {
                      setSelectedChartTab(ChartTabs.LINES);
                    }}
                  >
                    <img src={LineUp} alt="" />
                  </div>
                  <div
                    className={cn(
                      'publishedBotsContainer_botPopUpContainer_content_chartNavigation_mobTab',
                      selectedChartTab === ChartTabs.BARS &&
                        'publishedBotsContainer_botPopUpContainer_content_chartNavigation_mobTab_active',
                    )}
                    onClick={() => {
                      setSelectedChartTab(ChartTabs.BARS);
                    }}
                  >
                    <img src={CandleStick} alt="" />
                  </div>
                </div>
              </div>
              <div className="mainConatiner_content_statistics_chartNavigation_periodsContainer">
                API keys: 3
                <div className="mainConatiner_content_statistics_chartNavigation_periods">
                  <span
                    onClick={() => {
                      setSelectedPopUpPeriod(0);
                    }}
                    className={cn(
                      'mainConatiner_content_statistics_chartNavigation_period',
                      selectedPopUpPeriod === 0 &&
                        'mainConatiner_content_statistics_chartNavigation_period_active',
                    )}
                  >
                    7 days
                  </span>
                  <span
                    onClick={() => {
                      setSelectedPopUpPeriod(1);
                    }}
                    className={cn(
                      'mainConatiner_content_statistics_chartNavigation_period',
                      selectedPopUpPeriod === 1 &&
                        'mainConatiner_content_statistics_chartNavigation_period_active',
                    )}
                  >
                    30 days
                  </span>
                  <span
                    onClick={() => {
                      setSelectedPopUpPeriod(2);
                    }}
                    className={cn(
                      'mainConatiner_content_statistics_chartNavigation_period',
                      selectedPopUpPeriod === 2 &&
                        'mainConatiner_content_statistics_chartNavigation_period_active',
                    )}
                  >
                    90 days
                  </span>
                  <span
                    onClick={() => {
                      setSelectedPopUpPeriod(3);
                    }}
                    className={cn(
                      'mainConatiner_content_statistics_chartNavigation_period',
                      selectedPopUpPeriod === 3 &&
                        'mainConatiner_content_statistics_chartNavigation_period_active',
                    )}
                  >
                    All time
                  </span>
                </div>
                <TabSwitcher
                  className="mainConatiner_content_statistics_chartNavigation_chartTab chartTab"
                  defaultTab={selectedChartTab}
                  onChange={(tabKey: ChartTabs) => {
                    setSelectedChartTab(tabKey);
                  }}
                  options={[
                    { tabId: ChartTabs.BARS, tabLabel: 'Bars' },
                    { tabId: ChartTabs.LINES, tabLabel: 'Line' },
                  ]}
                />
              </div>
            </div>
            <div className="mainConatiner_content_statistics_chart">
              {selectedChartTab === ChartTabs.LINES && (
                <MergedStrategiesListChart values={[0, 0]} maxPnl={100} minPnl={-100} />
              )}
              {selectedChartTab === ChartTabs.BARS && <BarChart data={[]} />}
            </div>
          </div>
        </div>
        <div className="mainConatiner_pageNavigation">
          <div
            className={`mainConatiner_pageNavigation_item ${
              navigationValue === 0 && 'mainConatiner_pageNavigation_item_active'
            }`}
            onClick={() => {
              setNavigationValue(0);
            }}
          >
            <div>Published strategies</div>
            <div>{publishedStrategies.length}</div>
          </div>
          <div
            className={`mainConatiner_pageNavigation_item ${
              navigationValue === 1 && 'mainConatiner_pageNavigation_item_active'
            }`}
            onClick={() => {
              setNavigationValue(1);
            }}
          >
            <div>Published bots</div>
            <div>0</div>
          </div>
          <div
            className={`mainConatiner_pageNavigation_item ${
              navigationValue === 2 && 'mainConatiner_pageNavigation_item_active'
            }`}
            onClick={() => {
              setNavigationValue(2);
            }}
          >
            <div>Followers</div>
            <div>{data?.followers ?? 0}</div>
          </div>
        </div>
        {navigationValue === 0 && (
          <PublishedStrategies
            strategies={publishedStrategies}
            updatePublishedStrategy={updatePublishedStrategy}
          />
        )}
        {navigationValue === 1 && <PublishedBots />}
        {navigationValue === 2 && (
          <FollowersFollowingContainer
            id={data.id}
            followers={followers}
            followings={followings}
            setFollowers={setFollowers}
            setFollowings={setFollowings}
          />
        )}
      </div>
      {profilePage === 'home' && <ProfilePageMobileTab />}
      <ProfileContentSwitcherMenu fixed />
    </>
  );
};

export default UserProfile;
