import { createSlice } from '@reduxjs/toolkit';
import { type GridStyle, LineType } from '@basttyy/klinecharts';
import { type IRootState } from '../rootReducer';
import { getUpdatePath } from '../updateProperty';

const initialState: GridStyle = {
  show: true,
  horizontal: {
    show: true,
    style: LineType.Dashed,
    size: 1,
    color: 'rgba(255,255,255, 0.1)',
    dashedValue: [1.5, 1.5],
  },
  vertical: {
    show: true,
    style: LineType.Dashed,
    size: 1,
    color: 'rgba(255,255,255, 0.1)',
    dashedValue: [1.5, 1.5],
  },
};

const chartGridSettingsReducer = createSlice({
  name: 'chartGridSettings',
  initialState,
  reducers: {
    updateGridProperty: getUpdatePath<GridStyle>(),
    setInititalGridSettings: (state, action) => {
      return action.payload;
    },
    refreshGridSettings: () => {
      return initialState;
    },
  },
});
export const selectChartGridSettings = (state: IRootState): GridStyle => state.chartGridSettings;

export const { updateGridProperty, refreshGridSettings, setInititalGridSettings } =
  chartGridSettingsReducer.actions;

export default chartGridSettingsReducer.reducer;
