import React, { useRef } from 'react';
import { type ITradeData } from '../../../../interfaces/ITrade';
import './TradesTable.scss';
import { Virtuoso } from 'react-virtuoso';

interface TradesTableProps {
  trades: ITradeData[];
  qtyPrecision: Record<string, number> | number;
  pricePrecision: Record<string, number> | number;
}

// const RENDER_TRADES_LIMIT = 100;
export const TradesTable: React.FC<TradesTableProps> = ({
  trades,
  pricePrecision,
  qtyPrecision,
}) => {
  const ref = useRef(null);
  const formadivate = (timestamp: number): string => {
    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} ${hour}:${minute}`;
  };
  return (
    <div
      className="trades-table__scroll"
      onScroll={(e) => {
        // const target = e.target as HTMLElement;
        // const isReachedBottom = target.scrollHeight - target.scrollTop === target.clientHeight;
        // console.log(target.scrollHeight - target.scrollTop, target.clientHeight);
        // if (isReachedBottom && tradesLimit < trades.length - 1) {
        //   setTradesLimit(tradesLimit + RENDER_TRADES_LIMIT);
        //   setTimeout(() => {
        //     target.scrollTo({ top: target.scrollTop });
        //   }, 100);
        // }
      }}
    >
      <div
        className={` ${'trades-tabler-header'}  ${
          trades[0]?.ticker ? 'trades-tabler-header_big' : ''
        }`}
      >
        <div>Trade #</div>
        {trades[0]?.ticker && <div>Ticker</div>}
        <div>Date/Time</div>
        <div>Direction</div>
        <div>Status</div>
        <div>Exit time</div>
        <div>Contracts</div>
        <div>Entry price</div>
        <div>Avg. exit price</div>
        <div>Profit</div>
        <div>Open PNL</div>
        <div>Cum. profit</div>
      </div>
      <Virtuoso
        ref={ref}
        data={trades.slice(0)}
        totalCount={trades.slice(0).length}
        className="scrollbar-style"
        itemContent={(index, el: ITradeData) => (
          <div
            className={` ${'trades-table-item'}  ${
              trades[0]?.ticker ? 'trades-table-item_big' : ''
            }`}
            key={el.id}
          >
            <div>#{el.id}</div>
            {el.ticker && <div>{el.ticker}</div>}
            <div>{formadivate(el.time)}</div>
            <div>
              {el.side === 0 && <span className="trades-table__red">Short</span>}
              {el.side === 1 && <span className="trades-table__green">Long</span>}
            </div>
            <div>
              {el.exit_time && <span>Closed</span>}
              {!el.exit_time && <span>Open</span>}
            </div>
            <div>
              {el.exit_time && <span>{formadivate(el.exit_time)}</span>}
              {!el.exit_time && <span>-</span>}
            </div>
            <div>{el?.qty.toFixed(el.ticker ? qtyPrecision[el.ticker] : qtyPrecision)}</div>
            <div>
              {el.average_enter.toFixed(el.ticker ? pricePrecision[el.ticker] : pricePrecision)}$
            </div>
            <div>
              {el.average_exit.toFixed(el.ticker ? pricePrecision[el.ticker] : pricePrecision)}$
            </div>
            <div>
              {el.exit_time && el.pnl >= 0 && (
                <span className="trades-table__green">
                  {el.pnl.toFixed(2)}$({el.pnl_percent.toFixed(2)}%)
                </span>
              )}
              {el.exit_time && el.pnl < 0 && (
                <span className="trades-table__red">
                  {el.pnl.toFixed(2)}$({el.pnl_percent.toFixed(2)}%)
                </span>
              )}
              {!el.exit_time && <span>-</span>}
            </div>
            <div>
              {!el.exit_time && el.pnl >= 0 && (
                <span className="trades-table__green">
                  {el.pnl.toFixed(2)}$({el.pnl_percent.toFixed(2)}%)
                </span>
              )}
              {!el.exit_time && el.pnl <= 0 && (
                <span className="trades-table__red">
                  {el.pnl.toFixed(2)}$({el.pnl_percent.toFixed(2)}%)
                </span>
              )}
              {el.exit_time && <span>-</span>}
            </div>
            <div>
              {el.exit_time && <span>{el.balance.toFixed(2)}$</span>}
              {!el.exit_time && <span>-</span>}
            </div>
          </div>
        )}
      ></Virtuoso>
    </div>
  );
};
