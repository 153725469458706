import { ApiServiceBase } from '../../ApiServiceBase';
import type {
  IRecoveryCodeUserDto,
  IRecoverySubmitUserDto,
  IUser,
  IUserLoginRequest,
  IUserRegisterRequest,
} from '../../../interfaces/IUser';

import { type ISuccessResponse } from '../../types';

class ApiAuth extends ApiServiceBase {
  protected baseUrl = '/api/auth/';

  public login = async (data: IUserLoginRequest): Promise<IUser | string> => {
    try {
      const response = await this.post<IUserLoginRequest, ISuccessResponse<IUser>>('login', data);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (e) {
      return e.response.data.data;
    }
  };

  public register = async (data: IUserRegisterRequest): Promise<IUser | false> => {
    try {
      const response = await this.post<IUserRegisterRequest, ISuccessResponse<IUser>>(
        'register',
        data,
      );

      if (response.status === 200) {
        return response.data.data;
      }

      return false;
    } catch (e) {
      return false;
    }
  };

  public getRecoveryCode = async (data: IRecoveryCodeUserDto): Promise<true | string> => {
    try {
      const response = await this.post<IRecoveryCodeUserDto, ISuccessResponse<true | string>>(
        'recovery_code',
        data,
      );

      return response.data.data;
    } catch (e) {
      return 'Try later';
    }
  };

  public submitRecovery = async (data: IRecoverySubmitUserDto): Promise<IUser | null> => {
    try {
      const response = await this.post<IRecoverySubmitUserDto, ISuccessResponse<IUser | null>>(
        'recovery_submit',
        data,
      );

      return response.data.data;
    } catch (e) {
      return null;
    }
  };

  public changeLogin = async (login: string): Promise<boolean> => {
    try {
      const response = await this.post('change_login', {
        login,
      });

      return response.data?.data ?? false;
    } catch (_) {
      return false;
    }
  };

  public getUser = async (): Promise<IUser> => {
    const response = await this.get<ISuccessResponse<IUser>>('user');

    return response.data.data;
  };

  public getLoginById = async (id: string): Promise<string | null> => {
    try {
      const response = await this.get('get_login_by_id/' + id);

      return response.data.data ?? null;
    } catch (e) {
      return null;
    }
  };

  public getLastChangeLogin = async (): Promise<number | null> => {
    try {
      const response = await this.get('get_last_change_login');

      return response.data?.data ?? null;
    } catch (e) {
      return null;
    }
  };
}

export const apiAuth = new ApiAuth();
