import { createSlice } from '@reduxjs/toolkit';
import {
  CandleType,
  LineType,
  PolygonType,
  TooltipShowType,
  TooltipShowRule,
  type CandleStyle,
} from '@basttyy/klinecharts';
import { CandleTooltipRectPosition } from '../../pages/HomePage/components/ChartStyleSettings/Interfaces';
import { type IRootState } from '../rootReducer';
import { getUpdatePath } from '../updateProperty';

const initialState: CandleStyle = {
  type: CandleType.CandleSolid,
  bar: {
    upColor: '#2DC08E',
    downColor: '#F92855',
    noChangeColor: '#888888',
    upBorderColor: '#2DC08E',
    downBorderColor: '#F92855',
    noChangeBorderColor: '#888888',
    upWickColor: '#2DC08E',
    downWickColor: '#F92855',
    noChangeWickColor: '#888888',
  },
  area: {
    lineSize: 2,
    lineColor: '#2196F3',
    value: 'close',
    backgroundColor: [
      {
        offset: 0,
        color: 'rgba(33, 150, 243, 0.01)',
      },
      {
        offset: 1,
        color: 'rgba(33, 150, 243, 0.2)',
      },
    ],
  },
  priceMark: {
    show: true,
    high: {
      show: true,
      color: '#D9D9D9',
      textOffset: 5,
      textSize: 10,
      textFamily: 'Helvetica Neue',
      textWeight: 'normal',
    },
    low: {
      show: true,
      color: '#D9D9D9',
      textOffset: 5,
      textSize: 10,
      textFamily: 'Helvetica Neue',
      textWeight: 'normal',
    },
    last: {
      show: true,
      upColor: '#2DC08E',
      downColor: '#F92855',
      noChangeColor: '#888888',
      line: {
        show: true,
        style: LineType.Dashed,
        dashedValue: [4, 4],
        size: 1,
      },
      text: {
        show: true,
        style: PolygonType.Fill,
        size: 12,
        paddingLeft: 4,
        paddingTop: 4,
        paddingRight: 4,
        paddingBottom: 4,
        borderStyle: LineType.Solid,
        borderSize: 0,
        borderDashedValue: [2, 2],
        color: '#FFFFFF',
        family: 'Helvetica Neue',
        weight: 'normal',
        borderRadius: 2,
        borderColor: 'transparent',
      },
    },
  },
  tooltip: {
    showRule: TooltipShowRule.Always,
    showType: TooltipShowType.Standard,
    custom: null,
    defaultValue: 'n/a',
    rect: {
      position: CandleTooltipRectPosition.Fixed,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 6,
      offsetLeft: 10,
      offsetTop: 8,
      offsetRight: 10,
      offsetBottom: 8,
      borderRadius: 4,
      borderSize: 1,
      borderColor: '#f2f3f5',
      color: '#FEFEFE',
    },
    text: {
      size: 12,
      family: 'Helvetica Neue',
      weight: 'normal',
      color: '#D9D9D9',
      marginLeft: 10,
      marginTop: 8,
      marginRight: 6,
      marginBottom: 0,
    },
    //  sample:1
    //  [{
    //    id: 'icon_id',
    //    position: 'left', //  types include 'left', 'middle', 'right'
    //    marginLeft: 8,
    //    marginTop: 6,
    //    marginRight: 0,
    //    marginBottom: 0,
    //    paddingLeft: 1,
    //    paddingTop: 1,
    //    paddingRight: 1,
    //    paddingBottom: 1,
    //    icon: '\ue900',
    //    fontFamily: 'iconfont',
    //    size: 12,
    //    color: '#76808F',
    //    backgroundColor: 'rgba(33, 150, 243, 0.2)',
    //    activeBackgroundColor: 'rgba(33, 150, 243, 0.4)'
    //  }]
    icons: [],
  },
};

const chartCandleSettingsReducer = createSlice({
  name: 'chartCandleSettings',
  initialState,
  reducers: {
    updateCandleProperty: getUpdatePath<CandleStyle>(),
    setInititalCandleSettings: (state, action) => {
      return action.payload;
    },
    refreshCandleSettings: () => {
      return initialState;
    },
  },
});
export const selectChartCandleSettings = (state: IRootState): CandleStyle =>
  state.chartCandleSettings;

export const { updateCandleProperty, refreshCandleSettings, setInititalCandleSettings } =
  chartCandleSettingsReducer.actions;

export default chartCandleSettingsReducer.reducer;
