import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/rootReducer';
import { refreshGridSettings, updateGridProperty } from 'redux/chartGridSettings/reducer';
import { CustomColorPicker } from '../../ColorPicker/ColorPicker';
import CustomDropDown from '../../DropDownComponents/CustomMultiLineDropDown/CustomStyleSettingsDropDown';
import GTumbler from 'components/GTumbler/GTumbler';
import { numberSizes } from '../../../Data/dropdownData';

import { type GridStyle } from '@basttyy/klinecharts';
import useWindowWidth from 'hooks/useWindowWidth';
import { apiChartStyles } from '../../../../../../../services/api/ApiChartStyles/ApiChartStyles';

export const GridSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const settings: GridStyle = useSelector((state) => state.chartGridSettings);
  const isMobileVersion = useWindowWidth() < 768;
  return (
    <div className="propertyMainContainer">
      <div className="container__content_main_content_header">
        GRID SETTINGS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Show Grids
        <GTumbler
          checked={settings.show}
          size={isMobileVersion ? 'large' : null}
          name="grid"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'grid.show',
              value,
            });
            dispatch(
              updateGridProperty({
                propertyNode1: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Show horizontal
        <GTumbler
          checked={settings.horizontal.show}
          size={isMobileVersion ? 'large' : null}
          name="grid"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'grid.horizontal.show',
              value,
            });
            dispatch(
              updateGridProperty({
                propertyNode1: 'horizontal',
                propertyNode2: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Horizontal size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.horizontal.size),
              name: settings.horizontal.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'grid.horizontal.size',
                value,
              });
              dispatch(
                updateGridProperty({
                  propertyNode1: 'horizontal',
                  propertyNode2: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Horizontal color{' '}
        <CustomColorPicker
          defaultColor={settings.horizontal.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'grid.horizontal.color',
              value: color,
            });
            dispatch(
              updateGridProperty({
                propertyNode1: 'horizontal',
                propertyNode2: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>

      <div className="container__content_main_content_header">
        VERTICAL SETTINGS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Show vertical
        <GTumbler
          checked={settings.vertical.show}
          size={isMobileVersion ? 'large' : null}
          name="grid"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'grid.vertical.show',
              value,
            });
            dispatch(
              updateGridProperty({
                propertyNode1: 'vertical',
                propertyNode2: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Vertical size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.vertical.size),
              name: settings.vertical.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'grid.vertical.size',
                value,
              });
              dispatch(
                updateGridProperty({
                  propertyNode1: 'vertical',
                  propertyNode2: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Vertical color{' '}
        <CustomColorPicker
          defaultColor={settings.vertical.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'grid.vertical.color',
              value: color,
            });
            dispatch(
              updateGridProperty({
                propertyNode1: 'vertical',
                propertyNode2: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>

      <button
        className="container__content_main_content_refresh"
        onClick={() => {
          apiChartStyles.refreshChartStylingSettings('grid');
          dispatch(refreshGridSettings());
        }}
      >
        {' '}
        Refresh settings
      </button>
    </div>
  );
};
