import { useState } from 'react';
import './Drop.scss';
import ArrowWhite from 'assets/images/MenuPage/arrowWhite.svg';
import BinanceIcon from 'assets/images/MenuPage/BinanceIcon.svg';
// import WhiteBitIcon from "assets/HomeLog/MenuPage/WhiteBitIcon.svg";
// import CoinBaseIcon from "assets/HomeLog/MenuPage/CoinBaseIcon.svg";

// you can remove comment to see ful version with available platforms
const HeaderPlatformDropDown = (): JSX.Element => {
  const platforms: { id: number; name: string; imgSrc: string }[] = [
    { id: 1, name: 'Binance', imgSrc: BinanceIcon },
    // { id: 2, name: "WhiteBit", imgSrc: WhiteBitIcon },
    // { id: 3, name: "CoinBase", imgSrc: CoinBaseIcon }
  ];
  const [selectedplatform, setSelectedPlatform] = useState(platforms[0]);
  const [isOpened, setIsOpened] = useState(false);

  const selectPlatformHandler = (platform: { id: number; name: string; imgSrc: string }): void => {
    setSelectedPlatform(platform);
    setIsOpened(false);
    // закрывает выпадающее меню и сетает выбраную платформу
  };
  return (
    <div className="dropdownHeaderContainer">
      <div
        className={`dropdownHeaderContainer__dropdown ${
          isOpened ? 'dropdownHeaderContainer__dropdown_opened' : ''
        }`}
        onBlur={() => {
          setIsOpened(false);
        }}
      >
        <div
          className={`dropdownHeaderContainer__dropdown__selected ${
            isOpened ? 'dropdownHeaderContainer__dropdown__selected_active' : ''
          }`}
          onClick={() => {
            // setIsOpened(!isOpened);
          }}
        >
          <img
            className="dropdownHeaderContainer__dropdown__selected__icon"
            src={selectedplatform.imgSrc}
            alt={selectedplatform.name}
          />
          {selectedplatform.name}
          <img
            className="dropdownHeaderContainer__dropdown__selected__arrow"
            src={ArrowWhite}
            alt=""
          />
        </div>
        {platforms.map((platform: { id: number; name: string; imgSrc: string }) => (
          <div
            className="dropdownHeaderContainer__dropdown__item"
            key={platform.id}
            onClick={() => {
              selectPlatformHandler(platform);
            }}
          >
            <img
              className="dropdownHeaderContainer__platformIcon"
              src={platform.imgSrc}
              alt={platform.name}
            />
            {platform.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeaderPlatformDropDown;
