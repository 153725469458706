import { type FC } from 'react';
import { passIconProps, type IPassIconProps } from './passIconProps';

export const ChartHorizontalLineIcon: FC<IPassIconProps> = (props) => {
  return (
    <svg {...passIconProps(props)} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.41465,11L18.5,11C18.7761,11,19,11.22386,19,11.5C19,11.77614,18.7761,12,18.5,12L12.41465,12C12.20873,12.5826,11.65311,13,11,13C10.34689,13,9.79127,12.5826,9.58535,12L3.5,12C3.223857,12,3,11.77614,3,11.5C3,11.22386,3.223857,11,3.5,11L9.58535,11C9.79127,10.417404,10.34689,10,11,10C11.65311,10,12.20873,10.417404,12.41465,11Z"
        strokeOpacity="0"
        stroke="none"
      />
    </svg>
  );
};
