import { type Dispatch, type FC, type SetStateAction, useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSelector } from 'redux/rootReducer';
import {
  setSelectedStrategyId as reduxSetSelectedStrategyId,
  deleteSavedtrategy,
} from 'redux/strategy/reducer';
import { Components } from '../../Components';
import './strategyEditorList.scss';
import {
  type IStrategyAllData,
  type IStrategyAllResponse,
} from 'services/api/Strategy/types/StrategyResponse';
import { COLORS } from 'configs/chart';
import cn from 'classnames';
import CloseButton from 'assets/images/Strategy/StrategyCancel.svg';
import AddIcon from 'assets/images/Strategy/AddIcon.svg';

interface IStrategyEditorListProps {
  strategiesHistory: IStrategyAllResponse;
  strategyEnabled: boolean;
  hidden?: boolean;
  setStrategyNode: Dispatch<SetStateAction<Components | null>>;
  removeStrategyHistory: Dispatch<SetStateAction<IStrategyAllData>>;
}

const StrategyEditorList: FC<IStrategyEditorListProps> = ({
  strategiesHistory,
  strategyEnabled,
  hidden,
  setStrategyNode,
  removeStrategyHistory,
}) => {
  const { selectedStrategyId: selectedStrategy, strategies: userStrategies } = useSelector(
    (state) => state.strategy,
  );
  const [hoveredStrategy, setHoveredStrategy] = useState<string | null>(null);
  const scrollableDivRef = useRef(null);
  const scrollableDivRef2 = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setSelectedStrategyId = (value: string | null): void => {
    dispatch(reduxSetSelectedStrategyId(value));
  };

  useEffect(() => {
    const handleWheel = (event, ref): void => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        // eslint-disable-next-line   @typescript-eslint/restrict-plus-operands
        ref.scrollLeft += event.deltaY;
      }
    };

    const scrollableDiv = scrollableDivRef.current;
    const scrollableDiv2 = scrollableDivRef2.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener('wheel', (event) => {
        handleWheel(event, scrollableDiv);
      });
      return () => {
        scrollableDiv.removeEventListener('wheel', (event) => {
          handleWheel(event, scrollableDiv);
        });
      };
    }
    if (scrollableDiv2) {
      scrollableDiv2.addEventListener('wheel', (event) => {
        handleWheel(event, scrollableDiv2);
      });
      return () => {
        scrollableDiv2.removeEventListener('wheel', (event) => {
          handleWheel(event, scrollableDiv2);
        });
      };
    }
  }, [hidden]);
  return (
    <div
      className={cn(
        'strategy-editor-list',
        !strategyEnabled && 'strategy-editor-list_disabled',
        strategiesHistory.length > 0 && 'strategy-editor-list_no-wrap',
      )}
    >
      {hidden ? (
        <>
          {' '}
          <div ref={scrollableDivRef} className="strategy-editor-list-strategies">
            {strategiesHistory.map((strategy) => {
              return (
                <div
                  key={strategy._id}
                  className={cn(
                    'strategy-editor-list-strategies__element',
                    selectedStrategy === strategy._id &&
                      'strategy-editor-list-strategies__element_active',
                  )}
                  onClick={() => {
                    if (strategyEnabled) {
                      if (
                        userStrategies.findIndex(
                          (userStrategy) => userStrategy._id === strategy._id,
                        ) !== -1
                      ) {
                        setStrategyNode(Components.CONSTRUCTOR);
                      } else {
                        setStrategyNode(Components.EDIT_MERGED_STRATEGY);
                      }
                      selectedStrategy !== strategy._id && setSelectedStrategyId(strategy._id);
                    }
                  }}
                  onMouseEnter={() => {
                    setHoveredStrategy(strategy._id);
                  }}
                  onMouseLeave={() => {
                    if (strategy._id === hoveredStrategy) setHoveredStrategy(null);
                  }}
                >
                  <div className="strategy-editor-list-strategies__element__text">
                    {' '}
                    {strategy.name}
                  </div>
                  <img
                    src={CloseButton}
                    alt="X"
                    onClick={(event) => {
                      event.stopPropagation();
                      dispatch(deleteSavedtrategy(strategy._id));
                      removeStrategyHistory(strategy);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <button
            type="button"
            className={cn(
              'strategy-editor-list__create',
              strategiesHistory.length > 0 && 'strategy-editor-list__create_circle',
            )}
            style={{ backgroundColor: strategyEnabled ? COLORS.LIGHT_VIOLET : '' }}
            disabled={!strategyEnabled || userStrategies.length > 24}
            onClick={() => {
              navigate('/');
              setSelectedStrategyId(null);
              if (strategyEnabled) {
                if (strategiesHistory.length > 0) {
                  setStrategyNode(null);
                } else {
                  setStrategyNode(Components.MODES);
                }
              }
            }}
          >
            <img src={AddIcon} alt="+" />
          </button>
        </>
      ) : (
        <>
          {strategiesHistory.length > 0 ? (
            <div ref={scrollableDivRef2} className="strategy-editor-list-strategies">
              {strategiesHistory.map((strategy) => {
                return (
                  <div
                    key={strategy._id}
                    className={cn(
                      'strategy-editor-list-strategies__element',
                      selectedStrategy === strategy._id &&
                        'strategy-editor-list-strategies__element_active',
                    )}
                    onFocus={(e) => {
                      e.stopPropagation();
                    }}
                    onClick={() => {
                      if (strategyEnabled) {
                        if (
                          userStrategies.findIndex(
                            (userStrategy) => userStrategy._id === strategy._id,
                          ) !== -1
                        ) {
                          setStrategyNode(Components.CONSTRUCTOR);
                        } else {
                          setStrategyNode(Components.EDIT_MERGED_STRATEGY);
                        }
                        selectedStrategy !== strategy._id && setSelectedStrategyId(strategy._id);
                      }
                    }}
                    onMouseEnter={() => {
                      setHoveredStrategy(strategy._id);
                    }}
                    onMouseLeave={() => {
                      if (strategy._id === hoveredStrategy) setHoveredStrategy(null);
                    }}
                  >
                    <div
                      className="strategy-editor-list-strategies__element__text"
                      onScroll={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {' '}
                      {strategy.name}
                    </div>
                    <img
                      src={CloseButton}
                      alt="X"
                      onClick={(event) => {
                        event.stopPropagation();
                        dispatch(deleteSavedtrategy(strategy._id));
                        removeStrategyHistory(strategy);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <span className="strategy-editor-list__empty">You no have strategy!</span>
          )}
          <button
            type="button"
            className={cn(
              'strategy-editor-list__create',
              strategiesHistory.length > 0 && 'strategy-editor-list__create_circle',
            )}
            style={{ backgroundColor: strategyEnabled ? COLORS.LIGHT_VIOLET : '' }}
            disabled={!strategyEnabled}
            onClick={() => {
              navigate('/');
              setSelectedStrategyId(null);
              if (strategyEnabled) {
                if (strategiesHistory.length > 0) {
                  setStrategyNode(null);
                } else {
                  setStrategyNode(Components.MODES);
                }
              }
            }}
          >
            <img src={AddIcon} alt="+" />
          </button>
        </>
      )}
    </div>
  );
};

export default StrategyEditorList;
