import { useState, type FC } from 'react';
import './StrategyAdditionalSettings.module.scss';

import { StrategySettingsHeader } from '../StrategySettingsHeader/StrategySettingsHeader';
import CustomDropDown from '../CustomElements/MultiLineDropDown/CustomMultiLineDropDown';

interface IProps {
  onClose: () => void;
}
interface IState {
  mode: number;
  mainSide: 'long' | 'short';
}

export const StrategyAdditionalSettingsDetails: FC<IProps> = (props) => {
  const [data, setData] = useState<IState>({
    mode: 1,
    mainSide: 'long',
  });
  return (
    <>
      <div className="strategy-settings">
        <StrategySettingsHeader name={'Simultaneous hedging'} onClose={props.onClose} />
        <div className="strategy-settings__row">
          <div>Main Side</div>

          <div className="strategy-settings__switcher">
            <div
              className={
                'strategy-settings__switcher__item' +
                (data.mainSide === 'long' ? ' strategy-settings__switcher__item_active' : '')
              }
              onClick={() => {
                setData({ ...data, mainSide: 'long' });
              }}
            >
              Long
            </div>
            <div
              className={
                'strategy-settings__switcher__item' +
                (data.mainSide === 'short' ? ' strategy-settings__switcher__item_active' : '')
              }
              onClick={() => {
                setData({ ...data, mainSide: 'short' });
              }}
            >
              Short
            </div>
          </div>
        </div>
        <div className="strategy-settings__row">
          <div>Mode</div>
          <div className="strategy-settings__row_modeContainer">
            <CustomDropDown
              numbersOnly
              options={[
                { id: 1, name: '1' },
                { id: 2, name: '2' },
              ]}
              onChange={(e: number) => {
                setData({ ...data, mode: e });
              }}
              activeOption={{ id: data.mode, name: data.mode.toString() }}
            />
          </div>
        </div>
        <div className="strategy-settings__description">
          <div className="strategy-settings__description_header">Description:</div>
          <div className="strategy-settings__description_title">
            <span> Mode 1 </span>- Simultaneous hedging option, which allows to simultaneously open
            long and short trades. If long is selected as the main side, then when opening a trade
            by the long strategy, a short trade will simultaneously be opened by the short strategy.
            If the short side is chosen as the main side, then the conditions are opposite, the
            short strategy will be the leading strategy.
          </div>
          <div className="strategy-settings__description_title">
            <span>Mode 2</span> - If long is selected as the main side, then short trades are opened
            only if a long trade is open. If short is chosen as the main side, then the opposite is
            true.
          </div>
        </div>

        <button className={'strategy-settings__saveButton'} onClick={() => {}}>
          Save
        </button>
      </div>
    </>
  );
};
