import { useEffect, useState, type FC } from 'react';
import TimeIcon from 'assets/images/Strategy/TimeIcon.svg';
import './CustomTimeSelectorStyles.scss';
interface IProps {
  defaultTime?: { id: number; value: string };
  from?: { id: number; value: string };
  to?: { id: number; value: string };
  onChange: (value: [{ id: number; value: string }, { id: number; value: string }]) => void;
  disabledLast?: boolean;
}
const TimeSelector: FC<IProps> = (props): JSX.Element => {
  const [isOpened, setOpened] = useState(false);
  const [selectedTime, setSelectedTime] = useState(props.defaultTime);
  const createHourList = (): { id: number; value: string }[] => {
    const hourList = [];
    for (let hour = 0; hour < 24; hour++) {
      // зміна <= 24 замість < 24
      for (let minute = 0; minute < 60; minute += 15) {
        // Формування рядка часу
        let time;
        if (hour === 24 && minute === 0) {
          time = '24:00';
        } else {
          time = hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0');
        }
        // Додавання об'єкта до списку
        hourList.push({ id: hour * 4 + minute / 15, value: time });
      }
    }
    hourList.push({ id: 24 * 4, value: '24:00' });
    if (props.from?.id) {
      return hourList.splice(hourList.findIndex((item) => item.id === props.from?.id + 1));
    }
    if (props.disabledLast) {
      return hourList.splice(0, hourList.length - 1);
    }

    return hourList;
  };
  useEffect(() => {
    setSelectedTime(props.defaultTime);
  }, [props.defaultTime]);

  // Створення списку
  const hours = createHourList();
  return (
    <div
      className="time-selector"
      tabIndex={0}
      style={{ zIndex: isOpened ? 10 : 1 }}
      onBlur={() => {
        setOpened(false);
      }}
    >
      <div
        className={'time-selector__title' + (isOpened ? ' time-selector__title__opened' : '')}
        onClick={() => {
          setOpened(!isOpened);
        }}
      >
        <div className="time-selector__title__text">{selectedTime.value}</div>
        <img src={TimeIcon} alt="time" />
      </div>
      {isOpened && (
        <div className="time-selector__content">
          {hours.map((hour) => (
            <div
              key={hour.id}
              className={
                'time-selector__content__item' +
                (hour.id === selectedTime.id && props.disabledLast
                  ? ' time-selector__content__item__selected'
                  : '')
              }
              onClick={() => {
                setSelectedTime({ id: hour.id, value: hour.value });
                if (props.disabledLast && hour.id === 95) {
                  props.onChange([
                    { id: hour.id, value: hour.value },
                    { id: 24 * 4, value: '24:00' },
                  ]);
                } else {
                  if (hour.id === 96) {
                    props.onChange([
                      { id: 24 * 4, value: '24:00' },
                      { id: 24 * 4, value: '24:00' },
                    ]);
                  } else {
                    props.onChange([
                      { id: hour.id, value: hour.value },
                      {
                        id: hours.findIndex((item) => item.id === hour.id + 1),
                        value: hours[hours.findIndex((item) => item.id === hour.id + 1)].value,
                      },
                    ]);
                  }
                }
                setOpened(false);
              }}
            >
              {hour.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TimeSelector;
