export interface ColorDataSettings {
  color: string;
  gradientData: {
    isGradient: boolean;
    gradientType: string | undefined;
    degrees: string;
    colors: { value: string; left?: number }[];
  };
}
export enum CandleTooltipRectPosition {
  Fixed = 'fixed',
  Pointer = 'pointer',
}

export interface LineSettings {
  color: string;
  thickness: 1 | 2 | 3 | 4;
}
export interface MarkSettings {
  color: string;
  size: 1 | 2 | 3 | 4;
}
export interface TextSettings {
  size: { id: number; name: string };
  color: string;
}

export interface BackgroundStyle {
  background: ColorDataSettings;
}
export interface TradesSettingsState {
  tp_levels: LineSettings;
  sl_levels: LineSettings;
  trailing_sl_levels: LineSettings;
  entry_levels: LineSettings;
  liquidation_levels: LineSettings;
  dca_levels: LineSettings;
  trades_background: {
    profit_area: {
      long: ColorDataSettings;
      short: ColorDataSettings;
    };
    loss_area: {
      long: ColorDataSettings;
      short: ColorDataSettings;
    };
    dca_area: {
      long: ColorDataSettings;
      short: ColorDataSettings;
    };
  };
  side_bar_marks: {
    entry_point: MarkSettings;
    closing_orders: MarkSettings;
    averaging_orders: MarkSettings;
  };
  under_above_marks: {
    entry_point: MarkSettings;
    closing_orders: MarkSettings;
    averaging_orders: MarkSettings;
  };
  caption_text: {
    fontFamily: string;
    open_long: TextSettings;
    open_short: TextSettings;
    tp_levels: TextSettings;
    sl_levels: TextSettings;
    trailing_sl_levels: TextSettings;
    dca_levels: TextSettings;
    liquidation_levels: TextSettings;
  };
  trade_size_caption: {
    positive_quantity: TextSettings;
    negative_quantity: TextSettings;
  };
  level_lines_caption: {
    tp: TextSettings;
    sl: TextSettings;
    trailing_sl: TextSettings;
    averages: TextSettings;
    liquidation: TextSettings;
  };
  tradeStatisticsVisible: boolean;
}
