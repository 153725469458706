import { useEffect, useState, type Dispatch, type FC, type SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/rootReducer';
import {
  setSelectedStrategyId as reduxSetSelectedStrategyId,
  setMergedStrategies,
  setStrategies as setReduxStrategies,
} from 'redux/strategy/reducer';
import { type StrategyModes } from 'interfaces/IStrategy';
import { apiStrategy } from 'services/api/Strategy/ApiStrategy';
import type {
  IMergedStrategy,
  IStrategyAllData,
  IStrategyAllResponse,
} from 'services/api/Strategy/types/StrategyResponse';
import StrategyMergeElement from 'components/StrategyMergeElement/StrategyMergeElement';
import StrategyElement from 'components/StrategyElement/StrategyElement';
import { Components } from '../../Components';
import './SavedStrategies.scss';
import ToggleIcon from 'assets/images/Strategy/ToggleIcon.svg';

interface IProps {
  setStrategyNode: (value: Components | null) => void;
  addStrategyHistory: (value: { _id: string; name: string; isMerged?: boolean }) => void;
  removeStrategyHistory: Dispatch<SetStateAction<IStrategyAllData | IMergedStrategy>>;
  setPublishOpen?: (value: boolean) => void;
  setPublishData?: Dispatch<SetStateAction<any>>;
}

const SavedStrategies: FC<IProps> = ({
  setStrategyNode,
  addStrategyHistory,
  removeStrategyHistory,
  setPublishOpen,
  setPublishData,
}) => {
  const { mergedStrategies, strategies } = useSelector((state) => state.strategy);
  const [openedTypes, setOpenedTypes] = useState([]);
  const ONE_WAY_MODE = 'One way strategies';
  const HEDGE_MODE = 'Hedge strategies';
  const MERGE_STRATEGIES = 'Merged strategies';
  const dispatch = useDispatch();
  const setSelectedStrategyId = (value: string | null): void => {
    dispatch(reduxSetSelectedStrategyId(value));
  };
  const removeStrategyHandler = async (mode: StrategyModes, id: string): Promise<void> => {
    const result = await apiStrategy.removeStrategy(mode, id);

    if (result) {
      apiStrategy.getStrategies().then((result) => {
        dispatch(setReduxStrategies(result));
      });
      // const index = strategies.findIndex((e) => e._id === id);

      // if (index !== -1) {
      //   setStrategies(
      //     strategies.slice(0, index).concat(strategies.slice(index + 1, strategies.length)),
      //   );
      // }
    }
  };
  const removeMergedStrategyHandler = async (id: string): Promise<void> => {
    const result = await apiStrategy.removeMergedStrategy(id);
    removeStrategyHistory(mergedStrategies.find((e) => e._id === id));
    if (result) {
      dispatch(
        setMergedStrategies([
          ...mergedStrategies.filter((e) => {
            return e._id !== id;
          }),
        ]),
      );
    }
  };
  const sortedStrategies = (strategies: IStrategyAllResponse): IStrategyAllResponse => {
    return [...strategies].sort((a, b) => {
      const isAFavouriteDefined = typeof a.is_favourite !== 'undefined' && a.is_favourite !== null;
      const isBFavouriteDefined = typeof b.is_favourite !== 'undefined' && b.is_favourite !== null;
      if (!isAFavouriteDefined && !isBFavouriteDefined) {
        return 0;
      } else if (!isAFavouriteDefined) {
        return 1;
      } else if (!isBFavouriteDefined) {
        return -1;
      } else {
        return b.is_favourite - a.is_favourite;
      }
    });
  };
  const sortedMergedStrategies = (strategies: IMergedStrategy[]): IMergedStrategy[] => {
    return [...strategies].sort((a, b) => {
      const isAFavouriteDefined = typeof a.is_favourite !== 'undefined' && a.is_favourite !== null;
      const isBFavouriteDefined = typeof b.is_favourite !== 'undefined' && b.is_favourite !== null;
      if (!isAFavouriteDefined && !isBFavouriteDefined) {
        return 0;
      } else if (!isAFavouriteDefined) {
        return 1;
      } else if (!isBFavouriteDefined) {
        return -1;
      } else {
        return b.is_favourite - a.is_favourite;
      }
    });
  };
  const removeStrategy = (id: string, strategy: IStrategyAllData): void => {
    removeStrategyHistory(strategy);
    removeStrategyHandler(strategy.mode ?? 0, id);
    setStrategyNode(Components.SAVED_STRATEGIES);
  };
  const strategyWithModeExists = (mode: 1 | 0): boolean => {
    return strategies.some((strategy) => strategy.mode === mode);
  };

  const availableMergedStrategies = sortedMergedStrategies(mergedStrategies).filter(
    (mergedStrategy) => {
      let existedStrategiesOfMerge = 0;
      for (let i = 0; i < mergedStrategy.strategies.length; i++) {
        if (
          strategies.findIndex((strategy) => strategy._id === mergedStrategy.strategies[i]) !== -1
        ) {
          existedStrategiesOfMerge++;
        }
      }
      if (existedStrategiesOfMerge < 2) removeMergedStrategyHandler(mergedStrategy._id);
      return existedStrategiesOfMerge > 1;
    },
  );
  useEffect(() => {
    if (mergedStrategies.length > 0) {
      mergedStrategies.forEach((mergedStrategy) => {
        let existedStrategiesOfMerge = 0;
        for (let i = 0; i < mergedStrategy.strategies.length; i++) {
          if (
            strategies.findIndex((strategy) => strategy._id === mergedStrategy.strategies[i]) !== -1
          ) {
            existedStrategiesOfMerge++;
          }
        }
        if (existedStrategiesOfMerge < 2) {
          removeStrategyHistory({
            _id: mergedStrategy._id,
            name: mergedStrategy.name,
          });
          apiStrategy.removeMergedStrategy(mergedStrategy._id);
        }
      });
    }
  }, [strategies, mergedStrategies]);

  return (
    <>
      <button
        className={
          'strategyTypeToggleButton ' +
          (openedTypes.includes(ONE_WAY_MODE) ? ' strategyTypeToggleButton__opened' : '') +
          (strategyWithModeExists(0) ? ' ' : ' strategyTypeToggleButton__disabled')
        }
        disabled={!strategyWithModeExists(0)}
        onClick={() => {
          if (strategyWithModeExists(0)) {
            if (!openedTypes.includes(ONE_WAY_MODE)) {
              setOpenedTypes([...openedTypes, ONE_WAY_MODE]);
            } else {
              setOpenedTypes(openedTypes.filter((type) => type !== ONE_WAY_MODE));
            }
          }
        }}
      >
        One-way mode <img src={ToggleIcon} alt="ToggleIcon" />
      </button>
      {openedTypes.includes(ONE_WAY_MODE) &&
        sortedStrategies(strategies).map((strategy) => {
          if (strategy.mode === 1) {
            return null;
          }
          return (
            <StrategyElement
              key={strategy._id}
              strategy={strategy}
              editStrategy={() => {
                addStrategyHistory(strategy);
                setSelectedStrategyId(strategy._id);
                setStrategyNode(Components.CONSTRUCTOR);
              }}
              removeHandler={(id) => {
                removeStrategy(id, strategy);
              }}
              setPublishOpen={setPublishOpen}
              setPublishData={setPublishData}
            />
          );
        })}
      <button
        className={
          'strategyTypeToggleButton ' +
          (openedTypes.includes(HEDGE_MODE) ? ' strategyTypeToggleButton__opened' : '') +
          (strategyWithModeExists(1) ? ' ' : ' strategyTypeToggleButton__disabled')
        }
        disabled={!strategyWithModeExists(1)}
        onClick={() => {
          if (strategyWithModeExists(1)) {
            if (!openedTypes.includes(HEDGE_MODE)) {
              setOpenedTypes([...openedTypes, HEDGE_MODE]);
            } else {
              setOpenedTypes(openedTypes.filter((type) => type !== HEDGE_MODE));
            }
          }
        }}
      >
        Hedge mode <img src={ToggleIcon} alt="ToggleIcon" />
      </button>

      {openedTypes.includes(HEDGE_MODE) &&
        sortedStrategies(strategies).map((strategy) => {
          if (strategy.mode === 0) return null;
          return (
            <StrategyElement
              key={strategy._id}
              strategy={strategy}
              editStrategy={() => {
                addStrategyHistory(strategy);
                setSelectedStrategyId(strategy._id);
                setStrategyNode(Components.CONSTRUCTOR);
              }}
              removeHandler={(id) => {
                removeStrategy(id, strategy);
              }}
              setPublishOpen={setPublishOpen}
              setPublishData={setPublishData}
            />
          );
        })}
      <button
        className={
          'strategyTypeToggleButton ' +
          (openedTypes.includes(MERGE_STRATEGIES) ? ' strategyTypeToggleButton__opened' : '') +
          (availableMergedStrategies.length !== 0 ? ' ' : ' strategyTypeToggleButton__disabled')
        }
        disabled={availableMergedStrategies.length === 0}
        onClick={() => {
          if (availableMergedStrategies.length !== 0) {
            setOpenedTypes(
              openedTypes.includes(MERGE_STRATEGIES)
                ? openedTypes.filter((type) => type !== MERGE_STRATEGIES)
                : [...openedTypes, MERGE_STRATEGIES],
            );
          }
        }}
      >
        Merged strategies <img src={ToggleIcon} alt="ToggleIcon" />
      </button>
      {openedTypes.includes(MERGE_STRATEGIES) &&
        sortedMergedStrategies(availableMergedStrategies).map((strategy) => {
          return (
            <StrategyMergeElement
              key={strategy._id}
              strategy={strategy}
              mergedStrategies={strategy?.strategies.length}
              editStrategy={() => {
                addStrategyHistory({ _id: strategy._id, name: strategy.name, isMerged: true });
                setSelectedStrategyId(strategy._id);
                setStrategyNode(Components.EDIT_MERGED_STRATEGY);
              }}
              removeHandler={(id) => {
                removeMergedStrategyHandler(id);
                setStrategyNode(Components.SAVED_STRATEGIES);
              }}
            />
          );
        })}
    </>
  );
};

export default SavedStrategies;
