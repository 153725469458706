import {
  type FC,
  useState,
  useEffect,
  type Dispatch as DispatchType,
  type SetStateAction,
} from 'react';
import './savedStrategies.scss';
import { StrategyModes } from 'interfaces/IStrategy';
import { apiStrategy } from 'services/api/Strategy/ApiStrategy';
import type { IAllPublishedStrategy } from 'pages/UserProfile/interfaces/interfaces';
import { generateUniqueId } from 'services/utils/generateId';
import PublishedStrategyElement from './StrategyElement';
import type { Components } from '../../Components';
import ToggleIcon from 'assets/images/Strategy/ToggleIcon.svg';
interface IProps {
  setStrategyNode: (value: Components | null) => void;
  addStrategyHistory: (value: { _id: string; name: string; isMerged?: boolean }) => void;
  setStrategyEnabled: DispatchType<SetStateAction<boolean>>;
}

const StrategyLibrary: FC<IProps> = ({
  setStrategyNode,
  addStrategyHistory,
  setStrategyEnabled,
}) => {
  const [publishedStrategies, setPublishedStrategies] = useState<
    Record<StrategyModes, IAllPublishedStrategy[]>
  >({
    [StrategyModes.ONE_SIDE]: [],
    [StrategyModes.HEDGE]: [],
  });
  const [publishedMergedStrategies, setPublishedMergedStrategies] = useState([]);
  const [openedTypes, setOpenedTypes] = useState([]);
  const ONE_WAY_MODE = 'One way strategies';
  const HEDGE_MODE = 'Hedge strategies';
  const MERGE_STRATEGIES = 'Merged strategies';
  useEffect(() => {
    apiStrategy.getAllPublishedStrategies().then((data) => {
      setPublishedStrategies(data);
    });
  }, []);

  useEffect(() => {
    apiStrategy.getAllPublishedMergedStrategies().then((data) => {
      setPublishedMergedStrategies(data);
    });
  }, []);
  const strategyWithModeExists = (mode: 1 | 0): boolean => {
    return publishedStrategies[mode].some((strategy) => strategy.mode === mode);
  };
  return (
    <>
      <button
        className={
          'strategyTypeToggleButton ' +
          (openedTypes.includes(ONE_WAY_MODE) ? ' strategyTypeToggleButton__opened' : '') +
          (strategyWithModeExists(0) ? ' ' : ' strategyTypeToggleButton__disabled')
        }
        disabled={!strategyWithModeExists(0)}
        onClick={() => {
          if (strategyWithModeExists(0)) {
            if (!openedTypes.includes(ONE_WAY_MODE)) {
              setOpenedTypes([...openedTypes, ONE_WAY_MODE]);
            } else {
              setOpenedTypes(openedTypes.filter((type) => type !== ONE_WAY_MODE));
            }
          }
        }}
      >
        One-way mode <img src={ToggleIcon} alt="ToggleIcon" />
      </button>
      {openedTypes.includes(ONE_WAY_MODE) &&
        publishedStrategies[0].map((strategy) => {
          if (strategy.mode === 1) {
            return null;
          }

          return (
            <PublishedStrategyElement
              key={generateUniqueId()}
              setStrategyNode={setStrategyNode}
              strategy={strategy}
              addStrategyHistory={addStrategyHistory}
              setStrategyEnabled={setStrategyEnabled}
            />
          );
        })}
      <button
        className={
          'strategyTypeToggleButton ' +
          (openedTypes.includes(HEDGE_MODE) ? ' strategyTypeToggleButton__opened' : '') +
          (strategyWithModeExists(1) ? ' ' : ' strategyTypeToggleButton__disabled')
        }
        disabled={!strategyWithModeExists(1)}
        onClick={() => {
          if (strategyWithModeExists(1)) {
            if (!openedTypes.includes(HEDGE_MODE)) {
              setOpenedTypes([...openedTypes, HEDGE_MODE]);
            } else {
              setOpenedTypes(openedTypes.filter((type) => type !== HEDGE_MODE));
            }
          }
        }}
      >
        Hedge mode <img src={ToggleIcon} alt="ToggleIcon" />
      </button>

      {openedTypes.includes(HEDGE_MODE) &&
        publishedStrategies[1].map((strategy) => {
          if (strategy.mode === 0) {
            return null;
          }

          return (
            <PublishedStrategyElement
              key={generateUniqueId()}
              setStrategyNode={setStrategyNode}
              strategy={strategy}
              addStrategyHistory={addStrategyHistory}
              setStrategyEnabled={setStrategyEnabled}
            />
          );
        })}
      <button
        className={
          'strategyTypeToggleButton ' +
          (openedTypes.includes(MERGE_STRATEGIES) ? ' strategyTypeToggleButton__opened' : '') +
          (publishedMergedStrategies.length !== 0 ? ' ' : ' strategyTypeToggleButton__disabled')
        }
        disabled={publishedMergedStrategies.length === 0}
        onClick={() => {
          if (publishedMergedStrategies.length !== 0) {
            setOpenedTypes(
              openedTypes.includes(MERGE_STRATEGIES)
                ? openedTypes.filter((type) => type !== MERGE_STRATEGIES)
                : [...openedTypes, MERGE_STRATEGIES],
            );
          }
        }}
      >
        Merged strategies <img src={ToggleIcon} alt="ToggleIcon" />
      </button>
      {openedTypes.includes(MERGE_STRATEGIES) &&
        publishedMergedStrategies.map((strategy) => {
          return (
            <PublishedStrategyElement key={generateUniqueId()} isMerge={true} strategy={strategy} />
          );
        })}
    </>
  );
};

export default StrategyLibrary;
