import { useEffect, useState, type FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  setBackTest,
  setMergedStrategyData,
  updateSelectedMergedStrategy,
  setSelectedStrategyId as reduxSetSelectedStrategyId,
} from 'redux/strategy/reducer';
import { useSelector } from 'redux/rootReducer';
import {
  type IMergedStrategy,
  type IStrategyAllResponse,
} from 'services/api/Strategy/types/StrategyResponse';
import { apiStrategy } from 'services/api/Strategy/ApiStrategy';
import { StrategyName } from '../StrategyName/StrategyName';
import MergeStrategyEditor from './components/MergeStrategyEditor';
import GTumbler from 'components/GTumbler/GTumbler';
import { Components } from '../../Components';
import MergedStrategiesList from './components/MergedStrategiesList/MergedStrategiesList';
import CustomNumberInput from '../CustomElements/CustomInput/CustomInput';
import './MergedStrategy.scss';
import AddMergeStrategyIcon from 'assets/images/Strategy/AddMergeStrategyIcon.svg';
import CloseIconType2 from 'assets/images/Strategy/CloseIconType2.svg';
import useWindowWidth from 'hooks/useWindowWidth';

interface IProps {
  setStrategyNode?: (value: Components) => void;
  addStrategyHistory?: (value: { _id: string; name: string; isMerged?: boolean }) => void;
}

const MergedStrategy: FC<IProps> = ({ setStrategyNode, addStrategyHistory }): JSX.Element => {
  const isMobileVersion = window.innerWidth < 768;
  const { selectedStrategyId } = useSelector((state) => state.strategy);
  const strategies: IStrategyAllResponse = useSelector((state) => state.strategy.strategies);

  const selectedStrategyList = useSelector(
    (state) => state.strategy.mergedStrategyData?.backtestData,
  );
  const width = useWindowWidth();

  const [isSelectorOpened, setIsSelectorOpened] = useState(false);
  const [strategy, setStrategy] = useState<IMergedStrategy>({
    name: 'Merge',
    balance: 50000,
    max_loss: 5000,
    max_daily_loss: 5000,
    max_profit: 5000,
    max_daily_profit: 5000,
    strategies: [],
    aggr_rev_martingale: false,
    is_infinite_deposit: false,
    likes: 0,
  });
  const [switcherMode, setSwitcherMode] = useState<0 | 1>(strategy._id ? 1 : 0);
  const [strategyIsReady, setStrategyIsReady] = useState(null);
  const CREATE_MODE = 0;
  const EDIT_MODE = 1;
  const VIOLET = 'rgba(105, 78, 240, 1)';
  const viewMode = strategy.user_id ? EDIT_MODE : CREATE_MODE;
  const isForbiddenToMerge =
    strategy.strategies.length < 2 || strategy.balance === 0 || strategy.name === '';
  const dispatch = useDispatch();
  const setSelectedStrategyId = (value: string | null): void => {
    dispatch(reduxSetSelectedStrategyId(value));
  };
  useEffect(() => {
    if (strategy?._id) {
      setSwitcherMode(1);
    }
  }, [strategy._id]);
  useEffect(() => {
    apiStrategy.getAllMergedStrategies().then((data) => {
      if (selectedStrategyId) {
        if (data.findIndex((strategy) => strategy._id === selectedStrategyId) !== -1) {
          setStrategy(data.find((strategy) => strategy._id === selectedStrategyId));
        }
      }
    });
  }, [selectedStrategyId]);
  useEffect(() => {
    if (viewMode === EDIT_MODE) {
      apiStrategy.saveMergedStrategy(strategy).then((data) => {
        addStrategyHistory({ _id: strategy._id, name: strategy.name, isMerged: true });
      });
    }
  }, [strategy.strategies.length]);
  useEffect(() => {
    if (viewMode === EDIT_MODE) {
      for (let i = 0; i < strategy.strategies.length; i++) {
        if (strategies.findIndex((s) => s._id === strategy.strategies[i]) === -1) {
          setStrategy((prev) => {
            return {
              ...prev,
              strategies: [
                ...prev.strategies.filter(
                  (strategyItem, index) => strategyItem !== strategy.strategies[i],
                ),
              ],
            };
          });
        }
      }
      if (strategyIsReady) {
        setSelectedStrategyId(strategy._id);
        setStrategyNode(Components.EDIT_MERGED_STRATEGY);
        dispatch(updateSelectedMergedStrategy(strategy));
        dispatch(setBackTest(true));
      }
    }
  }, [viewMode, strategyIsReady]);
  useEffect(() => {
    if (strategy.strategies.length > 0) {
      const nonExistedStrategies = [];
      for (let i = 0; i < strategy.strategies.length; i++) {
        if (strategies.findIndex((s) => s._id === strategy.strategies[i]) === -1) {
          nonExistedStrategies.push(strategy.strategies[i]);
        }
        setStrategyIsReady(!(nonExistedStrategies.length > 0));
      }
    }
    dispatch(updateSelectedMergedStrategy(strategy));
  }, [strategy]);

  return (
    <div
      id="merged-strategy-editor"
      style={{
        position: 'relative',
        overflow: (width > 768 || (width < 768 && isSelectorOpened)) && 'hidden',
        width: '100%',
        minHeight: '200px',
      }}
    >
      {isSelectorOpened && (
        <MergeStrategyEditor
          strategies={strategies}
          strategy={strategy}
          updateStrategy={setStrategy}
          onClose={() => {
            setIsSelectorOpened(false);
          }}
        />
      )}
      <div className="create-merged-strategy" style={{ position: 'relative' }}>
        <StrategyName
          onChange={(e) => {
            setStrategy({ ...strategy, name: e });
          }}
          onSave={() => {
            apiStrategy.getMergeStrategy(strategy._id).then((data) => {
              apiStrategy.saveMergedStrategy({ ...data, name: strategy.name });
            });
          }}
          name={strategy?.name}
          isMergedStrategy
        />
        {viewMode === EDIT_MODE && (
          <div style={{ marginTop: '32px', marginBottom: '16px' }} className="strategy-switcher">
            <div
              style={{ background: switcherMode === 1 && VIOLET }}
              className={'strategy-switcher__option'}
              onClick={() => {
                setSwitcherMode(1);
              }}
            >
              Strategies
            </div>
            <div
              style={{ background: switcherMode === 0 && VIOLET }}
              className={'strategy-switcher__option'}
              onClick={() => {
                setSwitcherMode(0);
              }}
            >
              Settings
            </div>
          </div>
        )}

        {switcherMode === 0 && (
          <>
            <div className="create-merged-strategy__header">Initial capital:</div>
            <div className="create-merged-strategy__amount">
              Amount:{' '}
              <div className="create-merged-strategy__amount__inputContainer">
                <CustomNumberInput
                  value={strategy.balance}
                  step={0.01}
                  onChange={(e) => {
                    setStrategy({ ...strategy, balance: Number(e.target.value) });
                  }}
                />
                <span>$</span>
              </div>
            </div>

            <div className="create-merged-strategy__amount">
              Max. unrealized drawdown:{' '}
              <div className="create-merged-strategy__amount__inputContainer">
                <CustomNumberInput
                  value={strategy.max_loss}
                  step={0.01}
                  onChange={(e) => {
                    setStrategy({ ...strategy, max_loss: Number(e.target.value) });
                  }}
                />
                <span>$</span>
              </div>
            </div>
            <div className="create-merged-strategy__amount">
              Max. daily drawdown:{' '}
              <div className="create-merged-strategy__amount__inputContainer">
                <CustomNumberInput
                  value={strategy.max_daily_loss}
                  step={0.01}
                  onChange={(e) => {
                    setStrategy({ ...strategy, max_daily_loss: Number(e.target.value) });
                  }}
                />
                <span>$</span>
              </div>
            </div>

            <div className="create-merged-strategy__amount">
              Max. unrealized PnL:{' '}
              <div className="create-merged-strategy__amount__inputContainer">
                <CustomNumberInput
                  value={strategy.max_profit}
                  step={0.01}
                  onChange={(e) => {
                    setStrategy({ ...strategy, max_profit: Number(e.target.value) });
                  }}
                />
                <span>$</span>
              </div>
            </div>
            <div className="create-merged-strategy__amount">
              Max. daily PnL:{' '}
              <div className="create-merged-strategy__amount__inputContainer">
                <CustomNumberInput
                  value={strategy.max_daily_profit}
                  step={0.01}
                  onChange={(e) => {
                    setStrategy({ ...strategy, max_daily_profit: Number(e.target.value) });
                  }}
                />
                <span>$</span>
              </div>
            </div>
            <div className="create-merged-strategy__amount">
              Aggregate rev. martingale
              <GTumbler
                size={isMobileVersion ? 'large' : null}
                name="Aggregate rev. martingale"
                checked={strategy.aggr_rev_martingale}
                onToggle={() => {
                  setStrategy({
                    ...strategy,
                    aggr_rev_martingale: !strategy.aggr_rev_martingale,
                  });
                }}
              />
            </div>
            <div className="create-merged-strategy__amount">
              Infinity balance
              <GTumbler
                size={isMobileVersion ? 'large' : null}
                name="Infinity balance"
                checked={strategy.is_infinite_deposit}
                onToggle={() => {
                  setStrategy({
                    ...strategy,
                    is_infinite_deposit: !strategy.is_infinite_deposit,
                  });
                }}
              />
            </div>
            <div className="create-merged-strategy__header">Strategies:</div>
            <div
              className="create-merged-strategy__strategies"
              // style={{ minHeight: strategy.strategies.length !== 0 ? '200px' : '' }}
            >
              {strategy.strategies.map((strategyItem, index) => (
                <div key={index} className="create-merged-strategy__itemContainer">
                  <div className="create-merged-strategy__itemContainer__itemInfo">
                    <div className="create-merged-strategy__itemContainer__itemInfo_content">
                      <div className="create-merged-strategy__itemContainer__itemInfo_content_name">
                        {' '}
                        {strategies.find((e: any) => e._id === strategyItem)?.name}
                      </div>
                      <div className="create-merged-strategy__itemContainer__itemInfo_content_description">
                        {strategies.find((e: any) => e._id === strategyItem)?.ticker}{' '}
                        {strategies.find((e: any) => e._id === strategyItem)?.mode === 1
                          ? 'Hedge'
                          : 'One-way'}
                      </div>
                    </div>
                    {strategy.strategies.length > 2 && (
                      <img
                        onClick={() => {
                          const newStrategies = strategy.strategies.filter(
                            (e, itemIndex) => itemIndex !== index,
                          );

                          setStrategy({ ...strategy, strategies: newStrategies });
                          apiStrategy
                            .saveMergedStrategy({ ...strategy, strategies: newStrategies })
                            .then(() => {
                              dispatch(
                                updateSelectedMergedStrategy({
                                  ...strategy,
                                  strategies: newStrategies,
                                }),
                              );
                            })
                            .then(() => {
                              dispatch(setBackTest(true));
                            });
                        }}
                        src={CloseIconType2}
                        alt="delete"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="create-merged-strategy__addNew"
              onClick={() => {
                setIsSelectorOpened(true);
              }}
            >
              {' '}
              <img src={AddMergeStrategyIcon} alt="AddMergeStrategyIcon" /> Add new strategies
            </div>
          </>
        )}
        <div className="strategies__wrap">
          {switcherMode === 1 &&
            selectedStrategyList &&
            selectedStrategyList.map((item, index) => {
              return (
                <MergedStrategiesList
                  key={item._id}
                  strategyData={item}
                  balance={strategy.balance}
                />
              );
            })}
          {width < 768 && (
            <div className="create-merged-strategy__buttonContainer">
              {viewMode === EDIT_MODE && (
                <div
                  style={isForbiddenToMerge ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                  className="create-merged-strategy__merge"
                  onClick={() => {
                    if (!isForbiddenToMerge) {
                      apiStrategy
                        .saveMergedStrategy(strategy)
                        .then(() => {
                          dispatch(updateSelectedMergedStrategy(strategy));
                          dispatch(setMergedStrategyData(null));
                        })
                        .then(() => {
                          dispatch(setBackTest(true));
                        });
                    }
                  }}
                >
                  Update
                </div>
              )}
              {viewMode === CREATE_MODE && (
                <div
                  style={isForbiddenToMerge ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                  className="create-merged-strategy__merge"
                  onClick={() => {
                    if (!isForbiddenToMerge) {
                      apiStrategy
                        .createMergedStrategy(strategy)
                        .then((data) => {
                          setSelectedStrategyId(data._id);
                          setStrategyNode(Components.EDIT_MERGED_STRATEGY);
                          addStrategyHistory({ _id: data._id, name: data.name, isMerged: true });
                          dispatch(updateSelectedMergedStrategy(data));
                        })
                        .then(() => {
                          dispatch(setBackTest(true));
                        });
                    }
                  }}
                >
                  Merge strategies
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {width > 768 && (
        <div className="create-merged-strategy__buttonContainer">
          {viewMode === CREATE_MODE && (
            <div
              style={isForbiddenToMerge ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
              className="create-merged-strategy__merge"
              onClick={() => {
                if (!isForbiddenToMerge) {
                  apiStrategy
                    .createMergedStrategy(strategy)
                    .then((data) => {
                      setSelectedStrategyId(data._id);
                      setStrategyNode(Components.EDIT_MERGED_STRATEGY);
                      addStrategyHistory({ _id: data._id, name: data.name, isMerged: true });
                      dispatch(updateSelectedMergedStrategy(data));
                    })
                    .then(() => {
                      dispatch(setBackTest(true));
                    });
                }
              }}
            >
              Merge strategies
            </div>
          )}

          {viewMode === EDIT_MODE && (
            <div
              style={isForbiddenToMerge ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
              className="create-merged-strategy__merge"
              onClick={() => {
                if (!isForbiddenToMerge) {
                  apiStrategy
                    .saveMergedStrategy(strategy)
                    .then(() => {
                      dispatch(updateSelectedMergedStrategy(strategy));
                      dispatch(setMergedStrategyData(null));
                    })
                    .then(() => {
                      dispatch(setBackTest(true));
                    });
                }
              }}
            >
              Update
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MergedStrategy;
