import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/rootReducer';
import { setSelectedMergedStrategyIndex } from 'redux/strategy/reducer';
import styles from '../ChartWrapper.module.css';
import { useFakeScrollBar } from '../customHooks/useFakeScrollBar';
export interface IProps {
  isCustomTicker: boolean;
}
const StrategiesList = ({ isCustomTicker }: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const mergedStrategy = useSelector((state) => state.strategy.selectedMergedStrategy);
  const allStrategies = useSelector((state) => state.strategy.strategies);
  const selectedMergedStrategyIndex = useSelector(
    (state) => state.strategy.selectedMergedStrategyIndex,
  );

  const contentWrapperRef = useRef(null);
  const fakeScrollBarRef = useFakeScrollBar(contentWrapperRef);

  return (
    <div
      className={styles.mergedStrategiesHeaderList}
      ref={contentWrapperRef}
      style={{ marginBottom: isCustomTicker && '0', minHeight: isCustomTicker ? '42px' : '35px' }}
    >
      {allStrategies.length > 0 &&
        mergedStrategy &&
        mergedStrategy.strategies.map((strategyItem, index) => (
          <div
            key={index}
            className={`${styles.mergedStrategiesHeaderListItem} ${
              index === selectedMergedStrategyIndex && styles.mergedStrategiesHeaderListItemSelected
            } ${isCustomTicker && styles.mergedStrategiesHeaderListItemCustomTicker}`}
            onClick={() => {
              dispatch(setSelectedMergedStrategyIndex(index));
            }}
          >
            {allStrategies.find((e) => e._id === strategyItem)?.name}
          </div>
        ))}
      <div
        className={`${styles.fakeChartScrollBar}  ${
          !isCustomTicker && styles.fakeChartScrollBarNotCustomTicker
        }`}
        style={{ bottom: isCustomTicker && '0px' }}
        ref={fakeScrollBarRef}
      ></div>
      <div className={styles.fakeChartScrollBarContainer}></div>
    </div>
  );
};

export default StrategiesList;
