import { useState, type FC } from 'react';
import './CustomFontSizeDropDown.scss';
import Arrow from 'assets/images/Strategy/DropDownTriangle.svg';
interface IProps {
  options: {
    id: number;
    name?: string;
  }[];
  activeOption: {
    id: number;
    name?: string;
  };
  onChange: (id: number) => void;
  header?: string;
}
const CustomFontSizeDropDown: FC<IProps> = ({ options, onChange, activeOption, header }) => {
  const [isOpened, setOpened] = useState(false);
  return (
    <div
      className="font-size-custom-dropdown"
      tabIndex={0}
      onBlur={() => {
        setOpened(false);
      }}
    >
      {header && <div className="thickness-custom-dropdown__header">{header}</div>}
      <div
        className=" font-size-custom-dropdown__selected"
        onClick={() => {
          setOpened(!isOpened);
        }}
      >
        <div className="font-size-custom-dropdown__selected_name">{activeOption.name}</div>
        <img
          className={
            'font-size-custom-dropdown__selected_arrow' +
            (isOpened ? ' font-size-custom-dropdown__selected_arrowOpened' : '')
          }
          src={Arrow}
          alt=">"
        />
      </div>

      {isOpened && (
        <div
          style={{ minHeight: options.length * 36 < 180 && `${options.length * 36}px ` }}
          className="font-size-custom-dropdown__itemsContainer"
        >
          {options.map((option, index) => (
            <div
              style={{
                borderTopLeftRadius: index === 0 ? '4px' : '',
                borderTopRightRadius: index === 0 ? '4px' : '',
              }}
              className="font-size-custom-dropdown__item"
              key={index}
              onClick={() => {
                setOpened(false);
                onChange(option.id);
              }}
            >
              <div className="font-size-custom-dropdown__item_name">{option.name}</div>
            </div>
          ))}{' '}
        </div>
      )}
    </div>
  );
};

export default CustomFontSizeDropDown;
