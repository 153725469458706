import { type Exchanges } from '../interfaces/IExchange';
import BinanceLogo from '../assets/images/binance-logo.svg';
import WhiteBitLogo from '../assets/images/whitebit-logo.svg';
import BybitLogo from '../assets/images/bybit-logo.svg';

const EXCHANGES: Exchanges = {
  binance: {
    name: 'Binance',
    icon: BinanceLogo,
  },
  whiteBit: {
    name: 'WhiteBit',
    icon: WhiteBitLogo,
    disabled: false,
  },
  byBit: {
    name: 'ByBit',
    icon: BybitLogo,
    disabled: false,
  },
};

export default EXCHANGES;
