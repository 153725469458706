import { type ChangeEvent, type FC, useRef, useState } from 'react';
import Minus from 'assets/images/Strategy/NumberInputArrow.svg';
import './CustomInput.scss';

interface IProps {
  value: number;
  step: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: any) => void;
  min?: number;
  max?: number;
  showMobileArrows?: boolean;
  errorValue?: boolean;
  maxWidth?: number;
  maxHeight?: number;
  title?: string;
}

const CustomNumberInput: FC<IProps> = ({
  value,
  step,
  onChange,
  onBlur,
  min,
  max,
  showMobileArrows,
  errorValue,
  maxWidth,
  maxHeight,
  title,
}): JSX.Element => {
  const inputRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const isMobileVersion = window.innerWidth < 768;
  const triggerInputChange = (): void => {
    const inputElement = inputRef.current;
    if (inputElement) {
      const event = new Event('input', { bubbles: true });
      inputElement.dispatchEvent(event);
    }
  };

  const handleStepDown = (): void => {
    if (inputRef.current) {
      inputRef.current.stepDown();
      triggerInputChange();
    }
  };

  const handleStepUp = (): void => {
    if (inputRef.current) {
      inputRef.current.stepUp();
      triggerInputChange();
    }
  };
  return (
    <div
      className="input-container"
      onMouseEnter={() => {
        !isMobileVersion && setIsVisible(true);
      }}
      onMouseLeave={() => {
        !isMobileVersion && setIsVisible(false);
      }}
    >
      {(isMobileVersion ? showMobileArrows : isVisible) && (
        <>
          <button onClick={handleStepUp} className={showMobileArrows ? 'plus-mob' : 'plus'}>
            <img src={Minus} alt="+" />
          </button>
          <button onClick={handleStepDown} className={showMobileArrows ? 'minus-mob' : 'minus'}>
            <img src={Minus} alt="-" />
          </button>
        </>
      )}
      <input
        tabIndex={0}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
        className="input"
        type="number"
        value={value}
        step={step}
        min={min}
        max={max}
        readOnly={showMobileArrows}
        style={{
          maxWidth: maxWidth && `${maxWidth}px`,
          maxHeight: maxHeight && `${maxHeight}px`,
          marginRight: showMobileArrows ? '30px' : '',
          userSelect: showMobileArrows ? 'none' : 'auto',
          border: errorValue ? '1px solid red' : '',
        }}
        onChange={(e) => {
          onChange(e);
        }}
        ref={inputRef}
      />
      {title && <div className="input-title">{title}</div>}
    </div>
  );
};

export default CustomNumberInput;
