import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import lineVertical from '../../../../../../assets/images/charts/line-vertical.png';

import './BarChart.scss';

export interface IBarChartData {
  time: number;
  date: any;
  longProfit: number;
  shortProfit: number;
  longPercent: number;
  shortPercent: number;
  count: number;
}

interface IProps {
  data: IBarChartData[];
  width?: number;
  height?: number;
}

interface ChartData {
  labels: any[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
    borderWidth: number;
    borderRadius: number;
    minBarLength: number;
  }[];
}

interface ChartOptions {
  maxBarThickness: number;
  responsive: boolean;
  maintainAspectRatio: boolean;
  plugins: {
    legend: {
      display: boolean;
    };
    tooltip: {
      callbacks: {
        title: (tooltipItems: any) => string;
        footer: (tooltipItems: any) => string;
        afterLabel: (tooltipItems: any) => string[];
        label: (context: any) => any;
      };
    };
  };
  scales: {
    y: {
      position: any;
      beginAtZero: boolean;
      suggestedMin: number;
      suggestedMax: number;
      title: {
        display: boolean;
      };
      ticks: {
        color: string;
        font: {
          size: number;
        };
      };
      grid: {
        color: string;
        borderColor: string;
      };
    };
    x: {
      beginAtZero: boolean;
      ticks: {
        color: string;
        font: {
          size: number;
        };
      };
      grid: {
        color: string;
        borderColor: string;
      };
    };
  };
}

const BarChart: React.FC<IProps> = (props) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const barChartRef = useRef<HTMLDivElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);
  const [data, setData] = useState<IBarChartData[]>([]);

  if (
    props.data.length !== data.length ||
    props.data.find(
      (_, i) =>
        props.data[i].time !== data[i].time ||
        props.data[i].longProfit !== data[i].longProfit ||
        props.data[i].shortProfit !== data[i].shortProfit ||
        props.data[i].count !== data[i].count,
    )
  ) {
    setData(props.data);
  }
  // @ts-expect-error
  const pixelsForZero: number = chartInstance.current?.scales?.x?._startPixel || 0;
  // @ts-expect-error
  const endPixel: number = chartInstance.current?.scales?.x?._endPixel || 0;

  const linePosition: number = (endPixel + pixelsForZero) / 2;

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      const backgroundColors = data
        .map((item) => item.shortProfit + item.longProfit)
        .map((value: number) => (value >= 0 ? '#30D960' : '#FF0000'));

      const max = Math.max(
        ...props.data.map((item) => Math.abs(item.shortProfit + item.longProfit)),
      );
      const chartData: ChartData = {
        labels: data.map((item) => item.date),
        datasets: [
          {
            label: 'Profit',
            // @ts-expect-error
            data: data.map((item) =>
              item.shortProfit + item.longProfit === 0 ? 'N/A' : item.shortProfit + item.longProfit,
            ),
            backgroundColor: backgroundColors,
            borderWidth: 0,
            borderRadius: 2,
            minBarLength: 10,
          },
        ],
      };

      const chartOptions: ChartOptions = {
        maxBarThickness: 10,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => '',
              footer: (tooltipItems) => {
                const index = tooltipItems[0].dataIndex;
                return `Number of trades: ${data[index].count}`;
              },
              label: function (context: any): number | string {
                let label: number | string = String(context.dataset.label) || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += Number(context.parsed.y).toFixed(2);
                }

                return (
                  label +
                  `$ (${(
                    data[context.dataIndex].shortPercent + data[context.dataIndex].longPercent
                  ).toFixed(2)}%)`
                );
              },
              afterLabel: (tooltipItems) => {
                const index = tooltipItems.dataIndex;
                const longProfit = data[index].longProfit.toFixed(2);
                const longPercent = data[index].longPercent.toFixed(2);
                const shortProfit = data[index].shortProfit.toFixed(2);
                const shortPercent = data[index].shortPercent.toFixed(2);
                return [
                  `Long: ${longProfit}$ (${longPercent}%)`,
                  `Short: ${shortProfit}$ (${shortPercent}%)`,
                ];
              },
            },
          },
        },
        scales: {
          y: {
            position: 'left',
            beginAtZero: false,
            suggestedMin: -max,
            suggestedMax: max,
            title: {
              display: false,
            },
            ticks: {
              color: 'white',
              font: {
                size: 8,
              },
            },
            grid: {
              color: 'rgba(255,255,255,.0)',
              borderColor: 'rgba(255,255,255,.05)',
            },
          },
          x: {
            beginAtZero: true,
            ticks: {
              color: 'white',
              font: {
                size: 8,
              },
            },
            grid: {
              color: 'rgba(255,255,255,.05)',
              borderColor: 'rgba(255,255,255,.05)',
            },
          },
        },
      };

      if (chartInstance.current) {
        chartInstance.current.data = chartData;
        chartInstance.current.options = chartOptions;
        chartInstance.current.update();
      } else {
        chartInstance.current = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: chartOptions,
        });
      }
    }
  }, [data]);

  return (
    <div ref={barChartRef} className="bar-chart">
      <canvas ref={chartRef} />
      {data.length === 25 && (
        <img
          className="bar-chart__line"
          src={lineVertical}
          style={{ marginLeft: linePosition }}
          alt=""
        />
      )}
    </div>
  );
};
export default BarChart;
