import { type FC, useState } from 'react';
import ComeBackArrow from '../../../../../../../assets/images/Strategy/ComeBackArrow.svg';
// import ApiTradingIcon from '../../../../../../../assets/icons/ApiTradingIcon.svg';
// import CustomAlertsIcon from '../../../../../../../assets/icons/CustomAlertsIcon.svg';
// import TgAlertsIcon from '../../../../../../../assets/icons/TgAlertsIcon.svg';
// import AiOptimizationIcon from '../../../../../../../assets/icons/AiOptimizationIcon.svg';
import NavigationArrow from '../../../../../../../assets/images/NavigationArrow.svg';
import ArrowDisabled from '../../../../../../../assets/images/ArrowDisabled.svg';
import './TgBotStyles.scss';
import TgBotAlerts from '../TgBotAlerts/TgBotAlerts';

interface IProps {
  onClose: () => void;
}
const TgBotSettings: FC<IProps> = (props) => {
  const [selectedSettings, setSelectedSettings] = useState<
    'ApiTrades' | 'CustomAlerts' | 'TgAlerts' | 'AiOptimization' | null
  >(null);
  if (selectedSettings === 'TgAlerts')
    return (
      <TgBotAlerts
        onClose={() => {
          setSelectedSettings(null);
        }}
      />
    );
  if (!selectedSettings)
    return (
      <div className="tg-bot-container">
        <div className="tg-bot-header">
          <div className="tg-bot-header__back" onClick={props.onClose}>
            <img src={ComeBackArrow} alt="<" /> Back
          </div>
        </div>
        <div className="tg-bot-title">Strategy automation</div>

        <div className={'tg-bot-nav-item tg-bot-nav-item_disabled'} onClick={() => {}}>
          <div className={'tg-bot-nav-item_name tg-bot-nav-item_name_disabled'}>
            {' '}
            <div className={'tg-bot-nav-item_imgApiTrade'}> </div>
            API trading
          </div>
          <img src={ArrowDisabled} alt="navigationArrow" />
        </div>
        <div className="tg-bot-title">Trades alerts</div>

        <div
          className={'tg-bot-nav-item'}
          onClick={() => {
            setSelectedSettings('TgAlerts');
          }}
        >
          <div className={'tg-bot-nav-item_name'}>
            {' '}
            <div className={'tg-bot-nav-item_imgTgAlert'}> </div>
            Telegram alerts
          </div>
          <img src={NavigationArrow} alt="navigationArrow" />
        </div>
        <div className={'tg-bot-nav-item tg-bot-nav-item_disabled'} onClick={() => {}}>
          <div className={'tg-bot-nav-item_name tg-bot-nav-item_name_disabled'}>
            {' '}
            <div className={'tg-bot-nav-item_imgCustomAlert'}> </div>
            Custom alerts
          </div>
          <img src={ArrowDisabled} alt="navigationArrow" />
        </div>
        <div className="tg-bot-title">Strategy optimization</div>

        <div className={'tg-bot-nav-item tg-bot-nav-item_disabled'} onClick={() => {}}>
          <div className={'tg-bot-nav-item_name tg-bot-nav-item_name_disabled'}>
            {' '}
            <div className={'tg-bot-nav-item_imgAiOptimization'}></div>
            ML/AI optimization
          </div>
          <img src={ArrowDisabled} alt="navigationArrow" />
        </div>
      </div>
    );
};

export default TgBotSettings;
