import { type FC } from 'react';
import { type IPassIconProps, passIconProps } from './passIconProps';

export const CalenderIcon: FC<IPassIconProps> = (props) => {
  return (
    <svg {...passIconProps(props)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        d="M2 6.66667C2 6.04669 2 5.7367 2.06815 5.48236C2.25308 4.79218 2.79218 4.25308 3.48236 4.06815C3.7367 4 4.04669 4 4.66667 4H11.3333C11.9533 4 12.2633 4 12.5176 4.06815C13.2078 4.25308 13.7469 4.79218 13.9319 5.48236C14 5.7367 14 6.04669 14 6.66667H2Z"
        fill="rgba(151, 71, 255, 1)"
        fillOpacity="0.25"
      />
      <rect
        x="2"
        y="4"
        width="12"
        height="10"
        rx="2"
        stroke="rgba(151, 71, 255, 1)"
        strokeWidth="1.2"
      />
      <path
        d="M4.66699 2L4.66699 4"
        stroke="rgba(151, 71, 255, 1)"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M11.333 2L11.333 4"
        stroke="rgba(151, 71, 255, 1)"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <rect x="4.66699" y="8" width="2.66667" height="1.33333" rx="0.5" fill="#0049FF" />
      <rect x="4.66699" y="10.6667" width="2.66667" height="1.33333" rx="0.5" fill="#0049FF" />
      <rect x="8.66699" y="8" width="2.66667" height="1.33333" rx="0.5" fill="#0049FF" />
      <rect x="8.66699" y="10.6667" width="2.66667" height="1.33333" rx="0.5" fill="#0049FF" />
    </svg>
  );
};
