import { type FC, useState } from 'react';
import '../UserProfile.scss';
import { type IProfileStrategy, type NavigationType } from '../interfaces/interfaces';
import PublishedStrategy from './PublishedStrategy';

const PublishedStrategies: FC<{
  strategies: IProfileStrategy[];
  updatePublishedStrategy: (id: string, data: Partial<IProfileStrategy>) => void;
}> = ({ strategies, updatePublishedStrategy }) => {
  const [strategyType, setStrategyType] = useState<NavigationType>('All');
  const filteredStrategies = strategies.filter(
    (strategy) =>
      strategyType === 'All' ||
      (strategyType === 'Public' && strategy.isPublic) ||
      (strategyType === 'Private' && !strategy.isPublic),
  );

  return (
    <div className="publishedStrategiesContainer">
      <div className="publishedStrategiesContainer_navigation">
        <div
          className={`publishedStrategiesContainer_navigation_item ${
            strategyType === 'All' && 'publishedStrategiesContainer_navigation_item_active'
          }`}
          onClick={() => {
            setStrategyType('All');
          }}
        >
          All
        </div>
        <div
          className={`publishedStrategiesContainer_navigation_item ${
            strategyType === 'Public' && 'publishedStrategiesContainer_navigation_item_active'
          }`}
          onClick={() => {
            setStrategyType('Public');
          }}
        >
          Public
        </div>
        <div
          className={`publishedStrategiesContainer_navigation_item ${
            strategyType === 'Private' && 'publishedStrategiesContainer_navigation_item_active'
          }`}
          onClick={() => {
            setStrategyType('Private');
          }}
        >
          Private
        </div>
      </div>
      <div className="publishedStrategiesContainer_list">
        {filteredStrategies.map((strategy) => (
          <PublishedStrategy
            key={strategy.id}
            strategy={strategy}
            updatePublishedStrategy={updatePublishedStrategy}
          />
        ))}
      </div>
    </div>
  );
};

export default PublishedStrategies;
