import { useState, type ChangeEvent, type FC, useEffect } from 'react';
import { useSelector } from 'redux/rootReducer';
import { useDispatch } from 'react-redux';
import { setBackTest, updateSelectedStrategy } from 'redux/strategy/reducer';
import { type IStrategySettings, OrderTypes, StrategyModes } from 'interfaces/IStrategy';
import { StrategyType } from 'enums/Strategy';
import { StrategySettingsHeader } from '../StrategySettingsHeader/StrategySettingsHeader';
import { StrategyTypeSettingsTable } from './StrategyTypeSettingsTable';
import { StrategySelector } from '../StrategySelector/StrategySelector';
import CustomNumberInput from '../CustomElements/CustomInput/CustomInput';
import GTumbler from 'components/GTumbler/GTumbler';
import { TrailingStopItem } from '../StrategyTrailingStopItem';
import { getActiveSettingsStrategy } from 'utils/strategy';
import './StrategyTypeSettings.module.scss';
import useWindowWidth from 'hooks/useWindowWidth';

interface IProps {
  strategyType?: 'long' | 'short';
  type: StrategyType;
  onClose: () => void;
}

const strategyTypesNames: Record<StrategyType, string> = {
  [StrategyType.FixedRisk]: 'Fixed Risk',
  [StrategyType.DCA]: 'DCA',
};

const strategyTypeOptions = [
  {
    id: OrderTypes.Market,
    name: 'Market',
  },
  {
    id: OrderTypes.Limit,
    name: 'Limit',
  },
];

export const StrategyTypeSettings: FC<IProps> = (props) => {
  const isMobileVersion = useWindowWidth() < 768;
  const { selectedStrategy: strategy } = useSelector((state) => state.strategy);
  const [currentStrategySettings, setCurrentStrategySettings] = useState(
    strategy.mode === StrategyModes.HEDGE ? strategy.strategies[props.strategyType] : strategy,
  );
  const activeStrategySettings = getActiveSettingsStrategy();
  const [activeOption, setActiveOption] = useState(strategyTypeOptions[0].id);
  const [trailingStopOpened, setTrailingStopOpened] = useState(
    activeStrategySettings.take_profits.some((e) => e[2] !== -1),
  );
  const [inputsLockDataTP, setInputsLockDataTP] = useState<boolean[]>(
    new Array(currentStrategySettings.settings.take_profits.length).fill(false),
  );
  const [inputsLockDataDCA, setInputsLockDataDCA] = useState<boolean[]>(
    new Array(currentStrategySettings.settings?.average?.orders?.length).fill(false),
  );
  const [locksHighlighted, setLocksHighlighted] = useState(false);
  const [fixedDistanceOpened, setFixedDistanceOpened] = useState(!!activeStrategySettings.trailing);
  const dispatch = useDispatch();
  const isError =
    currentStrategySettings?.settings?.take_profits.some((e) => e[0] < 0.01) ||
    currentStrategySettings?.settings?.average?.orders.some((e) => e[0] < 0.01);

  const changeAmountData = (
    e: ChangeEvent<HTMLInputElement>,
    dataType: 'take_profits' | 'average',
    defValue: number,
  ): void => {
    const value = parseInt(e.target.value);

    if (isNaN(value) || value < 1 || value > 10) return;
    const arr = (
      dataType === 'take_profits'
        ? currentStrategySettings.settings.take_profits
        : currentStrategySettings.settings.average.orders
    ).slice();
    if (value > arr.length) {
      for (let i = 0; i < value - arr.length; i++) {
        const val = [arr[arr.length - 1][0] + 1, defValue];
        if (arr[0].length === 3) val[2] = -1;
        // @ts-expect-error
        arr.push(val);
      }
    } else if (value < arr.length) {
      const diff = arr.length - value;
      arr.splice(arr.length - diff, diff);
    }
    const data = {
      ...currentStrategySettings,
      settings: {
        ...currentStrategySettings.settings,
        [dataType]:
          dataType === 'take_profits'
            ? arr
            : {
                ...currentStrategySettings.settings.average,
                orders: arr,
              },
      },
    };
    setCurrentStrategySettings(data);
  };

  const changeData = (
    e: string,
    takeIndex: number,
    dataType: 'take_profits' | 'average',
    dataIndex: number,
    min: number,
    max: number,
  ): void => {
    let value = parseFloat(e);
    const arr = (
      dataType === 'take_profits'
        ? currentStrategySettings.settings.take_profits
        : currentStrategySettings.settings.average.orders
    ).slice();
    const inputsLockData = dataType === 'take_profits' ? inputsLockDataTP : inputsLockDataDCA;
    const firstFalseIndex = inputsLockData.findIndex((value, index) => {
      return !value && index !== takeIndex;
    });
    const falseIndexes = inputsLockData.reduce((acc, value, index) => {
      if (value || index === takeIndex) {
        acc.push(index);
      }
      return acc;
    }, []);
    const falseIndexesNoTakeIndex = inputsLockData.reduce((acc, value, index) => {
      if (value && index !== takeIndex) {
        acc.push(index);
      }
      return acc;
    }, []);
    const sumOfFalseIndexValuesNoTakeIndex = falseIndexesNoTakeIndex.reduce(
      (acc: number, index) => {
        if (index >= 0 && index < arr.length) {
          acc += Number(arr[index][1]);
        }
        return acc;
      },
      0,
    );
    const sumOfFalseIndexValues = falseIndexes.reduce((acc: number, index) => {
      if (index >= 0 && index < arr.length) {
        acc += Number(arr[index][1]);
      }
      return acc;
    }, 0);
    const newMaxValue = 100 - sumOfFalseIndexValuesNoTakeIndex;
    if (isNaN(value) || value < min) {
      value = min;
    } else if (value > max) {
      value = newMaxValue;
    }

    const modifiedElem = arr[takeIndex].slice();
    if (!modifiedElem) return;

    modifiedElem[dataIndex] = value;
    arr[takeIndex] = modifiedElem as [number, number, number];
    if (dataType === 'take_profits' && dataIndex === 1) {
      arr.forEach((e: number[], i: number) => {
        if (i === takeIndex) return;
        e = e.slice();

        if (e[1] < 0) {
          e[1] = 0;
        } else {
          //   @ts-expect-error
          const sum = arr.reduce((a: number, b: number[]) => a + b[1], 0);

          if (sum > 100) {
            if (firstFalseIndex === -1 || sumOfFalseIndexValues > 100) {
              return;
            }
            if (firstFalseIndex !== -1) {
              if (!inputsLockData[i]) {
                e[1] -= sum - 100;
                if (e[1] < 0) e[1] = 0;
              }
            }
          } else if (sum < 100) {
            if (firstFalseIndex === -1 || sumOfFalseIndexValues > 100) {
              return;
            }
            if (!inputsLockData[i]) {
              e[1] += 100 - sum;
            }
          }
        }

        arr[i] = e as [number, number, number];
      });
    }
    setCurrentStrategySettings({
      ...currentStrategySettings,
      settings: {
        ...currentStrategySettings.settings,
        [dataType]:
          dataType === 'take_profits'
            ? arr
            : {
                ...currentStrategySettings.settings.average,
                orders: arr,
              },
      },
    });
  };

  const updateTrailingData = (trailing: IStrategySettings['trailing']): void => {
    setCurrentStrategySettings({
      ...currentStrategySettings,
      settings: {
        ...currentStrategySettings.settings,
        trailing,
      },
    });
  };

  const updateTrailingPercent = (
    e: ChangeEvent<HTMLInputElement>,
    key: keyof IStrategySettings['trailing'],
  ): void => {
    if (e.target.value === '') {
      updateTrailingData({
        ...currentStrategySettings.settings.trailing,
        [key]: 0.1,
      });
    } else {
      let stopPercent = parseFloat(e.target.value);

      if (stopPercent > 100) stopPercent = 100;
      if (stopPercent < 0) stopPercent = 0;

      updateTrailingData({
        ...currentStrategySettings.settings.trailing,
        [key]: stopPercent,
      });
    }
  };
  const highLightLocks = (): void => {
    setLocksHighlighted(true);
    setTimeout(() => {
      setLocksHighlighted(false);
    }, 1000);
  };
  useEffect(() => {
    if (!trailingStopOpened) {
      const takeProfits = currentStrategySettings.settings.take_profits.map((e) => [
        e[0],
        e[1],
        -1,
      ]);
      setCurrentStrategySettings({
        ...currentStrategySettings,
        settings: {
          ...currentStrategySettings.settings,
          take_profits: takeProfits as [number, number, number][],
        },
      });
    }
  }, [trailingStopOpened]);
  useEffect(() => {
    const inputsNumber = currentStrategySettings.settings.take_profits.length;
    if (inputsLockDataTP.length > inputsNumber) {
      const newArr = [...inputsLockDataTP];
      newArr.pop();
      setInputsLockDataTP(newArr);
    }
    if (inputsNumber > inputsLockDataTP.length) {
      const newArr = [...inputsLockDataTP];
      newArr.push(false);
      setInputsLockDataTP(newArr);
    }
  }, [currentStrategySettings.settings.take_profits]);
  useEffect(() => {
    const inputsNumber = currentStrategySettings.settings?.average?.orders?.length;
    if (inputsLockDataDCA.length > inputsNumber) {
      const newArr = [...inputsLockDataDCA];
      newArr.pop();
      setInputsLockDataDCA(newArr);
    }
    if (inputsNumber > inputsLockDataDCA.length) {
      const newArr = [...inputsLockDataDCA];
      newArr.push(false);
      setInputsLockDataDCA(newArr);
    }
  }, [currentStrategySettings?.settings?.average?.orders]);
  return (
    <>
      <div className="strategy-type-settings">
        <StrategySettingsHeader name={strategyTypesNames[props.type]} onClose={props.onClose} />

        <div className="strategy-type-settings__content">
          {props.type === StrategyType.FixedRisk && (
            <div className="strategy-type-settings__content__elem">
              <h4>Stop loss length</h4>
              <CustomNumberInput
                step={0.1}
                value={currentStrategySettings.settings.stop_loss}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value > 100) return;
                  setCurrentStrategySettings({
                    ...currentStrategySettings,
                    settings: {
                      ...currentStrategySettings.settings,
                      stop_loss: value,
                    },
                  });
                }}
                onBlur={() => {
                  if (isNaN(currentStrategySettings.settings.stop_loss)) {
                    setCurrentStrategySettings({
                      ...currentStrategySettings,
                      settings: {
                        ...currentStrategySettings.settings,
                        stop_loss: 1,
                      },
                    });
                  }
                }}
              />
            </div>
          )}
          {props.type === StrategyType.DCA && (
            <>
              <div className="strategy-type-settings__content__elem">
                <h4>Amount of averages</h4>
                <CustomNumberInput
                  showMobileArrows={isMobileVersion}
                  step={1}
                  value={currentStrategySettings.settings.average.orders.length}
                  onChange={(e) => {
                    changeAmountData(e, 'average', 1.5);
                  }}
                />
              </div>
              <StrategyTypeSettingsTable
                list={currentStrategySettings.settings.average.orders}
                prefix="DCA "
                secondColumn="Factor"
                changeData={(e, takeIndex, dataIndex, min, max) => {
                  changeData(e, takeIndex, 'average', dataIndex, min, max);
                }}
                lockData={inputsLockDataDCA}
                toggleLockValue={(index) => {
                  const newArr = [...inputsLockDataDCA];
                  newArr[index] = !newArr[index];
                  setInputsLockDataDCA(newArr);
                }}
                locksHighlighted={locksHighlighted}
                highLightLocks={highLightLocks}
              />
            </>
          )}

          <div className="strategy-type-settings__content__elem">
            <h4>Amount of takes</h4>
            <CustomNumberInput
              value={currentStrategySettings.settings.take_profits.length}
              step={1}
              showMobileArrows={isMobileVersion}
              onChange={(e) => {
                const value =
                  100 - currentStrategySettings.settings.take_profits.reduce((a, b) => a + b[1], 0);

                changeAmountData(e, 'take_profits', value < 0 ? 0 : value);
              }}
            />
          </div>
          <StrategyTypeSettingsTable
            prefix="TP "
            secondColumn="Quantity(%)"
            list={currentStrategySettings.settings.take_profits}
            changeData={(e, takeIndex, dataIndex, min, max) => {
              changeData(e, takeIndex, 'take_profits', dataIndex, min, max);
            }}
            lockData={inputsLockDataTP}
            toggleLockValue={(index) => {
              const newArr = [...inputsLockDataTP];
              newArr[index] = !newArr[index];
              setInputsLockDataTP(newArr);
            }}
            locksHighlighted={locksHighlighted}
            highLightLocks={highLightLocks}
          />
          {props.type === StrategyType.DCA && (
            <div className="strategy-type-settings__content__elem">
              <h4 style={{ fontSize: '13px' }}>Type of averaging orders</h4>
              <StrategySelector
                width="100px"
                options={strategyTypeOptions}
                activeOption={activeOption}
                onChange={(e) => {
                  setActiveOption(e);
                }}
              />
            </div>
          )}
          <div className="strategy-type-settings__content__elem">
            <h4>Trailing-stop by levels</h4>
            <GTumbler
              name={'Trailing stop'}
              size="large"
              onToggle={() => {
                setTrailingStopOpened(!trailingStopOpened);
              }}
              checked={trailingStopOpened}
            />
          </div>
          {trailingStopOpened && (
            <>
              <div className="strategy-type-settings__content__elem">
                <div className="strategy-type-settings__content__elem__trailingLevelHeader">
                  Trigger level
                </div>
                <div className="strategy-type-settings__content__elem__trailingLevelHeader">
                  Move trailing-stop to
                </div>
              </div>
              {currentStrategySettings.settings.take_profits.map((e, i) => (
                <div key={i} className="strategy-type-settings__content__elem">
                  <TrailingStopItem
                    name={`TP ${i + 1}`}
                    itemNumber={i}
                    onChange={(data) => {
                      const takeProfits = currentStrategySettings.settings.take_profits.slice();
                      const elem = takeProfits[i]?.slice();
                      if (!elem) return;
                      elem[2] = data;
                      takeProfits[i] = elem as [number, number, number];
                      setCurrentStrategySettings({
                        ...currentStrategySettings,
                        settings: {
                          ...currentStrategySettings.settings,
                          take_profits: takeProfits,
                        },
                      });
                    }}
                  />
                </div>
              ))}
            </>
          )}
          <div className="strategy-type-settings__content__elem">
            <h4>Fixed distance trailing-stop</h4>
            <GTumbler
              name={'Trailing stop'}
              size="large"
              onToggle={() => {
                if (fixedDistanceOpened) {
                  updateTrailingData(undefined);
                  setFixedDistanceOpened(false);
                } else {
                  updateTrailingData({
                    stop_percent: 1,
                    activate_percent: 0,
                  });
                  setFixedDistanceOpened(true);
                }
              }}
              checked={fixedDistanceOpened}
            />
          </div>
          {fixedDistanceOpened && (
            <>
              <div className="strategy-type-settings__content__elem">
                <div>Activation percent</div>
                <CustomNumberInput
                  min={0.1}
                  max={100}
                  value={currentStrategySettings.settings.trailing?.activate_percent ?? 0}
                  step={0.1}
                  onChange={(e) => {
                    updateTrailingPercent(e, 'activate_percent');
                  }}
                />
              </div>
              <div className="strategy-type-settings__content__elem">
                <div>Trailing-stop distance</div>
                <CustomNumberInput
                  min={0.1}
                  max={100}
                  value={currentStrategySettings.settings.trailing?.stop_percent ?? 1}
                  step={0.1}
                  onChange={(e) => {
                    updateTrailingPercent(e, 'stop_percent');
                  }}
                />
              </div>
            </>
          )}
        </div>
        <button
          style={{
            opacity: isError ? 0.5 : 1,
          }}
          className="strategy-type-settings__saveButton"
          onClick={() => {
            if (!isError) {
              if (strategy.mode === StrategyModes.HEDGE) {
                dispatch(
                  updateSelectedStrategy({
                    strategies: {
                      ...strategy.strategies,
                      [props.strategyType]: {
                        ...strategy.strategies[props.strategyType],
                        settings: currentStrategySettings.settings,
                      },
                    },
                  }),
                );
              } else {
                dispatch(updateSelectedStrategy({ settings: currentStrategySettings.settings }));
              }
              dispatch(setBackTest(true));
            }
          }}
        >
          Save
        </button>
      </div>
    </>
  );
};
