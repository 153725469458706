import { type ITradeData } from './../../interfaces/ITrade';
import { type IStatistics } from 'interfaces/IStatistics';
import {
  type SavedIStrategy,
  type SavedIHedgeStrategy,
  type IBaseStrategySettings,
} from './../../interfaces/IStrategy';
import {
  type IStrategyAllData,
  type IMergedStrategy,
} from '../../services/api/Strategy/types/StrategyResponse';
import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { IStrategyState } from './types';
import { apiStrategy } from '../../services/api/Strategy/ApiStrategy';
import { type IHedgeStrategy, type IStrategy } from '../../interfaces/IStrategy';
import { isOneWayModeStrategy } from 'utils/strategy';
import { type IChartConfig } from '../../interfaces/IChart';

const initialState: IStrategyState = {
  strategies: [],
  mergedStrategies: [],
  editStrategies: [],
  isBackTest: false,
  selectedStrategyId: null,
  selectedStrategy: null,
  selectedMergedStrategy: null,
  selectedMergedStrategyIndex: 0,
  mergedStrategyData: null,
  savedStrategies: [],
};

export const fetchStrategyData = createAsyncThunk<
  IStrategy | IHedgeStrategy,
  string,
  { rejectValue: string }
>('strategy/get', (id) => {
  return apiStrategy.getStrategy(id);
});

const strategyReducer = createSlice({
  name: 'strategies',
  initialState,
  reducers: {
    setStrategies: (state, action: PayloadAction<Array<IStrategyAllData>>) => {
      const strategies = action.payload.slice();

      strategies.forEach((val, index) => {
        const strategy = { ...val };
        const matches = action.payload
          .filter((res) => {
            return res.name === strategy.name;
          })
          .sort((a, b) => a.createdAt - b.createdAt);

        if (matches.length > 1) {
          const index = matches.findIndex((e) => e._id === strategy._id) + 1;
          strategy.name = `${strategy.name} (${index})`;
        }
        strategies[index] = strategy;
      });

      state.strategies = strategies;
    },
    setMergedStrategies: (state, action: PayloadAction<Array<IMergedStrategy>>) => {
      state.mergedStrategies = action.payload;
    },

    updateStrategyInStrategies: (state, action: PayloadAction<Partial<IStrategyAllData>>) => {
      const strategyId = state.strategies.findIndex((e) => e._id === action.payload._id);
      if (strategyId !== -1) {
        state.strategies[strategyId] = {
          ...state.strategies[strategyId],
          ...action.payload,
        };
      }
    },
    updateMergedStrategyMergedInStrategies: (
      state,
      action: PayloadAction<Partial<IStrategyAllData>>,
    ) => {
      const strategyId = state.mergedStrategies.findIndex((e) => e._id === action.payload._id);
      if (strategyId !== -1) {
        state.mergedStrategies[strategyId] = {
          ...state.mergedStrategies[strategyId],
          ...action.payload,
        };
      }
    },
    addStrategy: (state, action: PayloadAction<IStrategyAllData>) => {
      state.strategies.push(action.payload);
    },
    removeStrategy: (state, action: PayloadAction<string>) => {
      const id = state.strategies.findIndex((strategy) => strategy._id === action.payload);

      if (id >= 0) {
        state.strategies.splice(id, 1);
      }
    },
    openStrategy: (state, action: PayloadAction<number>) => {
      if (state.editStrategies.length < 3) {
        state.editStrategies.push(action.payload);
      }
    },
    closeStrategy: (state, action: PayloadAction<number>) => {
      const elementId = state.editStrategies.findIndex((id) => id === action.payload);

      if (elementId >= 0) {
        state.editStrategies.splice(elementId, 1);
      }
    },
    setBackTest: (state, action: PayloadAction<boolean>) => {
      state.isBackTest = action.payload;
    },
    updateSelectedStrategy: (state, action: PayloadAction<Partial<IStrategy | IHedgeStrategy>>) => {
      if (action.payload._id) {
        state.selectedStrategyId = action.payload._id;
      }
      // @ts-expect-error
      state.selectedStrategy = { ...state.selectedStrategy, ...action.payload };
    },
    updateSelectedMergedStrategy: (state, action: PayloadAction<Partial<IMergedStrategy>>) => {
      if (action.payload._id) {
        state.selectedStrategyId = action.payload._id;
      }
      state.selectedMergedStrategy = {
        ...((state.selectedMergedStrategy ?? {}) as IMergedStrategy),
        ...action.payload,
      };
    },
    removeSelectedStrategy: (state) => {
      state.selectedStrategy = null;
      state.selectedMergedStrategy = null;
      state.selectedStrategyId = null;
    },
    setSelectedStrategyId: (state, action: PayloadAction<string | null>) => {
      if (
        state.strategies?.findIndex((strategy) => strategy._id === action.payload) === -1 &&
        state?.mergedStrategyData?._id !== action.payload
      ) {
        state.selectedMergedStrategy = null;
        // state.mergedStrategyData = null;
      }
      state.selectedStrategyId = action.payload;
      state.selectedStrategy = null;
    },
    setSelectedMergedStrategyIndex: (state, action: PayloadAction<number>) => {
      state.selectedMergedStrategyIndex = action.payload;
    },

    setMergedStrategyData: (
      state,
      action: PayloadAction<IStrategyState['mergedStrategyData'] | null>,
    ) => {
      state.mergedStrategyData = action.payload;
    },
    saveSelectedStrategy: (
      state,
      action: PayloadAction<{
        stats: IStatistics;
        trades?: ITradeData[];
        trades0?: ITradeData[];
        trades1?: ITradeData[];
        chartConfig: IChartConfig;
      }>,
    ) => {
      if (isOneWayModeStrategy(state.selectedStrategy)) {
        const savedStrategy: SavedIStrategy = {
          ...state.selectedStrategy,
          stats: action.payload.stats,
          trades: action.payload.trades,
          chartConfig: action.payload.chartConfig,
        };
        if (
          !state.savedStrategies.some((strategy) => strategy._id === state?.selectedStrategy?._id)
        ) {
          state.savedStrategies = [savedStrategy, ...state.savedStrategies];
        } else {
          const index = state.savedStrategies.findIndex(
            (strategy) => strategy._id === state?.selectedStrategy?._id,
          );
          state.savedStrategies[index] = savedStrategy;
        }
      } else {
        const savedStrategy: SavedIHedgeStrategy = {
          ...state.selectedStrategy,
          stats: action.payload.stats,
          shortTrades: action.payload?.trades0,
          longTrades: action.payload?.trades1,
          chartConfig: action.payload.chartConfig,
        };
        if (
          !state.savedStrategies.some((strategy) => strategy._id === state?.selectedStrategy?._id)
        ) {
          state.savedStrategies = [savedStrategy, ...state.savedStrategies];
        } else {
          const index = state.savedStrategies.findIndex(
            (strategy) => strategy._id === state?.selectedStrategy?._id,
          );
          state.savedStrategies[index] = savedStrategy;
        }
      }
    },
    updateSavedStrategy: (
      state,
      action: PayloadAction<Partial<SavedIStrategy | SavedIHedgeStrategy>>,
    ) => {
      // @ts-expect-error
      state.savedStrategies = state.savedStrategies.map((strategy) => {
        if (strategy._id === action.payload._id) {
          return { ...strategy, ...action.payload };
        }
        return strategy;
      });
    },
    deleteSavedtrategy: (state, action: PayloadAction<string>) => {
      state.savedStrategies = state.savedStrategies.filter(
        (strategy) => strategy._id !== action.payload,
      );
    },
    updateStrategyProperty: (state, action: PayloadAction<Partial<IBaseStrategySettings>>) => {
      if (state.selectedStrategy) {
        state.selectedStrategy.property = { ...state.selectedStrategy.property, ...action.payload };
      }
    },
    strategyManagerOff: (state) => {
      state.editStrategies = [];
      state.selectedStrategyId = null;
      state.selectedStrategy = null;
      state.selectedMergedStrategy = null;
      state.mergedStrategyData = null;
    },
  },
  extraReducers: {
    [fetchStrategyData.fulfilled.toString()]: (state, action: PayloadAction<IStrategy>) => {
      state.selectedStrategy = action.payload;
      state.selectedStrategyId = action.payload._id;
    },
  },
});

export const {
  setStrategies,
  setMergedStrategies,
  addStrategy,
  removeStrategy,
  openStrategy,
  closeStrategy,
  setBackTest,
  updateSelectedStrategy,
  updateSelectedMergedStrategy,
  removeSelectedStrategy,
  setSelectedStrategyId,
  setMergedStrategyData,
  saveSelectedStrategy,
  updateSavedStrategy,
  deleteSavedtrategy,
  updateStrategyProperty,
  updateStrategyInStrategies,
  updateMergedStrategyMergedInStrategies,
  strategyManagerOff,
  setSelectedMergedStrategyIndex,
} = strategyReducer.actions;

export default strategyReducer.reducer;
