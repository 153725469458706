import { createSlice } from '@reduxjs/toolkit';
import { type XAxisStyle } from '@basttyy/klinecharts';
import { type IRootState } from '../rootReducer';
import { getUpdatePath } from '../updateProperty';

const initialState: XAxisStyle = {
  show: true,
  size: 'auto',
  axisLine: {
    show: true,
    color: '#888888',
    size: 1,
  },
  tickText: {
    show: true,
    color: '#D9D9D9',
    family: 'Helvetica Neue',
    weight: 'normal',
    size: 12,
    marginStart: 4,
    marginEnd: 4,
  },
  tickLine: {
    show: true,
    size: 1,
    length: 3,
    color: '#888888',
  },
};

const chartXAxisSettingsReducer = createSlice({
  name: 'chartXAxisSettings',
  initialState,
  reducers: {
    updateXAxisProperty: getUpdatePath<XAxisStyle>(),
    setInititalXAxisSettings: (state, action) => {
      return action.payload;
    },
    refreshXAxisSettings: (state) => {
      return initialState;
    },
  },
});
export const selectChartXAxisSettings = (state: IRootState): XAxisStyle => state.chartXAxisSettings;

export const { updateXAxisProperty, refreshXAxisSettings, setInititalXAxisSettings } =
  chartXAxisSettingsReducer.actions;

export default chartXAxisSettingsReducer.reducer;
