import { useSelector } from 'redux/rootReducer';
import './DemoBalance.css';

const DemoBalance = (): JSX.Element => {
  const netProfit = useSelector((state) => state.chart.stats.net_profit);
  const strategyDeposit = useSelector(
    (state) => state.strategy?.selectedStrategy?.property?.deposit,
  );
  const mergeDeposit = useSelector((state) => state.strategy?.selectedMergedStrategy?.balance);
  if (netProfit && (strategyDeposit || mergeDeposit)) {
    return (
      <div className="demo-balance">
        <div className="demo-balance-title">Demo:</div>
        <div>
          <div className="demo-balance-subtitle">Balance</div>
          <div> {(netProfit[0] + (strategyDeposit || mergeDeposit)).toFixed(2)}$</div>
        </div>
      </div>
    );
  }
};

export default DemoBalance;
