import { useState, type FC } from 'react';
import './CustomMultiLineDropDownXL.scss';
import Arrow from 'assets/images/Strategy/DropDownTriangle.svg';
interface IProps {
  options: {
    id: unknown;
    name?: string;
    desc?: string;
  }[];
  activeOption: {
    id: unknown;
    name?: string;
    desc?: string;
  };
  onChange: (id: unknown) => void;
  nameFontWeight?: string;
  largeList?: boolean;
  numbersOnly?: boolean;
  height?: number;
  disabled?: boolean;
}
const CustomDropDownXL: FC<IProps> = ({
  options,
  onChange,
  activeOption,
  nameFontWeight,
  largeList,
  numbersOnly,
  height,
  disabled,
}) => {
  const [isOpened, setOpened] = useState(false);

  const handleClick = (e, option): void => {
    if (disabled) return;
    e.stopPropagation();
    setOpened(!isOpened);
    onChange(option.id);
  };
  return (
    <div
      className={'custom-dropdownXl' + (numbersOnly ? ' custom-dropdownXl__numbersOnly' : '')}
      tabIndex={0}
      onBlur={() => {
        setOpened(false);
      }}
      style={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
    >
      <div
        className={
          'custom-dropdownXl__selected' +
          (numbersOnly ? ' custom-dropdownXl__selected__numbersOnly' : '')
        }
        style={{
          height: height && `${height}px`,
          maxHeight: height && `${height}px`,
          minHeight: height && `${height}px`,
        }}
        onClick={() => {
          if (disabled) return;
          setOpened(!isOpened);
        }}
      >
        <div style={{ fontWeight: nameFontWeight }} className="custom-dropdownXl__selected_name">
          {activeOption.name}
        </div>
        <div
          style={{
            fontSize: activeOption.name ? '' : '12px',
          }}
          className="custom-dropdownXl__selected_desc"
        >
          {activeOption.desc}
        </div>
        <img
          className={
            'custom-dropdownXl__selected_arrow' +
            (isOpened ? ' custom-dropdownXl__selected_arrowOpened' : '')
          }
          src={Arrow}
          alt=">"
        />
      </div>

      {isOpened && largeList && (
        <div className="custom-dropdownXl__itemsContainer">
          {options.map((option, index) => (
            <div
              style={{
                borderTopLeftRadius: index === 0 ? '4px' : '',
                borderTopRightRadius: index === 0 ? '4px' : '',
              }}
              className={
                'custom-dropdownXl__item' +
                (numbersOnly ? ' custom-dropdownXl__item__numbersOnly' : '')
              }
              key={index}
              onClick={() => {
                if (disabled) return;
                setOpened(false);
                onChange(option.id);
              }}
            >
              <div style={{ fontWeight: nameFontWeight }} className="custom-dropdownXl__item_name">
                {option.name}
              </div>
              <div
                style={{ fontSize: option.name ? '' : '12px' }}
                className="custom-dropdownXl__item_desc"
              >
                {option.desc}
              </div>
            </div>
          ))}{' '}
        </div>
      )}
      {isOpened &&
        !largeList &&
        options.map((option, index) => (
          <div
            style={{
              height: height && `${height}px`,
              maxHeight: height && `${height}px`,
              minHeight: height && `${height}px`,
              borderTopLeftRadius: index === 0 ? '4px' : '',
              borderTopRightRadius: index === 0 ? '4px' : '',
              borderBottomLeftRadius: index === options.length - 1 ? '4px' : '',
              borderBottomRightRadius: index === options.length - 1 ? '4px' : '',
            }}
            className={
              'custom-dropdownXl__item' +
              (numbersOnly ? ' custom-dropdownXl__item__numbersOnly' : '')
            }
            key={index}
            onClick={(e) => {
              if (disabled) return;
              handleClick(e, option);
            }}
          >
            <div style={{ fontWeight: nameFontWeight }} className="custom-dropdownXl__item_name">
              {option.name}
            </div>
            <div
              style={{ fontSize: option.name ? '' : '12px' }}
              className="custom-dropdownXl__item_desc"
            >
              {option.desc}
            </div>
          </div>
        ))}
    </div>
  );
};

export default CustomDropDownXL;
