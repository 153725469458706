import type { RouteObject } from 'react-router-dom';

import ProtectedLayout from '../layouts/ProtectedLayout';

import HomePage from '../pages/HomePage';
import Auth from '../pages/Auth/Auth';
import MenuPage from '../pages/MenuPage';
// import { Profile } from '../pages/Profile';
import { StrategyViewer } from '../pages/UserProfile/components/StrategyViewer';
import UserProfile from 'pages/UserProfile/UserProfile';
import { Admin } from '../pages/Admin/Admin';

const routes: Array<RouteObject> = [
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/profile/:id',
    element: <UserProfile />,
  },
  {
    path: '/strategy/:id',
    element: <StrategyViewer />,
  },
  {
    path: '/',
    element: <ProtectedLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/menu',
        element: <MenuPage />,
      },
      {
        path: '/admin',
        element: <Admin />,
      },
    ],
  },
];

export default routes;
