import { type FC, useState } from 'react';
import styles from '../TickerSelector.module.css';
import { TickerSelectorCloseIcon } from '../../../assets/icons/TickerSelectorCloseIcon';
import CustomIndex from '../../../assets/images/CustomIndexIcon.svg';
import NavigationArrow from '../../../assets/images/NavigationArrow.svg';
import { parseTicker } from '../../../utils/parseTicker';
import {
  MODE_CREATING_INDEX_STEP_ONE,
  MODE_LIST_OF_INDEX_TICKERS,
  type TickerSelectorPageType,
} from '../types';
import { useSelector } from '../../../redux/rootReducer';
import { selectCustomTickers } from '../../../redux/customTicker/reducer';
import { updateChart } from '../../../redux/chart/reducer';
import { useDispatch } from 'react-redux';

interface IProps {
  setTickerSelectorState: (page: TickerSelectorPageType) => void;
  onClose: () => void;
  selectedChartId: string;
}

export const SearchingTicker: FC<IProps> = (props) => {
  const [search, setSearch] = useState('');
  const createdTickers = useSelector(selectCustomTickers);
  const dispatch = useDispatch();
  const tickers = useSelector((state) => state.customTickers.tickerIcons);
  return (
    <>
      <div className={styles.tickerSelectorHeader}>
        <span>Ticker Search</span>
        <TickerSelectorCloseIcon
          className={styles.tickerSelectorHeaderClose}
          onClick={() => {
            props.onClose();
          }}
        />
      </div>
      <input
        type="text"
        placeholder="Search"
        onChange={(e) => {
          setSearch(e.target.value.replace(/\s/g, ''));
        }}
      />
      <div
        onClick={() => {
          if (createdTickers.length === 0) {
            props.setTickerSelectorState(MODE_CREATING_INDEX_STEP_ONE);
          } else {
            props.setTickerSelectorState(MODE_LIST_OF_INDEX_TICKERS);
          }
        }}
        className={styles.tickerSelectorSearchingHeader}
      >
        <div>
          <img src={CustomIndex} alt="customIndex" />
          Custom index
        </div>
        <img src={NavigationArrow} alt="navigationArrow" />
      </div>
      <div className={styles.tickerSelectorContent}>
        {tickers.map(({ exchange, baseAsset, quoteAsset, since }, key) => {
          const name = parseTicker(exchange, `${baseAsset}${quoteAsset}`);
          return search === '' || name.includes(search.toUpperCase()) ? (
            <div
              key={key}
              onClick={() => {
                dispatch(
                  updateChart({
                    id: props.selectedChartId,
                    data: {
                      ticker: {
                        type: 'default',
                        baseAsset,
                        quoteAsset,
                      },
                      exchange,
                    },
                  }),
                );
                props.onClose();
              }}
            >
              <span>
                <img
                  className={styles.tickerSelectorContentImage}
                  src={`${process.env.PUBLIC_URL}/coins/${baseAsset}.png`}
                  alt=""
                />
                <span>
                  {name} | From {new Date(since).toLocaleDateString()}
                </span>
              </span>
            </div>
          ) : null;
        })}
      </div>
    </>
  );
};
