import { useEffect, useRef } from 'react';

export const useFakeScrollBar = (
  contentWrapperRef: React.RefObject<HTMLDivElement>,
): React.RefObject<HTMLDivElement> => {
  const fakeScrollBarRef = useRef<HTMLDivElement>(null);

  const syncFakeScrollBar = (): void => {
    const contentWrapper = contentWrapperRef.current;
    const fakeScrollBar = fakeScrollBarRef.current;

    if (contentWrapper && fakeScrollBar) {
      const contentWidth = contentWrapper.scrollWidth;
      const visibleWidth = contentWrapper.clientWidth;

      const scrollBarWidth = (visibleWidth / contentWidth) * visibleWidth;
      if (contentWidth === visibleWidth) {
        fakeScrollBar.style.display = 'none';
      } else {
        fakeScrollBar.style.display = 'block';
        fakeScrollBar.style.maxWidth = `${scrollBarWidth}px !important`;
        fakeScrollBar.style.width = `${scrollBarWidth}px`;

        const scrollPercentage = contentWrapper.scrollLeft / (contentWidth - visibleWidth);
        const scrollBarLeft = scrollPercentage * (visibleWidth - scrollBarWidth);
        fakeScrollBar.style.left = `${scrollBarLeft}px`;
      }
    }
  };

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    if (contentWrapper) {
      contentWrapper.scrollLeft = 0.01;

      setTimeout(() => {
        syncFakeScrollBar();
      }, 100);
      contentWrapper.scrollLeft = 0;
    }
  }, [contentWrapperRef.current]);

  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;
    if (contentWrapper) {
      contentWrapper.scrollLeft = 0.01;

      setTimeout(() => {
        syncFakeScrollBar();
      }, 100);
      contentWrapper.scrollLeft = 0;
    }
  }, [contentWrapperRef?.current?.clientWidth]);
  useEffect(() => {
    const contentWrapper = contentWrapperRef.current;

    const handleScroll = (): void => {
      requestAnimationFrame(syncFakeScrollBar);
    };

    const handleWheel = (event: WheelEvent): void => {
      if (contentWrapper) {
        contentWrapper.scrollLeft += event.deltaY;
        syncFakeScrollBar();
      }
    };

    if (contentWrapper) {
      contentWrapper.addEventListener('scroll', handleScroll);
      contentWrapper.addEventListener('wheel', handleWheel);
    }
    window.addEventListener('resize', syncFakeScrollBar);

    return () => {
      if (contentWrapper) {
        contentWrapper.removeEventListener('scroll', handleScroll);
        contentWrapper.removeEventListener('wheel', handleWheel);
      }
      window.removeEventListener('resize', syncFakeScrollBar);
    };
  }, []);

  return fakeScrollBarRef;
};

// OLD working variant
// const contentWrapperRef = useRef(null) as MutableRefObject<HTMLDivElement>;
// const fakeScrollBarRef = useRef(null);

// const syncFakeScrollBar = (): void => {
//   const contentWrapper = contentWrapperRef.current;
//   const fakeScrollBar = fakeScrollBarRef.current;

//   if (contentWrapper && fakeScrollBar) {
//     const contentWidth = contentWrapper.scrollWidth;
//     const visibleWidth = contentWrapper.clientWidth;

//     const scrollBarWidth = (visibleWidth / contentWidth) * visibleWidth;
//     if (contentWidth === visibleWidth) {
//       fakeScrollBar.style.display = 'none';
//     } else {
//       fakeScrollBar.style.display = 'block';
//       fakeScrollBar.style.maxWidth = `${scrollBarWidth}px !important`;
//       fakeScrollBar.style.width = `${scrollBarWidth}px`;

//       const scrollPercentage = contentWrapper.scrollLeft / (contentWidth - visibleWidth);
//       const scrollBarLeft = scrollPercentage * (visibleWidth - scrollBarWidth);
//       fakeScrollBar.style.left = `${scrollBarLeft}px`;
//     }
//   }
// };

// useEffect(() => {
//   const contentWrapper = contentWrapperRef.current;
//   if (contentWrapper) {
//     contentWrapper.scrollLeft = 0.01;

//     setTimeout(() => {
//       syncFakeScrollBar();
//     }, 100);
//     contentWrapper.scrollLeft = 0;
//   }
// }, [contentWrapperRef?.current?.clientWidth]);

// useLayoutEffect(() => {
//   const contentWrapper = contentWrapperRef.current;

//   const handleScroll = (): void => {
//     requestAnimationFrame(syncFakeScrollBar);
//   };

//   const handleWheel = (event: WheelEvent): void => {
//     if (contentWrapper) {
//       contentWrapper.scrollLeft += event.deltaY;
//       syncFakeScrollBar();
//     }
//   };

//   if (contentWrapper) {
//     contentWrapper.addEventListener('scroll', handleScroll);
//     contentWrapper.addEventListener('wheel', handleWheel);
//   }
//   window.addEventListener('resize', syncFakeScrollBar);

//   // Initial sync after DOM is fully rendered
//   if (contentWrapper) {
//     contentWrapper.scrollLeft = 0.01;

//     setTimeout(() => {
//       syncFakeScrollBar();
//     }, 100);
//     contentWrapper.scrollLeft = 0;
//   }

//   return () => {
//     if (contentWrapper) {
//       contentWrapper.removeEventListener('scroll', handleScroll);
//       contentWrapper.removeEventListener('wheel', handleWheel);
//     }
//     window.removeEventListener('resize', syncFakeScrollBar);
//   };
// }, []);
