import { useEffect, useState } from 'react';
import TgBotIcon from 'assets/images/MenuPage/TgBotIconMenu.webp';
import TelegramIcon from 'assets/icons/TgMessengerIcon.svg';
import './TgBotConnection.scss';
import TgBotConnectionInfo from '../TgBotConnectionInfo/TgBotConnectionInfo';
import { useSelector } from '../../../../../redux/rootReducer';
import QRCode from 'react-qr-code';
import { apiTelegram } from '../../../../../services/api/Telegram/ApiTelegram';
import { useDispatch } from 'react-redux';
import { setTelegram } from '../../../../../redux/auth/reducer';

const TgBotConnection = (): JSX.Element => {
  const [isOpened, setIsOpened] = useState(false);
  const userId = useSelector((state) => state?.auth?.user?.id);
  const connectionInfo = useSelector((state) => state?.auth?.telegram);
  const [isCheckConnectionOpened, setIsCheckConnectionOpened] = useState(!!connectionInfo);
  const authUrl = 'https://t.me/algoth_bot?start=' + userId;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!connectionInfo) {
      apiTelegram.getData().then((data) => {
        if (data) {
          dispatch(setTelegram(data));
          setIsCheckConnectionOpened(true);
        }
      });
    }
  }, [isOpened, isCheckConnectionOpened]);

  useEffect(() => {
    if (!isCheckConnectionOpened && connectionInfo) {
      setIsOpened(false);
    }
  }, [isCheckConnectionOpened]);

  useEffect(() => {
    if (isOpened) {
      if (connectionInfo) {
        setIsCheckConnectionOpened(true);
      }
    }
  }, [isOpened]);

  if (!isOpened) {
    return (
      <button
        className="pageContainer__menuItem  pageContainer__menuItemTg"
        onClick={() => {
          setIsOpened(true);
        }}
      >
        <img className="pageContainer__menuItem_tgBotIcon" src={TgBotIcon} alt="TgBotIcon" />
        <div>Telegram bot</div>
        <div className="button__connectionIndicator">
          {connectionInfo ? (
            <>
              {' '}
              Connected{' '}
              <div className="button__connectionIndicator__circle button__connectionIndicator__circle_connected"></div>
            </>
          ) : (
            <>
              {' '}
              No connection{' '}
              <div className="button__connectionIndicator__circle button__connectionIndicator__circle_notConnected"></div>
            </>
          )}
        </div>
      </button>
    );
  }
  return (
    <>
      {!connectionInfo && (
        <div className="tgConnectionContainer">
          <div className="tgConnection" tabIndex={0}>
            <button
              className="tgConnection__closeButton"
              onClick={() => {
                setIsOpened(false);
              }}
            ></button>
            <div className="tgConnection__header">Connecting to AlgoTH telegram bot</div>
            <div className="tgConnection__subHeader">
              to receive important updates, trading alerts and login alerts to your account
            </div>
            <div className="tgConnection__text">Select connection method:</div>
            <button
              className="tgConnection__openTgButton"
              onClick={() => {
                window.open(authUrl);
              }}
            >
              Open telegram <img src={TelegramIcon} alt="TelegramIcon" />
            </button>
            <div className="tgConnection__qrText">or scan the QR-code to connect the bot</div>
            <div className="tgConnection__QRCode">
              <QRCode value={authUrl} />
            </div>
            <button
              className="tgConnection__checkConnectionButton"
              onClick={() => {
                setIsCheckConnectionOpened(true);
              }}
            >
              Check connection
            </button>
          </div>
        </div>
      )}
      {isCheckConnectionOpened && (
        <TgBotConnectionInfo setIsCheckConnectionOpened={setIsCheckConnectionOpened} />
        // <TgBotAuthenticator setIsCheckConnectionOpened={setIsCheckConnectionOpened} />
      )}
    </>
  );
};

export default TgBotConnection;
