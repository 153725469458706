import { createSlice } from '@reduxjs/toolkit';

import { type CrosshairStyle, LineType, PolygonType } from '@basttyy/klinecharts';
import { type IRootState } from '../rootReducer';
import { getUpdatePath } from '../updateProperty';

const initialState: CrosshairStyle = {
  show: true,
  horizontal: {
    show: true,
    line: {
      show: true,
      style: LineType.Dashed,
      dashedValue: [4, 2],
      size: 1,
      color: '#888888',
    },
    text: {
      show: true,
      style: PolygonType.Fill,
      color: '#FFFFFF',
      size: 12,
      family: 'Helvetica Neue',
      weight: 'normal',
      borderStyle: LineType.Solid,
      borderDashedValue: [2, 2],
      borderSize: 1,
      borderColor: '#686D76',
      borderRadius: 2,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 4,
      paddingBottom: 4,
      backgroundColor: '#686D76',
    },
  },
  vertical: {
    show: true,
    line: {
      show: true,
      style: LineType.Dashed,
      dashedValue: [4, 2],
      size: 1,
      color: '#888888',
    },
    text: {
      show: true,
      style: PolygonType.Fill,
      color: '#FFFFFF',
      size: 12,
      family: 'Helvetica Neue',
      weight: 'normal',
      borderStyle: LineType.Solid,
      borderDashedValue: [2, 2],
      borderSize: 1,
      borderColor: '#686D76',
      borderRadius: 2,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 4,
      paddingBottom: 4,
      backgroundColor: '#686D76',
    },
  },
};

const chartCrosshairSettingsReducer = createSlice({
  name: 'chartCrosshairSettings',
  initialState,
  reducers: {
    updateCrosshairProperty: getUpdatePath<CrosshairStyle>(),
    setInititalCrosshairSettings: (state, action) => {
      return action.payload;
    },
    refreshCrosshairSettings: () => {
      return initialState;
    },
  },
});
export const selectChartCrosshairSettings = (state: IRootState): CrosshairStyle =>
  state.chartCrosshairSettings;

export const { updateCrosshairProperty, refreshCrosshairSettings, setInititalCrosshairSettings } =
  chartCrosshairSettingsReducer.actions;

export default chartCrosshairSettingsReducer.reducer;
