export enum ChartEditorFigure {
  HORIZONTAL_LINE = 'horizontalLine',
  PRICE_CHANNEL_LINE = 'priceChannelLine',
  CIRCLE = 'circle',
  FIBONACCI_LINE = 'fibonacciLine',
  XABCD = 'xabcd',
  MAGNET = 'magnet',
  HORIZONTAL_RAY = 'horizontalRay',
  HORIZONTAL_LINE_SEGMENT = 'horizontalLIneSegment',
  VERTICAL_STRAIGHT_LINE = 'verticalStraightLine',
  VERTICAL_RAY = 'verticalRay',
  VERTICAL_LINE_SEGMENT = 'verticalLineSegment',
  STRAIGHT_LINE = 'straightLine',
  RAYS = 'rays',
  LINE_SEGMENT = 'lineSegment',
  ARROW = 'arrow',
  PRICE_LINE = 'priceLine',
  PARALLEL_STRAIGHT_LINES = 'parallelStraightLines',
  RECTANGLE = 'rectangle',
  PARALLELOGRAM = 'parallelogram',
  TRIANGLE = 'triangle',
  FIBONACCI_RETRACEMENT_LINES = 'fibonacciFetracementLines',
  FIBONACCI_CIRCLES = 'fibonacciCircles',
  FIBONACCI_SPIRAL = 'fibonacciSpiral',
  FIBONACCI_SPEED_RESISTANCE_FAN = 'fibonacciSpeedResistanceFan',
  FIBONACCI_TREND_EXPANSION = 'fibonacciTrendExpansion',
  GANN_BOX = 'gannBox',
  ABCD = 'ABCD',
  THREE_WAVES = 'threeWaves',
  FIVE_WAVES = 'fiveWaves',
  EIGHT_WAVES = 'eightWaves',
  ANY_WAVE = 'anyWave',
  STRONG_MAGNETIC = 'strongMagnetic',
}
