import { type FC } from 'react';
import './StrategyName.scss';

interface IProps {
  onChange: (value: string) => void;
  name: string;
  isMergedStrategy: boolean;
  disabled?: boolean;
  onSave?: () => void;
}

export const StrategyName: FC<IProps> = ({ name }) => {
  return (
    <>
      <div className={'strategy-name'}>
        <input type="text" placeholder="Name of strategy" readOnly={true} value={name} />
      </div>
    </>
  );
};
