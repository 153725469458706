import './NavigationMenu.scss';
import MenuBurger from 'assets/icons/MenuBurgerIcon.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'redux/rootReducer';

const NavigationMenu = ({
  isOpened,
  setIsOpened,
}: {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const login = useSelector((state) => state.auth.user?.login);
  return (
    <div
      onMouseEnter={() => {
        setIsOpened(true);
      }}
      onMouseLeave={() => {
        setTimeout(() => {
          setIsOpened(false);
        }, 800);
      }}
      className={`navigationMenu ${isOpened ? 'navigationMenuOpened' : ''}`}
    >
      {!isOpened && (
        <div className="navigationMenu_header">
          {' '}
          <img src={MenuBurger} alt="MenuBurger" /> Menu
        </div>
      )}
      {isOpened && (
        <div className="navigationMenu_content">
          <Link className="navigationMenu_item" to="/menu">
            Main menu
          </Link>
          <Link to={`/profile/${login}`} className="navigationMenu_item">
            Profile
          </Link>
          <div className="navigationMenu_item navigationMenu_item_selected ">Trading Panel</div>
          <div className="navigationMenu_item navigationMenu_item_disabled">Leaderboard</div>
          <a href="https://algoth.gitbook.io/docs" className="navigationMenu_item ">
            Docs
          </a>
        </div>
      )}
    </div>
  );
};

export default NavigationMenu;
