import { useState, type FC } from 'react';
import styles from './TimeframeSelector.module.css';
import TimeFrameIcon from 'assets/images/ChartTimeFrameIcon.svg';
import TimeFrameIconSelected from 'assets/images/ChartTimeSelectedFrameIcon.svg';
import TimeFrameIconSaved from 'assets/images/ChartTimeSavedFrameIcon.svg';
import OpenTimeFrame from 'assets/images/OpenTimeFrame.svg';
import { TIMEFRAMES } from '../ChartInfoSelector/Data/data';
import { useSelector } from '../../redux/rootReducer';
import { COLORS } from '../../configs/chart';

interface IProps {
  onChange: (tf: number) => void;
  selectedTimeframe: string;
}

export const TimeframeSelector: FC<IProps> = ({ onChange, selectedTimeframe }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showStars, setShowStars] = useState(false);
  const [timeframes, setTimeframes] = useState(TIMEFRAMES);
  const { isPreview } = useSelector((state) => state.chart);
  const { selectedMergedStrategy, selectedStrategy } = useSelector((state) => state.strategy);
  const allTimeFramesSelected = timeframes.filter((item) => item.marked).length;
  const disabledSelection = (): boolean =>
    isPreview || (selectedMergedStrategy && !selectedStrategy);
  const timeFrameItemClickHandler = (item: {
    id: number;
    time: number;
    value: string;
    disabled: boolean;
    marked: boolean;
  }): void => {
    if (!showStars && !item.disabled) {
      onChange(item.time);
      setIsModalOpen(false);
    }
    if (showStars && allTimeFramesSelected === 5 && item.marked) {
      setTimeframes((prev) => {
        return prev.map((i) => {
          if (i.id === item.id) {
            return { ...i, marked: !item.marked };
          } else {
            return i;
          }
        });
      });
    }
    if (showStars && allTimeFramesSelected < 5) {
      setTimeframes((prev) => {
        return prev.map((i) => {
          if (i.id === item.id && !item.disabled) {
            if (!item.marked) return { ...i, marked: !item.marked };
            if (item.marked && allTimeFramesSelected !== 1) {
              return { ...i, marked: !item.marked };
            } else {
              return { ...i, marked: item.marked };
            }
          } else {
            return i;
          }
        });
      });
    }
  };
  return (
    <div>
      <div className={styles.timeframeSelectorHeader}>
        <div className={styles.timeframeSelectorHeader_text}>Selected TF:</div>
        <div
          className={styles.timeFrameItem}
          style={{
            cursor: disabledSelection() ? 'not-allowed' : 'pointer',
            backgroundColor: COLORS.VIOLET,
          }}
          onClick={() => {
            !disabledSelection() && setShowStars(false);
            !disabledSelection() && setIsModalOpen(!isModalOpen);
          }}
        >
          {selectedTimeframe}
        </div>
        {!disabledSelection() && (
          <img style={{ margin: '10px' }} src={TimeFrameIconSelected} alt="image" />
        )}
        {!disabledSelection() &&
          timeframes
            .filter((item) => item.marked)
            .map((item) => (
              <div
                key={item.id}
                style={{
                  backgroundColor: selectedTimeframe === item.value && COLORS.VIOLET,
                }}
                className={styles.timeFrameItem}
                onClick={() => {
                  onChange(item.time);
                  setIsModalOpen(false);
                }}
              >
                {item.value}
              </div>
            ))}
        {!disabledSelection() && (
          <div
            className={styles.timeFrameToggler}
            onClick={() => {
              setShowStars(true);
              setIsModalOpen(!isModalOpen);
            }}
          >
            <img src={OpenTimeFrame} alt="" />
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className={styles.timeframeSelector}>
          <div className={styles.timeframeHeader}>Select Timeframe </div>
          <div className={styles.timeframeSelectorList}>
            {timeframes.map((item, key) => (
              <div
                style={{
                  backgroundColor: selectedTimeframe === item.value && COLORS.VIOLET,
                  cursor: item.disabled ? 'not-allowed' : 'pointer',
                }}
                key={key}
                onClick={() => {
                  timeFrameItemClickHandler(item);
                }}
              >
                {showStars && allTimeFramesSelected === 5 && item.marked && (
                  <>
                    <img
                      src={
                        item.marked
                          ? selectedTimeframe === item.value
                            ? TimeFrameIconSelected
                            : TimeFrameIconSaved
                          : TimeFrameIcon
                      }
                      alt="star"
                    />{' '}
                  </>
                )}
                {showStars && allTimeFramesSelected < 5 && (
                  <>
                    <img
                      src={
                        item.marked
                          ? selectedTimeframe === item.value
                            ? TimeFrameIconSelected
                            : TimeFrameIconSaved
                          : TimeFrameIcon
                      }
                      alt=""
                    />{' '}
                  </>
                )}
                {item.value}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
