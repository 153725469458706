import { type FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/rootReducer';
import { setChartDateConfig } from 'redux/chart/reducer';

import { DayPicker } from 'components/DayPicker';
import { Button } from 'components/Button/Button';

import { type IChartConfig } from 'interfaces/IChart';

import classNames from 'classnames';
import stylesButton from 'components/Button/Button.module.css';
import styles from '../ChartWrapper.module.css';

export const HeaderDayPicker: FC = () => {
  const { dateConfig } = useSelector((state) => state.chart);
  const [from, setFrom] = useState(dateConfig.dateFrom ? new Date(dateConfig.dateFrom) : null);
  const [to, setTo] = useState(dateConfig.dateTo ? new Date(dateConfig.dateTo) : null);
  const disabledDaysFrom = [{ after: to ?? undefined }];
  const disabledDaysTo = [{ before: from ?? undefined }];
  const dispatch = useDispatch();

  useEffect(() => {
    setFrom(dateConfig.dateFrom ? new Date(dateConfig.dateFrom) : null);
  }, [dateConfig.dateFrom]);
  useEffect(() => {
    setTo(dateConfig.dateTo ? new Date(dateConfig.dateTo) : null);
  }, [dateConfig.dateTo]);

  const updateDateConfig = (data: Partial<IChartConfig>): void => {
    dispatch(
      setChartDateConfig({
        ...dateConfig,
        ...data,
      }),
    );
  };

  return (
    <div className={styles.rangeDatePicker}>
      <div className={styles.datePickerWrapper}>
        <DayPicker
          placeholder="from"
          toDate={new Date()}
          onSelect={(data) => {
            updateDateConfig({ dateFrom: data?.getTime() ?? null });
          }}
          disabled={disabledDaysFrom}
          value={from}
          fromYear={2015}
          footer={
            <Button className={classNames(stylesButton.primaryDark, styles.pickerButtonButton)}>
              Select the first available day
            </Button>
          }
        />
      </div>
      <div className={styles.separator}></div>
      <div className={styles.datePickerWrapper}>
        <DayPicker
          placeholder="to"
          fromDate={from ?? new Date()}
          toDate={new Date()}
          onSelect={(data) => {
            updateDateConfig({ dateTo: data?.getTime() ?? null });
          }}
          value={to}
          disabled={disabledDaysTo}
          fromYear={2015}
          footer={
            <Button
              onClick={() => {
                updateDateConfig({ dateTo: Date.now() });
              }}
              className={classNames(stylesButton.primaryDark, styles.pickerButtonButton)}
            >
              Today
            </Button>
          }
        />
      </div>
    </div>
  );
};
