import { type FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './TickerSelector.module.css';
import { type IDefaultTicker } from '../../services/api/Candles/types';
import { SearchingTicker } from './components/SearchingTicker';
import { CustomTickersList } from './components/CustomTickersList';
import { CustomTickerCreationOne } from './components/CustomTickerCreationOne';
import { CustomTickerCreationTwo } from './components/CustomTickerCreationTwo';
import {
  MODE_CREATING_INDEX_STEP_ONE,
  MODE_LIST_OF_INDEX_TICKERS,
  MODE_SEARCHING_TICKET,
  MODE_SETTING_INDEX_STEP_TWO,
  type TickerSelectorPageType,
} from './types';
import { useSelector } from '../../redux/rootReducer';
import { selectCustomTickers, setTickerIcons } from '../../redux/customTicker/reducer';
import { type ICustomTicker } from '../../redux/customTicker/types';
import { useDispatch } from 'react-redux';
import { apiTicker } from '../../services/api/Ticker/ApiTicker';

export const TickerSelector: FC<{ onClose: () => void }> = (props) => {
  const [tickerSelectorState, setTickerSelectorState] =
    useState<TickerSelectorPageType>(MODE_SEARCHING_TICKET);
  const createdTickers = useSelector(selectCustomTickers);
  const tickers = useSelector((state) => state.customTickers.tickerIcons);
  const { selectedChart: id } = useSelector((state) => state.chart);
  const [selectedIndexTicker, setSelectedIndexTicker] = useState<IDefaultTicker[]>([]);
  const [selectedTickersPercentsArray, setSelectedTickersPercentsArray] = useState([]);
  const [headerName, setHeaderName] = useState(`Custom index ${createdTickers.length + 1}`);
  const [createdTicker, setCreatedTicker] = useState<Omit<ICustomTicker, 'id'>>({
    name: headerName,
    tickers: [],
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (tickers.length === 0) {
      apiTicker
        .getTickers()
        .then((tickers: any) => {
          const tickerss = tickers.sort((a, b) => {
            if (a.baseAsset < b.baseAsset) return -1;
            if (a.baseAsset > b.baseAsset) return 1;
            return 0;
          });
          dispatch(setTickerIcons(tickerss));
        })
        .catch(() => {});
    }
  }, [tickers]);

  return ReactDOM.createPortal(
    <div className={styles.tickerSelectorOverlay}>
      <div className={styles.tickerSelector}>
        {tickerSelectorState === MODE_SEARCHING_TICKET && (
          <SearchingTicker
            selectedChartId={id}
            setTickerSelectorState={setTickerSelectorState}
            onClose={props.onClose}
          />
        )}
        {tickerSelectorState === MODE_LIST_OF_INDEX_TICKERS && (
          <CustomTickersList
            setTickerSelectorState={setTickerSelectorState}
            onClose={props.onClose}
            createdTickers={createdTickers}
          />
        )}
        {tickerSelectorState === MODE_CREATING_INDEX_STEP_ONE && (
          <CustomTickerCreationOne
            setTickerSelectorState={setTickerSelectorState}
            onClose={props.onClose}
            tickers={tickers}
            setCreatedTicker={setCreatedTicker}
            headerName={headerName}
            setHeaderName={setHeaderName}
            createdTicker={createdTicker}
            selectedIndexTicker={selectedIndexTicker}
            selectedTickersPercentsArray={selectedTickersPercentsArray}
            setSelectedIndexTicker={setSelectedIndexTicker}
            setSelectedTickersPercentsArray={setSelectedTickersPercentsArray}
          />
        )}
        {tickerSelectorState === MODE_SETTING_INDEX_STEP_TWO && (
          <CustomTickerCreationTwo
            setTickerSelectorState={setTickerSelectorState}
            onClose={props.onClose}
            tickers={tickers}
            setCreatedTicker={setCreatedTicker}
            headerName={headerName}
            setHeaderName={setHeaderName}
            createdTicker={createdTicker}
            selectedIndexTicker={selectedIndexTicker}
            selectedTickersPercentsArray={selectedTickersPercentsArray}
            setSelectedIndexTicker={setSelectedIndexTicker}
            setSelectedTickersPercentsArray={setSelectedTickersPercentsArray}
          />
        )}
      </div>
    </div>,
    document.body,
  );
};
