import { useState, type FC } from 'react';
import './StrategyTypeSettings.scss';
import CheckBoxChecked from 'assets/images/Strategy/CheckboxChecked.svg';
import CheckBox from 'assets/images/Strategy/Checkbox.svg';
import ColorPickerIcon from 'assets/images/Strategy/ColorPickerContainer.svg';

// TODO: change to checked

import { StrategySettingsHeader } from '../StrategySettingsHeader/StrategySettingsHeader';
import TimeSelector from '../CustomElements/CustomTimeSelector/CustomTimeSelector';
import CustomDropDown from '../CustomElements/MultiLineDropDown/CustomMultiLineDropDown';
import GTumbler from '../../../../../../components/GTumbler/GTumbler';
const gmtZones = Array.from({ length: 25 }, (_, i) => {
  const offset = i <= 12 ? `+${i}` : `-${i - 12}`;
  return { id: i, name: `GMT ${offset}` };
});

interface IProps {
  onClose: () => void;
}

export const StrategyOtherFiltersSetting: FC<IProps> = (props) => {
  const isMobileVersion = window.innerWidth < 768;
  const [otherFiltersData, setOtherFitlersData] = useState({
    sessions: [
      {
        id: 0,
        name: 'Session 1',
        selected: true,
        from: { id: 32, value: '08:00' },
        to: { id: 68, value: '17:00' },
        color: 'green',
      },
      {
        id: 1,
        name: 'Session 2',
        selected: true,
        from: { id: 32, value: '08:00' },
        to: { id: 68, value: '17:00' },
        color: 'green',
      },
    ],
    timeZone: { id: 0, name: 'GMT +0' },
    visibility: true,
    openTradesinside: true,
    closeTradesEnding: true,
  });
  // const changeFromTime = (e): void => {
  //  console.log(e);
  //   setOtherFitlersData({
  //     ...otherFiltersData,
  //     sessions: otherFiltersData.sessions.map((session) => {
  //       if (session.id === e.id) {
  //         return { ...session, from: e };
  //       }
  //       return session;
  //     }),
  //   });
  // };

  const changeToTime = (e): void => {
    console.log(e);
  };
  return (
    <>
      <div className="strategy-settings">
        <StrategySettingsHeader name={'Sessions Filter'} onClose={props.onClose} />
        {otherFiltersData.sessions.map((sessionItem) => (
          <div key={sessionItem.id} className="strategy-settings__session">
            <div className="strategy-settings__session__row">
              <div
                className="strategy-settings__session__row__checkbox"
                onClick={() => {
                  setOtherFitlersData({
                    ...otherFiltersData,
                    sessions: otherFiltersData.sessions.map((session) => {
                      if (session.id === sessionItem.id) {
                        return { ...session, selected: !session.selected };
                      }
                      return session;
                    }),
                  });
                }}
              >
                <img src={sessionItem.selected ? CheckBoxChecked : CheckBox} alt="" />
              </div>
              <input
                className={
                  'strategy-settings__session__row__input' +
                  (sessionItem.name.length === 0 ||
                  otherFiltersData.sessions.filter((session) => session.name === sessionItem.name)
                    .length > 1
                    ? ' strategy-settings__session__row__input_error'
                    : '')
                }
                value={sessionItem.name}
                onChange={(e) => {
                  setOtherFitlersData({
                    ...otherFiltersData,
                    sessions: otherFiltersData.sessions.map((session) => {
                      if (session.id === sessionItem.id) {
                        return { ...session, name: e.target.value };
                      }
                      return session;
                    }),
                  });
                }}
              />
              <div className="strategy-settings__session__row__colorPicker">
                Color: <img src={ColorPickerIcon} alt="" />
              </div>
            </div>
            <div className="strategy-settings__session__row">
              Time :
              <TimeSelector
                defaultTime={sessionItem.from}
                disabledLast
                onChange={(e) => {
                  setOtherFitlersData({
                    ...otherFiltersData,
                    sessions: otherFiltersData.sessions.map((session) => {
                      if (session.id === sessionItem.id) {
                        return { ...session, from: e[0], to: e[1] };
                      }
                      return session;
                    }),
                  });
                }}
              />{' '}
              -{' '}
              <TimeSelector
                defaultTime={sessionItem.to}
                onChange={changeToTime}
                from={sessionItem.from}
              />
            </div>
          </div>
        ))}
        <div className="strategy-settings__session__row">
          <div className="strategy-settings__session__row__text">Timezone</div>
          <div className="strategy-settings__session__row__timeZoneDrop">
            <CustomDropDown
              options={gmtZones}
              activeOption={otherFiltersData.timeZone}
              largeList
              onChange={(e: number) => {
                setOtherFitlersData({
                  ...otherFiltersData,
                  timeZone: gmtZones[e],
                });
              }}
            />
          </div>
        </div>
        <div className="strategy-settings__session__row">
          <div className="strategy-settings__session__row__text">Visibility</div>
          <GTumbler
            size={isMobileVersion ? 'large' : 'small'}
            checked={otherFiltersData.visibility}
            name="sessionTumbler"
            onToggle={() => {
              setOtherFitlersData({
                ...otherFiltersData,
                visibility: !otherFiltersData.visibility,
              });
            }}
          />
        </div>
        <div className="strategy-settings__session__row">
          <div className="strategy-settings__session__row__text">
            Open new trades inside sessions only
          </div>

          <GTumbler
            size={isMobileVersion ? 'large' : 'small'}
            checked={otherFiltersData.openTradesinside}
            name="sessionTumbler"
            onToggle={() => {
              setOtherFitlersData({
                ...otherFiltersData,
                openTradesinside: !otherFiltersData.openTradesinside,
              });
            }}
          />
        </div>
        <div className="strategy-settings__session__row">
          <div className="strategy-settings__session__row__text">
            Close trades at the end of the trading sessions
          </div>

          <GTumbler
            size={isMobileVersion ? 'large' : 'small'}
            checked={otherFiltersData.closeTradesEnding}
            name="sessionTumbler"
            onToggle={() => {
              setOtherFitlersData({
                ...otherFiltersData,
                closeTradesEnding: !otherFiltersData.closeTradesEnding,
              });
            }}
          />
        </div>

        <button
          className={
            'strategy-settings__saveButton' +
            (otherFiltersData.sessions.some((session) => session.name.length === 0)
              ? ' strategy-settings__saveButton_disabled'
              : '')
          }
          onClick={() => {}}
        >
          Save
        </button>
      </div>
    </>
  );
};
