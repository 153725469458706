import { ApiServiceBase } from '../../ApiServiceBase';
import type { IStrategyTickerData } from '../Strategy/types/StrategyResponse';

import type { ICustomTickerElement } from '../../../redux/customTicker/types';
import type { IDefaultTicker } from '../Candles/types';
import type { ISuccessResponse } from '../../types';

class ApiTicker extends ApiServiceBase {
  protected baseUrl = '/api/ticker/';

  public async getTickers(): Promise<IDefaultTicker[]> {
    try {
      const response = await this.get<ISuccessResponse<IDefaultTicker[]>>('get_tickers');

      return response.data.data ?? ([] as IDefaultTicker[]);
    } catch (_) {
      return [] as IDefaultTicker[];
    }
  }

  public async getCustomTickers(): Promise<IStrategyTickerData[]> {
    try {
      if (!localStorage.getItem('user')) return [];
      const response = await this.get('get');
      return response.data.data ?? [];
    } catch (_) {
      return [];
    }
  }

  public async addCustomTicker(ticker: {
    name: string;
    tickers: ICustomTickerElement[];
  }): Promise<IStrategyTickerData> {
    try {
      const response = await this.post('create', {
        data: {
          name: ticker.name,
          tickers: ticker.tickers,
        },
      });
      const { _id, name, tickers } = response.data.data ?? null;
      return { _id, name, tickers };
    } catch (_) {
      return null;
    }
  }

  public async saveCustomTicker(ticker: {
    _id: string;
    name: string;
    tickers: ICustomTickerElement[];
  }): Promise<IStrategyTickerData> {
    try {
      const response = await this.post('save', {
        data: {
          _id: ticker._id,
          name: ticker.name,
          tickers: ticker.tickers,
        },
      });
      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }

  public async deleteCustomTicker(_id: string): Promise<IStrategyTickerData> {
    try {
      const response = await this.post('remove', {
        id: _id,
      });
      return response.data.data ?? null;
    } catch (_) {
      return null;
    }
  }
}

export const apiTicker = new ApiTicker();
