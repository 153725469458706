import { useDispatch } from 'react-redux';

import { useSelector } from 'redux/rootReducer';
import { changePositionCharts } from 'redux/chart/reducer';
import styles from '../ChartWrapper.module.css';
import HorizontalIcon from 'assets/images/Strategy/HorizontalIcon.svg';
import VerticalIcon from 'assets/images/Strategy/VerticalIcon.svg';

const PositionSwitcher = (): JSX.Element => {
  const { positionCharts: position } = useSelector((state) => state.chart);
  const dispatch = useDispatch();

  const getStyle = (elem: 'vertical' | 'horizontal'): Record<string, string> => {
    return {
      backgroundColor: position === elem ? '#9747FF' : '',
      cursor: position === elem ? 'not-allowed' : 'pointer',
    };
  };

  return (
    <div className={styles.positionSwitcher}>
      <div
        style={getStyle('vertical')}
        className={styles.positionItem}
        onClick={() => {
          dispatch(changePositionCharts('vertical'));
        }}
      >
        <img src={HorizontalIcon} alt="" />
      </div>
      <div
        style={getStyle('horizontal')}
        className={styles.positionItem}
        onClick={() => {
          dispatch(changePositionCharts('horizontal'));
        }}
      >
        <img src={VerticalIcon} alt="" />
      </div>
    </div>
  );
};

export default PositionSwitcher;
