import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/rootReducer';
import {
  setAllTrades,
  setChartDateConfig,
  setChartLoading,
  setPricePrecision,
  setQtyPrecision,
  setSelectedChart,
  setStats,
  updateAllTrades,
  updateChart,
  useChartSelector,
} from 'redux/chart/reducer';
import {
  saveSelectedStrategy,
  setBackTest,
  setMergedStrategyData,
  setStrategies,
  updateSavedStrategy,
} from 'redux/strategy/reducer';

import { LoaderOverlay } from 'components/LoaderOverlay';
import { ResizeHandle } from 'components/ResizeHandle';

import { type ImperativePanelGroupHandle, Panel, PanelGroup } from 'react-resizable-panels';
import Chart from '../Chart';
import { ChartWrapper } from '../Chart/components/ChartWrapper/ChartWrapper';
import { type ITradeData } from 'interfaces/ITrade';
import { StrategyModes } from 'interfaces/IStrategy';
import {
  type IMergedLiveData,
  type IHedgeLiveData,
  type IOneWayLiveData,
  type IPrivateEventData,
} from 'interfaces/IChart';
import { type IStatistics } from 'interfaces/IStatistics';
import { type IStrategyAllResponse } from 'services/api/Strategy/types/StrategyResponse';

import { getStrategy } from 'services/utils/getStrategy';
import { apiStrategy } from 'services/api/Strategy/ApiStrategy';
import {
  apiBacktest,
  type IHedgeBackTestResponse,
  type IMergedBackTestResponse,
  type IOneWayBackTestResponse,
} from 'services/api/Backtest/ApiBacktest';
import { webSocketService } from 'services/WebSocketService';
import {
  areStrategiesArraysEqual,
  compareStrategyHeaderData,
  isHedgeModeStrategy,
  isOneWayModeStrategy,
  useIsSavedEqualToSelected,
} from 'utils/strategy';
import _ from 'lodash';
import styles from '../../HomePageStyles.module.css';
interface Iprops {
  setState: (e: any) => void;
  setHistoryContainerSize: (e: any) => void;
  selectedChart: string;
  expandConstructor?: () => void;
  isConstructorOpen?: boolean;
}
const defaultStats: IStatistics = {
  balance: 0,
  initial_balance: 0,
  net_profit: [0, 0], // USD, Percent
  trades_count: [0, 0], // Short, Long
  commission_paid: [0, 0], // Short, Long
  profit_percent: [0, 0, 0], // Short, Long, Both
  pnl_trades: [0, 0],
  liquidation_count: [0, 0, 0],
  max_drawdown: [
    [0, 0], // Short
    [0, 0], // Long
    [0, 0], // Both
  ],
  max_balance_drawdown: [0, 0],
  stoploss_in_row: [0, 0, 0],
  avg_trade_profit: [
    [0, 0], // Short
    [0, 0], // Long
    [0, 0], // Both
  ],
  open_pnl: [null, null],
  recovery_factor: 0,
  sharp_factor: 0,
  cv_factor: 0,
  overall_factor: 0,
};
let selectedStrategyId: string | null = null;
let mergedStrategyData = null;
export const Charts: React.FC<Iprops> = ({
  setState,
  selectedChart: selectedChartProps,
  setHistoryContainerSize,
  expandConstructor,
  isConstructorOpen,
}) => {
  const {
    charts,
    dateConfig: reduxDateConfig,
    selectedChart: reduxSelectedChart,
    stats,
    positionCharts,
    isLoading,
    showStatistics,
    fullScreenChart,
    fullScreenMode,
  } = useSelector((state) => state.chart);
  const selectedChart = useChartSelector(reduxSelectedChart);
  const reducerStrategy = useSelector((state) => state.strategy);
  const reducerMergedStrategyData = useSelector((state) => state.strategy.mergedStrategyData);
  const reducerSelectedStrategyId = useSelector((state) => state.strategy.selectedStrategyId);
  const reducerBackTest = useSelector((state) => state.strategy.isBackTest);
  useEffect(() => {
    selectedStrategyId = reducerSelectedStrategyId;
  }, [reducerSelectedStrategyId]);
  useEffect(() => {
    mergedStrategyData = reducerMergedStrategyData;
  }, [reducerMergedStrategyData]);
  const dispatch = useDispatch();
  const chartPanelsSection = React.createRef<ImperativePanelGroupHandle>();
  const reducerStats = useSelector((state) => state.chart.stats);
  const [visibleRange, setVisibleRangeState] = useState<{ from: number; to: number }>({
    from: 0,
    to: 0,
  });
  let ro: ResizeObserver | null = null;
  let historyRo: ResizeObserver | null = null;
  const getPrecisions = (trade: ITradeData): [price: number, qty: number] => {
    return [
      (`${trade.average_enter}`.split('.')[1] ?? '').length,
      (`${trade.qty}`.split('.')[1] ?? '').length,
    ];
  };
  // check
  const backTest = (): void => {
    const strategy = getStrategy(reducerStrategy.selectedStrategy);
    const mergedStrategy = reducerStrategy.selectedMergedStrategy;
    const backTestId = !strategy && mergedStrategy ? mergedStrategy._id : strategy?._id ?? null;
    const dateConfig = { ...reduxDateConfig };
    const sameListOfStrategies = areStrategiesArraysEqual(
      mergedStrategy?.strategies,
      reducerStrategy?.mergedStrategyData?.strategies,
    );
    if (!strategy && !mergedStrategy) {
      dispatch(setBackTest(false));
      dispatch(setStats(defaultStats));
      return;
    }
    if (
      mergedStrategy?._id &&
      reducerStrategy?.mergedStrategyData?._id &&
      mergedStrategy?._id === reducerStrategy?.mergedStrategyData?._id &&
      sameListOfStrategies &&
      !strategy
    ) {
      dispatch(setStats(reducerStrategy?.mergedStrategyData?.stats));
      dispatch(setBackTest(false));
      return;
    }
    const savedStrategy = reducerStrategy.savedStrategies?.find(
      (strategy) => strategy._id === reducerStrategy?.selectedStrategy?._id,
    );
    const isSavedEqualToSelected = useIsSavedEqualToSelected(
      reducerStrategy.selectedStrategy,
      savedStrategy,
    );
    if (
      strategy &&
      reducerStrategy.savedStrategies.some(
        (strategy) => strategy._id === reducerStrategy?.selectedStrategy?._id,
      ) &&
      !compareStrategyHeaderData(savedStrategy, selectedChart, reduxSelectedChart) &&
      isSavedEqualToSelected
    ) {
      dispatch(setStats(savedStrategy.stats));
      if (isOneWayModeStrategy(savedStrategy)) {
        dispatch(
          updateChart({
            id: '0',
            data: {
              trades: savedStrategy.trades || [],
            },
          }),
        );
      } else {
        dispatch(
          updateChart({
            id: '0',
            data: {
              trades: savedStrategy?.shortTrades || [],
            },
          }),
        );
        dispatch(
          updateChart({
            id: '1',
            data: {
              trades: savedStrategy?.longTrades || [],
            },
          }),
        );
      }
      dispatch(setBackTest(false));
      dispatch(setChartLoading(false));
      return;
    }
    dispatch(setChartLoading(true));
    if (!strategy && mergedStrategy) {
      const updatedMergeStrategy = { ...mergedStrategy, strategies: [] };
      updatedMergeStrategy.strategies = [];
      delete updatedMergeStrategy.__v;
      delete updatedMergeStrategy.is_favourite;
      delete updatedMergeStrategy.user_id;
      const promises = mergedStrategy.strategies.map((strategyId) => {
        return apiStrategy.getStrategy(strategyId);
      });
      Promise.all(promises)
        .then((strategies) => {
          strategies.forEach((strategy) => {
            updatedMergeStrategy.strategies.push(getStrategy(strategy));
          });
          if (window.location.href.includes('strategy')) {
            const allStrategies: IStrategyAllResponse = [...updatedMergeStrategy.strategies].map(
              (e) => {
                return {
                  name: e.name,
                  _id: e._id,
                  type: e.type,
                  ticker: e.ticker ? e.ticker : null,
                };
              },
            );
            dispatch(setStrategies(allStrategies));
          }
        })
        .then(() => {
          apiBacktest
            .backtest({
              chart: selectedChart.isDynamicBackTest
                ? {
                    date_from: selectedChart.candles[visibleRange.from]?.time,
                    date_to: selectedChart.candles[visibleRange.to - 1]?.time,
                  }
                : {
                    date_from: dateConfig.dateFrom,
                    date_to: dateConfig.dateTo,
                  },
              strategy: updatedMergeStrategy,
            })
            .then((_result) => {
              const result = _result as IMergedBackTestResponse;
              dispatch(setBackTest(false));
              dispatch(setChartLoading(false));
              if (!result) {
                dispatch(setStats(defaultStats));
                // heres
                charts.forEach((e) => {
                  dispatch(updateChart({ id: e.id, data: { trades: [] } }));
                });
                return;
              }
              charts.forEach((e) => {
                dispatch(updateChart({ id: e.id, data: { trades: [] } }));
              });
              result.stats.strategy_id = mergedStrategy._id;
              if (backTestId === mergedStrategy._id) {
                dispatch(
                  setMergedStrategyData({
                    ...mergedStrategy,
                    backtestData: result.strategies,
                    stats: result.stats,
                    chartConfig: dateConfig,
                  }),
                );
                dispatch(setStats(result.stats));
              }
            });
        })
        .catch((error) => {
          dispatch(setChartLoading(false));
          console.error('error', error);
        });
    } else {
      apiBacktest
        .backtest({
          chart: selectedChart.isDynamicBackTest
            ? {
                date_from: selectedChart.candles[visibleRange.from]?.time,
                date_to: selectedChart.candles[visibleRange.to - 1]?.time,
              }
            : {
                date_from: dateConfig.dateFrom,
                date_to: dateConfig.dateTo,
              },
          strategy,
        })
        .then((_result) => {
          const result = _result as IOneWayBackTestResponse | IHedgeBackTestResponse;
          dispatch(setBackTest(false));
          dispatch(setChartLoading(false));
          if (!result) {
            dispatch(setChartLoading(false));
            dispatch(setStats(defaultStats));
            charts.forEach((e) => {
              dispatch(updateChart({ id: e.id, data: { trades: [] } }));
            });
            return;
          }
          if (backTestId === strategy._id) {
            result.stats.strategy_id = strategy._id;
            if (Array.isArray(result.trades[0])) {
              dispatch(setStats(result.stats));
              dispatch(
                updateChart({
                  id: charts[0].id,
                  data: {
                    trades: result.trades[0],
                  },
                }),
              );
              dispatch(
                updateChart({
                  id: charts[1].id,
                  data: {
                    trades: result.trades[1] as ITradeData[],
                  },
                }),
              );
              if (result.trades[0] && result.trades[1]) {
                dispatch(
                  saveSelectedStrategy({
                    stats: result.stats,
                    trades0: result.trades[0],
                    trades1: result.trades[1] as ITradeData[],
                    chartConfig: dateConfig,
                  }),
                );
              }
              dispatch(setChartLoading(false));
              return;
            }

            dispatch(setStats(result.stats));
            dispatch(
              updateChart({
                id: charts[0].id,

                data: {
                  trades: result.trades as ITradeData[],
                },
              }),
            );
            dispatch(setChartLoading(false));
            dispatch(
              saveSelectedStrategy({
                stats: result.stats,
                trades: result.trades as ITradeData[],
                chartConfig: dateConfig,
              }),
            );
          }
        })
        .catch(() => {
          dispatch(setChartLoading(false));
        });
    }
  };
  const updateChartsSize = (chartContainer: {
    clientWidth: number;
    clientHeight: number;
  }): void => {
    charts.forEach((e) => {
      dispatch(
        updateChart({
          id: e.id,
          data: {
            width: chartContainer?.clientWidth + Math.random() / 10000000,
            height: chartContainer?.clientHeight - 30 + Math.random() / 10000000,
          },
        }),
      );
    });
  };
  const onWebSocketData = (data: IPrivateEventData): void => {
    // console.log(data.event_id.startsWith('strategy_'));
    if (data.event_id.startsWith('strategy_')) {
      const eventData: IOneWayLiveData | IHedgeLiveData | IMergedLiveData = JSON.parse(data.data);
      const strategyId = data.event_id.replace('strategy_', '');
      const savedStrategy = reducerStrategy?.savedStrategies?.find((e) => e._id === strategyId);
      if (savedStrategy) {
        dispatch(updateSavedStrategy({ _id: strategyId, stats: eventData.stats }));
      }
      if (strategyId === selectedStrategyId) {
        dispatch(setStats(eventData.stats));
        if (mergedStrategyData && 'strategies' in eventData) {
          console.log('onWebSocketData', eventData);
          const backtestData = mergedStrategyData.backtestData.slice();
          backtestData.forEach((e, index) => {
            const value = { ...e };
            const eventStrategyData = eventData.strategies[e._id];
            value.stats = eventStrategyData.stats;

            if (Array.isArray(value.trades[0]) && Array.isArray(value.trades[1])) {
              const shortTrades = value.trades[0].slice();
              const longTrades = value.trades[1].slice();
              if (
                shortTrades[shortTrades.length - 1]?.time === eventStrategyData.last_trade[0].time
              ) {
                shortTrades[shortTrades.length - 1] = eventStrategyData.last_trade[0];
                dispatch(updateAllTrades(eventStrategyData.last_trade[0]));
              } else {
                dispatch(updateAllTrades(eventStrategyData.last_trade[0]));
                shortTrades.push(eventStrategyData.last_trade[0]);
              }

              if (
                longTrades[longTrades.length - 1]?.time === eventStrategyData.last_trade[1].time
              ) {
                dispatch(updateAllTrades(eventStrategyData.last_trade[1]));
                longTrades[longTrades.length - 1] = eventStrategyData.last_trade[1];
              } else {
                dispatch(updateAllTrades(eventStrategyData.last_trade[1]));
                longTrades.push(eventStrategyData.last_trade[1]);
              }
              value.trades = [shortTrades, longTrades];
            } else {
              const lastTrade = eventStrategyData.last_trade as ITradeData;
              const trades = value.trades.slice() as ITradeData[];

              if (trades[trades.length - 1]?.time === lastTrade.time) {
                trades[trades.length - 1] = lastTrade;
                dispatch(updateAllTrades(lastTrade));
              } else {
                trades.push(lastTrade);
                dispatch(updateAllTrades(lastTrade));
              }
              value.trades = trades;
            }
            backtestData[index] = value;
          });

          dispatch(setMergedStrategyData({ ...mergedStrategyData, backtestData }));
        }
      }
    }
  };

  useEffect(() => {
    const chartContainer = document.querySelector('.chart_container');
    const historyContainer = document.querySelector('#history_container');
    if (chartContainer) {
      updateChartsSize(chartContainer);

      ro = new ResizeObserver((entries) => {
        const entry = entries[0];

        if (entry.target === chartContainer) {
          setTimeout(() => {
            updateChartsSize(chartContainer);
          }, 0);
        }
      });
      ro?.observe(chartContainer);
    }
    if (historyContainer) {
      historyRo = new ResizeObserver((entries) => {
        const entry = entries[0];
        if (entry.target === historyContainer) {
          setTimeout(() => {
            setHistoryContainerSize({
              width: historyContainer.clientWidth,
              height: historyContainer.clientHeight,
            });
          }, 0);
        }
      });

      historyRo.observe(historyContainer);
    }

    if (!webSocketService.active) {
      webSocketService.open();
    }
  }, [fullScreenChart, positionCharts, showStatistics, reducerStats]);
  useEffect(() => {
    if (reducerBackTest) {
      backTest();
    }
  }, [reducerBackTest]);
  // useEffect(() => {
  //     backTest(chart.charts[0].id, chart.charts[1]?.id);
  // }, [selectedStrategyId]);

  useEffect(() => {
    const strategyId = stats.strategy_id ?? null;
    if (
      reducerStrategy.selectedStrategyId !== strategyId &&
      reducerStrategy?.selectedMergedStrategy?._id !== reducerStrategy.selectedStrategyId
    ) {
      if (
        reducerStrategy.selectedStrategyId &&
        reducerStrategy.savedStrategies?.findIndex((e) => e._id === strategyId) !== -1
      ) {
        dispatch(setStats(reducerStrategy.savedStrategies.find((e) => e._id === strategyId).stats));
      } else {
        dispatch(setStats(defaultStats));
      }
    }
  }, [reducerStrategy.selectedStrategyId]);

  useEffect(() => {
    const offArray = [];
    reducerStrategy.savedStrategies?.forEach((strategy) => {
      const off = webSocketService.createStrategyInterval(strategy._id);
      offArray.push(off);
    });

    // TODO
    if (reducerStrategy.selectedMergedStrategy) {
      offArray.push(
        webSocketService.createStrategyInterval(reducerStrategy.selectedMergedStrategy._id),
      );
    }

    return () => {
      offArray.forEach((off) => off());
    };
  }, [reducerStrategy.savedStrategies, reducerStrategy.selectedMergedStrategy]);
  useEffect(() => {
    const offWs = webSocketService.on(onWebSocketData);

    return () => {
      offWs();
    };
  }, []);
  useEffect(() => {
    if (fullScreenChart) {
      if (reduxSelectedChart !== fullScreenChart) {
        dispatch(setSelectedChart(fullScreenChart));
      }
    }
    const chartContainer = document.querySelector('.chart_container');
    updateChartsSize(chartContainer);
    charts.forEach((e, index) => {
      const chartContainer = document.querySelector(`.chart_container_resize_${index}`);
      if (chartContainer) {
        dispatch(
          updateChart({
            id: e.id,
            data: {
              width: chartContainer.clientWidth + Math.random() / 1000000,
              height: chartContainer.clientHeight + Math.random() / 1000000,
            },
          }),
        );
      }
    });
  }, [fullScreenChart, positionCharts, showStatistics, reducerStats]);
  useEffect(() => {
    let trades: ITradeData[] = [];
    const savedStrategy = reducerStrategy.savedStrategies.find(
      (e) => e._id === reducerStrategy?.selectedStrategy?._id,
    );

    if (reducerStrategy.selectedStrategy) {
      switch (reducerStrategy.selectedStrategy.mode) {
        case StrategyModes.ONE_SIDE: {
          if (
            reducerStrategy.savedStrategies.findIndex(
              (e) => e._id === reducerStrategy?.selectedStrategy?._id,
            ) !== -1 &&
            charts[0]?.trades.length === 0
          ) {
            if (isOneWayModeStrategy(savedStrategy)) {
              console.log(_.isEqual(charts[0]?.trades, savedStrategy?.trades));

              trades = isOneWayModeStrategy(savedStrategy) ? savedStrategy?.trades : [];
            }
          } else {
            trades = charts[0]?.trades;
          }
          break;
        }
        case StrategyModes.HEDGE: {
          if (
            reducerStrategy.savedStrategies.findIndex(
              (e) => e._id === reducerStrategy?.selectedStrategy?._id,
            ) !== -1
          ) {
            trades = isHedgeModeStrategy(savedStrategy)
              ? savedStrategy.shortTrades
                  .slice()
                  .concat(savedStrategy.longTrades || [])
                  .sort((a, b) => a.id - b.id)
              : [];
          } else {
            trades = charts[0].trades
              .slice()
              .concat(charts[1]?.trades ?? [])
              .sort((a, b) => a.id - b.id);
          }
          break;
        }
      }
      dispatch(setChartDateConfig(savedStrategy?.chartConfig ?? { dateFrom: null, dateTo: null }));
    } else if (reducerStrategy.mergedStrategyData) {
      trades = reducerStrategy.mergedStrategyData.backtestData
        .reduce<ITradeData[]>(
          (a, b) => [
            ...a,
            ...(Array.isArray(b.trades[0])
              ? [...b.trades[0], ...(b.trades[1] as ITradeData[])]
              : (b.trades as ITradeData[])
            ).map((e) => ({ ...e, ticker: b.ticker })),
          ],
          [],
        )
        .sort((a, b) => a.id - b.id);

      dispatch(
        setChartDateConfig(
          reducerStrategy.mergedStrategyData.chartConfig ?? { dateFrom: null, dateTo: null },
        ),
      );
    }
    if (
      reducerStrategy.mergedStrategyData &&
      !reducerStrategy.selectedMergedStrategy &&
      !reducerStrategy.selectedStrategy
    ) {
      trades = [];
    }

    const isMergedStrategy = trades ? trades[0]?.ticker !== undefined : false;
    let pricePrecision: Record<string, number> | number = isMergedStrategy ? {} : 1;
    let qtyPrecision: Record<string, number> | number = isMergedStrategy ? {} : 1;

    for (let i = 0; i < trades.length; i++) {
      const trade = trades[i];
      const [price, qty] = getPrecisions(trade);

      if (isMergedStrategy) {
        if (price > (pricePrecision[trade.ticker] ?? 0)) pricePrecision[trade.ticker] = price;
        if (qty > (qtyPrecision[trade.ticker] ?? 0)) qtyPrecision[trade.ticker] = qty;
      } else {
        const [price, qty] = getPrecisions(trade);
        if (price > (pricePrecision as number)) pricePrecision = price;
        if (qty > (qtyPrecision as number)) qtyPrecision = qty;
      }
    }
    dispatch(setPricePrecision(pricePrecision));
    dispatch(setQtyPrecision(qtyPrecision));
    dispatch(setAllTrades(trades));
  }, [
    reducerStrategy.selectedStrategy,
    reducerStrategy.selectedMergedStrategy,
    reducerStrategy.mergedStrategyData,
    charts[0].trades,
    charts[1]?.trades,
  ]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      {/* <ChartWrapperHeader
        expandConstructor={expandConstructor}
        isConstructorOpen={isConstructorOpen}
        updateChartsSize={() => {
          const chartContainer = document.querySelector('.chart_container');
          if (chartContainer) {
            updateChartsSize(chartContainer);
          }
        }}
      /> */}
      <div className={styles.content}>
        <ChartWrapper
          updateChartsSize={() => {
            const chartContainer = document.querySelector('.chart_container');
            if (chartContainer) {
              updateChartsSize(chartContainer);
            }
          }}
          expandConstructor={expandConstructor}
          isConstructorOpen={isConstructorOpen}
        >
          <PanelGroup
            direction={positionCharts}
            ref={chartPanelsSection}
            onLayout={(e) => {
              if (e[0] !== 100 && e[1] !== 100) {
                setState(e);
              }
            }}
          >
            {charts
              .slice()
              .reverse()
              .map((e, index) =>
                !fullScreenChart || fullScreenChart === e.id ? (
                  <>
                    <Panel
                      className={`chart_container_resize_${index}`}
                      onResize={() => {
                        const chartContainer = document.querySelector(
                          `.chart_container_resize_${index}`,
                        );
                        if (chartContainer) {
                          dispatch(
                            updateChart({
                              id: e.id,
                              data: {
                                width: chartContainer.clientWidth,
                                height: chartContainer.clientHeight,
                              },
                            }),
                          );
                        }
                      }}
                      minSize={30}
                      collapsible={true}
                      style={{ position: 'relative' }}
                    >
                      <Chart
                        id={e.id}
                        setVisibleRange={(value) => {
                          setVisibleRangeState(value);
                        }}
                      />
                    </Panel>
                    {index !== charts.length - 1 && (
                      <ResizeHandle
                        className={
                          positionCharts === 'vertical'
                            ? styles.resizeHandleHorizontal
                            : styles.resizeHandleVertical
                        }
                      />
                    )}
                  </>
                ) : null,
              )}
          </PanelGroup>
        </ChartWrapper>
        <LoaderOverlay
          position={'absolute'}
          isShow={isLoading}
          isChart={fullScreenMode}
          imageWidth={140}
        />
      </div>
    </div>
  );
};
