import { useState, type FC } from 'react';
import './CustomThicknessDropDown.scss';
import Arrow from 'assets/images/Strategy/DropDownTriangle.svg';
interface IProps {
  activeOption: number;
  onChange: (id: number) => void;
  header?: string;
}
const CustomThicknessDropDown: FC<IProps> = ({ onChange, activeOption, header }) => {
  const options = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
  const [isOpened, setOpened] = useState(false);
  return (
    <div
      className="thickness-custom-dropdown"
      tabIndex={0}
      onBlur={() => {
        setOpened(false);
      }}
    >
      {header && <div className="thickness-custom-dropdown__header">{header}</div>}
      <div
        className={'thickness-custom-dropdown__selected'}
        onClick={() => {
          setOpened(!isOpened);
        }}
      >
        <div
          style={{
            height: `${activeOption}px`,
            minHeight: `${activeOption}px`,
            maxHeight: `${activeOption}px`,
            width: '50px',
            backgroundColor: ' white',
          }}
        ></div>
        <img
          className={
            'thickness-custom-dropdown__selected_arrow' +
            (isOpened ? ' thickness-custom-dropdown__selected_arrowOpened' : '')
          }
          src={Arrow}
          alt=">"
        />
      </div>

      {isOpened && (
        <div
          style={{ minHeight: options.length * 28 < 180 && `${options.length * 28}px ` }}
          className="thickness-custom-dropdown__itemsContainer"
        >
          {options.map((option, index) => (
            <div
              style={{
                borderTopLeftRadius: index === 0 ? '4px' : '',
                borderTopRightRadius: index === 0 ? '4px' : '',
              }}
              className={'thickness-custom-dropdown__item'}
              key={index}
              onClick={() => {
                setOpened(false);
                onChange(option.id);
              }}
            >
              <div
                style={{
                  height: `${option.id}px`,
                  minHeight: `${option.id}px`,
                  maxHeight: `${option.id}px`,
                  width: '50px',
                  backgroundColor: ' white',
                }}
              ></div>
            </div>
          ))}{' '}
        </div>
      )}
    </div>
  );
};

export default CustomThicknessDropDown;
