import React, { type FC } from 'react';
import classNames from 'classnames';
import ResizeIcon from 'assets/images/Strategy/ResizeIcon.svg';

import { PanelResizeHandle, type PanelResizeHandleProps } from 'react-resizable-panels';

import styles from './ResizableHandleStyles.module.css';

export const ResizeHandle: FC<PanelResizeHandleProps> = (props) => {
  return (
    <PanelResizeHandle {...props} className={classNames(styles.resizeHandleOuter, props.className)}>
      <div className={styles.resizeHandleInner}>
        <img src={ResizeIcon} alt="resize icon" />
      </div>
    </PanelResizeHandle>
  );
};
