import { type FC, useEffect, useState } from 'react';
import { type IAdminUserData } from '../../services/api/Admin/types';
import { apiAdmin } from '../../services/api/Admin/ApiAdmin';
import { useNavigate } from 'react-router-dom';
import { Roles } from '../../services/api/Profile/types';
import { rolesArray, rolesNames } from '../../configs/user';

export const Admin: FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<IAdminUserData[]>([]);
  const [deleteAccount, setDeleteAccount] = useState<number | null>(null);

  useEffect(() => {
    apiAdmin
      .getData()
      .then((r) => {
        if (!r) {
          navigate('/menu');
          return;
        }
        setData(r);
      })
      .catch(() => {
        navigate('/menu');
      });
  }, []);
  return (
    <center>
      <table style={{ color: 'white', width: '90%', overflow: 'auto' }}>
        <tr>
          <th>Login</th>
          <th>Email</th>
          <th>Telegram</th>
          <th>Roles</th>
          <th>One-Way Count</th>
          <th>Hedge Count</th>
          <th>Merged Count</th>
          <th>Last Activity</th>
          <th>Is Online</th>
          <th>Created At</th>
          <th>Download Strategies</th>
          <th>Delete account</th>
        </tr>

        {data.map((e, i) => (
          <tr key={i}>
            <th>{e.name}</th>
            <th>{e.email}</th>
            <th>{e.telegram ? 'Yes' : 'No'}</th>
            <th style={{ display: 'flex', gap: '5px' }}>
              {e.roles.map((role, key) => (
                <div
                  key={i + key}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    apiAdmin.removeRole(e.id, role).then((r) => {
                      if (r) {
                        const tempData = data.slice();
                        tempData[i] = {
                          ...tempData[i],
                          roles: e.roles.filter((e) => e !== role),
                        };
                        setData(tempData);
                      }
                    });
                  }}
                >
                  {Roles[role]}
                </div>
              ))}
              <select
                onChange={(event) => {
                  const role = parseInt(event.target.value);
                  apiAdmin.addRole(e.id, role).then((r) => {
                    if (r) {
                      if (r) {
                        const tempData = data.slice();
                        tempData[i] = {
                          ...tempData[i],
                          roles: [...e.roles, role],
                        };
                        setData(tempData);
                      }
                    }
                  });
                }}
              >
                <option value="" disabled selected>
                  Add role
                </option>
                {rolesArray
                  .filter((role) => !e.roles.includes(role))
                  .map((role, roleIndex) => (
                    <option key={roleIndex} value={role}>
                      {rolesNames[role]}
                    </option>
                  ))}
              </select>
            </th>
            <th>{e.strategy_count.oneway}</th>
            <th>{e.strategy_count.hedge}</th>
            <th>{e.strategy_count.merged}</th>
            <th>{new Date(e.last_login * 1000).toLocaleString()}</th>
            <th>{Math.floor(Date.now() / 1000) - e.last_login < 3 * 60 ? 'Yes' : 'No'}</th>
            <th>{new Date(e.created_at).toLocaleString()}</th>
            <th>
              <button
                style={{ color: 'white' }}
                onClick={() => {
                  apiAdmin.getStrategies(e.id).then((r) => {
                    if (r) {
                      const link = document.createElement('a');
                      const file = new Blob([JSON.stringify(r, null, 2)], {
                        type: 'text/plain',
                      });
                      link.href = URL.createObjectURL(file);
                      link.download = `strategies_${e.name}.json`;
                      link.click();
                      URL.revokeObjectURL(link.href);
                    }
                  });
                }}
              >
                Download
              </button>
            </th>
            <th>
              <button
                style={{ color: 'white' }}
                onClick={() => {
                  if (deleteAccount !== i) {
                    setDeleteAccount(i);
                  } else {
                    apiAdmin.removeAccount(e.id).then((r) => {
                      if (r) {
                        setData(data.filter((el) => el.id !== e.id));
                      }
                    });
                  }
                }}
              >
                {deleteAccount === i ? 'Confirm' : 'Delete Account'}
              </button>
            </th>
          </tr>
        ))}
      </table>
    </center>
  );
};
