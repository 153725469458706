// import { IStatistics } from 'interfaces/IStatistics';
// import { DefaultHomePageState } from './../../pages/HomePage/DefaultState';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type IUpdateListEditor, type IChartState, type IChartDataState } from './types';
import { ChartEditorFigure } from '../../enums/ChartEditorFigure';
import { type IChartConfig } from '../../interfaces/IChart';
import { Exchanges } from '../../interfaces/IStrategy';
import { TIMEFRAMES } from '../../configs/chart';
// import { v4 } from 'uuid';
import { type ITradeData } from 'interfaces/ITrade';
import { type IStatistics } from 'interfaces/IStatistics';
import { type IRootState } from 'redux/rootReducer';
import { type TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';

export const getNewChartData = (
  data: Partial<{ id: string } & IChartDataState>,
): IChartDataState => {
  return {
    id: data.id,
    ticker: {
      type: 'default',
      baseAsset: 'BTC',
      quoteAsset: 'USDT',
    },
    width: 500,
    height: 500,
    exchange: Exchanges.BinanceFutures,
    timeframe: TIMEFRAMES['15m'],
    candles: [],
    trades: [],
    selectedEditor: null,
    selectedTradeId: null,
    isFigureLock: false,
    isFigureVisible: true,
    isFigureDestroy: false,
    figuresList: [
      ChartEditorFigure.HORIZONTAL_LINE,
      ChartEditorFigure.PRICE_CHANNEL_LINE,
      ChartEditorFigure.CIRCLE,
      ChartEditorFigure.FIBONACCI_LINE,
      ChartEditorFigure.XABCD,
      ChartEditorFigure.MAGNET,
    ],
    isDynamicBackTest: false,
    enableScrollToReal: false,
    ...data,
  };
};

export const initialChartState: IChartState = {
  charts: [getNewChartData({ id: '0' })],
  selectedChart: null,
  fullScreenChart: null,
  fullScreenMode: false,
  positionCharts: 'vertical',
  isSideMenu: false,
  isLoading: false,
  showStatistics: true,
  isShowStatisticsData: false,
  dateConfig: {
    dateFrom: null,
    dateTo: null,
  },
  isPreview: false,
  allTrades: [],
  pricePrecision: 1,
  qtyPrecision: 1,
  stats: {
    balance: 0,
    initial_balance: 0,
    net_profit: [0, 0], // USD, Percent
    trades_count: [0, 0], // Short, Long
    commission_paid: [0, 0], // Short, Long
    profit_percent: [0, 0, 0], // Short, Long, Both
    pnl_trades: [0, 0],
    liquidation_count: [0, 0, 0],
    max_drawdown: [
      [0, 0], // Short
      [0, 0], // Long
      [0, 0], // Both
    ],
    max_balance_drawdown: [0, 0],
    stoploss_in_row: [0, 0, 0],
    avg_trade_profit: [
      [0, 0], // Short
      [0, 0], // Long
      [0, 0], // Both
    ],
    open_pnl: [null, null],
    cv_factor: 0,
    recovery_factor: 0,
    sharp_factor: 0,
    overall_factor: 0,
  },
};

initialChartState.selectedChart = initialChartState.charts[0].id;

type ChartPayload<T> = PayloadAction<{ data?: T; id: string; mode?: 'hedge' }>;

// export const getChartById = (id: string): IChartDataState => {
//   console.log(initialChartState);
//   return initialChartState?.charts?.find((e) => e.id === id);
// };
const chartReducer = createSlice({
  name: 'chart',
  initialState: initialChartState,
  reducers: {
    setSelectedEditor: (state, action: ChartPayload<ChartEditorFigure | null>) => {
      const chart = state.charts.find((e) => e.id === action.payload.id);

      chart.isFigureDestroy = false;
      chart.selectedEditor = action.payload.data;
    },
    updateListEditor: (state, action: ChartPayload<IUpdateListEditor>) => {
      const chart = state.charts.find((e) => e.id === action.payload.id);

      const idx = chart.figuresList.indexOf(action.payload.data.prevEditor);
      chart.figuresList[idx] = action.payload.data.newEditor;
    },
    setIsLose(state, action: ChartPayload<never>) {
      const chart = state.charts.find((e) => e.id === action.payload.id);
      chart.isFigureLock = !chart.isFigureLock;
    },
    changeFigureVisible(state, action: ChartPayload<never>) {
      const chart = state.charts.find((e) => e.id === action.payload.id);
      chart.isFigureVisible = !chart.isFigureVisible;
    },

    setFigureDestroy(state, action: ChartPayload<never>) {
      state.charts.find((e) => e.id === action.payload.id).isFigureDestroy = true;
    },

    setDynamicBackTest: (state, action: ChartPayload<boolean>) => {
      state.charts.find((e) => e.id === action.payload.id).isDynamicBackTest = action.payload.data;
    },

    setChartTimeframe: (state, action: ChartPayload<number>) => {
      state.charts.find((e) => e.id === action.payload.id).timeframe = action.payload.data;
    },
    updateTradesHedgeCharts: (
      state,
      action: ChartPayload<Partial<{ trades0?: ITradeData[]; trades1?: ITradeData[] }>>,
    ) => {
      state.charts[0] = {
        ...state.charts[0],
        trades: action.payload.data?.trades0,
      };

      state.charts[1] = {
        ...state.charts[1],
        trades: action.payload.data?.trades1,
      };
    },
    updateChart: (state, action: ChartPayload<Partial<IChartDataState>>) => {
      const index = state.charts.indexOf(state.charts.find((e) => e.id === action.payload.id));
      state.charts[index] = {
        ...state.charts[index],
        ...action.payload.data,
      };
    },
    changePositionCharts: (state, action: PayloadAction<IChartState['positionCharts']>) => {
      state.positionCharts = action.payload;
    },

    setSelectedChart: (state, action: PayloadAction<string>) => {
      state.selectedChart = action.payload;
    },

    addChart: (state, action: PayloadAction<Partial<IChartDataState>>) => {
      state.charts.push(getNewChartData(action.payload));
    },

    setCharts: (state, action: PayloadAction<IChartDataState[]>) => {
      const selectedIndex = state.charts.findIndex((e) => e.id === state.selectedChart);
      state.charts = action.payload;
      state.selectedChart = action.payload[selectedIndex]?.id ?? action.payload[0].id;
      state.fullScreenChart = null;
    },

    removeChart: (state, action: PayloadAction<string>) => {
      const index = state.charts.findIndex((e) => e.id === action.payload);

      if (index !== -1) {
        state.charts.splice(index, 1);
      }
    },

    toggleSideMenu: (state, action: PayloadAction<boolean | undefined>) => {
      state.isSideMenu = action.payload ?? !state.isSideMenu;
    },

    setChartLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setFullScreenChart: (state, action: PayloadAction<string | null>) => {
      state.fullScreenChart = action.payload;
    },
    setFullScreenMode: (state, action: PayloadAction<boolean>) => {
      state.fullScreenMode = action.payload;
    },

    setShowStatistics: (state, action: PayloadAction<boolean>) => {
      state.showStatistics = action.payload;
    },
    setIsShowStatisticsData: (state, action: PayloadAction<boolean>) => {
      state.isShowStatisticsData = action.payload;
    },

    setChartDateConfig: (state, action: PayloadAction<IChartConfig | null>) => {
      state.dateConfig = action.payload;
    },
    // new reducers
    setAllTrades: (state, action: PayloadAction<ITradeData[]>) => {
      state.allTrades = action.payload;
    },
    updateAllTrades: (state, action: PayloadAction<ITradeData>) => {
      for (let i = state.allTrades.length - 1; i >= 0; i--) {
        if (state.allTrades[i].id === action.payload.id) {
          state.allTrades[i] = action.payload;
        }
      }
    },
    setStats: (state, action: PayloadAction<IStatistics>) => {
      state.stats = action.payload;
    },
    setPricePrecision(state, action: PayloadAction<Record<string, number> | number>) {
      state.pricePrecision = action.payload;
    },
    setQtyPrecision(state, action: PayloadAction<Record<string, number> | number>) {
      state.qtyPrecision = action.payload;
    },
    setIsPreview(state, action: PayloadAction<boolean>) {
      state.isPreview = action.payload;
    },
  },
});

export const {
  setSelectedEditor,
  updateListEditor,
  setIsLose,
  changeFigureVisible,
  setFigureDestroy,
  setChartDateConfig,
  setDynamicBackTest,
  setChartTimeframe,
  changePositionCharts,
  updateChart,
  setSelectedChart,
  toggleSideMenu,
  setChartLoading,
  setCharts,
  setFullScreenChart,
  setShowStatistics,
  setIsShowStatisticsData,
  setAllTrades,
  updateAllTrades,
  setStats,
  setPricePrecision,
  setQtyPrecision,
  updateTradesHedgeCharts,
  setIsPreview,
  setFullScreenMode,
} = chartReducer.actions;

export default chartReducer.reducer;

export const useSelector: TypedUseSelectorHook<IRootState> = useReduxSelector;
export const getChartById = (state: IChartState, id: string): IChartDataState => {
  return state.charts.find((e) => e.id === id);
};
export const useChartSelector = (id: string): IChartDataState =>
  useSelector((state) => getChartById(state.chart, id));
