import RoundGTumbler from 'components/RoundGTumbler/RoundGTumbler';
import PercentIcon from 'assets/images/Profile/PercentIcon.svg';
import DollarIcon from 'assets/images/Profile/DollarIcon.svg';
import { useEffect, useState } from 'react';
// import { HeaderDayPicker } from 'pages/HomePage/components/Chart/components/HeaderDayPicker';
import { DayPicker } from '../../DayPicker';

export const StatisticSettings = (): JSX.Element => {
  const [unit, setUnit] = useState('percent');
  const [fromData, setFromData] = useState(null);
  const [toData, setToData] = useState(null);
  useEffect(() => {
    setFromData(new Date());
    setToData(new Date());
  }, []);

  return (
    <div className="propertyMainContainer">
      <div className="propertyContainer">VISIBILITY OF STATISTICS:</div>
      <div className="propertyContainer_inputContainer">
        Show my statistic <RoundGTumbler name="status" />
      </div>
      <div className="propertyContainer propertyContainer_dateContainer">
        <div className="propertyContainer_date">
          From:{' '}
          <DayPicker
            onSelect={(data) => {
              setFromData(data);
            }}
            fromYear={2015}
            value={fromData}
          />
        </div>
        <div className="propertyContainer_date">
          To:
          <DayPicker
            onSelect={(data) => {
              setToData(data);
            }}
            fromYear={2015}
            value={toData}
          />
        </div>
      </div>

      <div className="propertyContainer_inputContainer">
        Show in{' '}
        <div className="mainConatiner_content_info_profileContainer_general_userContainer_Settings_settings_menu_row_units">
          <span
            onClick={() => {
              setUnit('percent');
            }}
            style={{ backgroundColor: unit === 'percent' ? ' #A700F6' : '#a3a3a3' }}
          >
            <img src={PercentIcon} alt="" />
          </span>
          <span
            onClick={() => {
              setUnit('dollar');
            }}
            style={{ backgroundColor: unit === 'dollar' ? '#A700F6' : '#a3a3a3' }}
          >
            <img src={DollarIcon} alt="" />
          </span>
        </div>
      </div>
    </div>
  );
};
