import { ChartHorizontalLineIcon } from '../assets/icons/ChartHorizontalLineIcon';
import { ChartPriceChannelLineIcon } from '../assets/icons/ChartPriceChannelLineIcon';
import { ChartCircleIcon } from '../assets/icons/ChartCircleIcon';
import { ChartFibonacciLineIcon } from '../assets/icons/ChartFibonacciLineIcon';
import { ChartXABCDIcon } from '../assets/icons/ChartXABCDIcon';
import { ChartMagnetIcon } from '../assets/icons/ChartMagnetIcon';
import { type FunctionComponent } from 'react';
import { type IPassIconProps } from '../assets/icons/passIconProps';
import { ChartHorizontalReyIcon } from '../assets/icons/ChartHorizontalRayIcon';
import { ChartHorizontalLIneSegmentIcon } from '../assets/icons/ChartHorizontalLIneSegmentIcon';
import { ChartVerticalStraightLineIcon } from '../assets/icons/ChartVerticalStraightLineIcon';
import { ChartVerticalRayIcon } from '../assets/icons/ChartVerticalRayIcon';
import { ChartVerticalLineSegmentIcon } from '../assets/icons/ChartVerticalLineSegmentIcon';
import { ChartStraightLineIcon } from '../assets/icons/ChartStraightLineIcon';
import { ChartRaysIcon } from '../assets/icons/ChartRaysIcon';
import { ChartLineSegmentIcon } from '../assets/icons/ChartLineSegmentIcon';
import { ChartArrowIcon } from '../assets/icons/ChartArrowIcon';
import { ChartPriceIcon } from '../assets/icons/ChartPriceLineIcon';
import { ChartParallelStraightLinesIcon } from '../assets/icons/ChartParallelStraightLinesIcon';
import { ChartRectangleIcon } from '../assets/icons/ChartRectangleIcon';
import { ChartParallelogramIcon } from '../assets/icons/ChartParallelogramIcon';
import { ChartTriangleIcon } from '../assets/icons/ChartTriangleIcon';
import { ChartFibonacciRetracementLinesIcon } from '../assets/icons/ChartFibonacciRetracementLinesIcon';
import { ChartFibonacciCirclesIcon } from '../assets/icons/ChartFibonacciCirclesIcon';
import { ChartFibonacciSpiralIcon } from '../assets/icons/ChartFibonacciSpiralIcon';
import { ChartFibonacciSpeedResistanceFanIcon } from '../assets/icons/ChartFibonacciSpeedResistanceFanIcon';
import { ChartFibonacciTrendExpansionIcon } from '../assets/icons/ChartFibonacciTrendExpansionIcon';
import { ChartGannBoxIcon } from '../assets/icons/ChartGannBoxIcon';
import { ChartABCDIcon } from '../assets/icons/ChartABCDIcon';
import { ChartThreeWavesIcon } from '../assets/icons/ChartThreeWavesIcon';
import { ChartFiveWavesIcon } from '../assets/icons/ChartFiveWavesIcon';
import { ChartEightWavesIcon } from '../assets/icons/ChartEightWavesIcon';
import { ChartAnyWaveIcon } from '../assets/icons/ChartAnyWaveIcon';
import { ChartStrongMagneticWaveIcon } from '../assets/icons/ChartSrtongMagneticWaveIcon';
import { ChartEditorFigure } from '../enums/ChartEditorFigure';

export interface IChartEditorFigure {
  name: string;
  children: ChartEditorFigure[];
  icon: FunctionComponent<IPassIconProps>;
}

const firstEditors = [
  ChartEditorFigure.HORIZONTAL_LINE,
  ChartEditorFigure.HORIZONTAL_RAY,
  ChartEditorFigure.HORIZONTAL_LINE_SEGMENT,
  ChartEditorFigure.VERTICAL_STRAIGHT_LINE,
  ChartEditorFigure.VERTICAL_RAY,
  ChartEditorFigure.VERTICAL_LINE_SEGMENT,
  ChartEditorFigure.STRAIGHT_LINE,
  ChartEditorFigure.RAYS,
  ChartEditorFigure.LINE_SEGMENT,
  ChartEditorFigure.ARROW,
  ChartEditorFigure.PRICE_LINE,
];

const secondEditors = [
  ChartEditorFigure.PRICE_CHANNEL_LINE,
  ChartEditorFigure.PARALLEL_STRAIGHT_LINES,
];

const thirdEditors = [
  ChartEditorFigure.CIRCLE,
  ChartEditorFigure.RECTANGLE,
  ChartEditorFigure.PARALLELOGRAM,
  ChartEditorFigure.TRIANGLE,
];

const fourthEditors = [
  ChartEditorFigure.FIBONACCI_LINE,
  ChartEditorFigure.FIBONACCI_RETRACEMENT_LINES,
  ChartEditorFigure.FIBONACCI_CIRCLES,
  ChartEditorFigure.FIBONACCI_SPIRAL,
  ChartEditorFigure.FIBONACCI_SPEED_RESISTANCE_FAN,
  ChartEditorFigure.FIBONACCI_TREND_EXPANSION,
  ChartEditorFigure.GANN_BOX,
];

const fifthEditor = [
  ChartEditorFigure.XABCD,
  ChartEditorFigure.ABCD,
  ChartEditorFigure.THREE_WAVES,
  ChartEditorFigure.FIVE_WAVES,
  ChartEditorFigure.EIGHT_WAVES,
  ChartEditorFigure.ANY_WAVE,
];

const sixthEditor = [ChartEditorFigure.MAGNET, ChartEditorFigure.STRONG_MAGNETIC];
export const chartEditorIcons: Record<ChartEditorFigure, IChartEditorFigure> = {
  [ChartEditorFigure.HORIZONTAL_LINE]: {
    name: 'Horizontal straight line',
    children: firstEditors,
    icon: ChartHorizontalLineIcon,
  },
  [ChartEditorFigure.PRICE_CHANNEL_LINE]: {
    name: 'Price channel line',
    children: secondEditors,
    icon: ChartPriceChannelLineIcon,
  },
  [ChartEditorFigure.CIRCLE]: {
    name: 'Round',
    children: thirdEditors,
    icon: ChartCircleIcon,
  },
  [ChartEditorFigure.FIBONACCI_LINE]: {
    name: 'Fibonacci retracement line',
    children: fourthEditors,
    icon: ChartFibonacciLineIcon,
  },
  [ChartEditorFigure.XABCD]: {
    name: 'XABCD pattern',
    children: fifthEditor,
    icon: ChartXABCDIcon,
  },
  [ChartEditorFigure.MAGNET]: {
    name: 'Field weakening mode',
    children: sixthEditor,
    icon: ChartMagnetIcon,
  },
  [ChartEditorFigure.HORIZONTAL_RAY]: {
    name: 'Horizontal ray',
    children: firstEditors,
    icon: ChartHorizontalReyIcon,
  },
  [ChartEditorFigure.HORIZONTAL_LINE_SEGMENT]: {
    name: 'Horizontal line segment',
    children: firstEditors,
    icon: ChartHorizontalLIneSegmentIcon,
  },
  [ChartEditorFigure.VERTICAL_STRAIGHT_LINE]: {
    name: 'Vertical straight line',
    children: firstEditors,
    icon: ChartVerticalStraightLineIcon,
  },
  [ChartEditorFigure.VERTICAL_RAY]: {
    name: 'Vertical ray',
    children: firstEditors,
    icon: ChartVerticalRayIcon,
  },
  [ChartEditorFigure.VERTICAL_LINE_SEGMENT]: {
    name: 'Vertical line segment',
    children: firstEditors,
    icon: ChartVerticalLineSegmentIcon,
  },
  [ChartEditorFigure.STRAIGHT_LINE]: {
    name: 'Straight line',
    children: firstEditors,
    icon: ChartStraightLineIcon,
  },
  [ChartEditorFigure.RAYS]: {
    name: 'Rays',
    children: firstEditors,
    icon: ChartRaysIcon,
  },
  [ChartEditorFigure.LINE_SEGMENT]: {
    name: 'Line segment',
    children: firstEditors,
    icon: ChartLineSegmentIcon,
  },
  [ChartEditorFigure.ARROW]: {
    name: 'Arrow',
    children: firstEditors,
    icon: ChartArrowIcon,
  },
  [ChartEditorFigure.PRICE_LINE]: {
    name: 'Price line',
    children: firstEditors,
    icon: ChartPriceIcon,
  },
  [ChartEditorFigure.PARALLEL_STRAIGHT_LINES]: {
    name: 'Parallel straight lines',
    children: secondEditors,
    icon: ChartParallelStraightLinesIcon,
  },
  [ChartEditorFigure.RECTANGLE]: {
    name: 'Rectangle',
    children: thirdEditors,
    icon: ChartRectangleIcon,
  },
  [ChartEditorFigure.PARALLELOGRAM]: {
    name: 'Parallelogram',
    children: thirdEditors,
    icon: ChartParallelogramIcon,
  },
  [ChartEditorFigure.TRIANGLE]: {
    name: 'Triangle',
    children: thirdEditors,
    icon: ChartTriangleIcon,
  },
  [ChartEditorFigure.FIBONACCI_RETRACEMENT_LINES]: {
    name: 'Fibonacci retracement lines',
    children: fourthEditors,
    icon: ChartFibonacciRetracementLinesIcon,
  },
  [ChartEditorFigure.FIBONACCI_CIRCLES]: {
    name: 'Fibonacci circles',
    children: fourthEditors,
    icon: ChartFibonacciCirclesIcon,
  },
  [ChartEditorFigure.FIBONACCI_SPIRAL]: {
    name: 'Fibonacci spiral',
    children: fourthEditors,
    icon: ChartFibonacciSpiralIcon,
  },
  [ChartEditorFigure.FIBONACCI_SPEED_RESISTANCE_FAN]: {
    name: 'Fibonacci speed resistance fan',
    children: fourthEditors,
    icon: ChartFibonacciSpeedResistanceFanIcon,
  },
  [ChartEditorFigure.FIBONACCI_TREND_EXPANSION]: {
    name: 'Fibonacci trend expansion',
    children: fourthEditors,
    icon: ChartFibonacciTrendExpansionIcon,
  },
  [ChartEditorFigure.GANN_BOX]: {
    name: 'Gann box',
    children: fourthEditors,
    icon: ChartGannBoxIcon,
  },
  [ChartEditorFigure.ABCD]: {
    name: 'ABCD pattern',
    children: fifthEditor,
    icon: ChartABCDIcon,
  },
  [ChartEditorFigure.THREE_WAVES]: {
    name: 'Three waves',
    children: fifthEditor,
    icon: ChartThreeWavesIcon,
  },
  [ChartEditorFigure.FIVE_WAVES]: {
    name: 'Five waves',
    children: fifthEditor,
    icon: ChartFiveWavesIcon,
  },
  [ChartEditorFigure.EIGHT_WAVES]: {
    name: 'Eight waves',
    children: fifthEditor,
    icon: ChartEightWavesIcon,
  },
  [ChartEditorFigure.ANY_WAVE]: {
    name: 'Any wave',
    children: fifthEditor,
    icon: ChartAnyWaveIcon,
  },
  [ChartEditorFigure.STRONG_MAGNETIC]: {
    name: 'Strong magnetic mode',
    children: sixthEditor,
    icon: ChartStrongMagneticWaveIcon,
  },
};
