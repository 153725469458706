export const TIMEFRAMES = [
  { id: 1, time: 1000, value: '1s', disabled: true, marked: false },
  { id: 2, time: 5000, value: '5s', disabled: true, marked: false },
  { id: 3, time: 15000, value: '15s', disabled: true, marked: false },
  { id: 4, time: 30000, value: '30s', disabled: true, marked: false },
  { id: 5, time: 60000, value: '1m', disabled: false, marked: false },
  { id: 6, time: 3 * 60000, value: '3m', disabled: false, marked: true },
  { id: 7, time: 5 * 60000, value: '5m', disabled: false, marked: false },
  { id: 8, time: 15 * 60000, value: '15m', disabled: false, marked: true },
  { id: 9, time: 30 * 60000, value: '30m', disabled: false, marked: false },
  { id: 10, time: 60 * 60000, value: '1h', disabled: false, marked: false },
  { id: 11, time: 4 * 60 * 60000, value: '4h', disabled: false, marked: true },
  { id: 12, time: 24 * 60 * 60000, value: '1d', disabled: false, marked: false },
  { id: 13, time: 7 * 24 * 60 * 60000, value: '1w', disabled: false, marked: false },
];
