import { useDispatch } from 'react-redux';
import GTumbler from 'components/GTumbler/GTumbler';
import { useSelector } from 'redux/rootReducer';
import { refreshXAxisSettings, updateXAxisProperty } from 'redux/chartXAxisSettings/reducer';
import { CustomColorPicker } from '../../ColorPicker/ColorPicker';
import CustomDropDown from '../../DropDownComponents/CustomMultiLineDropDown/CustomStyleSettingsDropDown';
import { fontWeightTypes, numberSizes } from '../../../Data/dropdownData';
import { type XAxisStyle } from '@basttyy/klinecharts';
import useWindowWidth from 'hooks/useWindowWidth';
import { apiChartStyles } from '../../../../../../../services/api/ApiChartStyles/ApiChartStyles';

export const XAxisSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const settings: XAxisStyle = useSelector((state) => state.chartXAxisSettings);
  const isMobileVersion = useWindowWidth() < 768;
  return (
    <div className="propertyMainContainer">
      <div className="container__content_main_content_header">
        X-AXIS SETTINGS
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Show xAxis
        <GTumbler
          checked={settings.show}
          size={isMobileVersion ? 'large' : null}
          name="xAxis"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'xAxis.show',
              value,
            });
            dispatch(
              updateXAxisProperty({
                propertyNode1: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Show axis line
        <GTumbler
          checked={settings.axisLine.show}
          size={isMobileVersion ? 'large' : null}
          name="xAxis"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'xAxis.axisLine.show',
              value,
            });
            dispatch(
              updateXAxisProperty({
                propertyNode1: 'axisLine',
                propertyNode2: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Axis line size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.axisLine.size),
              name: settings.axisLine.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'xAxis.axisLine.size',
                value,
              });
              dispatch(
                updateXAxisProperty({
                  propertyNode1: 'axisLine',
                  propertyNode2: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Axis line color
        <CustomColorPicker
          defaultColor={settings.axisLine.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'xAxis.axisLine.color',
              value: color,
            });
            dispatch(
              updateXAxisProperty({
                propertyNode1: 'axisLine',
                propertyNode2: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>

      <div className="container__content_main_content_header">
        TICK TEXT SETTINGS(X)
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Show tick text
        <GTumbler
          checked={settings.tickText.show}
          size={isMobileVersion ? 'large' : null}
          name="grid"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'xAxis.tickText.show',
              value,
            });
            dispatch(
              updateXAxisProperty({
                propertyNode1: 'tickText',
                propertyNode2: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Tick text size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.tickText.size),
              name: settings.tickText.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'xAxis.tickText.size',
                value,
              });
              dispatch(
                updateXAxisProperty({
                  propertyNode1: 'tickText',
                  propertyNode2: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Tick text weight
        <div className="propertyContainer__dropDown">
          <CustomDropDown
            largeList
            options={fontWeightTypes}
            activeOption={{
              id: Number(
                fontWeightTypes.findIndex((item) => item.name === settings.tickText.weight),
              ),
              name: settings.tickText.weight.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'xAxis.tickText.weight',
                value: fontWeightTypes[value - 1].name,
              });

              dispatch(
                updateXAxisProperty({
                  propertyNode1: 'tickText',
                  propertyNode2: 'weight',
                  value: fontWeightTypes[value - 1].name,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Tick text color
        <CustomColorPicker
          defaultColor={settings.tickText.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'xAxis.tickText.color',
              value: color,
            });
            dispatch(
              updateXAxisProperty({
                propertyNode1: 'tickText',
                propertyNode2: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>
      <div className="container__content_main_content_header">
        TICK LINE SETTINGS(X)
        <div className="propertyContainer__candleTypeDropDown"></div>
      </div>
      <div className="propertyContainer">
        Show tick line
        <GTumbler
          checked={settings.tickLine.show}
          size={isMobileVersion ? 'large' : null}
          name="grid"
          lightBackground
          onToggle={(value) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'xAxis.tickLine.show',
              value,
            });
            dispatch(
              updateXAxisProperty({
                propertyNode1: 'tickLine',
                propertyNode2: 'show',
                value,
              }),
            );
          }}
        />
      </div>
      <div className="propertyContainer">
        Tick line size
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.tickLine.size),
              name: settings.tickLine.size.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'xAxis.tickLine.size',
                value,
              });
              dispatch(
                updateXAxisProperty({
                  propertyNode1: 'tickLine',
                  propertyNode2: 'size',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Tick line length
        <div className="propertyContainer__numberDropdown">
          <CustomDropDown
            largeList
            options={numberSizes}
            activeOption={{
              id: Number(settings.tickLine.length),
              name: settings.tickLine.length.toString(),
            }}
            onChange={(value) => {
              apiChartStyles.updateChartStylingProperty({
                key: 'xAxis.tickLine.length',
                value,
              });
              dispatch(
                updateXAxisProperty({
                  propertyNode1: 'tickLine',
                  propertyNode2: 'length',
                  value,
                }),
              );
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">
        Tick line color
        <CustomColorPicker
          defaultColor={settings.tickLine.color}
          onAddColor={(color) => {
            apiChartStyles.updateChartStylingProperty({
              key: 'xAxis.tickLine.color',
              value: color,
            });
            dispatch(
              updateXAxisProperty({
                propertyNode1: 'tickLine',
                propertyNode2: 'color',
                value: color,
              }),
            );
          }}
        />
      </div>
      <button
        className="container__content_main_content_refresh"
        onClick={() => {
          apiChartStyles.refreshChartStylingSettings('xAxis');
          dispatch(refreshXAxisSettings());
        }}
      >
        {' '}
        Refresh settings
      </button>
    </div>
  );
};
