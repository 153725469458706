export enum ChartTabs {
  BARS = 'bars',
  LINES = 'line',
}

export enum ChartTime {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

export enum HistorySectionModes {
  FULL_SCREEN = 'fullScreen',
  COLLAPSE = 'collapse',
}
