import { useEffect, useState } from 'react';
import CheckBoxChecked from 'assets/images/Strategy/CheckboxChecked.svg';
import CheckBox from 'assets/images/Strategy/Checkbox.svg';
import './style.scss';
import { StrategySelectorXL } from '../StrategySelectorXL/StrategySelectorXL';
import { getActiveSettingsStrategy } from '../../../../../../utils/strategy';

interface IProps {
  name: string;
  itemNumber: number;
  onChange: (data: number) => void;
}

export const TrailingStopItem = ({ name, itemNumber, onChange }: IProps): JSX.Element => {
  const activeSettings = getActiveSettingsStrategy();
  const [data, setData] = useState({
    isChecked: activeSettings.take_profits[itemNumber]?.[2] !== -1,
    stopTo: {
      id: 0,
      name: 'Entry',
    },
  });
  const stopToList = [{ id: 0, name: 'Entry' }];
  for (let i = 0; i < itemNumber; i++) {
    stopToList.push({
      id: i + 1,
      name: `TP${i + 1}`,
    });
  }

  useEffect(() => {
    if (data.isChecked) {
      onChange(data.stopTo.id);
    } else {
      onChange(-1);
    }
  }, [data.stopTo, data.isChecked]);
  return (
    <>
      <div className="nameContainer">
        <img
          onClick={() => {
            setData({ ...data, isChecked: !data.isChecked });
          }}
          src={data.isChecked ? CheckBoxChecked : CheckBox}
          alt=" icon"
        />
        <div>{name}</div>
      </div>
      <div className="dropDownContainer">
        <StrategySelectorXL
          options={stopToList}
          activeOption={data.stopTo.id}
          width="100%"
          onChange={(e) => {
            setData({ ...data, stopTo: { id: e, name: stopToList[e].name } });
          }}
        />
      </div>
    </>
  );
};
