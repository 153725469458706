import './StrategyMetrics.scss';
interface IProps {
  recoveryFactor: number;
}
const SharpFactor = ({ recoveryFactor }: IProps): JSX.Element => {
  const factor = recoveryFactor < 0 ? 0 : recoveryFactor > 2 ? 2 : recoveryFactor;
  const getColor = (value): string => {
    if (value <= 50) {
      const red = 255;
      const green = Math.round((value / 50) * 255);
      return `rgb(${red}, ${green}, 0)`;
    } else {
      const green = 255;
      const red = Math.round(((100 - value) / 50) * 255);
      return `rgb(${red}, ${green}, 0)`;
    }
  };
  return (
    <div className="strategy-metrics_perfomanceItem">
      <div className="strategy-metrics_perfomanceItem_text">
        {' '}
        <div>Sharpe ratio:</div>{' '}
        <span style={{ color: getColor(Number(factor * 50)) }}>{factor.toFixed(2)}</span>
      </div>
      <div className="strategy-metrics_perfomanceItem_progressBar">
        <span
          className="strategy-metrics_perfomanceItem_progressBar_fill"
          style={{ width: `${Number(factor * 50)}%` }}
        ></span>
      </div>
      <div className="strategy-metrics_perfomanceItem_text">
        <span>worst 0</span>
        <span>best 2</span>
      </div>
    </div>
  );
};

export default SharpFactor;
