import { useRef } from 'react';
import { type ICustomTickerElement } from 'redux/customTicker/types';
import styles from '../ChartWrapper.module.css';
import { useFakeScrollBar } from '../customHooks/useFakeScrollBar';
export interface IProps {
  tickers: ICustomTickerElement[];
}
const TickerList = ({ tickers }: IProps): JSX.Element => {
  const contentWrapperRef = useRef(null);
  const fakeScrollBarRef = useFakeScrollBar(contentWrapperRef);

  return (
    <div className={styles.chartHeaderStartCustomContent} ref={contentWrapperRef}>
      {tickers.map((ticker, index) => (
        <div key={index} className={styles.chartHeaderStartCustomContentItem}>
          <img
            className={styles.tickerSelectorContentImage}
            src={`${process.env.PUBLIC_URL}/coins/${ticker.baseAsset}.png`}
            alt=""
          />
          <div>
            {ticker.baseAsset} / {ticker.quoteAsset}
            <span> {ticker.percent.toFixed(2)}%</span>
          </div>
        </div>
      ))}
      <div
        className={styles.fakeChartScrollBar2}
        ref={fakeScrollBarRef}
        style={{ bottom: '4px' }}
      ></div>
      <div className={styles.fakeChartScrollBarContainer}></div>
    </div>
  );
};

export default TickerList;
