import { type FC, type ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import cn from 'classnames';

import './ReCaptcha.scss';

interface ReCaptchaProps {
  callback: (state: boolean, token?: string) => void;
  class?: string;
  ref?: ForwardedRef<{ reset: () => void }>;
}

// eslint-disable-next-line react/display-name
const ReCaptcha: FC<ReCaptchaProps> = forwardRef((props, ref) => {
  const reCaptchaKey = '6Ld8dBMqAAAAAN4yFlnTb47pb3BgcLf04jKPNlk-';
  const liRef = useRef<ReCAPTCHA>();

  useImperativeHandle(ref, () => ({
    reset: liRef.current.reset.bind(liRef.current),
  }));

  return (
    <div className={cn('recaptcha', props.class)}>
      <ReCAPTCHA
        sitekey={reCaptchaKey}
        ref={liRef}
        theme="dark"
        onChange={(token) => {
          props.callback(true, token);
        }}
        onExpired={() => {
          props.callback(false);
        }}
        onErrored={() => {
          props.callback(false);
        }}
      />
    </div>
  );
});

export default ReCaptcha;
