import { type FC } from 'react';
import styles from './ErrorBoundry.module.css';
interface IProps {
  error: Error;
}

export const CommonError: FC<IProps> = ({ error }) => {
  return (
    <div className={styles.errorWrapper}>
      <div className={styles.errorContent}>
        <h1>Oops. Something went wrong =(</h1>
        <p>Seems like an error occured!</p>
        <p>{error.message}</p>
      </div>
    </div>
  );
};
