import { useEffect, useState, type FC } from 'react';
import './DatePicker.scss';
import { ChartTime } from '../../../../../../enums/History';

interface IProps {
  period: ChartTime;
  value: number;
  setValue: (value: number) => void;
}
let currentValueDate1: string | null = null;

export const DatePicker: FC<IProps> = (props) => {
  if (props.period === ChartTime.YEAR) return;
  const [value, setValue] = useState<string | null>('');
  useEffect(() => {
    switch (props.period) {
      case ChartTime.DAY: {
        const startDate = new Date(props.value);
        const start = new Intl.DateTimeFormat('en-US', {
          month: 'long',
          year: 'numeric',
          timeZone: 'UTC',
        }).format(startDate);

        setValue(`${start}`);
        currentValueDate1 = `${start}`;
        break;
      }
      case ChartTime.MONTH: {
        const startDate = new Date(props.value);
        const startYear = startDate.getFullYear();

        const currentYear = startYear.toString();

        const previousYear = (startYear - 1).toString();

        setValue(`${previousYear} - ${currentYear}`);
        currentValueDate1 = `${previousYear} - ${currentYear}`;
        break;
      }
    }
  }, [props.value, props.period]);

  const currentValueDate = new Date(props.value);
  const currentDate = new Date(Date.now());
  const isNextMonthUnAvailable =
    currentValueDate.getMonth() === currentDate.getMonth() &&
    currentValueDate.getFullYear() === currentDate.getFullYear();
  const next = (): void => {
    switch (props.period) {
      case ChartTime.DAY:
        currentValueDate.setMonth(currentValueDate.getMonth() + 1);
        break;
      case ChartTime.MONTH:
        currentValueDate.setFullYear(currentValueDate.getFullYear() + 2);
        break;
    }

    if (!isNextMonthUnAvailable) {
      props.setValue(currentValueDate.getTime());
    }
  };

  const prev = (): void => {
    const currentDate = new Date(props.value);

    switch (props.period) {
      case ChartTime.DAY:
        currentDate.setMonth(currentDate.getMonth() - 1);
        break;
      case ChartTime.MONTH:
        currentDate.setFullYear(currentDate.getFullYear() - 2);
        break;
    }
    props.setValue(currentDate.getTime());
  };

  return (
    <div className="date-picker">
      <button className="date-picker__left" onClick={prev}></button>
      <div className="date-picker__content" key={currentValueDate1 + value}>
        {currentValueDate1}
      </div>
      <button
        className={`date-picker__right ${isNextMonthUnAvailable && 'date-picker__disabled'}`}
        onClick={next}
      ></button>
    </div>
  );
};
