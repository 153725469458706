import { useState } from 'react';

import BinanceIcon from 'assets/images/MenuPage/BinanceIcon.svg';
import WhiteBitIcon from 'assets/images/MenuPage/WhiteBitIcon.svg';
import CoinBase from 'assets/images/MenuPage/CoinBaseIcon.svg';
import UnVisibleIcon from 'assets/images/Profile/UnVisibleIcon.svg';
import VisibleIcon from 'assets/images/Profile/VisibleIcon.svg';
export const ApiSettings = (): JSX.Element => {
  const [accounts, setAccounts] = useState([
    {
      id: 0,
      name: 'Main account',
      view: false,
    },
    {
      id: 1,
      name: 'Secondary account',
      view: false,
    },
    {
      id: 2,
      name: 'Third account',
      view: false,
    },
    {
      id: 3,
      name: 'Fourth account',
      view: false,
    },
    {
      id: 4,
      name: 'Fifth account',
      view: false,
    },
    {
      id: 5,
      name: 'Sixth account',
      view: false,
    },
  ]);
  return (
    <div className="propertyMainContainer">
      <div className="propertyContainer">EXCHANGES:</div>
      <div className="popUp__platformContainer">
        <div className={'popUp__platform popUp__platform__selected'}>
          <img src={BinanceIcon} alt="BinanceIcon" />
          Binance
        </div>
        <div className={'popUp__platform popUp__platform__disabled'}>
          <img src={WhiteBitIcon} alt="WhiteBitIcon" />
          WhiteBit
        </div>
        <div className={'popUp__platform popUp__platform__disabled'}>
          <img src={CoinBase} alt="CoinBase" />
          CoinBase
        </div>
      </div>
      <div className="propertyContainer">ACCOUNTS:</div>
      <div className="publishedStrategiesContainer_keysSettingsPopUpContainer_content_list_header ">
        <div style={{ flex: 1 }}>№</div>
        <div style={{ flex: 3 }}>Name</div>
        <div style={{ flex: 21 }}>Status view:</div>
      </div>
      {accounts.map((account) => (
        <div
          key={account.id}
          className="publishedStrategiesContainer_keysSettingsPopUpContainer_content_list_item"
        >
          <div className="publishedStrategiesContainer_keysSettingsPopUpContainer_content_list_item_name">
            <div>{account.id + 1}</div>
            <div> {account.name}</div>
          </div>

          <div
            style={!account.view ? { backgroundColor: '#A700F6' } : null}
            onClick={() => {
              setAccounts(
                accounts.map((acc) => (acc.id === account.id ? { ...acc, view: !acc.view } : acc)),
              );
            }}
            className="publishedStrategiesContainer_keysSettingsPopUpContainer_content_list_item_view"
          >
            <img src={account.view ? VisibleIcon : UnVisibleIcon} alt="" />
            {account.view ? 'View' : 'Hide'}
          </div>
        </div>
      ))}
    </div>
  );
};
