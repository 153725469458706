import type { FC, MutableRefObject, ReactNode } from 'react';
import { useId, useState } from 'react';
import cn from 'classnames';

import './GCheckbox.scss';

interface IGCheckboxProps {
  children: ReactNode;
  reference?: MutableRefObject<HTMLInputElement>;
  name: string;
  onToggle?: (state: boolean) => void;
}

const GCheckbox: FC<IGCheckboxProps> = (props) => {
  const id = useId();

  const [checked, setChecked] = useState<boolean>(false);

  return (
    <div className="g-checkbox">
      <input
        ref={props.reference}
        id={id}
        name={props.name}
        type="checkbox"
        onChange={(event) => {
          if (props.onToggle) {
            props.onToggle(event.target.checked);
          }

          setChecked(event.target.checked);
        }}
      />

      <label htmlFor={id} className="g-checkbox__label">
        <div className={cn('g-checkbox-view', checked && 'g-checkbox-view_checked')}>
          <div className="g-checkbox-view__check" />
        </div>

        {props.children}
      </label>
    </div>
  );
};

export default GCheckbox;
