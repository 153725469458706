import { type FC, useEffect, useState } from 'react';
import SaveIcon from 'assets/images/Profile/SaveIcon.svg';
import EditIcon from 'assets/images/Profile/EditProfileIcon.svg';

interface IProps {
  value: string;
  setValue: (value: string) => void;
  disabled: boolean;
}
const AdditionalInfoTextArea: FC<IProps> = ({ value, setValue, disabled }) => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isSaveAvailable, setIsSaveAvailable] = useState(false);
  const [data, setData] = useState(value);

  useEffect(() => {
    setData(value);
  }, [value]);

  return (
    <div
      tabIndex={0}
      className="mainConatiner_content_info_additionalInfo"
      onMouseEnter={() => {
        if (!disabled) {
          setIsButtonVisible(true);
        }
      }}
      onMouseLeave={() => {
        setIsButtonVisible(false);
      }}
      onBlur={() => {
        setIsSaveAvailable(false);
        console.log(data, value);
        if (data !== value) {
          setValue(data);
        }
      }}
    >
      <textarea
        value={data}
        onClick={() => {
          if (!disabled) {
            setIsSaveAvailable(true);
          }
        }}
        onInput={(e) => {
          setData(e.currentTarget.value);
        }}
        disabled={disabled}
      >
        {data}
      </textarea>
      {isButtonVisible &&
        (isSaveAvailable ? (
          <button
            className="mainConatiner_content_info_additionalInfo_saveButton"
            onClick={() => {
              setIsSaveAvailable(false);
            }}
          >
            <img src={SaveIcon} alt="save icon" /> Save
          </button>
        ) : (
          <button className="mainConatiner_content_info_additionalInfo_editButton">
            <img src={EditIcon} alt="edit icon" />
            Edit{' '}
          </button>
        ))}
    </div>
  );
};

export default AdditionalInfoTextArea;
