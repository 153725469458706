import { type FC } from 'react';
import CustomDropDown from '../CustomElements/MultiLineDropDown/CustomMultiLineDropDown';
import './StrategySelector.scss';
import Settings from 'assets/images/strategy-settings.svg';

interface IProps {
  options: {
    id: unknown;
    name: string;
    desc?: string;
  }[];
  activeOption: number;
  onChange: (id: number) => void;
  openSettings?: () => void;
  width?: string;
  height?: string;
  border?: string;
  background?: string;
}

export const StrategySelector: FC<IProps> = (props) => {
  if (props.activeOption === undefined) return;
  return (
    <div
      className="strategy-selector"
      style={{
        height: props.height ? `${props.height}` : '',
        width: props.width ? `${props.width}` : '',
      }}
    >
      <CustomDropDown
        options={props.options}
        onChange={props.onChange}
        activeOption={props.options.find((e) => e.id === props.activeOption)}
      />
      {props.openSettings && (
        <button onClick={props.openSettings} className="strategy-selector__settings">
          <img src={Settings} alt="S" />
        </button>
      )}
    </div>
  );
};
