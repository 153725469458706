import { combineReducers } from 'redux';
import chartCandleSettings from '../chartCandleSettings/reducer';
import chartGridSettings from '../chartGridSettings/reducer';
import chartXAxisSettings from '../chartXAxisSettings/reducer';
import chartYAxisSettings from '../chartYAxisSettings/reducer';
import chartCrosshairSettings from '../chartCrosshairSettings/reducer';

const rootChartStyleReducer = combineReducers({
  candle: chartCandleSettings,
  grid: chartGridSettings,
  xAxis: chartXAxisSettings,
  yAxis: chartYAxisSettings,
  crosshair: chartCrosshairSettings,
});

export default rootChartStyleReducer;
