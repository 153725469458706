import { type Dispatch, type FC, type SetStateAction, useState } from 'react';
import { useSelector } from 'redux/rootReducer';
import { selectTheme } from 'redux/theme/reducer';
// import {
//   deleteSavedtrategy,
//   removeSelectedStrategy,
//   setMergedStrategies,
//   setSelectedStrategyId as reduxSetSelectedStrategyId,
//   setStrategies,
// } from 'redux/strategy/reducer';
// import { type IStrategyAllResponse } from 'services/api/Strategy/types/StrategyResponse';
// import { apiStrategy } from 'services/api/Strategy/ApiStrategy';

import StrategyEditorHeader from './components/StrategyEditorHeader/StrategyEditorHeader';
import { type Components } from './Components';
import MergedStrategy from './components/StrategyCreateMergedStrategy/MergedStrategy';
import { LoaderOverlay } from 'components/LoaderOverlay';
import './StrategyEditor.scss';
import useWindowWidth from 'hooks/useWindowWidth';
interface IProps {
  strategyEnabled: boolean;
  setStrategyEnabled: Dispatch<SetStateAction<boolean>>;
  collapseConstructor?: () => void;
}

const PreviewMergeStrategyEditor: FC<IProps> = ({
  strategyEnabled,
  setStrategyEnabled,
  collapseConstructor,
}) => {
  const [strategyNode] = useState<Components | null>(null);
  const isChartLoading = useSelector((state) => state.chart.isLoading);

  const theme = useSelector(selectTheme);

  const isMobileVersion = useWindowWidth() < 768;

  return (
    <>
      <div style={{ backgroundColor: theme.historyBackground }} className="strategy-editor">
        <LoaderOverlay
          isShow={isChartLoading}
          position="absolute"
          imageWidth={isMobileVersion ? 100 : 0}
        />
        <StrategyEditorHeader
          title={'Merge'}
          strategyEnabled={strategyEnabled}
          isPreview={true}
          setStrategyEnabled={setStrategyEnabled}
          collapseConstructor={collapseConstructor}
        />

        <div
          style={{
            justifyContent: strategyNode ? ' ' : '',
            backgroundColor: theme.historyBackground,
          }}
          className="strategy-editor__main"
        >
          <MergedStrategy isPreview />
        </div>
      </div>
    </>
  );
};

export default PreviewMergeStrategyEditor;
