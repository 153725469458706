import { type FC } from 'react';
import CustomDropDownXL from '../CustomElements/MultiLineDropDownXL/CustomMultiLineDropDownXL';
import Delete from 'assets/images/Strategy/DeleteIcon.svg';
import './StrategySelectorSettings.scss';

interface IProps {
  options: {
    id: number;
    name: string;
    desc?: string;
  }[];
  activeOption: number;
  onChange: (id: number) => void;
  openSettingsHandler?: () => void;
  deleteOption?: () => void;
  width?: string;
  height?: number;
  border?: string;
  background?: string;
  show?: boolean;
}

export const StrategySelectorXLSettings: FC<IProps> = (props) => {
  return (
    <div
      className="strategy-selectorXL-settings"
      style={{
        height: props.height && `${props.height}px`,
        maxHeight: props.height && `${props.height}px`,
      }}
    >
      <div
        className="strategy-selectorXL-settings__select"
        style={props.width ? { width: props.width } : null}
      >
        <CustomDropDownXL
          options={props.options ?? []}
          onChange={props.onChange}
          height={props.height}
          activeOption={
            props.options.find((item) => item.id === props.activeOption) ?? props.options[0]
          }
        />
      </div>
      <button
        onClick={props.openSettingsHandler}
        className="strategy-selectorXL-settings__settings"
      ></button>
      {props.deleteOption && (
        <button onClick={props.deleteOption} className="strategy-selectorXL-settings__delete">
          <img src={Delete} alt="S" />
        </button>
      )}
    </div>
  );
};
