import type { Dispatch, FC, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { getNewChartData, setCharts } from 'redux/chart/reducer';
import { useNavigate } from 'react-router';
import { strategyManagerOff } from 'redux/strategy/reducer';
import GTumbler from '../../../../../../components/GTumbler/GTumbler';
import './strategyEditorHeader.scss';
import CloseSection from 'assets/images/Strategy/CloseSectionIcon.svg';
import useWindowWidth from 'hooks/useWindowWidth';

interface IStrategyEditorHeaderProps {
  title: string;
  strategyEnabled: boolean;
  setStrategyEnabled: Dispatch<SetStateAction<boolean>>;
  collapseConstructor: () => void;
}

const StrategyEditorHeader: FC<IStrategyEditorHeaderProps> = (props) => {
  const isMobileVersion = useWindowWidth() < 768;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="strategy-editor-header">
      <span className="strategy-editor-header__title">{props.title}</span>

      <div className="strategy-editor-header-controls">
        <GTumbler
          name="strategyTumbler"
          size={isMobileVersion ? 'large' : null}
          checked={props.strategyEnabled}
          onToggle={(state) => {
            if (!state) {
              dispatch(strategyManagerOff());
              dispatch(setCharts([getNewChartData({ id: '0' })]));
              navigate('/');
            }
            props.setStrategyEnabled(state);
          }}
        />
        {!isMobileVersion && (
          <img
            src={CloseSection}
            className="strategy-editor-header-controls-close"
            onClick={props.collapseConstructor}
            alt="close"
          />
        )}
      </div>
    </div>
  );
};

export default StrategyEditorHeader;
