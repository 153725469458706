import { createSlice } from '@reduxjs/toolkit';
import { type TradesSettingsState } from '../../pages/HomePage/components/ChartStyleSettings/Interfaces';
import { type IRootState } from '../rootReducer';
import { getUpdatePath } from '../updateProperty';
const initialState: TradesSettingsState = {
  tp_levels: {
    color: 'rgb(68,183,7)',
    thickness: 1,
  },
  sl_levels: {
    color: 'rgb(183,7,7)',
    thickness: 1,
  },
  trailing_sl_levels: {
    color: 'rgb(251, 9, 140)',
    thickness: 1,
  },
  entry_levels: {
    color: '#323232',
    thickness: 1,
  },
  liquidation_levels: {
    color: '#d501f8',
    thickness: 1,
  },
  dca_levels: {
    color: '#FF8A00',
    thickness: 1,
  },
  trades_background: {
    profit_area: {
      long: {
        color: 'rgba(51, 242, 70, 0.1)',
        gradientData: {
          isGradient: false,
          gradientType: null,
          degrees: null,
          colors: [
            {
              value: 'rgba(51, 242, 70, 0.1)',
            },
          ],
        },
      },
      short: {
        color: 'rgba(51, 242, 70, 0.1)',
        gradientData: {
          isGradient: false,
          gradientType: null,
          degrees: null,
          colors: [
            {
              value: 'rgba(51, 242, 70, 0.1)',
            },
          ],
        },
      },
    },
    loss_area: {
      long: {
        color: 'rgba(242, 16, 30, 0.1)',
        gradientData: {
          isGradient: false,
          gradientType: null,
          degrees: null,
          colors: [
            {
              value: 'rgba(242, 16, 30, 0.1)',
            },
          ],
        },
      },
      short: {
        color: 'rgba(242, 16, 30, 0.1)',
        gradientData: {
          isGradient: false,
          gradientType: null,
          degrees: null,
          colors: [
            {
              value: 'rgba(242, 16, 30, 0.1)',
            },
          ],
        },
      },
    },
    dca_area: {
      long: {
        color: 'rgba(242, 189, 51, 0.1)',
        gradientData: {
          isGradient: false,
          gradientType: null,
          degrees: null,
          colors: [
            {
              value: 'rgba(242, 189, 51, 0.1)',
            },
          ],
        },
      },
      short: {
        color: 'rgba(242, 189, 51, 0.1)',
        gradientData: {
          isGradient: false,
          gradientType: null,
          degrees: null,
          colors: [
            {
              value: 'rgba(242, 189, 51, 0.1)',
            },
          ],
        },
      },
    },
  },
  side_bar_marks: {
    entry_point: {
      color: 'rgba(92,254,0,0.35)',
      size: 1,
    },
    closing_orders: {
      color: 'rgba(92,254,0,0.35)',
      size: 2,
    },
    averaging_orders: {
      color: 'rgba(92,254,0,0.35)',
      size: 3,
    },
  },
  under_above_marks: {
    entry_point: {
      color: 'rgba(92,254,0,0.35)',
      size: 1,
    },
    closing_orders: {
      color: 'rgba(92,254,0,0.35)',
      size: 2,
    },
    averaging_orders: {
      color: 'rgba(92,254,0,0.35)',
      size: 3,
    },
  },
  caption_text: {
    fontFamily: 'Michroma',
    open_long: {
      size: {
        id: 14,
        name: '14',
      },
      color: 'white',
    },
    open_short: {
      size: {
        id: 14,
        name: '14',
      },
      color: 'white',
    },
    tp_levels: {
      size: {
        id: 14,
        name: '14',
      },
      color: 'white',
    },
    sl_levels: {
      size: {
        id: 14,
        name: '14',
      },
      color: 'white',
    },
    trailing_sl_levels: {
      size: {
        id: 14,
        name: '14',
      },
      color: 'white',
    },
    dca_levels: {
      size: {
        id: 14,
        name: '14',
      },
      color: 'white',
    },
    liquidation_levels: {
      size: {
        id: 14,
        name: '14',
      },
      color: 'white',
    },
  },
  trade_size_caption: {
    positive_quantity: {
      size: {
        id: 12,
        name: '12',
      },
      color: 'white',
    },
    negative_quantity: {
      size: {
        id: 12,
        name: '12',
      },
      color: 'white',
    },
  },
  level_lines_caption: {
    tp: {
      size: {
        id: 8,
        name: '8',
      },
      color: 'white',
    },
    sl: {
      size: {
        id: 8,
        name: '8',
      },
      color: 'white',
    },
    trailing_sl: {
      size: {
        id: 8,
        name: '8',
      },
      color: 'white',
    },
    averages: {
      size: {
        id: 8,
        name: '8',
      },
      color: 'white',
    },
    liquidation: {
      size: {
        id: 8,
        name: '8',
      },
      color: 'white',
    },
  },
  tradeStatisticsVisible: true,
};

const chartTradeSettingsReducer = createSlice({
  name: 'chartTradeSettings',
  initialState,
  reducers: {
    updateTradeSettingsProperty: getUpdatePath<TradesSettingsState>(),
    setInititalTradeSettings: (state, action) => {
      return action.payload;
    },
    refreshTradeSettings: () => {
      return initialState;
    },
  },
});
export const selectTradeChartSettings = (state: IRootState): TradesSettingsState => {
  return state.chartTradeSettings;
};

export const { updateTradeSettingsProperty, refreshTradeSettings, setInititalTradeSettings } =
  chartTradeSettingsReducer.actions;

export default chartTradeSettingsReducer.reducer;
