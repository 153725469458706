import { type IDefaultTicker } from '../../services/api/Candles/types';
import { type Dispatch, type SetStateAction } from 'react';
import { type ICustomTicker } from '../../redux/customTicker/types';

export const MODE_SEARCHING_TICKET = 'searching';
export const MODE_LIST_OF_INDEX_TICKERS = 'indexes';
export const MODE_CREATING_INDEX_STEP_ONE = 'creating';
export const MODE_SETTING_INDEX_STEP_TWO = 'setting';

export type TickerSelectorPageType =
  | typeof MODE_SEARCHING_TICKET
  | typeof MODE_LIST_OF_INDEX_TICKERS
  | typeof MODE_CREATING_INDEX_STEP_ONE
  | typeof MODE_SETTING_INDEX_STEP_TWO;

export interface ICustomTickerCreationProps {
  setTickerSelectorState: (page: TickerSelectorPageType) => void;
  onClose: () => void;
  tickers: IDefaultTicker[];
  selectedTickersPercentsArray: [number][];
  setSelectedTickersPercentsArray: Dispatch<SetStateAction<[number][]>>;
  selectedIndexTicker: IDefaultTicker[];
  setSelectedIndexTicker: Dispatch<SetStateAction<IDefaultTicker[]>>;
  setCreatedTicker: Dispatch<SetStateAction<Omit<ICustomTicker, 'id'>>>;
  createdTicker: Omit<ICustomTicker, 'id'>;
  setHeaderName: Dispatch<SetStateAction<string>>;
  headerName: string;
}
