import { type ColorDataSettings } from './../ChartStyleSettings/Interfaces/index';
import { type Axis } from '@basttyy/klinecharts';
export const drawText = (
  ctx: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  textAlign: CanvasTextAlign = 'right',
  color = 'black',
  size = 10,
  fontWeight = 'normal',
  fontFamily?: string,
): void => {
  ctx.fillStyle = color;
  ctx.textAlign = textAlign;
  ctx.font = `${fontWeight} ${size}px ${fontFamily || 'Michroma'}, sans-serif`;
  ctx.fillText(text, x, y);
};

export const drawPriceLine = (
  ctx: CanvasRenderingContext2D,
  yAxis: Axis,
  price: number,
  color: string,
  startX: number,
  endX: number,
  prefix: string,
  precision = 3,
  isHovered = false,
  lineThickness: number,
  amt?: number,
  textColor?: string,
  fontSize?: number,
): void => {
  const y = yAxis.convertToPixel(price);
  ctx.fillStyle = color;
  ctx.fillRect(startX, y, endX - startX, lineThickness || 1);

  if (isHovered) {
    drawText(
      ctx,
      `${prefix}: ${price.toFixed(precision)}`,
      endX + 5,
      y,
      'left',
      textColor || 'white',
      fontSize || 7.5,
    );
    if (amt !== undefined && !prefix.startsWith('DCA')) {
      drawText(ctx, `${amt.toFixed(2)}$`, startX - 5, y, 'right', '#E10000', 7.5, '500');
    }
  }
};

export const drawBackground = (
  ctx: CanvasRenderingContext2D,
  startX: number,
  endX: number,
  color: string | ColorDataSettings,
  y: number,
  h: number,
): void => {
  if (typeof color !== 'string' && color.gradientData.isGradient) {
    const gradient = ctx.createLinearGradient(startX, y, startX, y + h);
    for (const { value, left } of color.gradientData.colors) {
      gradient.addColorStop(Number(left / 100), value);
    }
    ctx.beginPath();
    ctx.fillStyle = gradient;
    ctx.fillRect(startX, y, endX - startX, h);
    ctx.closePath();
  } else {
    ctx.beginPath();
    ctx.fillStyle = typeof color !== 'string' ? color.color : color;
    ctx.fillRect(startX, y, endX - startX, h);
    ctx.closePath();
  }
};

export const drawDynamicLine = (
  ctx: CanvasRenderingContext2D,
  xAxis: Axis,
  yAxis: Axis,
  prices: [time: number, price: number][],
  color: string,
  prefix: string,
  startX: number,
  endX: number,
  precision = 3,
  isHovered: boolean,
  lineThickness?: number,
  textColor?: string,
  fontSize?: number,
): void => {
  let lastX = startX;
  let lastY = yAxis.convertToPixel(prices[0][1]);

  ctx.beginPath();
  ctx.setLineDash([]);
  ctx.moveTo(lastX, lastY);

  const arr = prices.slice(1);
  for (const [time, price] of arr) {
    const y = yAxis.convertToPixel(price);
    let x = xAxis.convertToPixel(time);
    if (!x) x = endX;

    ctx.lineTo(x, lastY);
    ctx.lineTo(x, y);

    lastY = y;
    lastX = x;
  }

  ctx.lineTo(endX, lastY);
  ctx.lineWidth = lineThickness || 1;
  ctx.strokeStyle = color;
  ctx.stroke();
  ctx.closePath();

  if (isHovered) {
    drawText(
      ctx,
      `${prefix}: ${prices[prices.length - 1][1].toFixed(precision)}`,
      endX + 5,
      lastY,
      'left',
      textColor || 'white',
      fontSize || 7.5,
    );
  }
};
