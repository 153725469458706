import { useState, type FC } from 'react';
import { type IndicatorsList, type ISettingsIndicator } from 'interfaces/IStrategy';
import {
  STRATEGY_SETTINGS,
  STRATEGY_SETTINGS_CONFIG,
  type StrategySettingsValuesConfig,
  STRATEGY_VALUES,
} from 'configs/strategy';
import GTumbler from 'components/GTumbler/GTumbler';
import { StrategySettingsHeader } from '../StrategySettingsHeader/StrategySettingsHeader';
import CustomDropDown from '../CustomElements/MultiLineDropDown/CustomMultiLineDropDown';
import CustomNumberInput from '../CustomElements/CustomInput/CustomInput';
import CustomDropDownXL from '../CustomElements/MultiLineDropDownXL/CustomMultiLineDropDownXL';
import './StrategySettings.scss';

interface IProps {
  indicator: {
    name: IndicatorsList;
    settings: ISettingsIndicator;
  };
  onClose: () => void;
  changeCond: (value: IndicatorsList) => void;
  onChange: (value: ISettingsIndicator, name: number, strategyType?: 'long' | 'short') => void;
  isStrategySettings?: [number, number];
  indicators: any;
  strategyType?: 'long' | 'short';
}

const getSettingsName = (str: string): string => {
  if (str === 'ma_percent') return 'n%';
  if (str === 'ma_type') return 'Moving average';
  if (str === 'center_line') return 'RSI centerline';
  return str
    .split('_')
    .map((e) => `${e[0].toUpperCase()}${e.slice(1).toLowerCase()}`)
    .join(' ');
};

const SettingChanger: FC<{
  name: string;
  config: StrategySettingsValuesConfig[keyof StrategySettingsValuesConfig];
  value: number | boolean;
  onChange: (value: number | boolean) => void;
}> = (props) => {
  if (!props.config) {
    return (
      <GTumbler
        name={props.name}
        size="large"
        onToggle={props.onChange}
        checked={(props.value as boolean) ?? false}
      />
    );
  }

  if (props.config.length === 2) {
    const config = props.config as [number, number];
    return (
      <CustomNumberInput
        onChange={(e) => {
          props.onChange(parseFloat(e.target.value));
        }}
        step={1}
        value={props.value as number}
        min={config[0]}
        max={config[1]}
      />
    );
  }
  const selectedOption = (([name = '', id = 0]) => ({ id, name }))(
    (props.config as [string, number][]).find(([_, value]) => value === props.value) || [],
  );

  return (
    <div style={{ width: '100px' }}>
      <CustomDropDown
        onChange={props.onChange}
        activeOption={{
          id: selectedOption.id,
          name: selectedOption.name,
        }}
        options={(props.config as [string, number][]).map(([name, id]) => ({
          id,
          name,
        }))}
        nameFontWeight="500"
      />
    </div>
  );
};

export const StrategySettings: FC<IProps> = (props) => {
  const [indicatorName, setIndicatorName] = useState(props.indicator.name);
  const [indicatorSettings] = useState(props.indicator.settings);
  const [currentStrategySettings, setCurrentStrategySettings] = useState(props.indicator.settings);
  const settings = Object.keys(STRATEGY_SETTINGS[indicatorName] ?? {});
  let condInfo: (typeof STRATEGY_SETTINGS_CONFIG)[0]['conditions'][number] | null = null;
  let groupInfo: (typeof STRATEGY_SETTINGS_CONFIG)[0] | null = null;

  for (const group of STRATEGY_SETTINGS_CONFIG) {
    for (const cond of group.conditions) {
      if (cond.id === indicatorName) {
        groupInfo = group;
        condInfo = cond;
        break;
      }
    }
  }

  if (groupInfo === null || condInfo === null) return;
  const activeOption = groupInfo.conditions.find((e) => e.id === indicatorName);
  const modifiedActiveOption = {
    ...activeOption,
    name: undefined,
    desc: activeOption?.name,
  };

  const modifiedOptions = groupInfo.conditions.map((e) => ({
    ...e,
    name: undefined,
    desc: e.name,
  }));

  const changeCond = (value: IndicatorsList): void => {
    const groupId = props.isStrategySettings?.[0];
    const condId = props.isStrategySettings?.[1];
    const indicators = props.indicators;
    const group = indicators[groupId];
    if (!group?.[condId]) return;
    if (value !== indicatorName) {
      setIndicatorName(value);
      setCurrentStrategySettings(STRATEGY_SETTINGS[value]);
    }
    if (value === props.indicator.name) {
      setCurrentStrategySettings(indicatorSettings);
    }
  };
  return (
    <div className="strategy-settings">
      <StrategySettingsHeader
        strategyType={props.strategyType}
        name={groupInfo.name}
        conditionName={`Condition #${props.isStrategySettings?.[0] + 1}`}
        onClose={props.onClose}
      />
      <CustomDropDownXL
        options={modifiedOptions}
        onChange={changeCond}
        activeOption={modifiedActiveOption}
      />
      <div className="strategy-settings__desc">
        <div className="strategy-settings__desc__elem">
          <h3>Long Trade:</h3>
          <h5>{condInfo.longDesc}</h5>
        </div>
        <div className="strategy-settings__desc__elem">
          <h3>Short Trade:</h3>
          <h5>{condInfo.shortDesc}</h5>
        </div>
      </div>
      <div className="strategy-settings__settings">
        {settings.map((e, i) => (
          <div className="strategy-settings__settings__elem" key={i}>
            <h4>{getSettingsName(e)}</h4>
            <SettingChanger
              onChange={(value) => {
                setCurrentStrategySettings({ ...currentStrategySettings, [e]: value });
              }}
              config={(() => {
                if (e === 'interval' && groupInfo.name === 'RSI') {
                  return [3, 255];
                }
                return STRATEGY_VALUES[e];
              })()}
              value={currentStrategySettings[e]}
              name={e}
            />
          </div>
        ))}
        <button
          style={{ width: '100%' }}
          className="strategy-type-settings__saveButton"
          onClick={() => {
            if (props.strategyType) {
              props.onChange(currentStrategySettings, indicatorName, props.strategyType);
            } else {
              props.onChange(currentStrategySettings, indicatorName);
            }
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};
