class WebSocketService {
  private readonly url = 'wss://beta.cr-algo.com/api/ws/connect';
  private subscriptions = new Set<string>();
  private readonly reconnectTimeout = 500;
  private ws: WebSocket;
  public active = false;
  private handlers = new Set<(...args: unknown[]) => void>();

  public open(): void {
    if (!localStorage.getItem('user')) return;
    this.active = true;
    this.ws = new WebSocket(this.url);
    this.ws.onopen = this.onOpen.bind(this);
    this.ws.onmessage = this.onMessage.bind(this);
    this.ws.onclose = this.onClose.bind(this);
  }

  public close(): void {
    this.active = false;
    this.ws?.close();
  }

  public subscribe(params: string[]): void {
    params.forEach((event, index) => {
      if (this.subscriptions.has(event)) {
        params.splice(index, 1);
      } else {
        this.subscriptions.add(event);
      }
    });
    if (params.length === 0 || this.ws?.readyState !== 1) return;
    this.ws?.send(
      JSON.stringify({
        method: 'sub',
        params,
      }),
    );
  }

  public on(handler: (...args: unknown[]) => void): () => void {
    this.handlers.add(handler);
    return () => this.handlers.delete(handler);
  }

  public createStrategyInterval(strategyId: string): () => void {
    console.log('createStrategyInterval', strategyId);
    if (!strategyId || !this.active) return () => {};
    const interval = setInterval(() => {
      this.ws.send(
        JSON.stringify({
          method: 'strategy_response',
          params: [strategyId],
        }),
      );
      console.log('interval send', strategyId);
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }

  public unsubscribe(params: string[]): void {
    params.forEach((event, index) => {
      if (!this.subscriptions.has(event)) {
        params.splice(index, 1);
      } else {
        this.subscriptions.delete(event);
      }
    });
    if (params.length === 0 || this.ws?.readyState !== 1) return;
    this.ws?.send(
      JSON.stringify({
        method: 'unsub',
        params,
      }),
    );
  }

  private onOpen(): void {
    console.log('ws opened');
    if (this.subscriptions.size > 0) {
      const params: string[] = [];

      this.subscriptions.forEach((e) => params.push(e));

      this.ws.send(
        JSON.stringify({
          method: 'sub',
          params,
        }),
      );
    }
  }

  private onMessage(data: MessageEvent): void {
    const msg = data.data?.toString();

    if (msg === 'pong') {
      this.ws.send('ping');
      return;
    }

    try {
      const data = JSON.parse(msg);

      this.handlers.forEach((e) => {
        e(data);
      });
    } catch (e) {}
  }

  private onClose(): void {
    if (!this.active) this.close();
    console.log('ws closed, reconnecting...', this.active);
    setTimeout(() => {
      if (!this.active) return;
      this.open();
    }, this.reconnectTimeout);
  }
}

export const webSocketService = new WebSocketService();
