import { useEffect, useRef, useState } from 'react';
import UserIcon from 'assets/images/default_avatar.png';
import RoundGTumbler from 'components/RoundGTumbler/RoundGTumbler';
import SaveIcon from 'assets/images/Profile/SaveIcon.svg';
import { useSelector } from '../../../../../../../redux/rootReducer';
import { apiProfile } from '../../../../../../../services/api/Profile/ApiProfile';
import { useDispatch } from 'react-redux';
import { updateProfileSocial, updateShowOnline } from '../../../../../../../redux/profile/reducer';
import { avatarUrl, minTimeForChangeLogin } from '../../../../../../../configs/user';
import { useNavigate, useParams } from 'react-router-dom';
import { apiAuth } from '../../../../../../../services/api/Auth/ApiAuth';
import { updateLogin } from '../../../../../../../redux/auth/reducer';

export const EditProfileSettings = (): JSX.Element => {
  const {
    socials: socialsData,
    // @ts-expect-error
    show_online: showOnline,
    id,
  } = useSelector((state) => state.profile.selectedProfile);
  const { id: profileName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [previewUrl, setPreviewUrl] = useState(avatarUrl + id);
  const [userName, setUserName] = useState(profileName);
  const [socials, setSocials] = useState(socialsData);
  const [lastChangeLogin, setLastChangeLogin] = useState(0);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setUserName(profileName);
    apiAuth.getLastChangeLogin().then((res) => {
      if (res) {
        setLastChangeLogin(res);
      }
    });
  }, [profileName]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file) {
      const maxFileSize = 2 * 1024 * 1024;
      if (file.size > maxFileSize) {
        event.target.value = '';
        return;
      }
      apiProfile.uploadAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
        console.log(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = (): void => {
    fileInputRef.current.click();
  };
  const isError = userName === '123' || userName.trim().length === 0;
  return (
    <div className="propertyMainContainer">
      <div className="propertyContainer">PROFILE PHOTO:</div>
      <div className="fotoContainer">
        <object className="fotoContainer_foto" data={previewUrl} type="image/png">
          <img className="fotoContainer_foto" src={UserIcon} alt="" />
        </object>
        <input type="file" ref={fileInputRef} onChange={handleFileChange} hidden />
        <div className="fotoContainer_buttons">
          <button onClick={handleButtonClick} className="fotoContainer_buttons_uploadFoto">
            Upload photo <span>(size should’t exceed 2 MB)</span>
          </button>
          <button
            onClick={() => {
              apiProfile.deleteAvatar().then((res) => {
                if (res) {
                  setPreviewUrl(UserIcon);
                }
              });
            }}
            className="fotoContainer_buttons_deleteFoto"
          >
            Delete photo
          </button>
        </div>
      </div>
      <div className="propertyContainer">INFORMATION:</div>
      <div className="propertyContainer_inputContainer">
        Username{' '}
        <input
          style={{
            border: isError ? '1px solid red' : '',
          }}
          value={userName}
          onChange={(event) => {
            setUserName(event.target.value);
          }}
          type="text"
          className="propertyContainer_inputContainer_input"
          placeholder="Username"
          disabled={Date.now() - lastChangeLogin < minTimeForChangeLogin}
        />
      </div>
      <div className="propertyContainer_title" style={{ color: userName === '123' && 'red' }}>
        {userName === '123'
          ? 'This username already exists, choose another'
          : 'You can change the public username only once'}
      </div>
      {Date.now() - lastChangeLogin >= minTimeForChangeLogin && (
        <button
          className={`propertyContainer_saveButton ${
            isError ? 'propertyContainer_saveButton_disabled' : ''
          }`}
          onClick={() => {
            apiAuth.changeLogin(userName).then((e) => {
              if (e) {
                dispatch(updateLogin(userName));
                navigate('/profile/' + userName);
              }
            });
          }}
        >
          {' '}
          <img src={SaveIcon} alt="save icon" /> Save
        </button>
      )}
      <div className="propertyContainer">SOCIAL MEDIA:</div>
      <div className="propertyContainer_socialMediaContainer">
        <div className="propertyContainer_inputContainer">
          Twitter
          <input
            type="text"
            className="propertyContainer_inputContainer_input"
            placeholder="@username"
            value={socials.twitter}
            onBlur={() => {
              if (socials.twitter !== socialsData.twitter) {
                apiProfile.changeSocial('twitter', socials.twitter).then((r) => {
                  if (r) {
                    dispatch(updateProfileSocial(['twitter', socials.twitter]));
                  }
                });
              }
            }}
            onInput={(e) => {
              setSocials({
                ...socials,
                twitter: e.currentTarget.value,
              });
            }}
          />
        </div>
        <div className="propertyContainer_inputContainer">
          Telegram
          <input
            type="text"
            className="propertyContainer_inputContainer_input"
            placeholder="@username"
            value={socials.telegram}
            onBlur={() => {
              if (socials.telegram !== socialsData.telegram) {
                apiProfile.changeSocial('telegram', socials.telegram).then((r) => {
                  if (r) {
                    dispatch(updateProfileSocial(['telegram', socials.telegram]));
                  }
                });
              }
            }}
            onInput={(e) => {
              setSocials({
                ...socials,
                telegram: e.currentTarget.value,
              });
            }}
          />
        </div>
        <div className="propertyContainer_inputContainer">
          Instagram
          <input
            type="text"
            className="propertyContainer_inputContainer_input"
            placeholder="@username"
            value={socials.instagram}
            onBlur={() => {
              if (socials.instagram !== socialsData.instagram) {
                apiProfile.changeSocial('instagram', socials.instagram).then((r) => {
                  if (r) {
                    dispatch(updateProfileSocial(['instagram', socials.instagram]));
                  }
                });
              }
            }}
            onInput={(e) => {
              setSocials({
                ...socials,
                instagram: e.currentTarget.value,
              });
            }}
          />
        </div>
      </div>
      <div className="propertyContainer">SOCIAL MEDIA:</div>
      <div className="propertyContainer_inputContainer">
        Show my online status{' '}
        <RoundGTumbler
          name="status"
          checked={showOnline}
          onToggle={(val) => {
            apiProfile.changeShowOnline(val).then((r) => {
              if (r) {
                dispatch(updateShowOnline(val));
              }
            });
          }}
        />
      </div>
    </div>
  );
};
