import { accountSlice } from './account/reducer';
import { configureStore } from '@reduxjs/toolkit';
import { type TypedUseSelectorHook, useSelector as useReduxSelector } from 'react-redux';
import historyReducer from './history/reducer';
import chartReducer from './chart/reducer';
import customTickersReducer from './customTicker/reducer';
import authReducer from './auth/reducer';
import strategyReducer from './strategy/reducer';
import themeReducer from './theme/reducer';
import chartSettings from './chartSettings/reducer';
import chartTradeSettings from './chartTradeSettings/reducer';
import chartCandleSettings from './chartCandleSettings/reducer';
import chartGridSettings from './chartGridSettings/reducer';
import chartXAxisSettings from './chartXAxisSettings/reducer';
import chartYAxisSettings from './chartYAxisSettings/reducer';
import chartCrosshairSettings from './chartCrosshairSettings/reducer';
import chartBackgroundSettings from './chartBackgroundSettings/reducer';
import profile from './profile/reducer';
import homePage from './homePage/reducer';

export const store = configureStore({
  reducer: {
    history: historyReducer,
    chart: chartReducer,
    chartSettings,
    chartCandleSettings,
    chartGridSettings,
    chartXAxisSettings,
    chartYAxisSettings,
    chartCrosshairSettings,
    chartTradeSettings,
    chartBackgroundSettings,
    customTickers: customTickersReducer,
    auth: authReducer,
    strategy: strategyReducer,
    theme: themeReducer,
    accountData: accountSlice.reducer,
    homePage,
    profile,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type IRootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<IRootState> = useReduxSelector;
// export const useChartSelector = (id: string): IChartDataState =>
//   useSelector((state) => getChartById(id));
