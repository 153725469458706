import { type Dispatch, type FC, type SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedStrategyId, updateSelectedStrategy } from 'redux/strategy/reducer';
import { Components } from '../../Components';
import { apiStrategy } from 'services/api/Strategy/ApiStrategy';
import './strategyEditorModes.scss';
import oneSidePreview from 'assets/images/Strategy/OneWayModePreview.png';
import hedgePreview from 'assets/images/Strategy/HedgeModePreview.png';
import ToggleIcon from 'assets/images/Strategy/ToggleIcon.svg';

interface IStrategyEditorModesProps {
  addStrategyHistory: (value: { _id: string; name: string; isMerged?: boolean }) => void;
  setStrategyNode: Dispatch<SetStateAction<Components>>;
}

const StrategyEditorModes: FC<IStrategyEditorModesProps> = ({
  addStrategyHistory,
  setStrategyNode,
}) => {
  const ONE_WAY_MODE = 'One way strategies';
  const HEDGE_MODE = 'Hedge strategies';
  const PAIRS_TRADING = 'Pairs trading';
  const [openedTypes, setOpenedTypes] = useState([]);
  const dispatch = useDispatch();
  return (
    <div
      className="strategy-modes"
      style={openedTypes.length !== 0 ? { background: 'rgba(68, 63, 94, 0.15)' } : {}}
    >
      <button
        className={
          'strategyTypeCreateButton ' +
          (openedTypes.includes(ONE_WAY_MODE) ? ' strategyTypeCreateButton__opened' : '')
        }
        style={{
          marginBottom: openedTypes.includes(ONE_WAY_MODE) && 0,
          borderRadius: openedTypes.includes(ONE_WAY_MODE) && '4px 4px 0 0',
          marginTop: 0,
        }}
        onClick={() => {
          if (!openedTypes.includes(ONE_WAY_MODE)) {
            setOpenedTypes([...openedTypes, ONE_WAY_MODE]);
          } else {
            setOpenedTypes(openedTypes.filter((type) => type !== ONE_WAY_MODE));
          }
        }}
      >
        One-way mode <img src={ToggleIcon} alt="ToggleIcon" />
      </button>
      {openedTypes.includes(ONE_WAY_MODE) && (
        <div className="strategy-modes-mode">
          <img
            className="strategy-modes-mode__preview"
            src={oneSidePreview}
            alt="One-Way mode preview"
          />

          <p className="strategy-modes-mode__description">
            You can only open long and short trades sequentially on the one chart. The type of
            strategy for all types of trades will be the same.
          </p>

          <div
            className="strategy-modes-mode__select"
            onClick={() => {
              (async () => {
                try {
                  const resp = await apiStrategy.addStrategy(0);

                  if (resp) {
                    addStrategyHistory({ _id: resp._id, name: resp.name, isMerged: false });
                    dispatch(setSelectedStrategyId(resp._id));
                    dispatch(updateSelectedStrategy(resp));
                    setStrategyNode(Components.CONSTRUCTOR);
                  }
                } catch (e) {
                  console.log(e);
                }
              })();
            }}
          >
            Select
          </div>
        </div>
      )}

      <button
        className={
          'strategyTypeCreateButton ' +
          (openedTypes.includes(HEDGE_MODE) ? ' strategyTypeCreateButton__opened' : '')
        }
        style={{
          marginBottom: openedTypes.includes(HEDGE_MODE) && 0,
          borderRadius: openedTypes.includes(HEDGE_MODE) && '4px 4px 0 0',
        }}
        onClick={() => {
          if (!openedTypes.includes(HEDGE_MODE)) {
            setOpenedTypes([...openedTypes, HEDGE_MODE]);
          } else {
            setOpenedTypes(openedTypes.filter((type) => type !== HEDGE_MODE));
          }
        }}
      >
        Hedge mode <img src={ToggleIcon} alt="ToggleIcon" />
      </button>

      {openedTypes.includes(HEDGE_MODE) && (
        <div className="strategy-modes-mode">
          <img
            className="strategy-modes-mode__preview"
            src={hedgePreview}
            alt="One-Way mode preview"
          />

          <p className="strategy-modes-mode__description">
            You can open and hold long and short trades at the same time. You will see two different
            charts, on each you can separately setup long and short strategies.
          </p>

          <div
            className="strategy-modes-mode__select"
            onClick={() => {
              (async () => {
                try {
                  const resp = await apiStrategy.addStrategy(1);

                  if (resp) {
                    addStrategyHistory({ _id: resp._id, name: resp.name, isMerged: false });
                    setSelectedStrategyId(resp._id);
                    dispatch(updateSelectedStrategy(resp));
                    setStrategyNode(Components.CONSTRUCTOR);
                  }
                } catch (e) {
                  console.log(e);
                }
              })();
            }}
          >
            {' '}
            Select
          </div>
        </div>
      )}

      <button
        className={
          'strategyTypeCreateButton ' +
          (openedTypes.includes(PAIRS_TRADING) ? ' strategyTypeCreateButton__opened' : '') +
          ' strategyTypeCreateButton__disabled'
        }
        style={{ marginBottom: 0 }}
        disabled={true}
        onClick={() => {
          if (!openedTypes.includes(PAIRS_TRADING)) {
            setOpenedTypes([...openedTypes, PAIRS_TRADING]);
          } else {
            setOpenedTypes(openedTypes.filter((type) => type !== PAIRS_TRADING));
          }
        }}
      >
        Pairs trading <img src={ToggleIcon} alt="ToggleIcon" />
      </button>
    </div>
  );
};

export default StrategyEditorModes;
