import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMobileContent } from 'redux/homePage/reducer';
import { setFullScreenMode } from 'redux/chart/reducer';
import styles from '../../HomePageStyles.module.css';
import ChartIcon from 'assets/images/Strategy/MobileContent/ChartIcon.svg';
import MetricsIcon from 'assets/images/Strategy/MobileContent/MetricsIcon.svg';
import TradesIcon from 'assets/images/Strategy/MobileContent/TradesIcon.svg';
import ConstructorIcon from 'assets/images/Strategy/MobileContent/ConstructorIcon.svg';
import HomeIcon from 'assets/images/Strategy/MobileContent/HomeIcon.svg';
import { useSelector } from '../../../../redux/rootReducer';
type Pages = 'chart' | 'home' | 'metrics' | 'constructor' | 'trades';

interface MobileContentSwitcherMenuProps {
  excludes?: Pages[];
  settingsVersion?: boolean;
}
export const MobileContentSwitcherMenu: React.FC<MobileContentSwitcherMenuProps> = ({
  excludes: excludesProp,
  settingsVersion,
}) => {
  const excludes = excludesProp ?? [];
  const { fullScreenMode, showStatistics, stats, allTrades } = useSelector((state) => state.chart);
  const [searchParams] = useSearchParams();
  const selectedStrategyId = useSelector((state) => state.strategy.selectedStrategyId);
  const contentStateValue = useSelector((state) => state.homePage.mobileContent);
  const dispatch = useDispatch();
  const showMetricsData =
    stats && (stats?.trades_count[0] !== 0 || stats?.trades_count[1] !== 0) && showStatistics;
  useEffect(() => {
    const strategyId = selectedStrategyId ?? searchParams.get('id');
    if (strategyId !== null) {
      dispatch(setMobileContent('chart'));
    }
  }, []);
  useEffect(() => {
    if (contentStateValue !== 'chart') {
      dispatch(setFullScreenMode(false));
    }
  }, [contentStateValue]);
  if (fullScreenMode) return;

  return (
    <div
      className={styles.mobileContentSwitcher}
      style={settingsVersion ? { position: 'absolute', bottom: 0 } : {}}
    >
      {!excludes.includes('home') && (
        <button
          className={`${styles.mobileContentSwitcherItem} ${
            contentStateValue === 'home' && styles.mobileContentSwitcherItemActive
          }`}
          onClick={() => {
            dispatch(setMobileContent('home'));
          }}
        >
          <img src={HomeIcon} alt="" />
          Home
        </button>
      )}
      <button
        className={`${styles.mobileContentSwitcherItem} ${
          contentStateValue === 'chart' && styles.mobileContentSwitcherItemActive
        }`}
        onClick={() => {
          dispatch(setMobileContent('chart'));
        }}
      >
        <img src={ChartIcon} alt="chart" />
        Chart
      </button>
      {!excludes.includes('constructor') && (
        <button
          className={`${styles.mobileContentSwitcherItem} ${
            contentStateValue === 'constructor' && styles.mobileContentSwitcherItemActive
          }`}
          onClick={() => {
            dispatch(setMobileContent('constructor'));
          }}
        >
          <img src={ConstructorIcon} alt="constructor" />
          Strategy manager
        </button>
      )}
      <button
        style={{ opacity: showMetricsData ? 1 : 0.5 }}
        className={`${styles.mobileContentSwitcherItem} ${
          contentStateValue === 'metrics' && styles.mobileContentSwitcherItemActive
        }   `}
        onClick={() => {
          showMetricsData && dispatch(setMobileContent('metrics'));
        }}
      >
        <img src={MetricsIcon} alt="" />
        Metrics
      </button>
      <button
        style={{ opacity: allTrades.length > 0 ? 1 : 0.5 }}
        className={`${styles.mobileContentSwitcherItem} ${
          contentStateValue === 'trades' && styles.mobileContentSwitcherItemActive
        }`}
        onClick={() => {
          allTrades.length > 0 && dispatch(setMobileContent('trades'));
        }}
      >
        <img src={TradesIcon} alt="" />
        Trades
      </button>
    </div>
  );
};
