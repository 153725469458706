import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { IProfilePage, State } from './types';
import { type IProfileSelf } from '../../services/api/Profile/types';
import { type IFollowing } from '../../pages/UserProfile/interfaces/interfaces';

const initialState: State = {
  page: 'profile',
  selectedProfile: null,
  followings: [],
};
const profileReducer = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfilePage: (state, action: PayloadAction<IProfilePage>) => {
      state.page = action.payload;
    },
    setSelectedProfile: (state, action: PayloadAction<State['selectedProfile']>) => {
      state.selectedProfile = action.payload;
    },
    updateShowOnline: (state, action: PayloadAction<boolean>) => {
      (state.selectedProfile as IProfileSelf).show_online = action.payload;
    },
    updateProfileSocial: (state, action: PayloadAction<[string, string]>) => {
      state.selectedProfile.socials[action.payload[0]] = action.payload[1];
    },
    setFollowings: (state, action: PayloadAction<IFollowing[]>) => {
      state.followings = action.payload;
    },
  },
});

export const {
  setProfilePage,
  setSelectedProfile,
  updateShowOnline,
  updateProfileSocial,
  setFollowings,
} = profileReducer.actions;

export default profileReducer.reducer;
