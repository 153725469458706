import { useEffect, useState, type FC } from 'react';
import { useSelector } from 'redux/rootReducer';
import SaveIcon from 'assets/images/Profile/SaveIcon.svg';
import EditIcon from 'assets/images/Profile/EditProfileIcon.svg';
import './StrategyName.scss';

interface IProps {
  onChange: (value: string) => void;
  name: string;
  isMergedStrategy: boolean;
  disabled?: boolean;
  onSave?: () => void;
}

export const StrategyName: FC<IProps> = ({
  onChange,
  name,
  isMergedStrategy,
  disabled,
  onSave,
}) => {
  const { selectedStrategyId, strategies, mergedStrategies } = useSelector(
    (state) => state.strategy,
  );
  const [strategyNamesPull, setStrategyNamesPull] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [isSaveAvailable, setIsSaveAvailable] = useState(false);
  useEffect(() => {
    if (isMergedStrategy) {
      setStrategyNamesPull(
        mergedStrategies
          .filter((e) => e._id !== selectedStrategyId)
          .map((strategy) => strategy.name),
      );
    } else {
      setStrategyNamesPull(
        strategies.filter((e) => e._id !== selectedStrategyId).map((strategy) => strategy.name),
      );
    }
  }, [strategies]);

  return (
    <>
      <div
        className={'strategy-name' + (isError ? ' strategy-name-error' : '')}
        onMouseEnter={() => {
          if (!disabled) {
            setIsButtonVisible(true);
          }
        }}
        onMouseLeave={() => {
          setIsButtonVisible(false);
        }}
      >
        <input
          type="text"
          placeholder="Name of strategy"
          onFocus={() => {
            setIsSaveAvailable(true);
          }}
          onBlur={() => {
            setIsSaveAvailable(false);
          }}
          onClick={() => {
            setIsSaveAvailable(true);
          }}
          maxLength={35}
          onChange={(e) => {
            const sameNames = strategyNamesPull.filter(
              (strategyName) =>
                strategyName.toLowerCase().trim() === e.target.value.toLowerCase().trim(),
            );
            if (sameNames.length > 0) {
              setIsError(true);
            } else {
              setIsError(e.target.value === '');
            }
            onChange(e.target.value);
          }}
          value={name}
        />
        <div
          className="strategy-name_buttonsContainer"
          onClick={() => {
            if (isError) return;
            setIsSaveAvailable(false);
            onSave();
          }}
        >
          {isButtonVisible &&
            (isSaveAvailable ? (
              <button
                style={{
                  cursor: isError ? 'not-allowed' : 'pointer',
                  opacity: isError ? '0.5' : '1',
                }}
                className="strategy-name_saveButton"
              >
                <img src={SaveIcon} alt="save icon" /> Save
              </button>
            ) : (
              <button className="strategy-name_editButton">
                <img src={EditIcon} alt="edit icon" />
                Edit{' '}
              </button>
            ))}
        </div>

        {isError && (
          <div className="strategy-name-error-message">
            {' '}
            {name === '' ? 'This field can not be empty' : 'Strategy with this name already exists'}
          </div>
        )}
      </div>
    </>
  );
};
